import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-post-gift-list',
  templateUrl: './post-gift-list.component.html',
  styleUrls: ['./post-gift-list.component.scss']
})
export class PostGiftListComponent implements OnInit {
  searchText: string  = '';
  subscription: Subscription = new Subscription();
  @Input() gifList: any = [];
  @Output() actionCTA = new EventEmitter();
  constructor(
  ) { }


  backToPost() {
    this.actionCTA.emit('')
  }


  addGif(item: any) {
    this.actionCTA.emit(item)
  }

  ngOnInit(): void {
  }

}
