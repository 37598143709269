<div class="flex flexJustifyBetween padright10">
    <div class="flex flex2">
        <div>
            <app-profile-thumbnail class="cursorPtr" [image]="post?.posted_by?.photo || post?.post?.posted_by?.photo" [isShowBorder]="true" [imageClass]="'d45'" (click)="profileNav(post?.posted_by?.user_type, post?.posted_by?.id)"></app-profile-thumbnail>
        </div>
        <div class="pad5"></div>
        <div class="flex1">
            <div class="font16 poppins-semibold timeline-label txtTransformCaps">
                <span class="cursorPtr" (click)="profileNav(post?.posted_by?.user_type, post?.posted_by?.id)">{{post?.posted_by?.name || post?.post?.posted_by?.name}}</span>
                <span class=" txtTransformLow color-8E8E8E font14 poppins-regular" *ngIf="post.post_type == 'ProfilePic' || post.post_type == 'CoverPhoto'">
                    updated  {{post.post_type == 'ProfilePic' ? 'profile picture.' : 'cover photo.'}}
                </span>
                <ng-container *ngIf="post?.post?.posted_to && post?.post?.posted_to?.id && ((post?.post?.posted_by?.id != post?.post?.posted_to?.id) || ((post?.post?.posted_by?.id == post?.post?.posted_to?.id) && post?.post?.posted_to?.user_type != 'User' && post?.post?.posted_to?.user_type != 'Organization'))|| post?.posted_to && post?.posted_to?.id && ((post?.posted_by?.id != post?.posted_to?.id) || ((post?.posted_by?.id == post?.posted_to?.id) && post?.posted_to?.user_type != 'User' && post?.posted_to?.user_type != 'Organization'))">
                    <i class="icns d15 icon-arrow-right black mrgnleft5 mrgnright5"></i> {{post?.post?.posted_to?.name || post?.posted_to?.name}}
                </ng-container>
                <ng-container *ngIf="post?.tags?.length>0">
                    <!-- <i class="icns d15 black mrgnright5"></i> -->
                    &nbsp;{{'with'|translate}}<span>&nbsp;{{post?.tags[0]?.user?.name}} </span><span *ngIf="post?.tags?.length>1">&nbsp;{{'and'|translate}} {{post?.tags?.length-1}} others</span>
                </ng-container>
            </div>
            <div class="color-8E8E8E font14 poppins-medium ">{{post?.created_at | dateAgo }}
            </div>
        </div>

    </div>
    <div class="links padbtm10 cursorPtr color-8E8E8E poppins-medium font14 posRelative profile-icon cursorPtr" *ngIf="authService.isLoggedIn() && post.post_type != 'VolunteerEvent'">
        <i class="icns icon-three-dots d20"></i>
        <div *ngIf=" (id == post?.post?.posted_by?.id || id == post?.posted_by?.id || (post.is_share && (id == post?.posted_on?.id || id == post?.post?.posted_on?.id)))" class="bdr-1-EEEEEE zIndex1 profile-menu posAbsolute posRight bg-white bdrRadius6px posTop12px">
            <div class="padleft10 padright10">
                <a class="flex flexJustifyCenter color-8E8E8E txtCenter poppins-medium font14 flexAlignItemsCenter links padtop10 padbtm10 ripple color-122A4D" (click)="remove(post?.post?.id || post?.id)">
                    {{'delete' | translate}}
                </a>
            </div>
            <div class="padleft10 padright10">
                <a class="flex flexJustifyCenter color-8E8E8E txtCenter poppins-medium font14 flexAlignItemsCenter links padtop10 padbtm10 ripple color-122A4D" (click)="edit(post)">
                    {{'edit' | translate}}
                </a>
            </div>
            <div class="padleft10 padright10" *ngIf="post?.posted_by?.id != id">
                <a class="flex flexJustifyCenter color-8E8E8E txtCenter poppins-medium font14 flexAlignItemsCenter links padtop10 padbtm10 ripple color-122A4D" (click)="report(post)">
                    {{'Report' | translate}}
                </a>
            </div>            
        </div>
    </div>
</div>