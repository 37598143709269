import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fullDate'
})
export class FullDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return (`${moment(value).format('ddd, DD MMM')} at ${moment(value).format('LT')}`);
    } else {
        value = '';
    }
    return value;
  }

}
