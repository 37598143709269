<app-simple-carousel [autoSlide]="'true'" [side]="side">
  <ng-container *ngFor="let ads of data">
      <ng-container *carouselItem>
        <div class="bdrRadius10px posRelative bdr-1-EEEEEE boxShadow-RGBA00005 posRelative width100 pad15 bg-F6F6F6 mrgnbtm10" [ngClass]="userType">
          <div class="flex ">
            <div class="width30">
              <img [src]="ads?.photo ? ads?.photo : 'assets/images/web-series.png'" class="bdrRadius10px width100">
            </div>
            <div class="pad5"></div>
            <div class="width70">
              <div class="color-122A4D font16 poppins-medium lineHeight16px maxWidth160px">{{ ads?.name }}</div>
              <div class="color-8E8E8E font14 poppins-regular mrgnbtm5">{{ ads?.website_link.substring(0, 12) }}
                <span *ngIf="(ads?.website_link?.length || 0) > 12">.</span>
              </div>
              <a href="http://{{ads?.website_link}}" class="color-122A4D font13 poppins-regular">{{'Click here for more details...' |
                translate}}</a>
            </div>
      
          </div>
          <div
            class="pad4 posRight5px posTop5px bdrRadius50p ripple cursorPtr d35 posAbsolute flexNoShrink flex flexAlignItemsCenter flexJustifyCenter">
            <i class="icns icon-three-dots d20"></i>
          </div>
        </div>
      </ng-container>
  </ng-container>
</app-simple-carousel>