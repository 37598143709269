import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { OrganizationService } from '../../../../../organizations/service/organization.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../core/services/auth-service/auth.service';
import { OrganizationStoreService } from '../../../../../organizations/store/organization-store.service';
import { Router } from '@angular/router';
import { AppService } from '../../../../../core/services/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-organization-membership-form-preview',
  templateUrl: './organization-membership-form-preview.component.html',
  styleUrls: ['./organization-membership-form-preview.component.scss']
})
export class OrganizationMembershipFormPreviewComponent implements OnChanges {
  @Input('popup') popup: any;
  @Output('close') close = new EventEmitter();
  moment = moment;
  @Input('formValue') formValue: any;
  form: FormGroup = new FormGroup({});
  @Input() isPreview: boolean = true;
  fields: any = [];
  date: Date = new Date();
  @Input() name: string = '';
  @Input('isShowSubmitButton') isShowSubmitButton: boolean = true;
  showError: string = '';
  imagesValue: any = {};
  public subscription: Subscription = new Subscription();
  userData: any;
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private authService: AuthService,
    private organizationStoreService: OrganizationStoreService,
    private router: Router,
    private appService: AppService
  ) {
    this.userData = JSON.parse(localStorage.profileData);
   }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   * 
   * @param { SimpleChanges } changes
   * @memberof OrganizationMembershipFormPreviewComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.popup && changes.popup.currentValue) {
      this.fields = this.popup.fields || (this.popup.field_settings ? this.popup.field_settings.fields : [])
      for (let index = 0; index < this.fields.length; index++) {
        switch (this.fields[index].field_type) {
          case 'email':
            this.form.addControl(`${this.fields[index].field_name.toLowerCase().split(" ").join("")}`,new FormControl('', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]))
            break;
          default:
            this.form.addControl(`${this.fields[index].field_name.toLowerCase().split(" ").join("")}`,new FormControl('', [Validators.required]))
            break;
        }
      }
      if (this.formValue) {
        for (let index = 0; index < this.fields.length; index++) {
          let value: any = '';
          // switch (this.fields[index].field_name.toLowerCase().split(" ").join("")) {
          //   case 'name':
          //     value = this.formValue.name || ''
          //     break;
          //   case 'dateofbirth':
          //     value = new Date(this.formValue.date_of_brith) || ''
          //     break;
          //   case 'address':
          //     value = this.formValue.address || ''
          //     break;
          //   case 'emailaddress':
          //     value = this.formValue.email_address || ''
          //     break;  
          //   default:
          //     break;
          // }
          if (this.fields[index]?.field_type == 'date') {
            value = new Date(this.formValue[this.fields[index].field_name.toLowerCase().split(" ").join("")])
          } else {
            value = this.formValue[this.fields[index].field_name.toLowerCase().split(" ").join("")];
          }
          this.form.patchValue({
            [this.fields[index].field_name.toLowerCase().split(" ").join("")]: value
          })
          
        }
      }
      
    }
  }


  /**
   * @description
   * this method is used for set form data
   *
   * @memberof OrganizationMembershipFormPreviewComponent
   */
  setFormData() {
    let formData = new FormData();
    formData.append('user',(this.authService.getSessionData().orgId).toString());
    formData.append('organization',(this.organizationStoreService.profileDetail.id).toString())
    //console.log(this.fields)
    for (let index = 0; index < this.fields.length; index++) {
      formData.append(`membership_submitted_data[${this.fields[index].field_name.toLowerCase().split(" ").join("")}]`, this.form.value[this.fields[index].field_name.toLowerCase().split(" ").join("")])
      // switch (this.fields[index].field_name.toLowerCase().split(" ").join("")) {
      //   case 'name':
      //     formData.append('membership_submitted_data[name]', this.form.value[index])
      //     break;
      //   case 'dateofbirth':
      //     formData.append('membership_submitted_data[date_of_birth]', this.form.value[index])
      //     break;
      //   case 'address':
      //     formData.append('membership_submitted_data[address]', this.form.value[index])
      //     break;
      //   case 'emailaddress':
      //     formData.append('membership_submitted_data[email_address]', this.form.value[index])
      //     break;   
      //   default:

      //     break;
      // }
    }
    return formData;
  }


  changeFiles(event: any, fieldName: any) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this, fieldName);
    reader.readAsDataURL(file);
    
  }

   handleReaderLoaded(fieldName: any, e: any ): void {
    console.log( e.target.result)
    this.imagesValue[fieldName] = e.target.result;
   
  }


  /**
   * @description
   * method is used for submiting the form
   *
   * @memberof OrganizationMembershipFormPreviewComponent
   */
  onSubmit() {
    let value =  {...{membership_submitted_data: {...this.form.value, ...this.imagesValue} }, ...{user: this.authService.getSessionData().orgId.toString(), organization: this.organizationStoreService.profileDetail.id.toString() } };
    console.log(value)
    this.subscription.add(
      this.organizationService.createMembership(value).subscribe(response => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-right',
          context: { description: `Membership form submitted successfully` }
        });
        this.router.navigate(['/']);
      }, err => {
        this.showError = err.error.message;
      })
    )
  }

}
