import { AppStore } from './../../../../../core/stores/app.store';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-briindlet-event-promote',
  templateUrl: './briindlet-event-promote.component.html',
  styleUrls: ['./briindlet-event-promote.component.scss']
})
export class BriindletEventPromoteComponent implements OnInit {
  @Input() userType: string = '';
  @Input() formGrp: any;
  
  constructor(
    public appStore: AppStore
  ) { }

  ngOnInit(): void {
  }

}
