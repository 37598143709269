import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CanActivate, Router} from '@angular/router';
import {QBHelper} from '../helper/qbHelper';
import {QBconfig} from '../essentials/QBconfig';
import { config } from 'src/environments/environment';
import { AppService } from './app.service';

declare var QB: any;

@Injectable({
  providedIn: 'root'
})
export class QbService {
  public errorSubject = new Subject<any>();
  public successSubject = new Subject<boolean>();
  public user: any;
  public _usersCache: any = [];
  usersCacheEvent: EventEmitter<any> = new EventEmitter();
  
  constructor(private qbHelper: QBHelper, private router: Router, private appService: AppService) {
  }
  public addToCache(user: any) {
    if(user) {
      const id = user?.id;
      if (!this._usersCache[id]) {
        this._usersCache[id] = {
          id: id,
          color: Math.floor(Math.random() * (10 - 1 + 1)) + 1
        };
      }
      this._usersCache[id].last_request_at = user.last_request_at;
      this._usersCache[id].name = user.full_name || user.login || 'Unknown user (' + id + ')';
      this.usersCacheEvent.emit(this._usersCache);
      return this._usersCache[id];
    }
    
  }


  public findUser(userId: string) {
    var searchParams = {login: userId};
    return new Promise((resolve, reject) => {
      QB.users.get(searchParams, function(error: any, user: any) {
      if (user) {
        resolve(user);
      }
      if (error) {
        reject(error)
      }
    });
    })
  }

  public deleteChatUser(userId: string) {
    return new Promise((resolve, reject) => {
      QB.users.delete(userId, function(error: any, result: any) {
        resolve(result);
        if (error) reject(error);
      });
    })
  }

  // create User
  public createUser(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.users.create(user, function (createErr: any, createRes: any) {
        if (createErr) {
          // // //console.log('User creation Error : ', createErr);
          reject(createErr);
        } else {
          // // //console.log('User Creation successfull ');
          resolve(createRes);
        }
      });
    });
  }

  // create User
  public checkOnlineUser(userID: any): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.chat.ping(userID,function (error: any) {
        if (error) {
          reject(error);
        } else {
          resolve({});
        }
      })
      // QB.users.create(user, function (createErr: any, createRes: any) {
      //   if (createErr) {
      //     // // //console.log('User creation Error : ', createErr);
      //    
      //   } else {
      //     // // //console.log('User Creation successfull ');
      //     resolve(createRes);
      //   }
      // });
    });
  }


  // update User
  public updateUser(userId: any, params: any): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      QB.users.update(userId, params, function (updateError: any, updateUser: any) {
        if (updateError) {
          // // //console.log('User update Error : ', updateError);
          reject(updateError);
        } else {
          self.addToCache(updateUser);
          // // //console.log('User update successfull ', updateUser);
          resolve(updateUser);
        }
      });
    });
  }

  public getUserOnline(userID: any): Promise<any> {
    const self = this;
    return new Promise(function (resolve, reject) {

      // QB.chat.onContactListListener = function(userID: any, type: any) {
      //  //console.log(type)
      //  resolve(type);
      // };
      QB.chat.ping(userID,  function (userErr: any, usersRes: any) {
        if (userErr) {
          reject(userErr);
        } else {
          resolve(usersRes);
        }
      })
      // QB.users.listUsers(params, function (userErr: any, usersRes: any) {
      //   if (userErr) {
      //     reject(userErr);
      //   } else {
      //     // // //console.log('User List === ', usersRes);
      //     const users = usersRes.items.map((userObj: any) => {
      //       self.addToCache(userObj.user);
      //       return userObj.user;
      //     });
      //     resolve(users);
      //   }
      // });
    });
  }


  // get Users List
  public getUserList(args: any): Promise<any> {
    if (typeof args !== 'object') {
      args = {};
    }
    const
      self = this,
      params = {
        filter: {
          field: args.field || 'full_name',
          param: 'in',
          value: args.value || [args.full_name || '']
        },
        order: args.order || {
          field: 'updated_at',
          sort: 'desc'
        },
        page: args.page || 1,
        per_page: args.per_page || 100
      };
    return new Promise(function (resolve, reject) {
      QB.users.listUsers(params, function (userErr: any, usersRes: any) {
        if (userErr) {
          reject(userErr);
        } else {
          // // //console.log('User List === ', usersRes);
          const users = usersRes?.items?.map((userObj: any) => {
            self.addToCache(userObj.user);
            return userObj.user;
          });
          resolve(users);
        }
      });
    });
  }

  public getAllUserList(page: any): Promise<any> {
   
    const
      self = this,
      params = {
        order: {
          field: 'updated_at',
          sort: 'desc'
        },
        page: page,
        per_page: 100
      };
    return new Promise(function (resolve, reject) {
      QB.users.listUsers(params, function (userErr: any, usersRes: any) {
        if (userErr) {
          reject(userErr);
        } else {
          // // //console.log('User List === ', usersRes);
          const users = usersRes?.items?.map((userObj: any) => {
            self.addToCache(userObj.user);
            return userObj.user;
          });
          resolve(users);
        }
      });
    });
  }

  public setUser(User: any) {
    this.user = User;
    localStorage.setItem('loggedinUser', JSON.stringify(User));
  }

  public async login(loginPayload : any) {
    //console.log('Calling yeah okay')
    return new Promise( async (resolve, reject) => {
      if(this.user) {
        //console.log("chat connected")
        resolve({})
      }
      QB.init(config.credentials?.appId, config.credentials?.authKey, config.credentials?.authSecret, config.credentials?.accountKey, config?.appConfig);
      const user = {
        login: loginPayload.userLogin,
        password: 'quickblox',
        full_name: loginPayload.full_name || loginPayload.userName
      };
     
      const loginSuccess = async (loginRes : any) => {
       
        //console.log('This is loginRes yeah', loginRes)
        this.setUser(loginRes);
        // // //console.log('chat connection :', loginRes.id, user.password);
        // Establish chat connection
        this.qbHelper.qbChatConnection(loginRes.id, user.password).then(chatRes => {
            this.successSubject.next(true);
            resolve({});
          },
          chatErr => {
            // // //console.log('chat connection Error :', chatErr);
          });
      };

      const loginError = (error :any) => {
        const message = Object.keys(error.detail).map(function (key) {
          //console.log('This is data before error', loginPayload)

          //console.log('this is qb login key and error', key, error)
          return
        });
        // alert(message);
      };

      // // //console.log('User : ', user);
      try {
        let loginRes = await this.qbHelper.qbCreateConnection(user)
        
        /** Update info */
        if (loginRes.full_name !== user.full_name) {
          let updateUser = await this.updateUser(loginRes.id, {
            'full_name': user.full_name
          })
          await loginSuccess(updateUser);
         
        } else {
          await loginSuccess(loginRes);
        }
    
      } catch(e) {
        let createUser = await this.createUser(user)
        if(createUser) {
          let reLoginRes = await this.qbHelper.qbCreateConnection(user)
          loginSuccess(reLoginRes);
        }
        
      }
      
        
    });
  }

  public getRecipientUserId(users: any) {
    const self = this;
    if (users.length === 2) {
      return users.filter(function (user : any) {
        if (user !== self.user.id) {
          return user;
        }
      })[0];
    }
  }

  async getQBUsersByChatIds(chatIds: any) {
    const self = this;
    return new Promise(function (resolve, reject) {
      var searchParams = {filter: { field: 'id', param: 'in', value: chatIds }};
      QB.users.listUsers(searchParams, function (userErr: any, usersRes: any) {
        if (userErr) {
          resolve([]);
        } else {
          const users = usersRes?.items?.map((userObj: any) => {
            self.addToCache(userObj.user);
            return userObj.user;
          });
          resolve(users);
        }
      });
    });
  }
}
