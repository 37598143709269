<div class="cover-image boxShadow-RGBA00005 flex flexAlignItemsEnd bdrRadius6px bgSize100p100p  bgRepeat-noRepeat {{userType}} flex minHeight"
    style="background-image: url({{groupDetail?.cover_photo}})">
    <div class="pad15 flex flexJustifyBetween flexResponsive width100">
        <button class="btn-user-default btn-get-started pad10 bdrRadius5px cursorPtr ripple">
            <span class="color-EFEFEF poppins-medium lineHeight15px font15">{{'group created by name' | translate: {name: groupDetail?.group_created_by || 'untitled'} }}</span>
        </button>
        <button class="btn-user-default btn-get-started pad10 bdrRadius30px cursorPtr ripple margin-top-mob">
            <div class="posRelative flex flexAlignItemsCenter ripple flexJustifyCenter">
                <input type="file"
                    class="posAbsolute ripple posTop posRight posLeft posRight opacity0 width100 height100p"
                    (change)="updatePhoto($event, 'cover_photo')" accept="image/*" *ngIf="isWeb">
                <input type="button"
                    class="posAbsolute ripple posTop posRight posLeft posRight opacity0 width100 height100p"
                    (click)="updatePhoto($event, 'cover_photo')" accept="image/*" *ngIf="!isWeb">
                <i class="icns icon-camera white d20"></i>
                <div class="pad5 desktopVisible"></div>
                <div class="poppins-medium font14 desktopVisible color-FFFFFF">{{'update cover photo' | translate}}
                </div>
            </div>
        </button>
    </div>
</div>

<div class="bdrRadius6px pad15 flex flexJustifyBetween flexAlignItemsCenter bdr-1-EEEEEE boxShadow-RGBA00005">
    <div>
        <div class="timeline-label font20 poppins-semibold">{{groupDetail?.name}}</div>
    <div class="color-8E8E8E font14 poppins-regular">{{'members count' | translate:{count: groupDetail?.members_count} }}</div>
    </div>
    <button (click)="openInviteFriendsPopUp()" *ngIf="groupDetail?.is_admin" class="flex flexAlignItemsCenter btn-get-started cursorPtr bdrRadius20px bg-FFFFFF bdr-1-EEEEEE boxShadow-RGBA00005 poppins-semibold font14 timeline-label pad1020 ripple">
        <i class="icns icon-plus {{iconTheme}} d20"></i>
        <div class="padleft5"></div>
        <span>{{'invite' | translate}}</span>
    </button>
</div>
<app-crop-image-popup (close)="showCropper = false" (uploadImageEvent)="uploadImage($event)" [imageType]="'cover_photo'" [imageChangedEvent]="imageChangedEvent"  *ngIf="showCropper"></app-crop-image-popup>