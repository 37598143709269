import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bussiness-invite-card',
  templateUrl: './bussiness-invite-card.component.html',
  styleUrls: ['./bussiness-invite-card.component.scss']
})
export class BussinessInviteCardComponent implements OnInit {
  @Input('data') data: any;
  constructor() { }

  ngOnInit(): void {
  }

}
