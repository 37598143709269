
<div #profileContainer>

    <ng-container *ngIf="thumbnailUrl">
        <span *ngIf="!isChatThumbnail" class="online-img-shadow bg-RGBA2691221  bdrRadius50p posRelative displayFlex zIndex1003" (click)="navigateToProfile($event)">
            <img [ngClass]="{'bdr-profile-image': isShowBorder}" [src]="image" class="{{imageClass}} bdrRadius50p">
        </span>
        
        <div *ngIf="isChatThumbnail" class="online-img-shadow bg-RGBA2691221  bdrRadius50p posRelative displayFlex p-0 zIndex1002" (click)="navigateToProfile($event)">
            <img *ngIf="image" [ngClass]="{'bdr-profile-image': isShowBorder}" [src]="image" class="{{imageClass}} bdrRadius50p testDiv">
            <div *ngIf="!image" [ngClass]="{'bdr-profile-image': isShowBorder}" class="{{imageClass}} chat-avatar-thumbnail bdrRadius50p testDiv">
                {{ newInitials }}
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="!thumbnailUrl">
        <span *ngIf="!isChatThumbnail" class="online-img-shadow bg-RGBA2691221  bdrRadius50p posRelative displayFlex">
            <img [ngClass]="{'bdr-profile-image': isShowBorder}" [src]="image" class="{{imageClass}} bdrRadius50p">
        </span>
        
        <div *ngIf="isChatThumbnail" class="online-img-shadow bg-RGBA2691221  bdrRadius50p posRelative displayFlex p-0">
            <img *ngIf="image" [ngClass]="{'bdr-profile-image': isShowBorder}" [src]="image" class="{{imageClass}} bdrRadius50p testDiv">
            <div *ngIf="!image" [ngClass]="{'bdr-profile-image': isShowBorder}" class="{{imageClass}} chat-avatar-thumbnail bdrRadius50p testDiv">
                {{ newInitials }}
            </div>
        </div>
    </ng-container>
</div>