export const newToUs = [{
    id: 1,
    name: 'Default',
    thumbnail: 'assets/images/temp-prev.png',
    previewImage: "assets/images/temp-prev.png",
    isSelected: true
},{
    id: 2,
    name: 'Template 1',
    thumbnail: 'assets/images/temp-1.png',
    previewImage: "assets/images/temp-1.png",
    isSelected: false
}]

export const membershipTemplate = [{
    id: 1,
    name: 'template1',
    thumbnail: 'assets/images/membership_form2.jpg',
    isSelected: true
},
{
    id: 2,
    name: 'template2',
    thumbnail: 'assets/images/membership_form1.jpg',
    isSelected: false
},{
    id: 3,
    name: 'template3',
    thumbnail: 'assets/images/membership_form3.jpg',
    isSelected: false
}]

export const BankAccountTypes = [{
    id: "savings",
    name: "Savings Account"
  },{
    id: "current",
    name: "Current Account"
  },{
    id: "checking",
    name: "Checking Account"
  }]