<div class="{{appStore.userType}}" [formGroup]="formGrp">
    <div class="timeline-label  header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{ (formGrp.value?.step_1?.is_event == "True" ? 'event from and to date and time' : 'briindlet from and to date and time') | translate}}
    </div>
    <div class="body width100" formGroupName="step_6">
        <div class="flex flexJustifyBetween flexResponsive mrgnbtm20">
            <div class="width50 flex">
                <input [owlDateTime]="dt1" [min]="minDate" [owlDateTimeTrigger]="dt1" formControlName="event_from_date" placeholder="{{'event from' | translate}}" type="text" readonly="true" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white outline0 bdrRadius6px calender-{{appStore?.iconClass}} input-icon">
                <owl-date-time (afterPickerClosed)="startDateChange()" [pickerType]="'calendar'" #dt1></owl-date-time>
            </div>
            <div class="pad10"></div>
            <div class="width50 flex">
                <input [owlDateTime]="dt2" [min]="minEndDate" [owlDateTimeTrigger]="dt2" formControlName="event_to_date" placeholder="{{'event to' | translate}}" type="text" readonly="true" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white outline0 bdrRadius6px calender-{{appStore?.iconClass}} input-icon">
                <owl-date-time [pickerType]="'calendar'" (afterPickerClosed)="checkEndDateValid()" #dt2></owl-date-time>
            </div>
        </div>
        <div class="flex flexJustifyBetween flexResponsive mrgnbtm20">
            <div class="width50 flex">
                <input [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" formControlName="event_from_time" placeholder="{{'starting time' | translate}}" type="text" readonly="true" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon clock bdr-1-EEEEEE bg-white outline0 bdrRadius6px clock-{{appStore?.iconClass}} input-icon">
                <owl-date-time (afterPickerClosed)="startDateChange()" (afterPickerClosed)="checkEndDateValid()" [pickerType]="'timer'" #dt3></owl-date-time>
            </div>
            <div class="pad10"></div>
            <div class="width50 flex">
                <input [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4" formControlName="event_to_time" placeholder="{{'ending time' | translate}}" type="text" readonly="true" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon clock bdr-1-EEEEEE bg-white outline0 bdrRadius6px clock-{{appStore?.iconClass}} input-icon">
                <owl-date-time [pickerType]="'timer'" (afterPickerClosed)="checkEndDateValid()" #dt4></owl-date-time>
            </div>
        </div>
        <p class="txt-left color-red poppins-medium font15 mrgn0 mrgntop5" *ngIf="invalidTime">
            {{'Please enter a valid start and end time' | translate}}
        </p>
        <div class="width100 padtop30 flex">
            <button (click)="actionCTA('previous')" class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="isInvalid" (click)="actionCTA('continue')" class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
</div>