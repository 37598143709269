<div id="createalbum-popup"
    class="bdrRadius6px bg-white pad20 padright10 posRelative custom-scrollbar {{appStore?.userType}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div id="social-create-album-pop" class="overflowHidden">
        <div class="color-122A4D poppins-semibold font18 padtop20 padbtm20 txtCenter">{{'share' | translate}}
        </div>
        <div class="maxHeight70vh overflowAuto custom-scrollbar minHeight50vh">
        <app-share-post-detail [user]="popup.context?.user" [media]="popup?.context?.media" [post]="popup?.context?.post"
            (shareMessage)="shareMessageEmit($event)"></app-share-post-detail>
        </div>
        <div class="padtop30 padbtm20 flex">
            <button type="button" (click)="closePopup();"
                class="btn-get-started minwidth100px  ripple cancel-btn cursorPtr font14 poppins-regular pad1030">
                {{'cancel' | translate}}
            </button>
            <div class="pad5"></div>
            <button type="button" [disabled]="isLoader" (click)="share();"
                class="btn-get-started  minwidth100px btn-user-default ripple cursorPtr font14 poppins-regular pad1030">
                {{'share' | translate}}
            </button>
        </div>
    </div>
</div>