import { PushNotificationService } from './core/services/push-notification.service';
import { AuthService } from './core/services/auth-service/auth.service';
import { NotificationSocketService } from './core/services/notification-socket.service';
import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppStore } from 'src/app/core/stores/app.store';
import { AppService } from './core/services/app.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from 'src/environments/environment';
import { LoaderService } from './core/services/loader.service';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { filter } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Device } from '@capacitor/device';
import { detectAnyAdblocker } from 'just-detect-adblock'
import { ChatService } from './chat/services/chat.service';
import { DialogService } from './core/services/dialog.service';
import { QBHelper } from './core/helper/qbHelper';
import { MessageService } from './core/services/message.service';
import { QbService } from './core/services/qb.service';
import { UserService } from './users/services/user.service';
import { UserStoreService } from './users/store/user-store.service';
import { OrganizationService } from './organizations/service/organization.service';
import { OrganizationStoreService } from './organizations/store/organization-store.service';
import {Location} from '@angular/common';


declare var gtag: any;
declare var QB: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loaderColor: string = '#02457A';
  message: any = '';
  topInsetBackground: string = 'default'
  intervalsId: any = [];

  constructor(
    public sanitizer: DomSanitizer,
    public appStore: AppStore,
    public router: Router,
    public appService: AppService,
    public route: ActivatedRoute,
    public deviceDetectService: DeviceDetectorService,
    public loaderService: LoaderService,
    private notificationSocketService: NotificationSocketService,
    private authService: AuthService,
    private pushNotificationService: PushNotificationService,
    private chatService: ChatService,
    public dialogService: DialogService,
    private qbHelper: QBHelper,
    private messageService: MessageService,
    private qbService: QbService,
    private userService: UserService,
    private userStoreService: UserStoreService,
    private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService,
    private _location: Location
  ) {
    this.overrideReuseStrategy();

    this.appStore.sessionLoginListener$.subscribe(async (res: any) => {
      //console.log('session login list', res)
      if(res ==='logout') {
          this.clearAllIntervals()
          await this.qbHelper.qbLogout()
          location.reload();
          return
      }
      if(res) {

        this.appStore.userType = res.type || res.user_type

        this.appStore.iconClass = this.appStore.userType?.toLowerCase() == 'user' ? 'blue' : 'green';

        //console.log('Org dets', this.authService.getSessionData())

        let profileData: any = null
        if(this.appStore.userType?.toLowerCase() === 'user') {
          const response = await this.userService.getUserInfoPromise(this.authService.getSessionData().briind_id)
          this.userStoreService.getUserDetails();
          profileData = response
        }

        if(this.appStore.userType?.toLowerCase() === 'organization') {
          const response = await this.organizationService.getOrganizationDetailPromise(this.authService.getSessionData().briind_id)
          this.organizationStoreService.getOrganizationDetails();
          
          profileData = response
          this.organizationService.getOrganizationBasicProfilDetail({organization_id: profileData.id}).subscribe(response => {
            this.organizationStoreService.setBasicProfile()
          })
          this.loaderColor = '#18878c'
        }

        this.appStore.saveProfileState(profileData)
        

        // chat 
        this.intervalsId.length === 0 ? this.preloadPolling(profileData) : null
        // end chat
      }
    })

    if(this.authService.isLoggedIn()) {
      // if user is logged in, update login session store here
      let sessionData  = this.authService.getSessionData()
      this.appStore.saveLoginSessionState(sessionData)

        let profileString = localStorage.getItem('profileData')
        if(this.isValidJSON(profileString) && profileString) {
          this.preloadPolling(JSON.parse(profileString))
        }


    }

  }

  isValidJSON(jsonString: any) {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  }

  overrideReuseStrategy() {
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    }
  };


  /**
   * @description
   * Listener Events to monitor the Device network offiline status
   *
   * @param {*} [event]
   * @memberof AppComponent
   */
  @HostListener('window:offline', ['$event'])
  isOffline(event ?: any){
    this.appService.deviceNetworkStatus(event.type)
  }

  /**
   * @description
   * Listener Events to monitor the Device network  online status
   *
   * @param {*} [event]
   * @memberof AppComponent
   */
  @HostListener('window:online', ['$event'])
  isOnline(event ?: any){
    this.appService.deviceNetworkStatus(event.type)
  }


  /**
   * @description
   * Function to check the browser compatibility
   *
   * @memberof AppComponent
   */
  checkBrowserCompatibility(){
    const deviceDetails = this.deviceDetectService.getDeviceInfo();
    if(config.compatibleBrowsers[deviceDetails.browser.toUpperCase()] > parseInt(deviceDetails.browser_version.split('.')[0])){
      let popupObj = { type: 'incompatible', load: true, overlayClose: true, context: {title: 'browser not compatible'} }
      this.appService.togglePopup(popupObj);
    }
  }

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   *
   * @memberof AppComponent
   */
  async ngOnInit(): Promise<void> {
    //this.appService.checkSession();
    // this.pushNotificationService.requestPermission()
    // this.pushNotificationService.receiveMessage()
    // this.message = this.pushNotificationService.currentMessage

    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.appStore.isMobile = true

    this.router.events.pipe(
        filter((event: any) => event instanceof NavigationEnd)
      ).subscribe((value: any) => {
        // Handle route change event

        let isMobile = false
        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') isMobile = true


       
           if(isMobile) {

            SafeArea.getSafeAreaInsets().then(({ insets }) => {
              let customPaddingMobile: any = document.getElementById('sign-in-container')
              let customPaddingLogo: any = document.getElementsByClassName('welcome-icon')

              //console.log("Whoo" + customPaddingLogo)
              let customPaddingMobileTimeline: any = document.getElementById('user-navbar')

              let customInset  = insets.top - (insets.top/4)
              customPaddingMobile.style.margin = `${customInset}px 0px 0px 0px`; 
              customPaddingLogo[0].style.setProperty('padding-top', `${customInset}px`, 'important');; 

              customPaddingMobileTimeline.style.margin = `${customInset}px 0px 0px 0px`; 
              //console.log("styleing" + customPaddingMobile.style)    
            });
          }
        
        
        SafeArea.getStatusBarHeight().then(({statusBarHeight}) => {
          // //console.log(statusBarHeight, 'statusbarHeight');
        })

        gtag('config', 'G-YB7KE1G0GJ', {
          'page_path': value?.urlAfterRedirects
        });


        let customPaddingMobile: any = document.getElementById('sign-in-container')

        customPaddingMobile.classList.remove(customPaddingMobile.classList.item(0));
        if(value.url === '/users/auth/signin') customPaddingMobile.classList.add("personal-sign-in-inset")
        else if(value.url === '/organizations/auth/signin') customPaddingMobile.classList.add("org-sign-in-inset")
        else customPaddingMobile.classList.add("default-sign-in-inset")

        if(value.url.includes('advertisement')) {
          setTimeout(()=> {
            //console.log('is ads url')
            var mainElement = document.getElementById('main-screen-container');
            //console.log('This is main element test tag value:')
            //console.log(mainElement?.clientHeight)
            //console.log(mainElement?.offsetHeight)

            if (mainElement?.clientHeight === 0 || mainElement?.offsetHeight === 0) {
                // Adblocker might be present
                //console.log('Adblocker detected');
                this._location.back();
                
                  this.appService.togglePopup({
                    type: 'warning',
                    load: true,
                    refreshParent: true,
                    overlayClose: true,
                    context: {
                      title: "Adblocker Detected",
                      userType: this.authService.getSessionData().type.toLowerCase(),
                      data: { message: "Adblocker needs to be disabled, then refresh the page in order to create an advertisement"},
                    }
                  });
              
            }
          }, 500)
        }

      });

      if(!(await this.isEnableScreenRotate())) {
        ScreenOrientation.lock({ orientation: "portrait" })
      }
  }

  async isEnableScreenRotate(): Promise<boolean> {
    const deviceInfo = await Device.getInfo();
    const isiPad = deviceInfo.platform === 'ios' && deviceInfo.model.startsWith('iPad');
    const isiPhone = deviceInfo.platform === 'ios' && deviceInfo.model.startsWith('iPhone');
  
    if (isiPad) {
      //console.log('The device is an iPad.');
      return true
    } else if (isiPhone) {
      //console.log('The device is an iPhone.');
      return false
    }
    return true
  };

   // Chats List
   async preloadPolling(data: any) {
    const initPolling = setInterval(async ()=> {
    }, 100000)

    this.intervalsId.push(initPolling)

    this.qbService.login({'userLogin': data.briind_id, 'userName': data.briind_id, 'full_name': `${data.first_name || data.name} ${data.last_name || '' }`}).then(async (res)=> {
      const getChatListInterval = setInterval(async ()=> {
        this.chatService.getChatList('chat')
      }, 3000)

      const getUnreadMessage = setInterval(async () => {
        await this.chatService.getUnreadMessageCount()
      }, 3000)

      const getNotificationCount = setInterval(async () => {
        const userType = this.appStore.userType?.toLowerCase() === 'user' ? 'users' : 'organizations'
        const data: any = await this.appService.getNotificationsCount(userType)
        if(data) {
          this.appStore.apiResp = data
          this.appStore.notificationCount = data.not_seen_count;
        }
       
      }, 3000)

      const getTopNotifications = setInterval(async () => {
        const userType = this.appStore.userType?.toLowerCase() === 'user' ? 'users' : 'organizations'
        await this.appService.getTopNotifications(userType, {})
      }, 3000)
      
      this.intervalsId.push(getChatListInterval, getUnreadMessage, getTopNotifications, getNotificationCount)
    }) 
  }


  clearAllIntervals() {
    this.intervalsId.forEach((item:any) => {
      clearInterval(item);
    });
  
    // Clear the intervals array
    this.intervalsId = [];
  }
}

