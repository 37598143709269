import { Component, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { ProgressCircleModel } from 'src/app/core/models';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss']
})
export class ProgressCircleComponent implements OnDestroy,OnChanges {

  @Input('progressObj') data: ProgressCircleModel | any;
  radius = 54;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number;
  displayValue: string = '';
  displayValuePercent: number = 0;
  circleDiameter: number = 80;

  constructor() {
    this.dashoffset = this.circumference;
  }



  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof ProgressCircleComponent
   */
  ngOnChanges(changes: SimpleChanges){
    if(changes.data.currentValue.detailsInside){
      if(changes.data.currentValue.diameter && changes.data.currentValue.diameter > 49){
        this.circleDiameter = changes.data.currentValue.diameter;
      }
    } else {
      this.circleDiameter = changes.data.currentValue.diameter ? changes.data.currentValue.diameter : 35;
    }
    setTimeout(() => {
      if(changes.data.currentValue.value != null)
        this.progress(Math.round(changes.data.currentValue.value)); // Rounding the number value to avoid decimal values inside the progress circle
    }, 500);
  }


  /**
   * @description
   * this method used for Progress Data Method
   * @private
   * @param {number} value
   * @memberof ProgressCircleComponent
   */
  private progress(value: number) {
    switch(this.data.type){
      case 'percent':{
        if(value > -1){
          const progress = value / 100;
          this.data.color = (this.data.color != null) ? this.data.color : this.getColor(value);
          this.dashoffset = this.circumference * (1 - progress);
          if(this.data.animateCount){
            this.data.animateCountDuration = this.data.animateCountDuration ? this.data.animateCountDuration 
                                                                            : (value < 10) ? 100 : 10;                                                  
            this.animateCount(value);
          } else {
            this.displayValue = value + '%';
          }
        }
        break;
      };
      
      default:
        break;
    }
  }

  /*  */
  /**
   * @description
   * this method is used for getting color
   *
   * @param {*} [value]
   * @returns
   * @memberof ProgressCircleComponent
   */
  getColor(value ?: any){
    return (value >= 80) ? 'progressHigh' : (value < 50) ? 'progressLow' : 'progressMedium';
  }

  animateCount(endVal: number){
    let incrementVal: number = 0;
    let timeIntervalVariable: any;
    timeIntervalVariable = setInterval(()=>{
      if(endVal > incrementVal){
        incrementVal++;
        this.displayValue = incrementVal + '%';
      } else {
        clearInterval(timeIntervalVariable);
        this.displayValue = endVal + '%';
      }
    }, this.data.animateCountDuration);
  }

  ngOnDestroy(){
    this.displayValue = '';
  }

}
