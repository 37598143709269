<div class="bdr-1-EEEEEE boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px pad20 organization mrgnbtm15">
    <div>
        <img [src]="data?.about_info?.photo" class="bdrRadius6px width100 height300px" />
    </div>
    <div class="poppins-regular font14 color-484848" *ngIf="data?.small_description">
        <p>{{data?.about_info?.small_description}}</p>
    </div>
    <ng-container *ngIf="data?.about_info?.mission_statement">
        <div class="poppins-semibold font18 color-02457A padbtm10 padtop20 timeline-label">
            {{'mission statement' | translate}}</div>
        <div class="poppins-regular font14 color-484848">
            <p class="mrgn0">{{data?.about_info?.mission_statement}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="data?.about_info?.vision_statement">
        <div class="poppins-semibold font18 color-02457A padbtm10 padtop20 timeline-label">{{'vission statement' | translate}}</div>
        <div class="poppins-regular font14 color-484848">
            <p class="mrgn0">{{data?.about_info?.vision_statement}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="data?.about_info?.history_of_partner">
        <div class="poppins-semibold font18 color-02457A padbtm10 padtop20 timeline-label">
            {{'history of the partner' | translate}}</div>
        <div class="poppins-regular font14 color-484848">
            <p class="mrgn0">{{data?.about_info?.history_of_partner}}</p>
            <!-- <p> 
                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, 
            </p> -->
        </div>
    </ng-container>

    <ng-container *ngIf="data?.user_list?.founder_leader && data?.user_list?.founder_leader?.length !=0">
        <div class="poppins-semibold font18 color-02457A padbtm10 padtop20 timeline-label">
            {{'leaders information' | translate}}</div>
        <ng-container *ngFor="let item of data?.user_list?.founder_leader">
            <div class="flex flexAlignItemsCenter">
                <div class="width50 pad5">
                    <div class="width100Minus10MD mrgnauto">
                        <app-leader-card [data]="item" [leaderType]="'ministry'">
                        </app-leader-card>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>
    <ng-container *ngIf="data?.user_list?.team_leaders && data?.user_list?.team_leaders?.length != 0">
        <div class="poppins-semibold font18 timeline-label padbtm10 padtop20">{{'team leaders information' | translate}}
        </div>
        <div class="flex flexResponsive flexWrap">
            <div class="width50 pad5" *ngFor="let item of data?.user_list?.team_leaders">
                <div class="width100Minus10MD mrgnauto">
                    <app-leader-card [data]="item" [leaderType]="'ministry'"></app-leader-card>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="data?.user_list?.staffs && data?.user_list?.staffs?.length != 0">
        <div class="poppins-semibold font18 timeline-label padbtm10 padtop20">{{'staff information' | translate}}
        </div>
        <div class="flex flexResponsive flexWrap">
            <div class="width50 pad5" *ngFor="let item of data?.user_list?.staffs">
                <div class="width100Minus10MD mrgnauto">
                    <app-leader-card [data]="item" [leaderType]="'ministry'"></app-leader-card>
                </div>
            </div>
        </div>
    </ng-container>

</div>