<div class="flex width100" *ngFor="let item of data" (click)="getHashTagData(item)">
    <div class="d35 font15 lineHeight30px bdrRadius50p poppins-semibold hash-tags-container flex flexJustifyCenter flexAlignItemsCenter ">
        #
    </div>
    <div class="pad5"></div>
    <div class="flex1">
        <div class="width100 mrgnbtm10">
            <div class="timeline-label poppins-medium font16 lineHeight20px wrdBreakAll">
                {{item.hash_tag}}
            </div>
            <div class="color-8E8E8E font14 poppins-medium minHeight20px">
               {{item.count}} {{'posts' | translate}}
            </div>
        </div>
    </div>
</div>