<ng-container *ngIf="data?.work_information && data?.work_information?.length != 0">
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'work' | translate}}</div>
        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/work']" [queryParams]="{ open: 'work' }" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="flex flexResponsive flexWrap flexJustifyBetween">
        <ng-container *ngFor="let item of data?.work_information">
            <div class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="showUserInfo(settings,'show_works')">
                <div class="d20">
                    <i class="icns icon-work blue d20"></i>
                </div>
                <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                    <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                        {{ item.from_year | date: 'dd MMM yyyy'}} - {{ item.to_year ? (item.to_year | date: 'dd MMM yyyy') : 'present' | translate}}
                    </div>
                    <div class="color-122A4D font16 poppins-medium">
                        {{ item.designation}} {{'at' | translate}} {{ item.name}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

<div class="pad5"></div>

<!-- Education start here -->
<div class="flex flexJustifyBetween">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'education' | translate}}</div>

    <div *ngIf="showEditIcon" [routerLink]="['/users/profile/work']" [queryParams]="{ open: 'university' }" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20" *ngIf="data?.eduaction_information?.length !== 0"></i>
        <i class="icns d20 icon-plus blue" *ngIf="data?.eduaction_information?.length === 0"></i>
    </div>
</div>

<div class="flex flexResponsive flexWrap flexJustifyBetween">
    <ng-container *ngFor="let item of data?.eduaction_information">
        <div class="info-container bg-E5ECF1 bdrRadius6px flex pad10 width50 mrgn5" *ngIf="showUserInfoSchoolUniversity(settings,item)">
            <div class="d20">
                <i class="icns icon-education blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{ item.from_year | date: 'yyyy'}} - {{ item.to_year ? (item.to_year | date: 'yyyy') : 'present' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium" *ngIf="item.data_type == 'HighSchool' ">
                    {{item.name}}
                </div>
                <div></div>
                <div class="color-122A4D font16 poppins-medium" *ngIf="item.data_type == 'University' ">
                    {{item.name}}<span *ngIf="item.degree">, </span> <span class="color-8E8E8E"> {{item?.degree?.name}}, {{item?.major?.name}}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <app-empty-state [stateType]="'nofriends'" class=" width100" *ngIf="data?.eduaction_information?.length === 0 || !data?.eduaction_information?.length"></app-empty-state>
</div>
<!-- Education end here -->
<div class="pad5"></div>
<ng-container *ngIf="data?.skill_information?.length != 0 && showUserInfo(settings,'show_skills')">
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'skills' | translate}}</div>
        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/work']" [queryParams]="{ open: 'skills' }" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="service">
        <span class="displayFlex bg-E5ECF1 bdrRadius20px mrgnright10 mrgnbtm10 color-122A4D poppins-regular font16 pad1015" *ngFor="let item of data?.skill_information">
            {{item?.name}}
        </span>
    </div>
</ng-container>
<div class="pad5"></div>
<ng-container *ngIf="data?.interest_information?.length != 0 && showUserInfo(settings,'show_intrests')">
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'interests' | translate}}</div>

        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/work']" [queryParams]="{ open: 'interests' }" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="service">
        <span class="displayFlex bg-E5ECF1 bdrRadius20px mrgnright10 mrgnbtm10 color-122A4D poppins-regular font16 pad1015" *ngFor="let item of data?.interest_information">
            {{item?.name}}
        </span>
    </div>
</ng-container>