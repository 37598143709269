import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { CameraService } from 'src/app/core/services/camera.service';

@Component({
  selector: 'app-photo-from-file',
  templateUrl: './photo-from-file.component.html',
  styleUrls: ['./photo-from-file.component.scss']
})
export class PhotoFromFileComponent implements OnInit {
  @Input() imageType:string = "profile_pic";
  @Output() selectedFile:EventEmitter<File> = new EventEmitter();
  showCropper: any = false;
  imageChangedEvent: any = '';
  isWeb = true;

  constructor(
    private cameraService: CameraService
  ) {
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isWeb = false
  }

  ngOnInit(): void {
  }

  async updatePhoto(event: any) {
    this.showCropper = false;

    if (!this.isWeb) {
      this.imageChangedEvent = await this.cameraService.getPhoto();
      this.showCropper = true;
    }
    if (this.isWeb) {
      if (event.target.files.length > 0) {
        this.imageChangedEvent = event;
        this.showCropper = true;
        return;
      }
    }
  }
  
  uploadImage(event: any) {
    this.showCropper = false;
    this.selectedFile.emit(event);
  }
}
