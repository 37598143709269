import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';

@Injectable({
  providedIn: 'root'
})
export class CameraService {
  capturedFile:File | undefined;
  constructor(
  ) { }

  async getPhoto() {


    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices.getUserMedia({ video: true })
    //     .then(function(stream) {
    //       var videoElement: any = document.getElementById('camera-feed');
    //       videoElement.srcObject = stream;
    //     })
    //     .catch(function(error) {
    //       console.error("Error accessing camera:", error);
    //     });
    // } else {
    //   console.error("getUserMedia is not supported in this browser.");
    // }
    // return;

    
    let permission = await Camera.checkPermissions()
    if((permission.camera === 'denied' || permission.photos === 'denied')) {
      let askPermission = await Camera.requestPermissions()
    }
    const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      });   
      let customImage = `data:image/jpeg;base64,${image.base64String}`;
   
      return this.setFileStructure(customImage)
  }

  choosePhoto() {

  }

  convertFileToBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let main = reader.result
        //console.log(reader.result)
        resolve(this.setFileStructure(main))
      };
      reader.onerror = error => reject(error);
    })
  }

  setFileStructure(base64File: any) {
    let file64 = [base64File]
    let eventFinal = {
      target: {
        files: file64
      }
    }
    return eventFinal
  }
}
