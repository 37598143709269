import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-organization-basic-info-preview',
  templateUrl: './organization-basic-info-preview.component.html',
  styleUrls: ['./organization-basic-info-preview.component.scss']
})
export class OrganizationBasicInfoPreviewComponent implements OnInit {
  @Input('data') data: any;
  @Input('organizationID') organizationID: any;
  
  @Input('showEditIcon') showEditIcon: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
