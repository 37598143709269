<div class="pad20 bdrRadius6px bg-white posRelative organization posRelative" id="addLeaderPopup">
    <div class="header color-18878C font20 lineHeight40px poppins-semibold txtCenter posRelative">
        {{'leaders information' | translate}}
    </div>
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <form class="mrgntop20 user" [formGroup]="form" (submit)="onSubmit()">
        <div class="flex flexJustifyBetween leaderPopupScroll">
            <div class="width95">
                <div class="">
                    <div class=" flex flexJustifyBetween flexAlignItemsCenter  cursorPtr ripple
                    font16 width100 boxShadow-RGBA00005 pad1015 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadiusTopRight6px bdrRadiusTopLeftt6px">
                        <div class="title poppins-medium font14 fontWeightNormal txtLeft
                        timeline-label">{{'add leader' | translate}}</div>
                        <button class="bgTransparent bdrRadius50p outline0 border0
                        cursorPtr">
                            <i class="icns icon-plus green d25"></i>
                        </button>
                    </div>
                    <div class="transitionMaxHeightEaseOutp2" id="addLeader">

                        <div class="pad15 bdr-1-EEEEEE bdrRadiusBottomRight6px bdrRadiusBottomLeft6px">
                            <div class="flex flexJustifyBetween mrgnbtm10">
                                <div class="width100">
                                    <input type="text" formControlName="search" appDebounce (debounceClick)="changeBriindId()" placeholder="{{'enter existing user briind id or email' | translate}}" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                                </div>
                            </div>
                            <div class="pad20"></div>
                            <div class="width60 brstyle"></div>
                            <div class="timeline-label font18 poppins-semibold txtCenter mrgntopM15"><span class="orr">{{'or' | translate}}</span></div>
                            <div class="pad20"></div>
                            <div class="boxShadow-RGBA00005 bgn pad20 bdrRadius4px" [ngClass]="{'pointerEventsNone': form.value.briind_id}">
                                <div class="flex flexResponsive">
                                    <div class="txtCenter">
                                        <div class="d120 mrgnauto bdrRadius50p posRelative img-overlay overflowHidden">
                                            <img [src]="imagePath" class="d120 bdrRadius50p">
                                            <div class="overlay flex flexAlignItemsCenter flexJustifyCenter flexDirectionCol">
                                                <i class="icns icon-camera white d25"></i>
                                                <input type="file" (change)="uploadImage($event)" accept="image/x-png,image/png,image/jpeg,image/jpg" class="posAbsolute posTop posBtm posRight posLeft width100 opacity0">
                                                <div class="color-FFFFFF font14 poppins-medium">
                                                    {{'add profile picture' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pad10"></div>
                                    <div>
                                        <div class="flex flexJustifyBetween mrgntop10 flexResponsive">
                                            <div class=" width50Minus10pxLap width100">
                                                <div class="width100">
                                                    <input type="text" [ngClass]="{ 'bdr-red': control['first_name'].invalid && (control['first_name'].touched || control['first_name'].dirty) }" formControlName="first_name" placeholder="{{'name' | translate}}" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                                                </div>
                                            </div>
                                            <div class="pad10"></div>
                                            <div class=" width50Minus10pxLap width100">
                                                <div class="width100">
                                                    <input type="email" formControlName="email" [ngClass]="{ 'bdr-red': control['email'].invalid && (control['email'].touched || control['email'].dirty) }" placeholder="{{'add email' | translate}}" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex flexJustifyBetween flexResponsive mrgntop20">
                                            <div class=" width50Minus10pxLap width100">
                                                <div class="width100">
                                                    <input type="tel" formControlName="mobile_number" [ngClass]="{ 'bdr-red': control['mobile_number'].invalid && (control['mobile_number'].touched || control['mobile_number'].dirty) }" placeholder="{{'add cell phone' | translate}}" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="listError">
                                <strong class="color-red txtTransformCaps poppins-regular font15 mrgnbtm0" *ngFor="let err of listError">
                                    {{(err) | translate}}
                                </strong>
                            </ng-container>
                            <div class="flex padbtm15">
                                <button (click)="cancel();" type="button" class="btn-get-started minwidth100px mrgntop20 btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815 cancel-btn">
                                {{'Clear' | translate}}
                            </button>
                                <button class="btn-get-started minwidth100px mrgntop20 btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815 mrgnleft10">
                                {{'save' | translate}}
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad5"></div>
            <div class="posRelative">
                <app-privacy-check [theme]="'green'" (onSelect)="onLeaderPrivacySelect($event)"></app-privacy-check>
            </div>
        </div>
    </form>
</div>