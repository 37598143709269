<div class="pad20 bdrRadius6px bdr-1-EEEEEE bg-white posRelative {{popup?.context?.userType}} posRelative" id="addLeaderPopup">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>


    <ng-container>
        <div class="header timeline-label font20 lineHeight40px poppins-semibold txtCenter posRelative">
            {{'invite' | translate }}
        </div>
        <p class="color-02457A font16 poppins-regular txtLeft">
            {{'Let’s build your Briind community! Invite friends, family and individuals who are a part of your organization to join you on Briind and start creating purposeful & meaningful connections today!' | translate}}
        </p>
        <div class="mrgntop20 ">
            <div class="create-post-box bdrRadius10px pad15 boxShadow-RGBA00005 bdr-1-EEEEEE bg-white">
                <div class="flex">
                    <form [formGroup]="form" class="width100">


                        <div class="width100 mrgnbtm20">
                            <input placeholder="{{'enter email id invite' | translate}}" formControlName="email" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
                  bdr-1-EEEEEE bg-white outline0 bdrRadius6px" [ngClass]="{ 'bdr-red': form.get('email' || '')?.invalid && form.get('email' || '')?.dirty && form.get('email' || '')?.touched}">
                            <strong class="txt-left color-red poppins-regular font13" *ngIf="form.get('email' || '')?.invalid && form.get('email' || '')?.dirty && form.get('email' || '')?.touched">
                    {{'Enter Valid Email' | translate}}
                </strong>
                        </div>

                        <div class="flex mrgnbtm20">
                            <div class="width100">
                                <textarea formControlName="description" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px minHeight150px" placeholder="{{'invite message' | translate}}" rows="2"></textarea>
                            </div>
                        </div>

                    </form>


                </div>

            </div>
            <div class="pad5"></div>

            <div class="flex mrgntop20">

                <button [disabled]="form.invalid || formSubmitting" (click)="onSubmit()" class="btn-get-started flex flexJustifyCenter posRelative padleft20 padright20 minWidth120px padtop10 padbtm10 btn-user-default font15 ripple poppins-medium cursorPtr">{{'invite' | translate}}
                    <div class="posAbsolute posRight5px" >
                        <!-- <app-loader [widthClass]="'d20'"></app-loader> -->
                    </div>
                </button>
            </div>
        </div>
    </ng-container>
</div>