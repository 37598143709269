import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models/popup.model';

@Component({
  selector: 'app-profile-completed-success-popup',
  templateUrl: './profile-completed-success-popup.component.html',
  styleUrls: ['./profile-completed-success-popup.component.scss']
})
export class ProfileCompletedSuccessPopupComponent implements OnInit {

  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor() { }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof ProfileCompletedSuccessPopupComponent
  */
  closePopup() {
    this.close.emit('close-success');
  }
  

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   *
   * @memberof ProfileCompletedSuccessPopupComponent
   */
  ngOnInit(): void {
    
  }

}
