<div class="flex flexJustifyBetween">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'leaders information' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/organizations',organizationID,'profile','social','leaders']" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20"></i>
    </div>

</div>

<div class="flex flexWrap width100 flexResponsive">
    <div class="width100 mrgntop10" *ngFor="let item of data">
        <app-leader-card [data]="item" (removeItem)="changeData($event)"></app-leader-card>
    </div>
</div>