import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from './../../../../../core/stores/app.store';
import { config } from 'src/environments/environment';
import { getImagePath } from 'src/app/core/essentials/common.libs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { CameraService } from 'src/app/core/services/camera.service';

@Component({
  selector: 'app-briindlet-event-briindlet-media',
  templateUrl: './briindlet-event-briindlet-media.component.html',
  styleUrls: ['./briindlet-event-briindlet-media.component.scss']
})
export class BriindletEventBriindletMediaComponent implements OnInit {
  @Output() stepperActions = new EventEmitter();
  @Input() formGrp: any;
  selectedFiles: any = [];
  showCropper:any = false;
  imageChangedEvent: any = '';
  isWeb: boolean = true
  constructor(
    public appStore: AppStore,
    private appService: AppService,
    private cameraService: CameraService
  ) { 
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isWeb = false
  }

  async uploadPhoto(event: any) {
    this.imageChangedEvent = await this.cameraService.getPhoto();
    this.showCropper =true;
  }

  async getImage(event: any) {
    if((event.target.files[0].type).includes('video')){
      if ((event.target.files[0].size /1000000) < config.postFileSize) {
        let reader: any = getImagePath(event,'');
        reader.onload = (e:any) => {
          this.selectedFiles.push({base64: e.target.result, file: event.target.files[0], file_type : 'video' });
        };
        this.setFormValue();
      } else {
        this.appService.toggleToast({
          type: 'error',
          load: true,
          position: 'bottom-left',
          context: { description: `Maximum file size ${config.postFileSize}MB` }
        });
      }
    }
    else if((event.target.files[0].type).includes('image')){
      if(!this.isWeb) this.imageChangedEvent = await this.cameraService.convertFileToBase64(event.target.files[0])
      if(this.isWeb) this.imageChangedEvent = event;

      this.showCropper =true;
    }
    
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  uploadImage(event: any) {
    this.getBase64(event).then(data => {
      this.selectedFiles.push({base64: data, file: event, file_type : 'photo' })
      this.setFormValue();
      this.showCropper = false;
    })
  }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
    this.appService.setCache1(this.formGrp.value)
    // sessionStorage.setItem('briindletCache1', JSON.stringify(this.formGrp.value))
  }

  setFormValue() {
    this.formGrp['controls']['step_13'].patchValue({
      photos: this.selectedFiles
    })
  }

  removeImage(index: number) {
    this.selectedFiles.splice(index,1);
    this.setFormValue();
  }

  ngOnInit(): void {
    if (this.formGrp['value']['step_13']['photos']['length']) {
      this.selectedFiles = this.formGrp['value']['step_13']['photos'];
    }
  }

  get invalid(){
    if(this.formGrp['value']['step_13']['photos']['length']){
      return false
    }
    else{
      return true
    }
  }

}
