import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-privacy-check',
  templateUrl: './privacy-check.component.html',
  styleUrls: ['./privacy-check.component.scss']
})
export class PrivacyCheckComponent implements OnInit {
  @Input('selectedPrivacy') selectedPrivacy: string = 'private';
  isShowPrivacyList: boolean = false;
  @Input('theme') theme ?: string = 'blue';
  @ViewChild('privacy') privacy: ElementRef | any;
  @ViewChild('privacyList') privacyList: ElementRef | any;
  @Output('onSelect') onSelect = new EventEmitter();
  @Input() isCreatePost: boolean = false;
  constructor() { }

  /**
   * @description
   * Host listner for out side click close
   *
   * @param {*} event
   * @memberof MultiSelectDropdownComponent
   */
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event ?: any): void {
    if (((this.privacy && !this.privacy.nativeElement.contains(event.target))) && (this.privacyList && !this.privacyList.nativeElement.contains(event.target))) {
      this.isShowPrivacyList = false;
    }
  }


  /**
   * @description
   * this method is used for showing the privacy list based on 'isShowPrivacyList' boolean value
   *
   * @memberof PrivacyCheckComponent
   */
  privacyCTA() {
    this.isShowPrivacyList = !this.isShowPrivacyList
  }

  /**
   * @description
   * this method is used for selecting the privacy
   *
   * @param {string} [privacyType]
   * @memberof PrivacyCheckComponent
   */
  selectPrivacy(privacyType : string) {
    this.selectedPrivacy = privacyType;
    this.isShowPrivacyList = false;
    this.onSelect.emit(this.selectedPrivacy);
  }

  ngOnInit(): void {
  }

}
