export const socialNetworkSection: any = [
  {
    title: "drive with a purpose",
    description: "briind believes purpose with profit period briind Social life is designed for member based organizations to create impacts by outreaching and do social networking among people with the same passions go with briind to engage and impact",
    icon: 'icon-drive-purpose'
  }, {
    title: "integrated reports", 
    description: "briind is capable to generate integrated and individual reports of every business processes and social interactions that happens in a member based organization as social media and business process software are integrated into a single application briinds reports and insights will help the leaders to take learned decisions",
    icon: 'icon-integraated-reports'
  }, {
    title: "total solution",
    description: "briind is designed as a platform for developing meaningful connections in social media and simplify business functions of a member based organization Member based businesses and organizations leverage from using briind a single platform for socializing with members and managing business",
    icon: 'icon-total-solution'
  }, {
    title: "next level social media",
    description: "briind is not just a social media it is family friendly and socially pleasant briinds intention is to enhance our social life with meaningful communications and to create an impact in our life",
    icon: 'icon-next-level-social-media'
  }, {
    title: "enhance member engagement",
    description: "members are the most valued asset of all member based organizations briind provides a novel way to effectively engage and meaningfully communicate with members",
    icon: 'icon-enhance-member-management'
  }, {
    title: "simplify business functions",
    description: "briind is the answer of the social enterprise organizations quest to simplify their business functions in one single application you will enjoy using briinds different applications for your business needs",
    icon: 'icon-simplify-business-functions'
  }
]


export const featuresYouHaveSectiontop: any = [
  {
    title: "Social Media integrated with business processes",
    description: "We created this application because of our long-standing struggles to manage different standalone business applications and different social media platforms we use in our member-based organizations. The goal was to create a single platform to manage business applications and social media to address the different needs of a member-based organization so people with the same passion can easily interact. Thus, Briind was born. Briind is scalable, predictable, secure, and customizable.",
    icon: 'icon-social-media-integrated-with-bussiness-purpose'
  },{
    title: "Briindlet and Pro-Briindlet",
    description: "Briindlet and Pro-Briindlet are tools for promoting events and projects to members and other social media. Organizations and individuals use these dynamic tools to create impact in their communities. Briindlet Reports will help organizations to make informed decisions about the needs of their members.",
    icon: 'icon-briindlet-and-pro-briindlet'
  },{
    title: "Built-in Communication Tools",
    description: "Briind’s social media platform is designed to promote networking for member-based organization. Therefore, Briind simplifies communication by enabling people and organizations to connect with others. Briind can manage all kinds of communications including messages, calls, emails, and texts.",
    icon: 'icon-in-built-communication-tools'
  }
]

export const featuresYouHaveSection: any = [
  {
    title: "You Save money",
    description: "Instead of subscribing to many standalone apps, you will subscribe to just one platform, tailored to your member-based organization’s needs. This means  you not only save money and human resources considerably but you also get the right features to manage your organization. Briind functions are selectable and customizable to fit your organization’s needs.",
    icon: 'icon-social-media-integrated-with-bussiness-purpose'
  },{
    title: "You can make better, more informed decisions",
    description: "Your organization is purpose driven, so along with financial reports, it is important to know your organization’s mission effectiveness within the community: socially, spiritually and economically, Briind helps ensure you understand the wellbeing of the community you lead.",
    icon: 'icon-briindlet-and-pro-briindlet'
  },{
    title: "You can focus on your mission, not your processes",
    description: "Briind is designed to be simple. Briind users experience a consistent “look, feel and workflow”, making tasks easier. You Can easily navigate between applications in the open dialogue box. It’s so simple to use!",
    icon: 'icon-in-built-communication-tools'
  }
]

export const userCardList: any = [
  {
    title: "why briind",
    description: "Briind is changing the way member-based organizations connect communities, do business, and inform leaders. ",
    icon: 'icon-why-briind',
    actionText: "know more",
    link:"/users/auth/signup"
  },{
    title: "briind user",
    description: "Sign Up for a Briind user account and start using Briind today! By using Briind, it is easy to connect with people having the same passion and manage your memberships in organizations and churches. Get this powerful tool and start creating impact in your community. Briind user account is free!",
    icon: 'icon-briind-user',
    actionText: "sign up",
    link:"/users/auth/signup"
  },{
    title: "briind partner",
    description: "All member-based organizations including profit and non-profit can open a Briind partner account. By creating a partner account, you can inspire, engage, and support communities with a different feel and zeal. Create your organization’s profile now. It’s  free!",
    icon: 'icon-partner',
    actionText: "sign up",
    link:"/organizations/auth/signup"
  }
]

export const getInTouchList: any = [
  {
    title: "Experience Briind’s efficiency and simplicity",
    description: "<ul><li>Interested in business applications?  Request a demo.</li><li>Is social media your thing?  Look at our FAQ and tutorials or sign up and explore.  IT’S FREE!</li><li>Want to know more? We're eager to answer your questions. Please email us.</li><li>Got ideas? We're eager to receive your suggestions. Call or email us.</li</ul>",
    icon: 'icon-discover white'
  },{
    title: "Be a change agent: invest in Briind!",
    description: "<ul> <li>To know how to invest in Briind and became an instrument of change, visit our corporate website: <a href='www.nowgenn.com' target='_blank' class='color-FFFFFF poppins-light'> <u> www.nowgenn.com </u> </a> </li> </ul>",
    icon: 'icon-network white',
  }
] 