import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { AppStore } from 'src/app/core/stores/app.store';
import { config } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupModel, UserModel, OrganizationModel, RbacRoleModel } from '../models';
import * as moment from 'moment';

import { Location } from '@angular/common';
import { AuthService } from './auth-service/auth.service';
import { UserStoreService } from '../../users/store/user-store.service';
import { OrganizationStoreService } from '../../organizations/store/organization-store.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public idleScreenFlag: boolean = false;
  public subscription: Subscription = new Subscription();
  public toastTimer: any;
  moment = moment;
  userType: string = 'users';

  // sessionCache:any = sessionStorage.getItem('briindletCache')
  // cacheData = JSON.parse(this.sessionCache)
  // sessionCache1:any = sessionStorage.getItem('briindletCache1')
  // cacheData1 = JSON.parse(this.sessionCache1)
  
  cacheData = new BehaviorSubject('title');
  cacheData1 = new BehaviorSubject('title');

  setCache(cache: any = []) {
    this.cacheData.next(cache);
  }
  setCache1(cache: any = []) {
    this.cacheData1.next(cache);
  }

  constructor(
    private location: Location,
    public http: HttpClient,
    public appStore: AppStore,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private userStoreService: UserStoreService,
    private organizationStoreService: OrganizationStoreService
  ) {
 
  }



  getPostDetail(id: number, type ?: string) {
    const param: any = {
      id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type}s/${config.postsURI}${config.postDetailURI}`,{params: param})
    .pipe(map(response => this.appStore.apiResp = response));
  }

  getVideos(id?: number, type ?: string) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type}s/${type}_videos/${id}/`)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }

  getLoginType(params?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.getUserTypeURI}`, {params: params})
    .pipe(map(response => this.userStoreService.apiResp = response));
  }


  getVideoDetails(id?: number, type ?: string) {
    const param: any = {
      id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type}s/${type}_videos/${config.videoDetailURI}`, {params: param})
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  getPhotosDetails(id?: number, type ?: string) {
    const param: any = {
      id: id
    }
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type}s/${type}_photos/${config.photoDetailURI}`, {params: param})
    .pipe(map(response => this.userStoreService.apiResp = response));
  }
  /**
   * @description
   * this method used for update the group details
   *
   * @param {*} [data]
   * @param {number} [id]
   * @returns this api will return a observalble
   * @memberof AppService
   */
  updateGroup(data?: any, id?: number) {
    return this.http.patch(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/`, data)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  getTemplateList() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.briindletURI}${config.templatesURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
  }
  

  isCurrentUser(id: any) {
    if (id == this.authService.getSessionData().orgId || id == this.authService.getSessionData().briind_id) {
      return true
    }
  }


  /**
   * @description
   * this method used for update the group details
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof AppService
   */
  createGroup(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}`, data)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * @description
   * this method used for getting Group Category List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
  getGroupCategoryList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.groupCategoryURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
  }

   /**
   * @description
   * this method used for getting Group Category List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
  getCustomAPI(url?: any, param?: any) {
    return this.http.get(`${url}`,{params: param})
    .pipe(map(response => this.appStore.apiResp = response));
  }
  

  /**
   * @description
   * this method used for getting Group Category List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
  customApiRequest(url?: any, data ?: any) {
    return this.http.post(`${url}`,data)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * @description
   * this method used for getting all notification
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
  getNotifications(userType: string , param : any, page: any) {
    param.page = page;
    return this.http.get(`${config.baseApiURL}${config.apiURI}${userType}/${config.notificationsURI}`,{params: param})
    .pipe(map(response => this.appStore.apiResp = response));
  }

   /**
   * @description
   * this method used for getting all notification
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
    markAsReadNotification() {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${config.markAsReadURI}`, {})
      .pipe(map(response => this.appStore.apiResp = response));
    }

    seenNotification(data: any) {
      if(this.appStore.notificationCount ==  0) return
      this.appStore.notificationCount = 0;
      this.appStore.notifLoading = true

      return new Promise(async (resolve,reject)=> {
        try {
          if(data) {
            const res: any = await this.http.put(`${config.baseApiURL}${config.apiURI}${config.markAsReadURI}`, data).toPromise();
          } else {
            const res: any = this.http.post(`${config.baseApiURL}${config.apiURI}${config.markAsReadURI}`, {}).toPromise()
          }

          setTimeout(()=> {
            this.appStore.notifLoading = false
          }, 5000)
          resolve(data)
        } catch(e) {

          setTimeout(()=> {
            this.appStore.notifLoading = false
          }, 5000)
          reject(e)
        }
      })
    }
  

   /**
   * @description
   * this method used for getting all notification
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
    getNotificationsCount(userType: string ) {
      return new Promise(async (resolve,reject)=> {
        try {
          const data: any = await this.http.get(`${config.baseApiURL}${config.apiURI}${userType}/${config.notificationCountURI}`).toPromise();
          if(this.appStore.notifLoading) return null;
          resolve(data)
        } catch(e) {
          reject(e)
        }
        
      })
    }

  /**
   * @description
   * this method used for getting all notification
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
  getTopNotifications(userType: string , param : any) {
    return new Promise(async (resolve,reject)=> {
      const data: any = await this.http.get(`${config.baseApiURL}${config.apiURI}${userType}/${config.topNotificationsURI}`,{params: param}).toPromise()
      if(data.length >= 0) {
        const newData = data.filter((newItem: any) => !this.appStore.newNotificationList.some((existingItem: any) => existingItem.id === newItem.id));
        this.appStore.newNotificationList = [...this.appStore.newNotificationList, ...newData];
        this.appStore.apiResp = this.appStore.newNotificationList;
        return newData;
      }
      resolve(false)
    })
  }

  /**
   * @description
   * this method used for getting Group Category List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof AppService
   */
   getGifDatas(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.sharedURI}${config.masterGifDatasURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
  }


  /* format date  */
  public formatDateForApi(date: any) {
    let d = new Date(date)
    return this.moment(d).format('YYYY-MM-DD')
  };

  /* format time */
  public formatTimeForApi(date: any) {
    let d = new Date(date);
    return this.moment(d).format('T00:00:00.000Z')
  };
  
  createBriindletPro(type: any, data: any) {
    return this.http.post<any>(`${config.baseApiURL}${config.apiURI}${type}/${config.briindletsURI}${config.proURI}`, data)
    .pipe(map(response => this.appStore.apiResp = response))
  }

  getCombinedUserList(param ?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.sharedURI}${config.combinedUsersURI}`, { params: param })
    .pipe(map(response => this.appStore.apiResp = response));
  }


  /**
   * @description
   * This method is used for getting City List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
  getCityList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.citiesURI}`, { params: param })
    .pipe(map(response => this.appStore.apiResp = response));
  }

  searchBriindId(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.getOrganizationURI}`, { params: param })
    .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting City List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   getCountryList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.countriesURI}`, { params: param })
    .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * @description
   * This method is used for getting City List
   *
   * @param {*} [param]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   getStateList(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.statesURI}`, { params: param })
    .pipe(map(response => this.appStore.apiResp = response));
  }
  

  /* Function to Trigger the Toast */
  public toggleToast(toast?: any) {
    clearTimeout(this.toastTimer);
    this.appStore.toast.type = (toast.type != null) ? toast.type.split(' ').join('') : this.appStore.toast.type.split(' ').join('');
    this.appStore.toast.context = (toast.context != null) ? toast.context : this.appStore.toast.context;
    this.appStore.toast.load = (toast.load != null) ? toast.load : this.appStore.toast.load;
    this.appStore.toast.position = (toast.type != null) ? toast.position : this.appStore.toast.position;

    this.toastTimer = setTimeout(() => {
      if (this.appStore.toast.load) { this.appStore.toast.load = false; }
    }, this.appStore.toast.pause * 1000);
  }


  /**
   * @description
   * Response handler function for handling all http response actions
   *
   * @param {*} [response]
   * @memberof AppService
   */
  public responseHandler(response ?: any){
    if(response.body){
      // //console.log("------------------------im in---------------------")
    }
  }

  /**
   * @description
   * Logout Session Method Handling
   *
   * @memberof AppService
   */
  public logoutSession() {
    const type = JSON.stringify(this.authService.getSessionData().type)
    this.authService.removingLocalSessionData();
    this.userStoreService.userId = '';
    this.userStoreService.userDetails = new UserModel();
    this.organizationStoreService.organizationId = '';
    this.organizationStoreService.organizationDetails = new OrganizationModel();
    this.organizationStoreService.rbacRole = new RbacRoleModel();
    this.appStore.userType = '';
    this.appStore.iconClass = '';
    // if (type == 'User') {
    //   this.router.navigate(['/'])
    // } else {
    //   window.location.href = `${config.webAppURL}?logout=true`
    // }
    this.appStore.saveLoginSessionState('logout')
    this.router.navigate(['/'])
  }



  /**
   * @description
   * Function to trigger the Popup
   *
   * @param {PopupModel} popup
   * @memberof AppService
   */
  public togglePopup(popup: PopupModel){
    this.appStore.popup.type = (((typeof popup.type !== 'undefined'))?popup.type:this.appStore.popup.type);
    this.appStore.popup.context = ((typeof popup.context !== 'undefined')?popup.context:this.appStore.popup.context);
    this.appStore.popup.load = ((typeof popup.load !== 'undefined')?popup.load:this.appStore.popup.load);
    this.appStore.popup.refreshParent = popup.refreshParent ? popup.refreshParent : false;
    this.appStore.popup.overlayClose = ((typeof popup.overlayClose !== 'undefined')?popup.overlayClose:this.appStore.popup.overlayClose);
    let element = document.getElementById('popupContainer') as HTMLElement;
    setTimeout(() => {
      if (element) {
        element.classList.add('active');
      }
      document.body.classList.add('overflowHidden');
      
    }, 100);
  }

  /**
   * @description
   * This api will Call when loading the Two Factor Auth Setup page
   *
   * @returns - this api will return a observalble with Countries JSON
   * @memberof AppService
   */
  getCountriesList() {      
    return this.http.get<any>(`${config.baseApiURL}${config.apiURI}${config.sharedURI}${config.countriesURI}`)
    .pipe(map(response => this.appStore.apiResp = response))
  }


  /**
   * @description
   * This api will Call when entering briind id in sign up
   *
   * @param {*} [data]
   * @returns - this api will return a observalble with briind id availabilty data
   * @memberof AppService
   */
  checkBriindAvailabilty(data ?: any) {
    return this.http.post<any>(`${config.baseApiURL}${config.apiURI}${config.sharedURI}${config.checkBriindAvailabiltyURI}`, data)
    .pipe(map(response => this.appStore.apiResp = response))
  }


   /**
    * @description
    * Checking Session Based on the Token
    * Navigate to login if jwt doesnt exisit
    *
    * @memberof AppService
    */
   public checkSession(){
    /* Check jwt is present or not */
    // if (!localStorage.getItem('jwt')) {
    //   switch (config.envType) {
    //     case 'local':
    //       this.router.navigate(["/"]);
    //       break;
    //     default:
    //       // window.location.href = config.webAppURL;
    //       break;
    //   }
    // }
  };

  /**
   * @description
   * Back Previous Method
   *
   * @memberof AppService
   */
  backToPreviousPage() {
    this.location.back();
  }

  /* Function to Identifies and trigger the Popup */
  public errorIdentifier(errorObj ?: any){
    let popupObj: PopupModel = {
      type:'error',
      load: true,
      overlayClose: true,
      context: errorObj /* Passing the entire error object to the popup component */
    }
    this.togglePopup(popupObj);
  }

  /* Function will trigger when device is offline */
  public deviceNetworkStatus(type: string){
    if(type == 'offline'){
      /* The Device is Offline */
      // console.log("------------------im offline----------------")
      // let popupObj: PopupModel = {
      //   type: 'noInternet',
      //   load: true,
      //   context: {
      //     title: 'no internet',
      //     description: 'not connected to network'
      //   },
      //   overlayClose: false,
      // }
      // this.togglePopup(popupObj);

    } else if(type == 'online'){
      /* The Device is Offline */
      // console.log("------------------on line----------------")

      // this.appStore.popup.load = false;
    }
  }

  public showToaster(data: any) {
    this.toggleToast({
      type: 'success',
      load: true,
      position: 'top-center',
      context: { description: `${data.message} successfully !` }
    });
  }


      /**
   * @description
   * This method is used for elastic search
   *
   * @param {number} param
   * @returns this api will return a observable
   * @memberof AppService
   */
    searchAll(param: any) {
      // TEMP ENV
      // return this.http.get(`${config.baseApiURL}${config.apiURI}${config.searchURI}`, { params: param })
      // .pipe(map(response => this.appStore.apiResp = response));
      return this.http.get(`${config.elasticSearchUrl}${config.apiURI}${config.searchURI}`, { params: param })
      .pipe(map(response => this.appStore.apiResp = response));
    }

    async searchAllUsersToPromise(param: any) {
      return new Promise(async (resolve: any, reject: any) => {
        const data: any = await this.http.get(`${config.baseApiURL}${config.apiURI}${config.searchURI}`, { params: param }).toPromise();
        if(param.search_type === 'user') {
          resolve(data.results.users || [])
        }
        if(param.search_type === 'organization') {
          resolve(data.results.organizations || [])
        }
      })
    }

    checkAdvertisementPrice(param: any) {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${config.briindadURI}${config.priceCalcURI}`, param)
      .pipe(map(response => this.appStore.apiResp = response));
    }

    bulkShare(param: any, type: string) {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${type}/${config.sharesURI}${config.bulkShareURI}`, param)
      .pipe(map(response => this.appStore.apiResp = response));
    }

    updateSharePost(param: any, id: number, type: string) {
      return this.http.put(`${config.baseApiURL}${config.apiURI}${type}/${config.sharesURI}${id}/`, param)
      .pipe(map(response => this.appStore.apiResp = response));
    }

    onlineUserStatusUpdate() {
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.onlineURI}${config.statusUpdateURI}`, {})
      .pipe(map(response => this.appStore.apiResp = response));
    }

    checkOrganizationBriindID(param: any) {
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.checkOrganizationURI}`,  { params: param })
      .pipe(map(response => this.appStore.apiResp = response));
    }

    getFriends(param ?: any) {
      return this.http.get(`${config.baseApiURL}${config.apiURI}${config.userURI}${config.friendsURI}`,  { params: param })
      .pipe(map(response => this.appStore.apiResp = response));
    }

    changeGroupRole(param: any) {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${config.changeRoleURI}`, param)
      .pipe(map(response => this.appStore.apiResp = response));
    }

    muteGroupMember(param: any) {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${config.muteURI}`, param)
      .pipe(map(response => this.appStore.apiResp = response));
    }

    unmuteGroupMember(param: any) {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${config.unmuteURI}`, param)
      .pipe(map(response => this.appStore.apiResp = response));
    }

    deleteGroup(id: number) {
      return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${id}/`,{})
      .pipe(map(response => this.appStore.apiResp = response));
    }

    removeMemberGroup(param: any) {
      return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.groupsURI}${config.removeMemberURI}`,{params: param})
      .pipe(map(response => this.appStore.apiResp = response));
    }

    
    

  /**
  * @description
  * This method is used for getting user/org activity logs
  *
  * @param {number} param
  * @returns this api will return a observable
  * @memberof AppService
  */
  getUserOrgActivityLogs() {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.activityLogsURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.activityLogsURI}${config.getMembersActivityURI}`),
    ]).pipe(map(
      response => this.appStore.apiResp = response
    ));
  }

/**
  * @description
  * This method is used for getting regions
  *
  * @returns this api will return a observable
  * @memberof AppService
  */
 getRegions() {
  return this.http.get(`${config.baseApiURL}${config.apiURI}${config.regionURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
}


  /**
   * @description
   * Gets user activity logs
   * @returns  this api will return a observable
   * @memberof AppService
   */
  getUserActivityLogs() {
    return forkJoin([
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.activityLogsURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.activityLogsURI}${config.getFriendsActivityURI}`),
      this.http.get(`${config.baseApiURL}${config.apiURI}${config.activityLogsURI}${config.getOrganizationActivityURI}`),
    ]).pipe(map(
      response => this.appStore.apiResp = response
    ));
  }



  /**
  * @description
  * this method is used for payment
  *
  * @param {*} [data]
  * @returns
  * @memberof AppService
  */
  proceedToPayment(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.paymentURI}${config.startPaymentURI}`, data)
    .pipe(map(response => this.appStore.apiResp = response));
  }

    /**
   * TODO: comment updateUserDeviceInfo
   * @description Updates user device info
   * @param data 
   * @returns  
   * @memberof AppService
   */
    updateUserDeviceInfo(data: any) {
      return this.http.post(`${config.baseApiURL}${config.apiURI}${config.deviceURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
    }
  
     /**
   * TODO: comment getUserDeviceInfo
   * @description Gets user device info
   * @returns  
   * @memberof AppService
   */
  getOnlineUsersList(type: string) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type}/${config.connectionsURI}${config.onlineUsersURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * TODO: comment getUserDeviceInfo
   * @description Gets user device info
   * @returns  
   * @memberof AppService
   */
  getOrganizationOnlineUsersList() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.organizationsURI}${config.membershipURI}${config.onlineMembersURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  
  /**
   * TODO: comment getUserDeviceInfo
   * @description Gets user device info
   * @returns  
   * @memberof AppService
   */
  getUserDeviceInfo() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.deviceURI}`)
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /**
   * TODO: comment changeUserOrgPassword
   * @description Changes user org password
   * @param data 
   * @param userType 
   * @returns  
   * @memberof AppService
   */
  changeUserOrgPassword(data: any, apiType: string) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${apiType}/${config.settingsURI}${config.changePasswordURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
  }



  /**
   * TODO: comment getTwoFactorCountriesList
   * @description Gets two factor countries list
   * @param apiType 
   * @returns  
   * @memberof AppService
   */
  getTwoFactorCountriesList(apiType: string) {      
    return this.http.get<any>(`${config.baseApiURL}${config.apiURI}${apiType}/${config.countryListURI}`)
    .pipe(map(response => this.appStore.apiResp = response))
  }

  /**
   * TODO: comment getBlockedUsersList
   * @description Gets blocked users list
   * @returns  
   * @memberof AppService
   */
  getBlockedUsersList() {
    return this.http.get<any>(`${config.baseApiURL}${config.apiURI}${config.blockUserURI}`)
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /**
   * TODO: comment unblockUser
   * @description Unblocks user
   * @param data 
   * @param id 
   * @returns  
   * @memberof AppService
   */
  unblockUser(data:any,id:number) {
    return this.http.put(`${config.baseApiURL}${config.apiURI}${config.blockUserURI}${id}/`,data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * TODO: comment searchUsersOrgsToBlock
   * @description Searchs users orgs to block
   * @param [param] 
   * @returns  
   * @memberof AppService
   */
  searchUsersOrgsToBlock(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.sharedURI}${config.allUsersURI}`, { params: param }).pipe(map(
      response => this.appStore.apiResp = response
    ));
  }


  /**
   * TODO: comment blockUser
   * @description Blocks user
   * @param data 
   * @returns  
   * @memberof AppStore
   */
  blockUser(data:any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.blockUserURI}`,data)
      .pipe(map(response => this.userStoreService.apiResp = response));
  }


  /**
   * TODO: comment addPaymentCard
   * @description Adds payment card
   * @param data 
   * @param apiType 
   * @returns  
   * @memberof AppService
   */
  addPaymentCard(data:any,apiType:string) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${apiType}/${config.settingsURI}${config.addCardURI}`, data)
    .pipe(map(response => this.appStore.apiResp = response));
  }


  getUserSettings(userType: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${userType}/${config.settingsURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  getBriindletPrice(userType: string, data: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${userType}/${config.briindletsURI}${config.priceCalcURI}`,data)
    .pipe(map(response => this.appStore.apiResp = response));
  }
 

  getSettingsRegions() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.settingsRegionsURI}`)
    .pipe(map(response => this.appStore.apiResp = response));
  }
 


  /**
   * TODO: comment getSavedPaymentCards
   * @description Gets saved payment cards
   * @param apiType 
   * @returns  
   * @memberof AppService
   */
  getSavedPaymentCards(apiType: string) {      
    return this.http.get<any>(`${config.baseApiURL}${config.apiURI}${apiType}/${config.settingsURI}${config.getSavedCardsURI}`)
    .pipe(map(response => this.appStore.apiResp = response))
  }

  deleteSavedCard(apiType: string,id?: any) {
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${apiType}/${config.settingsURI}${id}/${config.deleteSavedCardURI}`)
      .pipe(map(response => this.organizationStoreService.apiResp = response));
  }

  /**
   * @description
   * This method is used for Create City
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   createCity(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.citiesURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * @description
   * This method is used for Create State
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   createState(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.statesURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * @description
   * This method is used for Create Country
   *
   * @param {*} [data]
   * @returns this api will return a observalble
   * @memberof UserService
   */
   createCountry(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.countriesURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
  }
  /**
  * @description
  * this method is used for save payment status
  *
  * @param {*} [data]
  * @returns
  * @memberof AppService
  */
   savePaymentStatus(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.paymentURI}`, data)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  /**
   * @description
   * this method is use for save the get in touch data
   * 
   * @param {*} data
   * @methodof AppService
   */
  getInTouch(data?: any) {
    return this.http.post(`${config.baseApiURL}/${config.getInTouchURI}`, data)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  inviteUser(param:any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.sharedURI}${config.inviteUsersURI}`, param)
    .pipe(map(response => this.appStore.apiResp = response));
  }

  deleteUser() {
    console.log(" deleteUser ",`${config.baseApiURL}${config.apiURI}${config.deleteUserURI}`);
    return this.http.delete(`${config.baseApiURL}${config.apiURI}${config.deleteUserURI}`, {})
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  reportPost(data?: any) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${config.reportPost}`, data)
    .pipe(map(response => this.userStoreService.apiResp = response));
  }

  get instructionalVideoMemberManagementURI() {
    return config.instructionalVideoMemberManagementURI;
  }
}
