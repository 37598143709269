import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../users/services/user.service';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { AppService } from 'src/app/core/services/app.service';
import * as _ from 'lodash';
//import { PostActionComponent } from '../../../components/cards/post-card/post-action/post-action.component';

@Component({
  selector: 'app-view-post-popup',
  templateUrl: './view-post-popup.component.html',
  styleUrls: ['./view-post-popup.component.scss']
})
export class ViewPostPopupComponent implements OnInit, OnChanges {
  subscription: Subscription = new Subscription();
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  isShowFullScreen: boolean = false;
  post: any;
  selectedIndex: number = 0;
  media: any;
  mediaType: any;
  comments: any;
  constructor(
    public authService: AuthService,
    private appService: AppService,
    private userService: UserService,
    private organizationService: OrganizationService,
    //  private _postActionComponent: PostActionComponent,
  ) { }

  /**
   * @description
   * this method is used for like post
   *
   * @memberof ViewPostPopupComponent
   */
  likePost() {
    //if (!this.post.liked) {
    const param = {
      "user_id": this.authService.getSessionData().orgId,
      "item_id": this.post.id,
      "item_type": this.post.item_type
    }
    switch (this.authService.getSessionData().type.toLowerCase()) {
      case 'user':
        this.userLikePost(param);
        break;
      case 'organization':
        this.organizationLikePost(param);
        break;
      default:
        break;
    }
    // }
  }

  /**
   * @description
   * this method is used for call the user like api
   *
   * @param {*} param
   * @memberof ViewPostPopupComponent
   */
  userLikePost(param: any) {
    this.subscription.add(
      this.userService.likePost(param).subscribe((response: any) => {
        // this.post.liked = true;
        // this.post.likes_count  = this.post.likes_count;
        if (this.post.liked) {
          this.post.liked = false;
          this.post.likes_count = this.post.likes_count - 1;
        } else {
          this.post.likes_count = (this.post.likes_count || 0) + 1;
          this.post.liked = true;
        }
      })
    )
  }

  /**
   * @description
   * this method is used for call the organization like api
   *
   * @param {*} [param]
   * @memberof ViewPostPopupComponent
   */
  organizationLikePost(param?: any) {
    this.subscription.add(
      this.organizationService.likePost(param).subscribe((response: any) => {
        // this.post.liked = true;
        // this.post.likes_count += 1;
        if (this.post.liked) {
          this.post.liked = false;
          this.post.likes_count = this.post.likes_count - 1;
        } else {
          this.post.likes_count += 1;
          this.post.liked = true;
        }
      })
    )
  }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof ViewPostPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }

  /**
   * @description
   * method is used for getting event from pos action component
   *
   * @param {*} [event]
   * @memberof ViewPostPopupComponent
   */
  postCTA(event?: any) {
    switch (event) {
      case 'like':
        this.likePost();
        break;
      default:
        break;
    }
  }

  /**
   * @description
   * slider action method, left/right
   *
   * @param {string} action
   * @memberof ViewPostPopupComponent
   */
  sliderCTA(action: string) {
    if (action == 'previous') {
      this.selectedIndex == 0 ? (this.selectedIndex = this.popup.context.medias.length - 1) : (this.selectedIndex = this.selectedIndex - 1);
    } else {
      (this.selectedIndex >= this.popup.context.medias.length - 1) ? (this.selectedIndex = 0) : (this.selectedIndex = this.selectedIndex + 1);
    }
    // if (this.popup.context.postType.toLowerCase().includes('photo') || this.popup.context.postType.toLowerCase().includes('video')) {
    //   // //console.log(this.popup.context.medias[this.selectedIndex].id);
      
    //   // this.post = this.popup.context.medias[this.selectedIndex].post;
      
    //   // this.mediaType = this.popup.context.postType;
    // }
    this.media = this.popup.context.medias[this.selectedIndex];
    this.checkMediaType();
  }
  
  /**
   * @description 
   * this method is use for getting photo details
   * @param id [number]
   * @memberof ViewPostPopupComponent
   */
  getPhotoDetail(id?: number) {
    const userType = ( this.post?.post?.item_type?.toLowerCase()?.includes('user') || this.post?.item_type?.toLowerCase()?.includes('user')) ? 'user' : 'organization';    this.subscription.add(
      this.appService.getPhotosDetails(id, userType).subscribe((response: any) => {
        this.media = response;
        this.comments = response.comments;
      })
    )
  }
  
  /**
   * @description
   * this method is used for toggle the full screen
   *
   * @memberof ViewPostPopupComponent
   */
  fullScreenCTA() {
    let elem: any = document.documentElement;
    if (!this.isShowFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      this.isShowFullScreen = true;
    } else {
      this.isShowFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof ViewPostPopupComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.popup && changes.popup.currentValue) {
      this.selectedIndex = this.popup.context.selectedItem || 0;
      this.post = this.popup.context.post;
      //console.log(this.post)
      // this.comments = this.popup.context.post.comments;
      this.media = this.popup.context.medias[this.selectedIndex];
      this.checkMediaType();
      // if (this.popup.context.post) {
        
      //   // this.mediaType = this.popup.context.postType;
      // } else {
      //   this.post = this.popup.context.medias[this.selectedIndex] ? this.popup.context.medias[this.selectedIndex].post : {};
      //   this.media = this.popup.context.medias[this.selectedIndex];
      //   this.comments = this.popup.context.medias[this.selectedIndex]?.comments;
      //   // this.mediaType = this.popup.context.postType;
      //   if(this.post) this.post.comments = this.comments;
      // }
    }
  }

  getVideoDetail(id: number) {
    const userType = ( this.post?.post?.item_type?.toLowerCase()?.includes('user') || this.post?.item_type?.toLowerCase()?.includes('user')) ? 'user' : 'organization';
    this.subscription.add(
      this.appService.getVideoDetails(id, userType).subscribe((response: any) => {
        if(response) {
          this.media = response;
          this.comments = response.comments;
          //console.log(response)
        }
      })
    )
  }
  
  checkMediaType() {
    if (this.popup?.context?.postType == 'post' || this.popup?.context?.postType == 'albumPhoto'|| this.popup?.context?.postType == 'albumVideo') {
      if (this.popup?.context?.medias?.length) {
        this.mediaType = ((this.post?.item_type?.includes('User') || this.post?.post?.item_type?.includes('User')) ?  (`User${_.capitalize(this.media.type || (this.popup?.context?.postType?.toLowerCase()?.includes('photo') ? 'Photo' : 'Video'))}`) : (`Organization${_.capitalize(this.media.type || (this.popup?.context?.postType?.toLowerCase()?.includes('photo') ? 'Photo' : 'Video'))}`))
        this.comments = this.media?.data?.comments || this.media?.comments;
        this.checkAttachmentDetail();
      } else if (!this.popup?.context?.medias?.length) {
        this.mediaType = this.post.item_type ;
        this.comments = this.post?.comments;
      }
    } else if (this.popup?.context?.postType == 'photo' || this.popup?.context?.postType == 'video') {
      this.mediaType = ((this.post?.post?.item_type?.includes('User') || this.post?.item_type?.includes('User')) ?  (`User${_.capitalize(this.popup?.context?.postType)}`) : (`Organization${_.capitalize(this.popup?.context?.postType)}`))
      this.checkAttachmentDetail();
    }
  }


  checkAttachmentDetail() {
    if (this.popup?.context?.postType == 'photo' || this.media.type == 'photo' ||  this.popup?.context?.postType == 'albumPhoto') {
      this.getPhotoDetail(this.media?.data?.id || this.media?.id)
    } else if (this.popup?.context?.postType == 'video' || this.media.type == 'video' ||  this.popup?.context?.postType == 'albumVideo'){
      this.getVideoDetail(this.media?.data?.id || this.media?.id)
    }
  }

  ngOnInit(): void {
   
  }

  /**
 * @description
 * this method is used for updating comments in posts
 *
 * @param {*} event
 * @memberof ViewPostPopupComponent
 */
  updatePosts(event: any) {
    if (this.comments == null) {
      this.comments = event.result;
    } else {
      this.comments.push(event.result);
    }
  }

  /**
   * @description
   * this method is used for open confirmation for delete an photo
   *
   * @param {*} [id]
   * @memberof PhotosComponent
   */
  removePost(id?: number) {
    this.appService.togglePopup({
      type: 'confirmation',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        title: "confirmation",
        userType: this.authService.getSessionData().type.toLowerCase(),
        data: { id: id, message: "are you sure you want to delete ?", action_type: "deleteAlbum" },
      }
    });
  }

  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   *
   * @memberof ViewPostPopupComponent
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
