<div class="bdrRadius6px bdr-ebebeb bg-F9F9F9">
    <div class="padleft20 padtop20 bold txtTransformUp txtLeft {{customizeUI?.event_title?.title.font_family}}" *ngIf=" data?.name || isPreview" [ngStyle]="{'font-size.px': customizeUI?.event_title?.title.font, 'color': customizeUI?.event_title?.title?.color}">
        {{(data?.name ? data?.name : isPreview ? 'Briind Event' : '') | translate}}</div>
    <div class=" pad20 padtop10  {{customizeUI?.event_title?.description?.font_family}}" *ngIf=" data?.description || isPreview" [ngStyle]="{'font-size.px': customizeUI?.event_title?.description?.font, 'color': customizeUI?.event_title?.description?.color}">
        {{(data?.description ? data?.description : isPreview ? 'lorem ipsum' : '') | translate}}
    </div>
    <div class="posRelative">
        <app-simple-carousel [autoSlide]="'false'" *ngIf="data?.photos?.length">
            <ng-container *ngFor="let item of data?.photos">
                <ng-container *carouselItem>
                    <video class="width100  minHeight350px maxHeight350px " height="350" width="100%" controls *ngIf="item?.type  == 'video'">
                        <source [src]="item?.photo || item?.base64" type="video/mp4">
                        <source [src]="item?.photo || item?.base64" type="video/ogg">
                        Your browser does not support HTML video.
                      </video>
                    <img [src]="item?.base64 || item?.photo" class="width100  minHeight350px maxHeight350px " *ngIf="item?.type  == 'photo'" />
                </ng-container>
            </ng-container>
        </app-simple-carousel>
        <img *ngIf="!data?.photos?.length && isPreview" src="assets/images/temp-2.png" class="width100" />
    </div>
    <div class="mrgntop15 flex flexAlignItemsStretch">
        <div class="width35 flex flexDirectionCol flexJustifyCenter" *ngIf="data?.event_from || isPreview">
            <div class="padleft20 padbtm5 bold  txtTransformUp txtLeft {{customizeUI?.venue_and_time?.time?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.time?.font, 'color': customizeUI?.venue_and_time?.time?.color}">
                {{moment(data?.event_from).format('MMMM DD')}} - {{moment(data?.event_to).format('MMMM DD')}}
            </div>
            <div class="padleft20" style="font-family: Segoe UI Italic;font-size: 13px;
color: #515151;">{{'start' | translate}}: {{moment(data?.event_from).format('hh:mm A')}} - {{'end' | translate}} : {{moment(data?.event_to).format('hh:mm A')}}</div>
        </div>
        <div class="width65 padright20" *ngIf="data?.venue || data?.venue_name || isPreview">
            <div class="temp2-bg height100p flex flexAlignItemsCenter">
                <div class=" padbtm10 padtop10 padleft20 {{customizeUI?.venue_and_time?.venue?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.venue?.font, 'color': customizeUI?.venue_and_time?.venue?.color}">
                    {{((data?.venue || data?.venue_name) ? (data?.venue?.name || data?.venue_name || data.venue) : isPreview ? 'At Briind' : '') | translate}}
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="data?.guest?.length || isPreview">
        <div class="{{customizeUI?.guest_speakers?.speakers_title?.font_family}} padbtm15 padleft20 padtop20" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_title?.font, 'color': customizeUI?.guest_speakers?.speakers_title?.color}">
            {{'guest_speakers' | translate}}</div>
        <div class="flex padleft20 padright20 mrgnauto flexWrap padbtm25">
            <ng-container *ngIf="data?.guest?.length && !isPreview">
                <div class="txtCenter width30 padtop15 mrgn5 padbtm20 bg-FFFFFF bdrRadius6px boxShadow-RGBA00005" *ngFor="let item of data?.guest">
                    <app-profile-thumbnail [image]="item.photo" [imageClass]="'d70'"></app-profile-thumbnail>

                    <div class="padtop10  {{customizeUI?.guest_speakers?.speakers_name?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                        {{item?.name}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isPreview">
                <div class="txtCenter width30 mrgn5 padtop15 padbtm20 bg-FFFFFF bdrRadius6px boxShadow-RGBA00005" *ngFor="let item of briindGuest">
                    <app-profile-thumbnail [image]="item.photo" [imageClass]="'d70'"></app-profile-thumbnail>
                    <div class="padtop10  {{customizeUI?.guest_speakers?.speakers_name?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                        {{item?.name}}
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="data?.registration_guidelines || isPreview">
        <div class="{{customizeUI?.registration_guideline?.title?.font_family}} padleft20 " [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.title?.font, 'color': customizeUI?.registration_guideline?.title?.color}">
            {{'registration guidlines' | translate}}</div>
        <div class="{{customizeUI?.registration_guideline?.description?.font_family}} padleft20" [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.description?.font, 'color': customizeUI?.registration_guideline?.description?.color}">
            {{(data?.registration_guidelines ? data?.registration_guidelines : isPreview ? 'lorem ipsum' : '') | translate}}
        </div>
    </ng-container>

    <ng-container *ngIf="data?.who_can_attend || isPreview">
        <div class="{{customizeUI?.guest_speakers?.who_can_attend_title?.font_family}} padtop20 padleft20" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_title?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_title?.color}">
            {{'who can attend' | translate}}</div>
        <div class="padleft20 {{customizeUI?.guest_speakers?.who_can_attend_description?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_description?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_description?.color}">
            {{(data?.who_can_attend ? data?.who_can_attend : isPreview ? 'lorem ipsum' : '') | translate}}
        </div>
    </ng-container>

    <div class="padtop20 posRelative mrgnbtm10 padleft20 mrgntop20">
        <div class="flex flexDirectionCol flexJustifyCenter flexAlignItemsCenter" style="position: absolute; border-radius: 50%; width: 90px; height: 90px; background: #fc696e; top: 0;">
            <div class="{{customizeUI?.registration_fee?.title?.font_family}} txtCenter" [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.title?.font, 'color': customizeUI?.registration_fee?.title?.color}">
                <span class="txtTransformUp">{{'reg' | translate}}</span> {{'fee' | translate}}</div>
            <div class="{{customizeUI?.registration_fee?.value?.font_family}} txtCenter" [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.value?.font, 'color': customizeUI?.registration_fee?.value?.color}">
                {{'amount in number' | translate: {amount: data?.fee || 0} }}
            </div>
        </div>
        <div class="padleft70" *ngIf="countdownTimer">
            <span class="poppins-semibold bdrRadius6px" style="display: inline-block;background: #4e4e4e;padding: 17px 35px 16px;font-size: 14px;font-weight: 600;color: #ffffff;">{{'time left' | translate}}
                    {{countdownTimer}}</span>
        </div>
    </div>

    <div class="flex padbtm20">
        <div class="padtop15 txtCenter width70 {{customizeUI?.date?.date?.font_family}}" *ngIf="data?.registration_last_date || isPreview" [ngStyle]="{'font-size.px': customizeUI?.date?.date?.font, 'color': customizeUI?.date?.date?.color}">
            {{'last date of registration' | translate}} - {{moment(data?.registration_last_date).format('DD. MMMM')}}
        </div>
        <!-- <div class="padtop15 padright20 txtRight padbtm15 width30" *ngIf="data?.website_link || isPreview">
                <a [href]="data?.website_link" class="{{customizeUI?.button_customization?.website?.font_family}}"
                    [ngStyle]="{'font-size.px': customizeUI?.button_customization?.website?.font , 'color': customizeUI?.button_customization?.website?.color}">{{data?.website_link || 'www.events.com'}}</a>
            </div> -->
    </div>

    <div class="flex flexJustifyBetween  bdrRadiusBottomLeftt6px bdrRadiusBottomRight6px overflowHidden">
        <div class="flex1" *ngIf="data?.organization_link || isPreview">
            <button (click)="saveBriindletViewProfile(data?.organization_link, 'organization_link')" class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started" class="{{customizeUI?.button_customization?.view_profile?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.view_profile?.font, 'background-color': customizeUI?.button_customization?.view_profile?.background_color , 'color': customizeUI?.button_customization?.view_profile?.color}">
                    {{customizeUI?.button_customization?.view_profile?.button_name | translate}}
                </button>
        </div>
        <div class="flex1" style="border-left: 2px solid #EEEEEE;" *ngIf="data?.cause  || isPreview">
            <button (click)="saveBriindletViewProfile(data?.cause, 'cause')" class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started" class="{{customizeUI?.button_customization?.donate_now?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.button_customization?.donate_now?.font, 'background-color': customizeUI?.button_customization?.donate_now?.background_color , 'color': customizeUI?.button_customization?.donate_now?.color}">
                    {{customizeUI?.button_customization?.donate_now?.button_name | translate}}
                </button>
        </div>
        <div class="flex1" style="border-left: 2px solid #EEEEEE;" *ngIf="data?.website_link || isPreview">
            <button (click)="saveBriindletRegister(data?.website_link, 'website_link')" class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started" class="{{customizeUI?.button_customization?.website?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.button_customization?.website?.font, 'background-color': customizeUI?.button_customization?.website?.background_color || 'rgb(0, 0, 139)' , 'color': customizeUI?.button_customization?.website?.color}">
                    {{customizeUI?.button_customization?.website?.button_name || 'www.events.com'}}
                </button>
        </div>
    </div>
</div>