<div @fadeIn id="toast-container" *ngIf="appStore.toast.load"  class="toast-{{appStore.toast.position}}">
    <div class="toast toast-{{appStore.toast.type}} flex flexAlignItemsCenter">
        <div class="">
            <i class="icns icon-{{appStore.toast.type}} d35 toast-icon"></i>
        </div>
        <div class="flex1 padleft5">
            <div class="toast-title" [innerHTML]="appStore.toast?.context?.title"
                *ngIf="appStore.toast?.context?.title"></div>
            <div class="toast-message" [innerHTML]="appStore.toast?.context?.description"
                *ngIf="appStore.toast?.context?.description"></div>
        </div>
    </div>
</div>