import { ActionModel } from './action.model';
import { HighSchoolModel, UniversityListModel, WorkListModel, CityLivedInModel, MoviesModel, ReligiousViewModel } from './shared.model';
export class UserModel {
    briind_id?: any = '';
    countries?: string[] = [];
    country_updated?: boolean = false;
    cover_photo?: string = '';
    email?: string = '';
    first_name?: string = '';
    id?: number = 0;
    last_name?: string = '';
    name?: string = '';
    profile_pic?: string = '';
    profile_completion?: number = 0;
    personal_information?: PersonalInformationModel = new PersonalInformationModel();
    contact_information?: ContactInformationModel = new ContactInformationModel();
    user_high_school_information: HighSchoolModel[] = [];
    user_university_information: UniversityListModel[] = [];
    user_work_information: WorkListModel[] = [];
    user_skill_information: SkillModel[] = [];
    user_interest_information: any[] = [];
    places_information: PlaceLivedInModel = new PlaceLivedInModel();
    user_movies_information: MoviesModel[] = [];
    user_religious_information: ReligiousViewModel[] = [];
    user_political_information: ReligiousViewModel[] = [];
    user_sports_information: MoviesModel[] = [];
    user_musics_information: MoviesModel[] = [];
    overview: OverviewModel = new OverviewModel();
    settings: SettingsModel = new SettingsModel();
    is_friend ?: boolean = false;
    is_own_profile ?: boolean = false;
    is_org_member ?: any ;
    is_friend_status ?: string = '';
    connection_id: string = '';

}


export class PlaceLivedInModel {
    home_town?: string = '';
    current_city?: any = '';
    cities_lived_in: CityLivedInModel[] = [];
}
export class PersonalInformationModel {
    about_me?: string = '';
    dob?: string = '';
    gender?: string = '';
    relationship_status?: string = '';
}

export class ContactInformationModel {
    facebook_link?: string = '';
    id?: number = 0;
    instagram_link?: string = '';
    website_link?: string = '';
    youtube_link?: string = '';
    twitter_link?: string = '';
    other_link?: string = '';
    mobile_number?: string = '';
    primary_email?: string = '';
    secondary_email?: string = '';
    secondary_phone_number?: string = '';
    user?: number = 0;
}

export class SkillModel {
    id?: string = '';
    name?: string = '';
}

export class OverviewModel {
    high_school?: HighSchoolModel = new HighSchoolModel();
    lives_in?: string = '';
    relationship_status?: string = '';
    work?: WorkListModel = new WorkListModel();
    past_work?: WorkListModel = new WorkListModel();
}

export class InvitesUserModel {
    group?: number = 0;
    id?: number = 0;
    invited_at?: string = '';
    member_from_date?: string = '';
    organization?: UserModel[] = [];
    role?: string = '';
    status?: string = '';
    user?: UserModel[] = [];
}

export class SuggestedUserModel {
    id?: number = 0;
    photo?: string = '';
    name?: string = '';
    connection_detail?: any = {};
    mutual_friends?: number = 0; 
    actions: ActionModel[] = []  
}

export class PendingAcceptedUserModel {
    id ?: number = 0;
    targeted_user ?: any = {};
    status ?: string = '';
    invited_at ?: string = '';
    send_user ?: [];
    accepted_at ?: string = '';
    blocked_at ?: string = '';
    photo?: string = '';
    name?: string = '';
    connection_detail ?: any = {};
    mutual_friends?: number = 0;
    actions: ActionModel[] = [];
    isChecked: boolean = false;
}

export class SettingsModel {
    id: number = 0;
    server_timezone: string = '';
    timezone_hour_difference: number = 0;
    region_name: string = '';
    send_friend_request: string = '';
    see_friends_list: string = '';
    email_lookup: string = '';
    phone_lookup: string = '';
    share_your_posts: boolean = false;
    hide_comment_words: boolean = false;
    see_tagged_post: string = '';
    review_post: boolean = false;
    review_tags: boolean = false;
    language: string = '';
    show_about: string = '';
    show_gender: string = '';
    show_dob: string = '';
    show_relationship: string = '';
    show_highschool: string = '';
    show_university: string = '';
    show_works: string = '';
    show_skills: string = '';
    show_intrests: string = '';
    show_current_city: string = '';
    show_hometown: string = '';
    show_other_cities: string = '';
    show_email: string = '';
    show_sec_email: string = '';
    show_phone: string = '';
    show_sec_phone: string = '';
    show_website: string = '';
    show_fb_link: string = '';
    show_twitter_link: string = '';
    show_insta_link: string = '';
    show_youtube_link: string = '';
    show_fav_movies: string = '';
    show_religious_views: string = '';
    show_political_views: string = '';
    show_fav_sports: string = '';
    show_fav_music: string = '';
    comment_push_notify: boolean = false;
    tags_push_notify: boolean = false;
    reminders_push_notify: boolean = false;
    requests_push_notify: boolean = false;
    requests_email_notify: boolean = false;
    requests_sms_notify: boolean = false;
    birthdays_push_notify: boolean = false;
    birthdays_email_notify: boolean = false;
    birthdays_sms_notify: boolean = false;
    group_push_notify: boolean = false;
    group_email_notify: boolean = false;
    group_sms_notify: boolean = false;
    events_push_notify: boolean = false;
    events_email_notify: boolean = false;
    events_sms_notify: boolean = false;
    org_follow_push_notify: boolean = false;
    org_follow_email_notify: boolean = false;
    org_follow_sms_notify: boolean = false;
    menu_order: any[] = [];
    region: string = '';
    user: number = 0;
}
