<div id="popupContainer" [ngClass]="appStore.popup.type+'PopUp'" class="flex flexJustifyCenter flexAlignItemsCenter "
  *ngIf="appStore.popup.load">
  <div id="popupOverlay" (click)="closePopup('overlay')"></div>
  <div id="popupContentBox" class="custom-scrollbar">
    <div [ngSwitch]="appStore.popup.type"  [ngClass]="{'studentReportMedia': appStore.popup.type == 'studentReport'}">
      <div *ngSwitchCase="'chooseAccount'">
        <app-choose-your-account-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-choose-your-account-popup>
      </div>
      <div *ngSwitchCase="'volunteerDetails'">
        <app-volunteer-details-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-volunteer-details-popup>
      </div>
      <div *ngSwitchCase="'userPreview'">
        <app-user-preview-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-user-preview-popup>
      </div>
      <div *ngSwitchCase="'organizationPreview'">
        <app-organization-preview-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-organization-preview-popup>
      </div>
      <div *ngSwitchCase="'profileCompletedSuccess'">
        <app-profile-completed-success-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-profile-completed-success-popup>
      </div>
      <div *ngSwitchCase="'addMilestone'">
        <app-add-milestone-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-add-milestone-popup>
      </div>

      <div *ngSwitchCase="'viewActivityImages'">
        <app-view-activity-images-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-view-activity-images-popup>
      </div>
      <div *ngSwitchCase="'addLeader'">
        <app-add-leader-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-add-leader-popup>
      </div>
      <div *ngSwitchCase="'addMembershipField'">
        <app-add-or-edit-membership-field-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-add-or-edit-membership-field-popup>
      </div>
      <div *ngSwitchCase="'previewMembershipForm'">
        <div id="membershipTemplate" class="custom-scrollbar">
          <app-organization-membership-form-preview (close)="closePopup($event)" [popup]="appStore.popup?.context">
          </app-organization-membership-form-preview>
        </div>
      </div>
      <div *ngSwitchCase="'createAlbum'">
        <app-create-album (close)="closePopup($event)" [popup]="appStore.popup"></app-create-album>
      </div>
      <div *ngSwitchCase="'membershipRejectReason'">
        <app-membership-reject-reason (close)="closePopup($event)" [popup]="appStore.popup"></app-membership-reject-reason>
      </div>
      
      <div *ngSwitchCase="'createGroup'">
        <app-create-group-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-create-group-popup>
      </div>
      <div *ngSwitchCase="'inviteFriends'">
        <app-invite-friends-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-invite-friends-popup>
      </div>
      <div *ngSwitchCase="'friends'">
        <app-friends-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-friends-popup>
      </div>
      <div *ngSwitchCase="'shareToUsers'">
        <app-share-to-friends-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-share-to-friends-popup>
      </div>
      <div *ngSwitchCase="'shareNow'">
        <app-share-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-share-popup>
      </div>
      <div *ngSwitchCase="'shareToGroup'">
        <app-share-to-group-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-share-to-group-popup>
      </div>
      <div *ngSwitchCase="'confirmation'">
        <app-confirmation-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-confirmation-popup>
      </div>
      <div *ngSwitchCase="'warning'">
        <app-confirmation-popup (close)="closePopup($event)" [popup]="appStore.popup" [removeConfirm]="true"></app-confirmation-popup>
      </div>
      <div *ngSwitchCase="'editCause'">
        <div class="pad20 bdrRadius6px bg-white posRelative organization" id="editCause">
          <div class="header color-18878C font25 lineHeight40px poppins-semibold txtCenter">
            {{'edit cause' | translate}}
          </div>
          <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup('icon');">×</span>
          <div class="maxHeight80vh overflowAuto custom-scrollbar">
            <app-add-or-edit-cause [data]="appStore?.popup?.context" (close)="closePopup($event)">
            </app-add-or-edit-cause>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'createPost'">
        <app-create-post-popup (close)="closePopup($event)"
          [uploadedFiles]="appStore?.popup?.context?.uploadedFiles || []" [popup]="appStore.popup">
        </app-create-post-popup>
      </div>
      <div *ngSwitchCase="'createbriindletEvent'">
        <app-create-briindlet-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-create-briindlet-popup>
      </div>
      <div *ngSwitchCase="'viewPost'">
        <app-view-post-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-view-post-popup>
      </div>
      <div *ngSwitchCase="'OrganizationPromote'">
        <app-organization-promote-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-organization-promote-popup>
      </div>

      <div *ngSwitchCase="'forwardMembership'">
        <app-forward-appilcation-detail-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-forward-appilcation-detail-popup>
      </div>

      <div *ngSwitchCase="'createRbac'">
        <app-create-rbac-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-create-rbac-popup>
      </div>

      <div *ngSwitchCase="'updateMemberRole'">
        <app-update-member-role (close)="closePopup($event)" [popup]="appStore.popup"></app-update-member-role>
      </div>

      <div *ngSwitchCase="'addRoleToMember'">
        <app-add-role-to-member-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-add-role-to-member-popup>
      </div>

      <div *ngSwitchCase="'createMemberGroup'">
        <app-create-organization-member-group-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-create-organization-member-group-popup>
      </div>

      <div *ngSwitchCase="'createOrganizationEvent'">
        <app-create-organization-event-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-create-organization-event-popup>
      </div>

      <div *ngSwitchCase="'membershipGroupMembersList'">
        <app-show-group-members-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-show-group-members-popup>
      </div>


      <div *ngSwitchCase="'elasticSearch'">
        <app-elastic-search-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-elastic-search-popup>
      </div>
      <div *ngSwitchCase="'advertisementPayment'">
        <app-advertisement-payment-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-advertisement-payment-popup>
      </div>

      <div *ngSwitchCase="'addPaymentCard'">
        <app-add-payment-card-popup [userType]="appStore.popup?.context?.userType" (close)="closePopup($event)" [popup]="appStore.popup"></app-add-payment-card-popup>
      </div>
      <div *ngSwitchCase="'causeDonatePayment'">
        <app-cause-donate-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-cause-donate-popup>
      </div>
      <div *ngSwitchCase="'inviteUser'">
        <app-invite-user-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-invite-user-popup>
      </div>
      <div *ngSwitchCase="'photoSelection'">
        <app-photo-selection-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-photo-selection-popup>
      </div>
      
      <div *ngSwitchCase="'instructionalVideoManagement'">
        <app-instructional-video-management-popup (close)="closePopup($event)" [popup]="appStore.popup">
        </app-instructional-video-management-popup>
      </div>
      <div *ngSwitchCase="'error'">
      </div>

      <div *ngSwitchDefault></div>
    </div>
  </div>
</div>
