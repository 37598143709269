<div id="create-organization-group" class="bdrRadius6px organization bg-white pad20 posRelative overflowHidden">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div id="create-group-popup">
        <div class="width100 mrgnauto">
            <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
                {{ popup?.context?.title | translate}}
            </div>
        </div>
        <div class="width100 ">

            <form [formGroup]="form" (submit)="onSubmit();"
                class="width100 maxHeight75vh overflowAuto custom-scrollbar mrgntop15">
                <app-upload-photo (imageEvent)="setCoverPhoto($event);"
                    [placeholderImage]="'assets/images/ministry_pic_background.png'"></app-upload-photo>
                <div class="width100 mrgntop15">
                    <input class="card-input"
                        [ngClass]="{'bdr-red': (form.controls?.name?.invalid && (form.controls?.name?.dirty || form.controls?.name?.touched))}"
                        formControlName="name" [placeholder]="'enter group title' | translate" />
                </div>
                <div class="width100 mrgntop15 timeline-label font18 poppins-medium txtCenter">
                    {{'add people to group' | translate}}
                </div>
                <div class="width100 flex flexJustifyCenter mrgntop10">
                    <app-search-input (search)="searchUser($event);" [icon]="'searchIcon'"></app-search-input>
                </div>
                <div class="width100 padbtm15" *ngIf=" popup?.context?.membersList?.length != 0">
                    <div *ngFor="let item of popup?.context?.membersList" class="mrgntop15">
                        <app-general-card [data]="item" [type]="'OrganizationGroupMembers'"
                            (cardActionCTA)="cardActionCTA($event)" [actions]="[]"></app-general-card>
                    </div>
                </div>
                <div class="padbtm15" *ngIf=" popup?.context?.membersList?.length == 0">
                    <app-empty-state></app-empty-state>
                </div>
                <div class="width100 flex padbtm15">
                    <button (click)="closePopup()"
                        class="btn-get-started minWidth90px mrgnright10 bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad1030 cancel-btn"
                        type="button">{{'cancel' | translate}}</button>
                    <button [disabled]="form.invalid || selectedUserIds.length == 0"
                        class="btn-get-started minWidth90px mrgnright10 bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad1030 btn-user-default"
                        type="submit">{{'create' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>