import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { getImagePath } from 'src/app/core/essentials/common.libs';
import { CameraService } from 'src/app/core/services/camera.service';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {
  @ViewChild('image') public image: any;
  @ViewChild('uploadCover') public uploadCover: any;
  @Output() imageEvent = new EventEmitter(); // will return event with image obj
  @Input() placeholderImage: string = '';
  @Input() isMultiShow: boolean = false;
  @Input() customHeightWidth: string = '';
  showCropper: boolean = false;
  imageChangedEvent: any = '';
  @Input() cropingRatio: any = 3/1; // crope ratio need to pass like his 2/2
  isWeb = true
  constructor(private cameraService: CameraService) { 
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isWeb = false
  }

  async getImage(event: any) {
    if(!this.isWeb) this.imageChangedEvent = await this.cameraService.getPhoto();
    this.showCropper =true;
  }

  getWebImage(event: any) { 
    this.imageChangedEvent = event;
    this.showCropper =true;
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  uploadImage(event: any) {
    this.getBase64(event).then(data => {
      if (!this.isMultiShow) {
        this.image.nativeElement.src = data;
      }
      this.imageEvent.emit({base64:  data, file: event})
      this.showCropper = false;
    })
  }

  clearFile() {
    if (this.uploadCover) {
      this.uploadCover.nativeElement.value = '';
    }
  }

  ngOnInit(): void {
  }

}
