import { Injectable } from '@angular/core';
import { action, observable } from 'mobx-angular';
import { SelectListModel } from 'src/app/core/models';
import { AdvertisementModel, AdvertisementTimelineModel } from 'src/app/core/models/briindlet.model';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementStoreService {
  @observable apiResp: any;
  @observable advertisementList: AdvertisementModel[] = [];
  @observable advertisementTimelineList: AdvertisementTimelineModel = {};
  @observable briindletProList: SelectListModel[] = [];
  constructor() { }

  /**
   * @description
   * setting Advertisement list
   *
   * @memberof AdvertisementStoreService
   */
   @action getAdvertisementList() {
    this.advertisementList = this.apiResp;
  }

  /**
   * @description
   * setting Briindlet Pro list
   *
   * @memberof AdvertisementStoreService
   */
   @action getBriindletProList() {
    this.briindletProList = this.apiResp;
  }

   /**
   * @description
   * setting advertisement timeline list
   *
   * @memberof AdvertisementStoreService
   */
    @action getAdvertisementTimelineList() {
      this.advertisementTimelineList = this.apiResp;
    }
}
