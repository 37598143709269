import { AuthService } from './../../../../core/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss']
})
export class HeaderNotificationComponent implements OnInit {
  @Input() notificationList: any = [];
  userType: any;
  constructor(
    private router: Router,
    private authService: AuthService,
    private appService: AppService,
    private appStore: AppStore
  ) {
    this.userType = this.authService.getSessionData().type.toLowerCase();
  }

  ngOnInit(): void {
  }

  navigateToPost(item: any, index: any) {
    // switch (true) {
    //   case item.content_type == "Membership":
    //     if ( this.userType == 'user') {
    //       this.router.navigate([`/${this.userType}s`,this.authService.getSessionData().briind_id,"membership","membership-trending"])
    //     } else {
    //       this.router.navigate([`/${this.userType}s`,this.authService.getSessionData().briind_id,"membership","submitted"])
    //     }
    //     break;
    //   case item.content_type == "UserPost" || item.content_type == "Organization":
    //     this.router.navigate([`/${this.userType}s/${this.authService.getSessionData().briind_id}/timeline`],  { queryParams: {post_ids: item.content_id}})
    //     break;
    //   case item.content_type == "Connection" :
    //     this.router.navigate([`/notification`],  { queryParams: { type: "social media" }})
    //     break;
    //   case item.content_type == 'UserGroupMembership':
    //     this.router.navigate([`/notification`],  { queryParams: {type: "invitation"}})
    //     break; 
    //   case (item.notification_type?.toLowerCase() === 'invitations' && item.notification_sub_type?.toLowerCase() === 'leadership'):
    //     this.router.navigate([`/${this.userType}s/${this.authService.getSessionData().briind_id}/timeline`]);
    //     break;
    //   case item.content_type == "OrganizationShare" || item.content_type == "UserShare" :
    //     this.router.navigate([`/${this.userType}s/${this.authService.getSessionData().briind_id}/timeline`],  { queryParams: {share_ids: item.content_id}})
    //     break;  
    //   case ((item.content_type.toLowerCase() == 'user' || item.content_type.toLowerCase() == 'organization')):
    //     this.router.navigate([`/${item.content_type}s/${this.authService.getSessionData().briind_id}/timeline`],  { queryParams: {post_ids: item.content_id}})
    //     break;  
    //   case item.content_type.toLowerCase() == 'user' || item.content_type.toLowerCase() == 'organization':
    //     this.router.navigate([`/${item.content_type.toLowerCase()}s/${item.content_id}/timeline`])
    //     break;  
    //   default:
    //     this.router.navigate([`/notification`]);
    //     break;
    // }
    this.router.navigate([`/notification`]);
  }
}
