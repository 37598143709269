/**
 * @description
 * Video Model
 * @export VideoModel
 * @class VideoModel
 */
export class VideoModel {
    id ?: number = 0;
    video ?: string ="";
    caption?: string ="";
    created_at ?: string ="";
    updated_at?:  string = '';
    video_url ?: string=""
    user_id?: number = 0;
  }
  