<div id="createalbum-popup" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar {{popup?.context?.parent}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div id="social-create-album-pop" class="overflowHidden">

        <div class="color-122A4D poppins-semibold font18 padtop30 padbtm30 txtCenter">{{'share to a group' | translate}}
        </div>
        <div class="maxHeight70vh overflowAuto custom-scrollbar minHeight50vh">
            <app-share-post-detail [user]="popup.context?.user" [post]="popup?.context?.post"
                (shareMessage)="shareMessageEmit($event)"></app-share-post-detail>

            <div class="flex flexAlignItemsCenter padbtm30">
                <div class="posRelative width55 mrgnauto">
                    <i class="icon-search green icns d15 posAbsolute posTop9px posLeft7px"></i>
                    <input class="searchInput boxShadow-RGBA00005 width100" placeholder="Search"
                        [(ngModel)]="searchInput">
                </div>
            </div>

            <div class="">
                <div class="width100">
                    <div class="width100 flex flexResponsive flexWrap">
                        <div class="padright10 padbtm10 width100"
                            *ngFor="let item of this.popup?.context?.groupList | search: searchInput"
                            (click)="setUserGroup(item)">
                            <app-share-to-group-card [userID]="userID" [data]="item" [type]="'Groups'"
                                [selectedGroup]="selectedUserGroup">
                            </app-share-to-group-card>
                        </div>
                    </div>
                </div>
                <app-empty-state [stateType]="'groupList'"
                    *ngIf="(this.popup?.context?.groupList | search: searchInput).length == 0"></app-empty-state>
            </div>
        </div>
        <div class="padtop30 padbtm20 flex organization">
            <button type="button" (click)="closePopup();"
                class="btn-get-started minwidth100px bdr-1-EEEEEE bg-white color-02457A ripple cursorPtr font14 poppins-regular pad1030">
                {{'cancel' | translate}}
            </button>
            <div class="pad5"></div>
            <button type="button" [disabled]="!selectedUserGroup.length || isLoader" (click)="shareToGroup();"
                [ngClass]="userType=='user' ? 'btn-02457A' : 'btn-18878C'"
                class="btn-get-started btn-02457A minwidth100px bdr-1-EEEEEE color-02457A ripple cursorPtr font14 poppins-regular pad1030">
                {{'share' | translate}}
            </button>
        </div>
    </div>
</div>