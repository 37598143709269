import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AppStore } from './../../../../../core/stores/app.store';

@Component({
  selector: 'app-briindlet-event-promote-hrs',
  templateUrl: './briindlet-event-promote-hrs.component.html',
  styleUrls: ['./briindlet-event-promote-hrs.component.scss']
})
export class BriindletEventPromoteHrsComponent implements OnInit {
  showCustomHoursTextBox: boolean = false;
  startDate: any = new Date();
  @Output('stepperActions') stepperActions = new EventEmitter();
  endDate: any = new Date();
  @Input() formGrp: any;
  constructor(
    public appStore: AppStore
  ) { }

  getDateDiff() {
    let todayDate = moment(this.startDate, "DD.MM.YYYY");
    let endDate = moment(this.endDate, "DD.MM.YYYY");
    this.formGrp['controls']['step_16'].patchValue({
      hours: endDate.diff(todayDate, 'days') * 24,
      start_timestamp: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
      expiry_timestamp: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss')
    });
    
  }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  ngOnInit(): void {
  }

  /**
   * @description
   * method is use for show the custom hours text box
   * 
   * @memberof BriindletEventPromoteHrsComponent
   */
  showCustomHoursBox(value: any) {
    if (value == 'custom') {
      this.showCustomHoursTextBox = true;
      this.getDateDiff();
    } else {
      this.formGrp['controls']['step_16'].patchValue({
        start_timestamp: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss')
      });
      this.showCustomHoursTextBox = false;
    }

  }

}
