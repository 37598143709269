<div>
    <div class="flex flexJustifyBetween">
        <div class="flex flex2">
            <div>
                <app-profile-thumbnail class="cursorPtr" [image]="user?.profile_pic" [isShowBorder]="true"
                    [imageClass]="'d45'"></app-profile-thumbnail>
            </div>
            <div class="pad5"></div>
            <div class="flex1">
                <div class="font16 poppins-semibold timeline-label txtTransformCaps flex flexAlignItemsCenter">
                    {{user?.name}}
                </div>
            </div>
        </div>
    </div>
    <div id="create-post" class="mrgntop20 ">
        <div class="flex flexAlignItemsCenter mrgnbtm15">
            <textarea class="poppins-regular padtop0 bg-white" (input)="postComment()" [(ngModel)]="postDetail"
                placeholder="{{'whats on your mind' | translate: {name: (user.first_name || user.name) | titlecase} }}"></textarea>
            <div class="emojy-container displyFlex flexNoShrink flexAlignItemsCenter posRelative">
                <div #emojiIconContainer>
                    <div class="ripple cursorPtr bdrRadius50p d40 flex flexAlignItemsCenter flexJustifyCenter"
                        (click)="toggled = !toggled">
                        <i class="icns icon-smiley grey d20 cursorPtr"></i>
                    </div>
                    <emoji-mart [style]="{ 'position': 'absolute', 'top': 0, 'right': 0 , 'z-index': '120'}"
                        *ngIf="toggled" [showPreview]="false" (emojiClick)="handleSelection($event)" set="google"
                        native="true">
                    </emoji-mart>
                </div>
            </div>
        </div>

        <div class="width100 padright10" *ngIf="post.attachments?.length && !media">
            <div [ngSwitch]="post.attachments[0]?.type">
                <div *ngSwitchCase="'photo'" class="height100p ripple cursorPtr">
                    <img [src]="post.attachments[0]?.data?.photo_url"
                        class="width100  bdrRadius6px boxShadow-RGBA00005">
                </div>
                <div *ngSwitchCase="'gif'">
                    <img [src]="post.attachments[0]?.data?.gif_url"
                        class="width100 height100p  bdrRadius6px boxShadow-RGBA00005">
                </div>
                <div *ngSwitchCase="'video'">
                    <app-video-preview-card [media]="post.attachments[0]?.data">
                    </app-video-preview-card>
                </div>
            </div>
        </div>

        <div class="width100 padright10" *ngIf="media">
            <div [ngSwitch]="true">
                <div *ngSwitchCase="media?.item_type?.toLowerCase().includes('photo')"
                    class="height100p ripple cursorPtr">
                    <img [src]="post.attachments[0]?.data?.photo_url"
                        class="width100  bdrRadius6px boxShadow-RGBA00005">
                </div>
                <div *ngSwitchCase="media?.item_type?.toLowerCase().includes('gif')">
                    <img [src]="post.attachments[0]?.data?.gif_url"
                        class="width100 height100p  bdrRadius6px boxShadow-RGBA00005">
                </div>
                <div *ngSwitchCase="media?.item_type?.toLowerCase().includes('video')">
                    <app-video-preview-card [media]="post.attachments[0]?.data">
                    </app-video-preview-card>
                </div>
            </div>
        </div> 
        <!-- Show when sharing profile or cover photo -->
        <div class="width100 padright10" *ngIf='post.post_type == "ProfilePic" || post.post_type == "CoverPhoto"'>
            <div [ngSwitch]="true">
                <div *ngSwitchCase='post.post_type == "ProfilePic" && post.profile_pic_url !== ""'
                    class="height100p ripple cursorPtr">
                    <img [src]="post.profile_pic_url"
                        class="width100  bdrRadius6px boxShadow-RGBA00005">
                </div>                
                <div *ngSwitchCase='post.post_type == "CoverPhoto" && post.cover_photo_url !== ""'
                    class="height100p ripple cursorPtr">
                    <img [src]="post.cover_photo_url"
                        class="width100  bdrRadius6px boxShadow-RGBA00005">
                </div>
            </div>
        </div>
    </div>

    <div class="padtop10 flex flexAlignItemsCenter">
        <div class="postAuthor color-122A4D poppins-semibold font16">
            {{ post?.posted_by?.name || post?.post?.posted_by?.name }}
        </div>
        <div class="pad5"></div>
        <div class="color-8e8e8e poppins-regular font14 ">
            {{post?.created_at | dateAgo }}
        </div>
    </div>
    <div class="poppins-regular color-122A4D font14 padbtm10">
        <ng-container *ngIf="post?.description && !media">
            <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length > 200">
                <span [innerHtml]="(showMore) ? post?.description : post?.description.slice(0,300)"></span>
                <span *ngIf="!showMore">...</span>
                <a href="javascript:;" class="seeMore" (click)="showMore = !showMore">{{showMore ? ' See Less': ' See more'}}</a>
                <div class="pad5"></div>
            </div>
            <div [innerHtml]="post?.description" class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length < 200">
                <div class="pad5"></div>
            </div>
        </ng-container>
    </div>
    <div class="bdr-top-1-EEEEEE width100 mrgnbtm10" *ngIf="!media"></div>
</div>