<div class="flex flexJustifyBetween">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'favourite movies' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/users/profile/interest']" [queryParams]="{open: 'favorite-movies'}"
    class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
    <i class="icns icon-edit blue d20" *ngIf="data?.user_movies_information || data?.user_religious_information || data?.user_political_information || data?.user_sports_information || data?.user_musics_information || data?.user_musics_information"></i>
        <i class="icns d20 icon-plus blue" *ngIf="!data?.user_movies_information && !data?.user_religious_information && !data?.user_political_information && !data?.user_sports_information && !data?.user_musics_information && !data?.user_musics_information"></i>
</div>
</div>
<ng-container *ngIf="data?.user_movies_information && data?.user_movies_information?.length != 0 && showUserInfo(settings,'show_fav_movies')">
    <div class="flex flexResponsive flexWrap">
        <div class="pad5 maxWidth200px" *ngFor="let item of data?.user_movies_information">
            <app-user-interest-card [data]="item"></app-user-interest-card>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.user_religious_information && data?.user_religious_information?.length != 0 && showUserInfo(settings,'show_religious_views')">
    <div class="pad10"></div>
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'religious views' | translate}}</div>
        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/interest']" [queryParams]="{open: 'religious'}"
            class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="flex flexResponsive flexWrap user">
        <div class="pad10 bdr-1-EEEEEE posRelative displayFlex mrgn0510 flexAlignItemsCenter bg-white bdrRadius6px"
            *ngFor="let item of data?.user_religious_information; let i = index; let last= last">
            <div class="timeline-label font16 poppins-medium ">{{item.name}}</div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.user_political_information && data?.user_political_information?.length != 0 && showUserInfo(settings,'show_political_views')">
    <div class="pad10"></div>
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'political views' | translate}}</div>
        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/interest']" [queryParams]="{open: 'political'}"
            class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="flex flexResponsive flexWrap user">
        <div class="pad10 bdr-1-EEEEEE posRelative displayFlex mrgn0510 flexAlignItemsCenter bg-white bdrRadius6px"
            *ngFor="let item of data?.user_political_information; let i = index; let last= last">
            <div class="timeline-label font16 poppins-medium ">{{item.name}}</div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.user_sports_information && data?.user_sports_information?.length != 0 && showUserInfo(settings,'show_fav_sports')">
    <div class="pad10"></div>
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'favourite sports' | translate}}</div>
        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/interest']" [queryParams]="{open: 'sports'}"
            class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="flex flexResponsive flexWrap">
        <div class="pad5 maxWidth200px" *ngFor="let item of data?.user_sports_information">
            <app-user-interest-card [data]="item"></app-user-interest-card>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.user_musics_information && data?.user_musics_information?.length != 0 && showUserInfo(settings,'show_fav_music')">
    <div class="pad10"></div>
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'favourite musics' | translate}}</div>
        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/interest']" [queryParams]="{open: 'favorite-musics'}"
            class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="flex  flexResponsive flexWrap">
        <div class="pad5 maxWidth200px" *ngFor="let item of data?.user_musics_information">
            <app-user-interest-card [data]="item"></app-user-interest-card>
        </div>
    </div>
</ng-container>
<app-empty-state [stateType]="'nofriends'" class=" width100" *ngIf="data?.user_movies_information?.length == 0 && data?.user_religious_information?.length == 0 && data?.user_political_information?.length == 0 && data?.user_sports_information?.length == 0 && data?.user_musics_information?.length == 0"></app-empty-state>