import {EventEmitter, Injectable} from '@angular/core';
import { QbService } from './qb.service';

declare var QB: any;

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public currentDialog: any = {};
  public dialogs: any = {};
  dialogsEvent: EventEmitter<any> = new EventEmitter();
  currentDialogEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private QbService: QbService
  ) {
  }

  // create Dialog
  public createDialog(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.chat.dialog.create(params, function (createErr: any, createRes: any) {
        if (createErr) {
          // //console.log('Dialog creation Error : ', createErr);
          reject(createErr);
        } else {
          // //console.log('Dialog Creation successfull ');
          resolve(createRes);
        }
      });
    });
  }

  // get dialogs
  public getDialogs(args: any): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.chat.dialog.list(args, (err: any, resDialogs: any) => {   
        if (err) {
          // //console.log('Dialog error: ', err);
          reject(err);
        } else {
          // //console.log('Dialog List: ', resDialogs);
          resolve(resDialogs);
        }
      });
    });
  }

  getDialogById(id: any): Promise<any> {
    return new Promise(function(resolve, reject) {
      QB.chat.dialog.list({'_id': id}, function (err: any, res: any) {
        if (err) {
          console.error(err);
          reject(err);
        }
        const dialog = res.items[0];
        if (dialog) {
          resolve(dialog);
        } else {
          reject(new Error('can\'t find dialog with this id: ' + id));
        }
      });
    });
  }

  public updateDialog(dialogId: any, params: any): Promise<any> {
    return new Promise(function (resolve, reject) {
      QB.chat.dialog.update(dialogId, params, function (err: any, dialog: any) {
        if (err) {
          reject(err);
        } else {
          resolve(dialog);
        }
      });
    });
  }

  public deleteDialogByIds(dialogIds: any): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      QB.chat.dialog.delete(dialogIds, function (err: any) {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          dialogIds.forEach((dialogId: any) => {
            delete self.dialogs[dialogId];
          });
          self.setDialogs(Object.values(self.dialogs));
          resolve({});
        }
      });
    });
  }

  public setDialogs(chats: any): any {
    this.dialogs = chats.reduce((obj: any, item: any) => {
      obj[item._id] = item;
      return obj;
    }, {});
    this.dialogsEvent.emit(this.dialogs);
  }

  public joinToDialog(dialog: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const
        jidOrUserId = dialog.xmpp_room_jid || dialog.jidOrUserId || this.QbService.getRecipientUserId(dialog.occupants_ids);
      QB.chat.muc.join(jidOrUserId, function (resultStanza: any) {
        for (let i = 0; i < resultStanza.childNodes.length; i++) {
          const elItem = resultStanza.childNodes.item(i);
          if (elItem.tagName === 'error') {
            //console.log(resultStanza);
            return reject();
          }
        }
        resolve({});
      });
    });
  }

  public setDialogParams(message: any) {
    const
      self = this,
      tmpObj: any = {};
    tmpObj[message.chat_dialog_id] = self.dialogs[message.chat_dialog_id];
    tmpObj[message.chat_dialog_id].last_message = message.message;
    tmpObj[message.chat_dialog_id].last_message_date_sent = message.date_sent;
    tmpObj[message.chat_dialog_id].last_message_id = message._id;
    tmpObj[message.chat_dialog_id].last_message_user_id = message.sender_id;
    if (!message.selfReaded) {
      tmpObj[message.chat_dialog_id].unread_messages_count++;
    }
    delete self.dialogs[message.chat_dialog_id];
    self.dialogs = Object.assign(tmpObj, self.dialogs);
    self.dialogsEvent.emit(self.dialogs);
  }

}
