<div class="flex flexJustifyBetween">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'contact information' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/users/profile/contact-info']"
        class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20" *ngIf="data"></i>
        <i class="icns d20 icon-plus blue" *ngIf="!data"></i>
    </div>
</div>
<ng-container
    *ngIf="data?.primary_email || data?.secondary_email || data?.mobile_number || data?.secondary_phone_number || data?.website_link">
    <div class="flex flexResponsive flexWrap">

        <a href="mailto:{{data?.primary_email}}" 
           class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50Minus10pxLap"
           *ngIf="data?.primary_email && showUserInfo(settings,'show_email')"
        >
            <div class="d20">
                <i class="icns icon-email blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'primary mail id' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.primary_email}}</div>
            </div>
        </a>

        <a href="mailto:{{data?.secondary_email}}" 
           class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50Minus10pxLap"
           *ngIf="data?.secondary_email && showUserInfo(settings,'show_sec_email')"
        >
            <div class="d20">
                <i class="icns icon-email blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'secondary mail id' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.secondary_email}}</div>
            </div>
        </a>


        <a href="tel:{{data?.mobile_number}}" 
           class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50Minus10pxLap"
           *ngIf="data?.mobile_number && showUserInfo(settings,'show_phone')"
        >

                <div class="d20">
                    <i class="icns icon-call blue d20"></i>
                </div>

                    <div class="padleft10 flex flexDirectionCol">

                            <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                                {{'primary phone number' | translate}}
                            </div>
                            <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                                {{data?.mobile_number}}</div>      
                    </div>

          
        </a>
        <a href="tel:{{data?.secondary_phone_number}}" 
            class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50Minus10pxLap"
            *ngIf="data?.secondary_phone_number && showUserInfo(settings,'show_phone')"
        >
            <div class="d20">
                <i class="icns icon-call blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol ">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'secondary phone number' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.secondary_phone_number}}</div>
            </div>
        </a>

        <a target="_blank" href="{{data?.website_link}}" 
            class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50Minus10pxLap"
            *ngIf="data?.website_link && showUserInfo(settings,'show_website')"
        >        

            <div class="d20">
                <i class="icns icon-public blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'website' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.website_link}}</div>
            </div>
        </a>
    </div>
</ng-container>


<ng-container *ngIf="data?.facebook_link || data?.twitter_link || data?.instagram_link || data?.youtube_link">
    <div class="pad10"></div>
    <div class="flex flexJustifyBetween">
        <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'social media' | translate}}</div>
        <div *ngIf="showEditIcon" [routerLink]="['/users/profile/contact-info']" [queryParams]="{ open: 'work' }"
            class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
            <i class="icns icon-edit blue d20"></i>
        </div>
    </div>
    <div class="flex flexWrap flexResponsive">
        <a  target="_blank" href="{{data?.facebook_link}}"
            class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50"
            *ngIf="data?.facebook_link && showUserInfo(settings,'show_fb_link')">
            <div class="d20">
                <i class="icns icon-facebook blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'facebook' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.facebook_link}}</div>
            </div>
        </a>
        <a  target="_blank" href="{{data?.twitter_link}}"
            class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50"
            *ngIf="data?.twitter_link && showUserInfo(settings,'show_twitter_link')">
            <div class="d20">
                <i class="icns icon-twitter blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'twitter' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.twitter_link}}</div>
            </div>
        </a>
        <a  target="_blank" href="{{data?.instagram_link}}"
            class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50"
            *ngIf="data?.instagram_link && showUserInfo(settings,'show_insta_link')">
            <div class="d20">
                <i class="icns icon-instagram blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'instagram' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.instagram_link}}</div>
            </div>
        </a>
        <a  target="_blank" href="{{data?.youtube_link}}"
            class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50"
            *ngIf="data?.youtube_link && showUserInfo(settings,'show_youtube_link')"
            >
            <div class="d20">
                <i class="icns icon-youtube blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'youtube' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium wrdBreakWord">
                    {{data?.youtube_link}}</div>
            </div>
        </a>
    </div>
</ng-container>
<app-empty-state [stateType]="'nofriends'" class=" width100" *ngIf="!data"></app-empty-state>