<div id="briindlet-event-date-popup" class="res bdrRadius6px bg-white pad40 posRelative {{popup?.context?.userType}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px timeline-label" (click)="closePopup();" *ngIf="stepperCount != 18">×</span>
    <ng-container [ngSwitch]="stepperCount">
        <ng-container *ngSwitchCase="0">
            <div class="{{appStore?.userType}} minWidth500px">
                <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
                    {{'please select briindlet type' | translate}}
                </div>
                <div class="body">
                    <div class="width100 flex flexResponsive">
                        <div [ngClass]="{'active': slectedEventType == 'createBriindlte'}" (click)="selectEventCTA('createBriindlte');" class="width50 bdr-1-EEEEEE ripple {{(appStore?.userType == 'user' ? 'account-one' : 'account-two')}} bg-white account-box bdrRadius10px
                        flex flexJustifyAround flexAlignItemsCenter">
                            <div class="pad10"></div>
                            <div [innerHTML]="'briindlet' | translate" class="width100  color-122A4D  account-title font18 poppins-medium fontWeight600 lineHeight27px">
                            </div>
                            <div class="tick bg-DEDEDE">
                                <i class="icns icon-tick account"></i>
                            </div>
                            <div class="pad10"></div>
                        </div>
                        <div class="pad15"></div>
                        <div [ngClass]="{'active': slectedEventType == 'createBriindltePro'}" (click)="selectEventCTA('createBriindltePro');" class="width50 ripple bdr-1-EEEEEE {{(appStore?.userType == 'user' ? 'account-one' : 'account-two')}} bg-white account-box bdrRadius10px
                         flex  flexJustifyBetween flexAlignItemsCenter">
                            <div class="pad10"></div>
                            <div [innerHTML]="'briindlet pro' | translate" class="width100  color-122A4D  account-title font18 poppins-medium fontWeight600 lineHeight27px">
                            </div>
                            <div class="tick bg-DEDEDE">
                                <i class="icns icon-tick account"></i>
                            </div>
                            <div class="pad10"></div>
                        </div>
                    </div>
                </div>
                <div class="width100 padtop30">
                    <button (click)="stepperActions('continue')" class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                        {{'continue' | translate}}
                    </button>
                </div>
            </div>
            <!-- <app-briindlet-event (closePopup)="closePopup();" [formGrp]="form" [briindletType]="popup?.context?.briindletType"
                (stepperActions)="stepperActions($event)"></app-briindlet-event> -->
        </ng-container>
        <ng-container *ngSwitchCase="1">
            <app-briindlet-event (closePopup)="closePopup();" [formGrp]="form" [briindletType]="popup?.context?.briindletType || slectedEventType" (stepperActions)="stepperActions($event)"></app-briindlet-event>
        </ng-container>
        <ng-container *ngSwitchCase="2">
            <app-briindlet-event-template (templateConfiguration)="eventCustomization = $event" [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-template>
        </ng-container>
        <ng-container *ngSwitchCase="3">
            <app-briindlet-event-name [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-name>
        </ng-container>
        <ng-container *ngSwitchCase="4">
            <app-event-venue [formGrp]="form" (stepperActions)="stepperActions($event)"></app-event-venue>
        </ng-container>
        <ng-container *ngSwitchCase="5">
            <app-briindlet-event-about [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-about>
        </ng-container>
        <ng-container *ngSwitchCase="6">
            <app-briindlet-event-date [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-date>
        </ng-container>
        <ng-container *ngSwitchCase="7">
            <app-briindlet-event-lastdate [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-lastdate>
        </ng-container>
        <ng-container *ngSwitchCase="8">
            <app-briindlet-event-guest [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-guest>
        </ng-container>
        <ng-container *ngSwitchCase="9">
            <app-briindlet-event-entrance [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-entrance>
        </ng-container>
        <ng-container *ngSwitchCase="10">
            <app-briindlet-event-guideline [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-guideline>
        </ng-container>
        <ng-container *ngSwitchCase="11">
            <app-briindlet-event-briind [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-briind>
        </ng-container>
        <ng-container *ngSwitchCase="12">
            <app-briindlet-event-website [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-website>
        </ng-container>
        <ng-container *ngSwitchCase="13">
            <app-briindlet-event-briindlet-media [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-briindlet-media>
        </ng-container>
        <ng-container *ngSwitchCase="14">
            <app-briindlet-dontaion-campaign [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-dontaion-campaign>
        </ng-container>
        <ng-container *ngSwitchCase="15">
            <app-briindlet-region-cost [formGrp]="form" [briindletType]="popup?.context?.briindletType" (stepperActions)="stepperActions($event)" (getRegion)="getRegion($event)" [previousStep]="previousStep" [region]="popup?.context?.regionInfo"></app-briindlet-region-cost>
        </ng-container>
        <ng-container *ngSwitchCase="16">
            <app-briindlet-event-promote-hrs [formGrp]="form" (stepperActions)="stepperActions($event)">
            </app-briindlet-event-promote-hrs>
        </ng-container>
        <ng-container *ngSwitchCase="17">
            <app-briindlet-event-preview (templateAmount)="setTemplateAmount($event)" [customizeUI]="eventCustomization" [data]="briindletData" (stepperActions)="stepperActions($event)"></app-briindlet-event-preview>
        </ng-container>
        <ng-container *ngSwitchCase="18">
            <app-briindlet-event-stripe [causeID]="popup?.context?.causeId || ''" [briindletProId]="briindletResponse.id" (close)="closePopup();" [data]="briindletData" [customizeUI]="eventCustomization" (stepperActions)="stepperActions($event)"></app-briindlet-event-stripe>
        </ng-container>
        <ng-container *ngSwitchCase="19">
            <app-briindlet-preview (closePopup)="closePopup()" [perviewType]="popup?.context?.perviewType" [data]="popup?.context?.data" [photo]="popup?.context?.photo"></app-briindlet-preview>
        </ng-container>
        <ng-container *ngSwitchCase="20">
            <app-customize-event-template (sendCustomizeUI)="sendCustomizeUI($event)" [isPreview]="previousStepperCount == 2 ? true : false" (stepperActions)="stepperActions($event)" [customizeData]="eventCustomization" [data]="briindletData">
            </app-customize-event-template>
        </ng-container>
    </ng-container>
</div>