export class AnalyticsReportModel {
    total_briindlet_views: number = 0;
    total_briindlet_clicks: number = 0;
    user_profile_views: number = 0;
    briindad_views: number = 0;
    briindad_clicks: number = 0;
    likes: number = 0;
    shares: number = 0;
    followers: number = 0;
    monthly_ad_views: MonthlyAdView[] = [];
    monthly_ad_clicks: MonthlyAdClick[] = [];
    most_views_posts: MostViewsPost[] = [];
    membership_data: MembershipData = new MembershipData();
    volunteer_data: VolunteerData = new VolunteerData();
    followers_data: FollowersData = new FollowersData();
    donation: Donation[] = [];
    donation_campaign_count: number = 0;
}

export class Donation {
    date: string = '';
    amount: number = 0;
}

export class FollowersData {
    gained: Gained[] = [];
    lost: Gained[] = [];
}

export class Gained {
    date: string = '';
    gained: number = 0;
}

export class MembershipData {
    applications_received: ApplicationsReceived[] = [];
    new_members: NewMember[] = [];
}

export class ApplicationsReceived {
    str_date: string = '';
    applications: number = 0;
}

export class NewMember {
    str_date: string = '';
    applications: number = 0;
}

export class MonthlyAdClick {
    str_date: string = '';
    click_count: number = 0;
}

export class MonthlyAdView {
    str_date: string = '';
    view_count: number = 0;
}

export class MostViewsPost {
    id: number = 0;
    photos: any[] = [];
    videos: Video[] = [];
    gifs: any[] = [];
    posted_by: PostedBy = new PostedBy();
    posted_to: MostViewsPostPostedTo = new MostViewsPostPostedTo();
    likes_count: number = 0;
    liked: boolean = false;
    attachments: Attachment[] = [];
    item_type: string = '';
    is_share: boolean = false;
    comments: Comment[] = [];
    allow_share: boolean = false;
    see_tagged_post: string = '';
    review_post: boolean = false;
    review_tag: boolean = false;
    name: string = '';
    created_at: Date = new Date();
    updated_at: string = '';
    description: string = '';
    object_id: number = 0;
    post_type: string = '';
    album_privacy: string = '';
    content_type: number = 0;
    organization: number = 0;
}

export class Attachment {
    data: Video = new Video();
    type: string = '';
}

export class Video {
    id: number = 0;
    video: string = '';
    caption: string = '';
    created_at: Date = new Date();
    updated_at: string = '';
    video_url: string = '';
    post: Post = new Post();
    comments: any[] = [];
}

export class Post {
    id: number = 0;
    posted_by: PostedBy = new PostedBy();
    posted_to: PostPostedTo = new PostPostedTo();
    likes_count: number = 0;
    liked: boolean = false;
    item_type: string = '';
    name: string = '';
    created_at: Date = new Date();
    updated_at: string = '';
    description: string = '';
    object_id: number = 0;
    post_type: string = '';
    album_privacy: string = '';
    content_type: number = 0;
    organization: number = 0;
}

export class PostedBy {
    id: number = 0;
    name: string = '';
    photo: string = '';
    display_name: string = '';
    user_type: string = '';
    briind_id: string = '';
}

export class PostPostedTo {
    id: number = 0;
    photo: string = '';
    name: string = '';
    user_type: string = '';
    briind_id: string = '';
}

export class Comment {
    id: number = 0;
    parent_id: string = '';
    user_type: CommentUserType = new CommentUserType();
    text: string = '';
    likes_count: number = 0;
    liked: boolean = false;
    replies: Reply[] = [];
    abusive: boolean = false;
}

export class Reply {
    id: number = 0;
    parent_id: number = 0;
    user_type: ReplyUserType = new ReplyUserType();
    text: string = '';
    likes_count: number = 0;
    liked: boolean = false;
    replies: any[] = [];
    abusive: boolean = false;
}

export class ReplyUserType {
    id: number = 0;
    name: string = '';
    profile_pic: string = '';
    display_name: string = '';
    user_type: string = '';
    briind_id: string = '';
}

export class CommentUserType {
    id: number = 0;
    name: string = '';
    profile_pic: string = '';
    display_name: string = '';
    user_type: string = '';
    briind_id: string = '';
}

export class MostViewsPostPostedTo {
    id: number = 0;
    photo: string = '';
    name: string = '';
    user_type: string = '';
    briind_id: string = '';
    display_name?: string = '';
}

export class VolunteerData {
    accepted: Accepted[] = [];
    rejected: any[] = [];
}

export class Accepted {
    date: string = '';
    applications: number = 0;
}
