import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionModel } from 'src/app/core/models';

@Component({
  selector: 'app-share-to-group-card',
  templateUrl: './share-to-group-card.component.html',
  styleUrls: ['./share-to-group-card.component.scss']
})
export class ShareToGroupCardComponent implements OnInit {
  @Input('type') type: string = "";
  @Input('userID') userID : any = "";
  @Input('data') data: any;
  @Input('selectedGroup') selectedGroup: any;
  userType: string = '';
  @Output() cardActionCTA = new EventEmitter();
  selectedClass: string="";
  selectedID: any;
  constructor() { }

  ngOnInit(): void {
  }

}
