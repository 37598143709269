import { BriindletStoreService } from './../../../../../briindlet/store/briindlet-store.service';
import { BriindletService } from 'src/app/briindlet/services/briindlet.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from './../../../../../core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListModel } from 'src/app/core/models';

@Component({
  selector: 'app-event-venue',
  templateUrl: './event-venue.component.html',
  styleUrls: ['./event-venue.component.scss']
})
export class EventVenueComponent implements OnInit {

  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  subscription: Subscription = new Subscription();
  dropDownStatus: any = '';
  venue: any;
  selectedData: any;
  constructor(
    public appStore: AppStore,
    private appService: AppService,
    private briindletService: BriindletService,
    public briindletStoreService: BriindletStoreService
  ) { }

  actionCTA(event: any) {
    this.stepperActions.emit(event);
  }

   /**
   * @description
   * this method is used for get selected venue
   * 
   * @memberof CreateBriindletDetailComponent
   */
    getSelectedVenue(venue: SelectListModel) {
      let selectedVenue = {
        id: venue.id,
        name: venue.name
      }
      localStorage.setItem('brindlet-pro-create-venue', JSON.stringify(selectedVenue))
      this.venue = venue;
      this.formGrp['controls']['step_4'].patchValue({
        venue: venue.name
      });
    }
  


  /**
   * @description
   * this method is used for search venue
   * 
   * @memberof CreateBriindletDetailComponent
   */
  getVenue(event: any, from ?: string) {
    let venueCache = localStorage.getItem('brindlet-pro-create-venue')
    if(venueCache) {
      this.venue = JSON.parse(venueCache)
    }
    let search ={
      'q': event
    }
   this.subscription.add(
     this.briindletService.getVenue(search).subscribe((response: any) => {
        this.briindletStoreService.getVenue();
        if (from == 'init' && !this.briindletStoreService.venueList.length && this.formGrp.value.venue_item) {
         let item = {
            name: this.formGrp['controls']['step_4'].value.venue,
            id: this.formGrp?.value?.venue_item
          }
          this.selectedData = item;
          this.briindletStoreService.venueList = [item]
        }
     })
   );
 }


  ngOnInit(): void {
    this.getVenue('', 'init');
  }

  /**
   * @description
   * this method is used for get selected venue
   * 
   * @memberof CreateBriindletDetailComponent
   */

}
