<!-- <div id="sucess-popup" class="flex flexJustifyCenter {{popup?.context?.profile_type}}">
    <div *ngIf="!showCamera" class="width80 bg-common pad20 bdrRadius6px" [ngClass]="{'hide': showCamera, 'show': !showCamera}">
        <div class="font25 color-FFFFFF txtCenter poppins-bold">{{ popup?.context?.title | translate}}</div>
        <div class="font20 color-FFFFFF txtCenter poppins-regular">
            {{popup?.context?.subtitle | translate}}
        </div>
        <div class="photoOptionContainer">
            <div class="photoOption flex flexAlignItemsCenter flexDirectionCol  cancel-btn"  [ngClass]='{"disbled": error, "cursorPtr": !error}'
                (click)="!error && openCameraPopUp()">
                <div class="img">
                    <i class="icns icon-capture-photo d70"></i>
                </div>
                <div class="title">
                    Take your photo
                </div>
            </div>

            <app-photo-from-file [imageType]="popup.context?.imageType"
                (selectedFile)="selectedFileHandlerFn($event)"></app-photo-from-file>
        </div>
        
      <div *ngIf="error" class="text-center mrgnbtm15 text-danger">
        {{ error }}. Please refresh the page
      </div>

        <div class="txtCenter">
            <button class="btn-get-started cancel-btn ripple cursorPtr txtTransformUp" (click)="closePopup();">{{'ok' |
                translate}}</button>
        </div>
    </div>
</div> -->

<app-webcam-capture [imageType]="popup.context?.imageType" [userType]="popup?.context?.profile_type"
    (close)="closePopup();" (selectedFile)="selectedFileHandlerFn($event)"></app-webcam-capture>