import {Injectable} from '@angular/core';

declare var QB: any;

@Injectable()
export class QBHelper {

  constructor(
  ) {
  }

  public getSession() {
    return QB && QB.service ? QB.service.getSession() : null;
  }

  public qbLogout() {
    return new Promise(async (resolve, reject) => { 
      try {
        localStorage.removeItem('loggedinUser');
        localStorage.removeItem('sessionResponse');
        await QB.chat.disconnect();
        await QB.destroySession(() => null);
        resolve(null)
      } catch(e) {
        reject(e)
      }  
    })
  }

  public async qbEndSession() {
    //console.log("ending session here")
    return new Promise(async (resolve, reject) => { 
      try {
        await QB.chat.disconnect();
        await QB.destroySession(() => null);
        resolve(null)
      } catch(e) {
        reject(e)
      }
      
    })
    
  }

  // App Session
  public appSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.createSession(function (sessionErr:any, sessionRes:any) {
        if (sessionErr) {
          reject(sessionErr);
        } else {
          resolve(sessionRes);
        }
      });
    });
  }

  public abCreateAndUpload(file:any): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.content.createAndUpload({
        public: false,
        file: file,
        name: file.name,
        type: file.type,
        size: file.size
      }, function (err:any, response:any) {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      });
    });
  }

  // create connection or login
  public qbCreateConnection(user:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appSession().then(sessionResponse => {
        localStorage.setItem('sessionResponse', JSON.stringify(sessionResponse));
        const params = {
          login: user.login,
          password: user.password
        };

        QB.login(params, function (loginErr:any, loginRes:any) {
          if (loginErr) {
            reject(loginErr);
          } else {
            resolve(loginRes);
          }
        });
      }).catch(error => {
        // alert(error);
        //console.log(error);
        error.status = 401;
        reject(error);
      });
    });
  }


  public qbCreateConnectionPatch(user:any): Promise<any> {
    return new Promise((resolve, reject) => {
      
        const params = {
          login: user.login,
          password: user.password
        };
        //console.log(params)

        QB.login(params, function (loginErr:any, loginRes:any) {
          //console.log(loginRes, "This is loginres")

          if (loginErr) {
            reject(loginErr);
          } else {
            resolve(loginRes);
          }
        });
     
    });
  }

  /**
   * chat connection
   * @password {String} password - The user's password or session token
   * */
  public qbChatConnection(userId: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        userId: userId,
        password: password
      };
      QB.chat.connect(params, function (chatErr:any, chatRes:any) {
        if (chatErr) {
          reject(chatErr);
        } else {
          // //console.log('chat connection successfull');
          resolve(chatRes);
        }
      });
    });
  }
  public updateUser(userId: any, params: any): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      QB.users.update(userId, params, function (updateError: any, updateUser: any) {
        if (updateError) {
          // // //console.log('User update Error : ', updateError);
          reject(updateError);
        } else {

          //console.log('User update successfull ', updateUser);
          resolve(updateUser);
        }
      });
    });
  }
}
