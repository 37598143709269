<div class="{{appStore?.userType}} minWidth500px" [formGroup]="formGrp">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'is this an event?' | translate}}
    </div>
    <span formGroupName="step_1">
        <input type="hidden" formControlName="is_event">
    </span>
    <div class="body">
        <div class="width100 flex flexResponsive">
            <div [ngClass]="{'active': slectedEventType == 'yes'}" (click)="selectEventCTA('yes');" class="width50 bdr-1-EEEEEE ripple {{(appStore?.userType == 'user' ? 'account-one' : 'account-two')}} bg-white account-box bdrRadius10px
            flex flexJustifyAround flexAlignItemsCenter">
                <div class="pad10"></div>
                <div [innerHTML]="'yes' | translate"
                    class="width100  color-122A4D  account-title font18 poppins-medium fontWeight600 lineHeight27px">
                </div>
                <div class="tick bg-DEDEDE">
                    <i class="icns icon-tick account"></i>
                </div>
                <div class="pad10"></div>
            </div>
            <div class="pad15"></div>
            <div [ngClass]="{'active': slectedEventType == 'no'}" (click)="selectEventCTA('no');" class="width50 ripple bdr-1-EEEEEE {{(appStore?.userType == 'user' ? 'account-one' : 'account-two')}} bg-white account-box bdrRadius10px
             flex  flexJustifyBetween flexAlignItemsCenter">
                <div class="pad10"></div>
                <div [innerHTML]="'no' | translate"
                    class="width100  color-122A4D  account-title font18 poppins-medium fontWeight600 lineHeight27px">
                </div>
                <div class="tick bg-DEDEDE">
                    <i class="icns icon-tick account"></i>
                </div>
                <div class="pad10"></div>
            </div>
        </div>
    </div>

    <div class="width100 padtop30">
        <button [disabled]="formGrp?.controls?.step_1?.invalid" (click)="continuteCTA('continue')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
            {{'continue' | translate}}
        </button>
    </div>
</div>