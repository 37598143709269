<div class="flex flexJustifyBetween">
    <div class="flex flex2">
        <div class="flex flexAlignItemsCenter padright15 ripple bdrRadius30px cursorPtr" (click)="postCTA('like')">
            <div class="d30 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                <i class="icns icon-like d30" [ngClass]="{'icon-liked': post?.post?.liked || post?.liked, 'icon-like': !post?.post?.liked || !post?.liked}"></i>
            </div>
            <span class="font14 poppins-medium color-8E8E8E">
                {{post?.likes_count || post?.post?.likes_count || 0}}
            </span>
        </div>

        <div class="flex flexAlignItemsCenter padright15 ripple bdrRadius30px cursorPtr" (click)="postCTA('comment')">
            <div
                class="d30 bg-FF4154 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                <i class="icns icon-post-comment d15 mrgnleft1 mrgnbtm1"></i>
            </div>
            <span class="font14 poppins-medium color-8E8E8E">
                {{comments?.length}}
            </span>
        </div>
    </div>
    <!-- <div class="flex flexAlignItemsCenter ripple bdrRadius30px cursorPtr">
            <div class="d30 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                <i class="icns icon-share {{theme}} d25"></i>
            </div>
            <span class="font14 poppins-medium timeline-label">
                {{'share' | translate}}
            </span>
        </div> -->
    <ul class="nav-ul flex mrgn0 flexAlignItemsCenter" *ngIf="post?.allow_share">
        <li class="nav-item posRelative profile-icon cursorPtr listStyleNone">
            <a class="pad10 posRelative flex">

                <div class="flex flexAlignItemsCenter ripple bdrRadius30px cursorPtr">
                    <div
                        class="d30 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                        <i class="icns icon-share {{theme}} d25"></i>
                    </div>
                    <span class="font14 poppins-medium color-122A4D">
                        {{'share' | translate}}
                    </span>
                </div>

            </a>

            
            <div class="bdr-1-EEEEEE profile-menu posAbsolute posRight bg-white bdrRadius6px zIndex10">

                <div class="">
                    <div [ngClass]="{'pointerEventsNone': isLoader}" class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple"
                        (click)="shareNow(post)">
                        <div
                            class="d30 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                            <i class="icns icon-share {{theme}} d25"></i>
                        </div>
                        <span class="font14 poppins-medium color-122A4D">
                            {{'share now public' | translate}}
                        </span>
                    </div>
                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple"
                        (click)="sharePost(post, 'group')">
                        <div
                            class="d30 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                            <i class="icns icon-friends {{theme}} d25"></i>
                        </div>
                        <span class="font14 poppins-medium color-122A4D">
                            {{'share to a group' | translate}}
                        </span>
                    </div>
                    <div class="flex flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple"
                        (click)="sharePost(post, 'user')">
                        <div
                            class="d30 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                            <i class="icns icon-user {{theme}} d25"></i>
                        </div>
                        <span class="font14 poppins-medium color-122A4D">
                            {{'share to people' | translate}}
                        </span>
                    </div>
                    <a class="flex  flexAlignItemsCenter padtop10 padleft15 padright15 padbtm10 ripple" href="https://www.facebook.com/sharer/sharer.php?u={{config.webAppURL}}/posts/{{post.id || post?.post?.id}}?postType={{post.item_type || post?.post?.item_type}}" target="_blank">
                    <div
                        class="d30 mrgnright5 flex cursorPtr flexAlignItemsCenter flexJustifyCenter ripple bdrRadius50p">
                        <i class="icns icon-facebook {{theme}} d25"></i>
                    </div>
                    <span class="font14 poppins-medium color-122A4D">
                        {{'share on facebook' | translate}}
                    </span>
                </a>
                </div>
            </div>
        </li>
    </ul>
</div>