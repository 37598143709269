import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { CameraService } from 'src/app/core/services/camera.service';

@Component({
  selector: 'app-photo-selection-popup',
  templateUrl: './photo-selection-popup.component.html',
  styleUrls: ['./photo-selection-popup.component.scss']
})
export class PhotoSelectionPopupComponent implements OnInit {

  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();

  public showCamera: Boolean = false;
  public error: any = null;

  constructor(private cameraService:CameraService) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.cameraService.capturedFile = undefined;
  }
  selectedFileHandlerFn(event: File) {
    this.showCamera = false;
    this.cameraService.capturedFile = event;
    setTimeout(() => {
      this.closePopup();
    }, 1000);
  }
  
  openCameraPopUp() {
    this.showCamera = true;
  }
  closePopup() {
    this.showCamera = false;
    this.close.emit('close-success');
  }
}
