import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-organization-milestone-preview',
  templateUrl: './organization-milestone-preview.component.html',
  styleUrls: ['./organization-milestone-preview.component.scss']
})
export class OrganizationMilestonePreviewComponent implements OnInit {
  @Input('data') data : any = [];
  @Input() organizationID: any;
  @Input('showEditIcon') showEditIcon: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
