export class BriindletAnalyticsModel {
    views: number = 0;
    total_clicks: number = 0;
    hyper_link_clicks: number = 0;
    last_month: Last[] = [];
    last_week: Last[] = [];
}

export class Last {
    created_at__date: Date = new Date();
    count: number = 0;
}
