<div class="flex" [ngClass]="theme" *ngIf="width > 769">
    <ng-container *ngIf="isShowPrevious">
        <button type="button" [disabled]="isDisablePrevious" class="btn-get-started cancel-btn minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815" (click)="btnCTA('previous')">
            {{'previous' | translate}}
        </button>
        <div class="pad5"></div>
    </ng-container>
    <ng-container *ngIf="isShowPreview">
        <button type="button" [disabled]="isDisablePreview" class="btn-get-started cancel-btn minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815" (click)="btnCTA('preview')">
            {{'preview' | translate}}
        </button>
        <div class="pad5"></div>
    </ng-container>
    <ng-container *ngIf="isShowContinue">
        <button type="submit" (click)="btnCTA('continue')" [disabled]="isDisableContinue" class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">
            {{'save and continue' | translate}}
        </button>
        <div class="pad5"></div>
    </ng-container>
    <ng-container *ngIf="isShowExit">
        <button type="submit" (click)="btnCTA('exit')" [disabled]="isDisableContinue" class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">
            {{'save and exit' | translate}}
        </button>
    </ng-container>
</div>
<div *ngIf="width < 769">
    <div class="flex flexJustifyCenter" [ngClass]="theme">
        <ng-container *ngIf="isShowPrevious">
            <button type="button" [disabled]="isDisablePrevious" class="btn-get-started cancel-btn minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815" (click)="btnCTA('previous')">
                {{'previous' | translate}}
            </button>
            <div class="pad5"></div>
        </ng-container>
        <ng-container *ngIf="isShowPreview">
            <button type="button" [disabled]="isDisablePreview" class="btn-get-started cancel-btn minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815" (click)="btnCTA('preview')">
                {{'preview' | translate}}
            </button>
            <div class="pad5"></div>
        </ng-container>
    </div>
    <div class="flex flexJustifyCenter pdd-top-mob" [ngClass]="theme">
        <ng-container *ngIf="isShowContinue">
            <button type="submit" (click)="btnCTA('continue')" [disabled]="isDisableContinue" class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">
                {{'save and continue' | translate}}
            </button>
            <div class="pad5"></div>
        </ng-container>
        <ng-container *ngIf="isShowExit">
            <button type="submit" (click)="btnCTA('exit')" [disabled]="isDisableContinue" class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">
                {{'save and exit' | translate}}
            </button>
        </ng-container>
    </div>
</div>