import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-membership-event-card',
  templateUrl: './membership-event-card.component.html',
  styleUrls: ['./membership-event-card.component.scss']
})
export class MembershipEventCardComponent implements OnInit {
  @Input() data: any;
  @Input() organizationID: number = 0;
  constructor(public appService: AppService) { }

  ngOnInit(): void {
  }


  editEventCTA(data:any) {
    this.appService.togglePopup({
      type: 'createOrganizationEvent',
      load: true,
      overlayClose: true,
      refreshParent: true,
      context: {
        organizationID: this.organizationID,
        data:data
      }
    })
  }

}
