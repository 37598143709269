import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';

@Component({
  selector: 'app-invite-friends-popup',
  templateUrl: './invite-friends-popup.component.html',
  styleUrls: ['./invite-friends-popup.component.scss']
})
export class InviteFriendsPopupComponent implements OnDestroy, OnChanges {
  subscription: Subscription = new Subscription();
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  public checkedId: number[] = [];
  selectedIDs: any[] = [];
  userList: any = [];
  sentError:boolean = false;
  errorMsg:any
  constructor(
    private userService: UserService,
    public userStoreService: UserStoreService,
    private organizationService: OrganizationService,
    public organizationStoreService: OrganizationStoreService,
    private appService: AppService
  ) {
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof InviteFriendsPopupComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.popup && changes.popup.currentValue) {
      if (this.popup.context.data && this.popup.context.data.length != 0) {
        this.userList = this.popup.context.data;
      }
    }
  }

  /**
   * @description
   * this method is used for searchin the users
   *
   * @param {*} event
   * @memberof InviteFriendsPopupComponent
   */
  searchFriends(event: any) {
    const param = {
      search: event
    }
    if(this.popup.context.userType == "user") { 
      this.searchUsers(param);
    } else {
      this.searchOrganization(param)
    }
  }

  /**
   * @description
   * method is used for search users
   *
   * @param {*} param
   * @memberof InviteFriendsPopupComponent
   */
  searchUsers(param: any) {
    this.subscription.add(
      this.appService.getCombinedUserList(param).subscribe((response: any) => {
       this.userList = response.results || response;
       this.checkIsAlreadyChecked();
      })
    );
  }

  /**
   * @description
   * method is used for getting output event from the invite friend item 
   *
   * @param {*} ev
   * @memberof InviteFriendsPopupComponent
   */
  userCheckedEvent(ev: any, user: any) {
    // //console.log(ev);
    
    if (ev.event) {
      this.selectedIDs.push({id: user.id, type: user.user_type, email:ev.email || 'User'});
    } else {
      this.selectedIDs = this.selectedIDs.filter(x => (x != user.id))
    }
  }

  /**
   * @description
   * this method is used for check already user checked or not
   *
   * @memberof InviteFriendsPopupComponent
   */
  checkIsAlreadyChecked() {
    for (const us of this.selectedIDs) {
      for (const user of this.userList) {
        if (us.id == user.id) {
          user.isChecked = true;
          break;
        }
      }
    }
  }

  /**
   * @description
   * method is used for search organization
   *
   * @param {*} param
   * @memberof InviteFriendsPopupComponent
   */
  searchOrganization(param: any) {
    this.subscription.add(
      this.appService.getCombinedUserList(param).subscribe((response: any) => {
        this.userList = response.results || response;
        this.checkIsAlreadyChecked();
      })
    );
  }


  /**
   * @description
   * method is used for invite the user group members
   *
   * @memberof InviteFriendsPopupComponent
   */
  userInviteGroupMembers() {
    let data = {
      'group_id': this.popup.context.id,
      'user_ids': this.selectedIDs.map(x => x.id)
    }
    this.subscription.add(
      this.userService.inviteGroupMembers(data).subscribe((response: any) => {
        this.appService.showToaster(response)
        this.closePopup();
      }, (error : any) => {
        this.appService.getCombinedUserList().subscribe((response: any) => {
          this.userList = response.results || response;
          this.selectedIDs = []
          this.sentError = true
          this.errorMsg = error.error.message
        });
      })
    );
  }

  /**
   * @description
   * method is used for invite the organization group members
   *
   * @memberof InviteFriendsPopupComponent
   */
  organizationInviteGroupMembers() {
    let data = {
      'group_id': this.popup.context.id,
      'organization_ids':  this.selectedIDs.filter((x: any) => x.type == 'Organization').map(x => x.id),
      'user_ids':  this.selectedIDs.filter((x: any) => x.type == 'User').map(x => x.id)
    }

    
    this.subscription.add(
      this.organizationService.inviteGroupMembers(data).subscribe((response: any) => {
        this.appService.showToaster(response)
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * This method is using for submit api call
   *
   * @returns
   * @memberof InviteFriendsPopupComponent
   */
  onSubmit() {
    ////console.log(this.selectedIDs)
    if(this.popup.context.userType == "user") {
      this.userInviteGroupMembers();
    } else {
      this.organizationInviteGroupMembers();
    }
    
  }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof InviteFriendsPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }

  /**
    * @description
    * Called once, before the instance is destroyed.
    * Add 'implements OnDestroy' to the class.
    * @memberof InviteFriendsPopupComponent
    */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
