<div class="bdr-1-EEEEEE boxShadow-RGBA00005 padleft15 padtop15 padbtm15 bg-FFFFFF bdrRadius6px {{userType}}">
    <div class="flex  flexJustifyBetween flexAlignItemsCenter padtop10 padbtm10 padright15">
        <div class="poppins-semibold font20 timeline-label">{{'photos' | translate}}</div>
        <button type="button" (click)="seeAll()"
            class="btn-get-started btn-user-default minmaxWidth100ppx bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad5 padleft15 padright15">{{'see all' | translate}}</button>
    </div>
    <div class="flex flexWrap padbtm10 padright5 width100">
        <div class="flex1 padbtm10 padright10 flexBasis33 maxWidth33p" *ngFor="let photo of photos | slice: 0:6; let i = index;">
            <div class="width100 height100p ripple">
                <img [src]="photo.photo || photo.photo_url" (click)="viewPhotos(i, photo.id)" class="bdrRadius4px cursorPtr bdr-1-EEEEEE boxShadow-RGBA00005  minHeight150px width100 height100p maxHeight150px" />
            </div>
        </div>
        <!-- <div class="flex1 padbtm10 padright10 flexBasis33 maxWidth33p" *ngFor="let photo of photos | slice: 0:6; let i = index;">
            <div class="cover-profile-image posRelative flex flexJustifyCenter">
                <span class="online-img-shadow bdrRadius50p posRelative displayFlex">
                  <img [src]="photo.photo" (click)="viewPhotos(i)" class="d150 ">
                 
                </span>
              </div>
        </div> -->
      
    </div>
</div>