import { AppService } from 'src/app/core/services/app.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStore } from 'src/app/core/stores/app.store';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-briindlet-event-guest',
  templateUrl: './briindlet-event-guest.component.html',
  styleUrls: ['./briindlet-event-guest.component.scss']
})
export class BriindletEventGuestComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  // @Output() selectedGuestFiles = new EventEmitter();
  constructor(
    public appStore: AppStore,
    private fb: FormBuilder,
    private appService: AppService
  ) { }

  getImage(event: any, index: number) {
    // this.selectedGuestFiles.emit(event);
    this.formGrp.controls.step_8.controls.guest.controls[index].patchValue({
      photo: event.base64 || event.file,
      file: event.file
    });
  }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  newGuest(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      photo: [''],
      file: ['']
    })
  }

  addGuest() {
    this.guests.push(this.newGuest())
  }
 
  get guests() : FormArray {
    return this.formGrp.get('step_8').get("guest") as FormArray
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_8'].patchValue({
    //   who_can_attend: this.appService.cacheData1?.step_8?.who_can_attend
    // })
    let index:number = 0
    // this.formGrp.controls.step_8.controls.guest.controls[index].patchValue({
    //   photo: this.appService.cacheData1?.step_8?.guest[0].photo,
    //   name: this.appService.cacheData1?.step_8?.guest[0].name
    // });
    // this.appService.cacheData1.subscribe(res=>{
    //   //console.log('steps 8', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_8'].patchValue({
    //     who_can_attend: steps.step_8?.who_can_attend
    //    })
    //    this.formGrp.controls.step_8.controls.guest.controls[index].patchValue({
    //     photo: steps.step_8?.guest[0].photo,
    //     name: steps.step_8?.guest[0].name,
    //    })
    // })
  }

}
