import {EventEmitter, Injectable} from '@angular/core';
import { observable, action } from "mobx-angular";
import { Subject, BehaviorSubject } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { QbService } from 'src/app/core/services/qb.service';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { UserStoreService } from 'src/app/users/store/user-store.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { AppService } from 'src/app/core/services/app.service';


declare var QB: any;
@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public components: any = {
    outer:true, //For Responsive
    chatsClicked: false, // For displaying OneToOne and Group Chats
    publicChatClicked: false, // For displaying Public Chats
    createGroupClicked: false, // For creating OneToOne and Group Chats
    onChatClick: false, // For displaying messages ( Dialog Component )
    welcomeChat: true, // Display default Welcome Chat screen
    updateDialog: false // For displaying update dialog
  };

  public componentsEvent = new Subject<any>();

  private _chatRoomsListener = new BehaviorSubject<any>(false);
  @observable chatRoomsListener$:any = this._chatRoomsListener.asObservable();
  

  constructor(
    public dialogService: DialogService,
    private qbService: QbService,
    private http: HttpClient,
    private userStoreService: UserStoreService,
    private appStore: AppStore,
    private appService: AppService
    ) {
  }

  public showComponent(components: Object) {
    Object.entries(components).forEach(([key, value]) => {
      this.components[key] = value;
    });
    this.componentsEvent.next(this.components)
    // this.componentsEvent.emit(this.components);
  }

  @action saveChatRoomStore(data: any) {
    // //console.log('Saving to behaviour subject here')
    this._chatRoomsListener.next(data)
  }

  @action async getChatList(type: any) {
    const filter: any = {
      limit: 100,
      sort_desc: 'updated_at'
    };


    if (type === 'chat') {
      filter['type[in]'] = [3, 2].join(',');
    } else {
      filter['type'] = 1; 
    }
    let occupanceIDs: any = [];
    let currentUser = JSON.parse(localStorage.getItem('loggedinUser') || '')


    let res = await this.dialogService.getDialogs(filter)
    if (res) {
      res?.['items']?.forEach(async (chat: any, index: any, self:any ) => {
        if ( chat.xmpp_room_jid ) {
          this.dialogService.joinToDialog(chat);
        }
        if(chat.occupants_ids.length == 2) {
          let frienIDs = chat.occupants_ids.filter((item: any) => item !== currentUser.id);
          let response = await this.qbService.getUserList({
            field: 'id',
            value: frienIDs,
            per_page: frienIDs.length
          })
            

            if (response?.[0]?.custom_data) {
              const fileUID = JSON.parse(response?.[0]?.custom_data)['avatar_uid'];
              let existingAvatar = QB.content.privateUrl(fileUID);
              if(existingAvatar === chat.avatarUrl) {

              } else {
                chat.avatarUrl = QB.content.privateUrl(fileUID);
              }
              
            } else {
              chat.avatarUrl = null;
            }
 
        
            this.qbService.addToCache(response[0]);
          
        } else {
          chat.avatarUrl = null;
        }
        
        self[index].last_message_date_sent = +chat.last_message_date_sent * 1000;
        
      });
      this.dialogService.setDialogs(res['items'])
      this.saveChatRoomStore(res['items'])
    }
    
      
  }

  getUnreadMessageCount(params?: any) {
    return new Promise(async (resolve,reject)=> {
      const data: any = await this.http.get(`https://api.quickblox.com/chat/Message/unread.json`).toPromise();

      this.userStoreService.apiResp = data
      this.appStore.chatCount = data.total || 0
      resolve(data)
    })
   
  }

    // get Users List
    public async searchUserInQuickblox(value: any): Promise<any> {
      return new Promise(async (resolve: any, reject: any) => {
        let searchedUsers: any
        let searchedOrg: any
    
        [searchedUsers, searchedOrg] = await Promise.all([
          this.appService.searchAllUsersToPromise({ search_term: value, search_type: 'user' }),
          this.appService.searchAllUsersToPromise({ search_term: value, search_type: 'organization' })
        ]);
    
        const usersChatIds = searchedUsers.length > 0 ? searchedUsers.map((user: any) => user.chat_id) : [];
        const orgChatIds = searchedOrg.length > 0 ? searchedOrg.map((org: any) => org.chat_id) : [];
        const combinedIdsSet = new Set([...usersChatIds, ...orgChatIds]);
        const chatIds = Array.from(combinedIdsSet);

        let finalChatId = chatIds.filter(function(element: any) {
          return element !== null && element !== "";
        });

        let searchResults = await this.qbService.getQBUsersByChatIds(finalChatId || '')
        
        //console.log("Testing Response", searchResults)
        resolve(searchResults)
      })
      
    }
}
