import { UpdateMetaTagService } from './../../core/services/update-meta-tag.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { config } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-view-post-detail',
  templateUrl: './view-post-detail.component.html',
  styleUrls: ['./view-post-detail.component.scss']
})
export class ViewPostDetailComponent implements OnInit {
  subscription: Subscription = new Subscription();
  queryParams: any;
  postData: any;
  user: any;
  postID: any;
  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private updateMetaTagService: UpdateMetaTagService,
    private router: Router
  ) {
    this.subscription.add(
      this.activatedRoute.params.subscribe(param => {
        this.postID = param['postID']
      })
    )
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(param => {
        if (param) {
          this.queryParams = param;
          if (!this.authService.isLoggedIn()) {
            this.getPostDetail();
          }
        }
      })
    )
  
  }

  currentData(event: any) {
    if (this.authService.isLoggedIn()) {
      this.user = event;
    } else {
      this.user = {
        profile_pic: 'assets/images/add-pic-layer.png',
        name: 'Guest',
        id: ''
      };
    };
    this.getPostDetail();
  }

  close(event: any) {
    this.router.navigate(['/'])
  }

  getPostDetail() {
    let userType = 'user';
    if (this.queryParams?.postType?.includes('User')) {
      userType = 'user';
    } else {
      userType = 'organization';
    }
    this.subscription.add(
      this.appService.getPostDetail(this.postID,userType).subscribe((response: any) => {
        this.postData =  {
          type: 'viewPost',
          load: true,
          refreshParent: true,
          overlayClose: true,
          context: {
            post: response,
            medias: response.attachments?.length ? response.attachments : [],
            postType: 'post',
            selectedItem: 0,
            posted_by: { profile_pic: this.user?.profile_pic, name: this.user?.name, id: this.user?.id },
            theme: (this.authService.getSessionData()?.type?.toLowerCase() == 'organization' ? 'green' : 'blue')
          }
        }
        this.updateMetaTagService.setSocialMediaTags(
          `${config.webAppURL}/posts/${response.id}?postType=${this.queryParams?.postType}`,
          (response.title || _.truncate(response.description, {'length': 22}) || 'No Title') ,
          response.description,
          (response?.attachments?.length ? (response?.attachments[0]?.data?.photo_url || response?.attachments[0]?.data?.video_url) : `${config.baseApiURL}/media/default.jpeg`));
          (<any>window).prerenderReady = true;
        })
      
    )
    
  }

  ngOnInit(): void {
  }

}
