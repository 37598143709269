import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';

@Component({
  selector: 'app-milestone-card',
  templateUrl: './milestone-card.component.html',
  styleUrls: ['./milestone-card.component.scss']
})
export class MilestoneCardComponent implements OnInit {
  @Input('data') data : any;
  organizationID: number = 0;
  isShowEdit: boolean = false;
  subscription: Subscription = new Subscription();
  constructor(public appService: AppService,
    private activatedRoute: ActivatedRoute,
    private organizationService: OrganizationService,) { 
    this.getRouterParams()
  }

  ngOnInit(): void {
    this.isShowEdit = this.organizationService.getIsValidOrganizationForEdit(this.organizationID);
    //console.log(this.isShowEdit);
  }

  getRouterParams() {
    if (this.activatedRoute.parent) {
      this.subscription.add(
        this.activatedRoute.parent.params.subscribe(param => {
          this.organizationID = param['id'];
        })
      )
    }
  }

  editMilestonePopupCTA(data: any): void {
    this.appService.togglePopup({
      type: 'addMilestone',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        data:data
      }
    });
  }
}
