<span class="close-popup ripple bg-F1F4FE bdrRadius4px timeline-label" (click)="closePopup();">×</span>
<div class="{{appStore?.userType}}">
    <div *ngIf="!paymentStatus">
        <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600  mrgnbtm40">
            {{'briindlet payment' | translate}}
        </div>
        <div class="body width100">

            <form novalidate (ngSubmit)="payBriindletPro()" [formGroup]="stripeTest">
                <input placeholder="{{'name' | translate}}" formControlName="name" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
    bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                <div class="pad10"></div>
                <div class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
    bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                    <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
                </div>
                <strong class="padtop10 color-red poppins-regular font15" *ngIf="errorStatus">{{errorMessage}}</strong>
                <div class="width100 padtop30 flex">
                    <button [disabled]="stripeTest?.invalid" class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                        {{ 'Pay' | translate}} {{'amount in number' | translate:{ amount: data?.template_amount || 0} }}
                    </button>
                </div>

            </form>
        </div>
    </div>
    <app-payment-status [success]="paymentStatus == 'success' ? true : false" *ngIf="paymentStatus"></app-payment-status>
</div>