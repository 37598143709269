<div class="{{appStore.userType}} minWidth1000px">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'customize your event template' | translate}}
    </div>
    <div class="flex maxHeight75vh height100p posRelative overflowAuto custom-scrollbar">
        <div class="width60 padleft0 pad15 padtop0">
            <span [ngSwitch]="data.template_name?.toLowerCase().split(' ').join('')">
                <span *ngSwitchCase="'defaulttemplate'">
                    <app-default-template [customizeUI]="customizeData" [isPreview]="true" [data]="data">
                    </app-default-template>
                </span>
                <span *ngSwitchCase="'template3'">
                    <app-briindlet-pro-template-three [customizeUI]="customizeData" [data]="data">
                    </app-briindlet-pro-template-three>
                </span>
                <span *ngSwitchCase="'template2'">
                    <app-briind-pro-template-two [isPreview]="isPreview" [customizeUI]="customizeData" [data]="data"></app-briind-pro-template-two>
                </span>
                <span *ngSwitchCase="'template1'">
                    <app-briindlet-pro-template-one [isPreview]="isPreview" [customizeUI]="customizeData" [data]="data"></app-briindlet-pro-template-one>
                </span>
            </span>
        </div>
        <div
            class="width40 padtop15 posSticky padbtm15 bdr-1-EEEEEE boxShadow-RGBA00005 bdrRadius6px height100p height75vh">
            <div class="padleft15 padright15 height100p" *ngIf="customizeData">
                <div class="col height100p">
                    <div class="tabs height100p">
                        <div class="tab timeline-label bdr-btm-2-EEEEEE custom-scrollbar" [ngClass]="{'overflowAuto': selectedRadio == i}" *ngFor="let key of objectKeys(customizeData); let i = index">
                            <input type="checkbox" id="{{key}}" [(ngModel)]="customizeData[key].isSelect" (change)="changeTab(key)"  [value]="i"  name="{{key}}">
                            <label class="tab-label timeline-label padleft0" for="{{key}}">{{key | translate}}</label>
                            <div class="tab-content pad0" *ngIf="key != 'button_customization'">
                                <div class="bdr-top-2-EEEEEE  padbtm10">
                                    <div class="padtop10" *ngFor="let subKey of objectKeys(customizeData[key])">
                                        <div class="color-7F7F7F font15 poppins-regular mrgnbtm10">
                                            {{ customizeData[key][subKey]?.header_title  | translate}}
                                        </div>
                                        <div class="flex mrgnbtm10" *ngIf="customizeData[key][subKey]">
                                            <select (change)="sendUIData()"
                                                [(ngModel)]="customizeData[key][subKey]['font_family']"
                                                class="select card-input">
                                                <option *ngFor="let item of fontFamilyList" value="{{item.id}}">
                                                    {{item.name | translate}}</option>
                                            </select>
                                            <div class="pad5"></div>
                                            <div class="d50">
                                                <input class="card-input pad15 d50" type="number" appDebounce
                                                    (debounceClick)="sendUIData()"
                                                    [(ngModel)]="customizeData[key][subKey]['font']">
                                            </div>
                                        </div>
                                        <div class="width100 mrgnbtm10">
                                            <input type="color" class="card-input pad5 d50" (change)="sendUIData()"
                                                [(ngModel)]="customizeData[key][subKey]['color']">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content pad0" *ngIf="key == 'button_customization'">
                                <div class="bdr-top-2-EEEEEE  padbtm10">
                                    <div class="padtop10" *ngFor="let subKey of objectKeys(customizeData[key])">
                                        <div class="timeline-label  font15 poppins-medium mrgnbtm10">
                                            {{ customizeData[key][subKey]?.header_title  | translate}}
                                        </div>
                                        <div class="mrgnbtm15">
                                            <div class="color-7F7F7F font15 poppins-regular mrgnbtm10">
                                                {{ 'change button name' | translate}}
                                            </div>
                                            <input class="card-input pad15" type="text" appDebounce
                                                (debounceClick)="sendUIData()"
                                                [(ngModel)]="customizeData[key][subKey]['button_name']">
                                        </div>
                                        <div class="mrgnbtm15">
                                            <div class="color-7F7F7F font15 poppins-regular mrgnbtm10">
                                                {{ 'text font' | translate}}
                                            </div>
                                            <div class="flex mrgnbtm10" *ngIf="customizeData[key][subKey]">
                                                <select (change)="sendUIData()"
                                                    [(ngModel)]="customizeData[key][subKey]['font_family']"
                                                    class="select card-input">
                                                    <option *ngFor="let item of fontFamilyList" value="{{item.id}}">
                                                        {{item.name | translate}}</option>
                                                </select>
                                                <div class="pad5"></div>
                                                <div class="d50">
                                                    <input class="card-input pad15 d50" type="number" appDebounce
                                                        (debounceClick)="sendUIData()"
                                                        [(ngModel)]="customizeData[key][subKey]['font']">
                                                </div>
                                            </div>
                                            <div class="width100 mrgnbtm15">
                                                <input type="color" class="card-input pad5 d50" (change)="sendUIData()"
                                                    [(ngModel)]="customizeData[key][subKey]['color']">
                                            </div>
                                        </div>
                                        <div class="mrgnbtm15" *ngIf="customizeData[key][subKey]['background_color']">
                                            <div class="color-7F7F7F font15 poppins-regular mrgnbtm10">
                                                {{ 'button background color' | translate}}
                                            </div>
                                            <div class="width100">
                                                <input type="color" class="card-input pad5 d50" (change)="sendUIData()"
                                                    [(ngModel)]="customizeData[key][subKey]['background_color']">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button (click)="actionCTA('reset')"
                class="btn-user-default mrgntop20 mrgnleft15 pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'save and continue' | translate}}
            </button>
        </div>
    </div>
</div>