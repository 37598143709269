import { Component, Input, TemplateRef, ViewChild, ElementRef, OnChanges } from '@angular/core';

@Component({
  selector: 'app-tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss']
})
export class TabPanelComponent {
  @Input() childTemplate: TemplateRef<any> | any;
  @Input() iconClass: string = '';
  @Input() label: string = '';
  @ViewChild("el", { read: ElementRef }) el: ElementRef | any;
  currentActiveTab: string = '';
  constructor() { }

  /**
   * @description
   * this method is used for setting the panel height
   *
   * @memberof TabPanelComponent
   */
  setPanelHeight() {
    let panel = this.el.nativeElement.nextElementSibling;
    this.el.nativeElement.classList.toggle("active");
    panel.classList.toggle('maxHeightUnset');
    this.currentActiveTab =this.el.nativeElement.innerText;
  }

  removePerviousHeight(event? : any) {
    let element: any = document.getElementsByClassName('active') || [];
    if (element.length != 0) {
      element[0].nextElementSibling.classList.remove('maxHeightUnset');
      element[0].classList.remove('active');
    }
    this.setPanelHeight();
    // this.currentActiveTab = event.target.innerText;
  }
  /**
   * @description
   * this method is used for toggling the panel
   *
   * @param {*} [event]
   * @memberof TabPanelComponent
   */
  showPanel(event?: any) {
    let element: any = document.getElementsByClassName('active') || [];
    if (this.currentActiveTab != '') {
      if(element.length != 0) {
        if (this.currentActiveTab == element[0].innerText && typeof (element[0].innerText) == 'string') {
          element[0].nextElementSibling.classList.remove('maxHeightUnset');
          element[0].classList.remove('active');
          this.currentActiveTab = "";
        } else {
          this.removePerviousHeight(event);
        }
      } else {
        this.removePerviousHeight(event);
      }
    } else {
      this.removePerviousHeight(event);
    }
  }



}
