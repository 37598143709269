<!-- promotions start here -->
<div class="bdrRadius10px boxShadow-RGBA00005 posRelative width100 pad15 bg-white mrgnbtm15">
    <div class="width100 posRelative">
        <img [src]="organization.profile_pic" class="bdrRadius10px width100 maxHeight200px">
        <a
            class="bg-F1F4FE pad0510 timeline-label poppins-light posRight5px  posTop5px bdrRadius30px font14 posAbsolute">{{'promoted'
            | translate}}</a>
    </div>
    <div class="width100 flex flexJustifyBetween mrgntop10 flexWrap">
        <div>
            <div class="poppins-semibold font14 color-122A4D ">{{organization?.name}}</div>
            <div class="poppins-regular font13 color-8E8E8E "
                *ngIf="organization?.overview?.city && organization?.overview?.country">
                {{organization?.overview?.city}}, {{organization?.overview?.country}}</div>
        </div>
        <div class="">
            <button
                class="btn-get-started ripple cursorPtr mrgnauto btn-user-default poppins-medium font14 flexAlignSelfCenter">
                {{'follow' | translate}}
            </button>
        </div>

    </div>
</div>

<!-- promotions end here -->