<footer id="footer-section" class="pad40 bg-RGBA631698">
    <div class="flex flexResponsive">
        <div class="width33">
            <img src="assets/images/brind-logo-white.png" class="footer-logo" />
            <div class="font18 lineHeight24px txtLeft color-FFFFFF poppins-light opacityp8 letterSpacingNormals padtop15 padbtm15">
                {{'Changing the way you connect socially and do business' | translate}}
            </div>
        </div>
        <div class="pad5"></div>
        <div class="width33 flex">
            <div class="width50">
                <div class="poppins-medium font20 lineHeight24px color-FFFFFF padbtm10">{{'explore' | translate}}</div>
                <div class="padtop5 padbtm5">
                    <a href="#" class="footer-links displayBlock font18 lineHeight24px txtLeft color-FFFFFF poppins-light opacityp8 letterSpacingNormals">
            {{'home' | translate}}
          </a>
                </div>
                <div class="padtop5 padbtm5">
                    <a href="#" class="footer-links displayBlock font18 lineHeight24px txtLeft color-FFFFFF poppins-light opacityp8 letterSpacingNormals">
            {{'pro briind' | translate}}
          </a>
                </div>
                <div class="padtop5 padbtm5">
                    <a href="#" class="footer-links displayBlock font18 lineHeight24px txtLeft color-FFFFFF poppins-light opacityp8 letterSpacingNormals">
            {{'about briind' | translate}}
          </a>
                </div>
            </div>
            <div class="pad5"></div>
            <div class="width50">
                <div class="poppins-medium font20 lineHeight24px color-FFFFFF padbtm10">{{'legal' | translate}}</div>
                <div class="padtop5 padbtm5">
                    <a [routerLink]="'/privacy-policy'" class="footer-links displayBlock font18 lineHeight24px txtLeft color-FFFFFF poppins-light opacityp8 letterSpacingNormals">
            {{'privacy policy' | translate}}
          </a>
                </div>
                <div class="padtop5 padbtm5">
                    <a [routerLink]="'/terms-and-condition'" class="footer-links displayBlock font18 lineHeight24px txtLeft color-FFFFFF poppins-light opacityp8 letterSpacingNormals">
            {{'terms and conditions' | translate}}
          </a>
                </div>
            </div>
        </div>
        <div class="pad5"></div>
        <div class="width33">
            <div class="poppins-medium font20 lineHeight24px color-FFFFFF padbtm10">{{'social' | translate}}</div>
            <div class="flex">
                <div class="social-ico-bg displayFlex cursorPtr ripple bdrRadius50p d45 bg-E7E8Eb flexAlignItemsCenter flexJustifyCenter">
                    <i class="icns icon-twitter d20"></i>
                </div>
                <div class="pad5"></div>
                <div class="social-ico-bg displayFlex cursorPtr ripple bdrRadius50p d45 bg-E7E8Eb flexAlignItemsCenter flexJustifyCenter">
                    <i class="icns icon-facebook d20"></i>
                </div>
                <div class="pad5"></div>
                <div class="social-ico-bg displayFlex cursorPtr ripple bdrRadius50p d45 bg-E7E8Eb flexAlignItemsCenter flexJustifyCenter">
                    <i class="icns icon-youtube d20"></i>
                </div>
            </div>
        </div>
    </div>

</footer>
<div class="copy-right pad30 bg-white poppins-regular font16 color-000000">
    &copy; {{currentYear | date: 'yyyy'}} {{'enjoy all rights reserved' | translate}}
</div>