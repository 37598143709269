import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdvertisementService } from 'src/app/advertisement/services/advertisement.service';
import { AdvertisementStoreService } from 'src/app/advertisement/store/advertisement-store.service';

@Component({
  selector: 'app-post-services-card',
  templateUrl: './post-services-card.component.html',
  styleUrls: ['./post-services-card.component.scss']
})
export class PostServicesCardComponent implements OnInit {
  subscription: Subscription = new Subscription();
  @Input('userType') userType: string = '';
  @Input('side') side: string = '';
  @Input() data: any = [];
  constructor(
    private advertisementService: AdvertisementService,
    public advertisementStoreService: AdvertisementStoreService
  ) { }

  ngOnInit(): void {
    // this.getAdvertisementTimelineList();
  }

  /**
   * @description
   * this method is used for getting the Advertisement list
   *
   * @memberof AdvertisementListComponent
   */
   getAdvertisementTimelineList() {
     let data = { 
      'page_number' : 1
     }
    this.subscription.add(
      this.advertisementService.getAdvertisementTimelineList(data).subscribe((response: any) => {
        this.advertisementStoreService.getAdvertisementTimelineList();
      })
    );
  }
}
