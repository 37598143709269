<div class="online-showing pad15 height100vh page-max-height bg-02457A posSticky posLeft desktopVisible">
    <div class="color-FFFFFF poppins-semibold font14 lineHeight40px mrgnbtm10 orgtxt">{{'online' | translate}}
    </div>
    <div *ngFor="let item of users" (click)="onSubmit(item)">
        <span class="online-img-shadow bdrRadius50p posRelative displayFlex mrgnbtm15 cursorPtr">
            <img [src]="item.profile_pic" class="online-img bdrRadius50p d40">
            <span class="green-online-dot"></span>
        </span>
    </div>
</div>

<div class=" res pad15  mobiVisible">
    <div class="color-02457A orgtxt poppins-semibold font14 lineHeight40px padtop5 mrgnbtm10 restxt">{{'online' | translate}}
    </div>
    <div *ngIf="!users?.length">
        <div class="txtCenter poppins-regular color-8E8E8E font14">No users Online</div>
    </div>
    <div class="flex" *ngIf="users?.length">
        <div *ngFor="let item of users" (click)="onSubmit(item)">
            <span class="online-img-shadow bdrRadius50p posRelative displayFlex mrgnbtm15 cursorPtr">
                <img [src]="item.profile_pic" class="online-img bdrRadius50p d40">
                <span class="green-online-dot"></span>
            </span>
        </div>
    </div>
</div>