import { AppStore } from 'src/app/core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-share-to-friends-popup',
  templateUrl: './share-to-friends-popup.component.html',
  styleUrls: ['./share-to-friends-popup.component.scss']
})
export class ShareToFriendsPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  searchInput: string = '';
  subscription: Subscription = new Subscription();
  selectedUsers: any = [];
  isLoader: boolean = false;
  shareMessage: string = '';
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  selectUsers(item : any) {
    if (this.selectedUsers.includes(item)) {
      this.selectedUsers = this.selectedUsers.filter((x: any) => x != item)
    } else {
      this.selectedUsers.push(item)
    }
  }

  shareMessageEmit(event: any) {
    this.shareMessage = event;
  }

  shareToUser() {
    this.isLoader = true;
    let param: any = {};
    param.description =  this.shareMessage;
    param.post_type = this.popup.context.postType;
    param.post_id = this.popup.context.postid;
    param.user_ids = this.selectedUsers;
    this.subscription.add(
      this.appService.bulkShare(param, `${this.appStore.userType.toLowerCase()}s`).subscribe((response: any) => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `Shared successfully!` }
        });
        this.isLoader = false;
        this.close.emit('icon');
      }, er => {
        this.isLoader = false;
      })
    );
  }

  closePopup() {
    this.close.emit('icon');
  }

  ngOnInit(): void {
  }

}
