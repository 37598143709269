import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  host: {
    '(document:click)': 'onCloseCalendar($event)'
  }
})
export class DatepickerComponent implements OnInit, OnChanges {
  moment = moment;
  date: any = new Date();
  month: any; 
  year: any; 
  days: any[] = [];
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November', 'December'];
  showCalendar: boolean = false;
  result: string = '';
  @Input()  label:  string = '';
  @Input()  placeholder:  string = '';
  @Input()  value:  any = '';
  @Input() iconCss: string = '';
  @Input()  formCntrlName:  any;
  @Input() formGrp: any;
  @Output() update: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('datePicker') datePicker: ElementRef | any;
  @ViewChild('dateShow') dateShow: ElementRef | any;
  isOpenedCount: number = 0;
  
  updateMonth(e?:Event, type?: string) {
    if (e) e.stopPropagation();
    if (type === 'dec') this.month--;
    if (type === 'inc') this.month++;
    if (this.month < 0) {
      this.month = 11;
      this.year--;
    } else if (this.month > 11) {
      this.month = 0;
      this.year++;
    }
    const date = new Date(this.year, this.month, 0);
    const actualDate = new Date(this.year, this.month + 1, 0);
    const days = actualDate.getDate();
    const day  = date.getDay();
    const prefix = new Array(day + 1);
    this.days = prefix.concat(this.range(1, days))
  }

  selectDay(day: number) {
    if (!day) return;
    const pad = (s: any) => s.length < 2 ? 0 + s : s;
    this.date = new Date(this.year, this.month, day);
    this.result = this.moment(this.date).format('dddd, MMMM DD YYYY');
    this.formGrp.patchValue({
      [this.formCntrlName]: this.moment(this.date).format('YYYY-MM-DD')
    })
    this.update.emit(this.date);
  }

  onShowCalendar(e: Event) {
    e.stopPropagation();
    this.isOpenedCount = 1;
    const windowHeight = document.documentElement.offsetHeight;
    const inputPosition = this.datePicker.nativeElement.getBoundingClientRect().bottom;
    if (inputPosition > windowHeight ) {
      this.dateShow.nativeElement.classList.add('topPosition')
    } else {
      this.dateShow.nativeElement.classList.add('bottomPostion')
    }
    this.showCalendar = true;
  }
  onCloseCalendar(e: Event) {
    if (this.showCalendar) {
      this.showCalendar = false;
      if(this.isOpenedCount == 1) {
        this.isOpenedCount = 2;
      }
      this.update.emit(this.result);
    }
    return;
  }

  range(start : number, end : number) {
    var myArray = [];
    for (var i = start; i <= end; i += 1) {
      myArray.push(i);
    }
    return myArray;
  };

  updateValue() {
    this.value ? this.date = new Date(this.value) : this.date = new Date(); 
    this.month = this.date.getMonth();
    this.year  = this.date.getFullYear();
    if (this.value) this.selectDay(this.date.getDate());
    this.updateMonth();
  }

  ngOnInit() {  
    this.updateValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.value && changes.value.currentValue) {
      this.updateValue();
    }
    if (this.value) {
      this.selectDay(this.date.getDate())
    } else {
      this.isOpenedCount = 0;
      this.result = '';
    };
  }

}
