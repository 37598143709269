import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choose-your-account-popup',
  templateUrl: './choose-your-account-popup.component.html',
  styleUrls: ['./choose-your-account-popup.component.scss']
})
export class ChooseYourAccountPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  public slectedAccountType: string | any = 'users';
  constructor(
    private router: Router
  ) { }

  /**
   * Click On Continue Button This method will call
   * This method is using for Navigate to corresponding sections
   *
   * @memberof ChooseYourAccountPopupComponent
   */
  continuteCTA() {
    this.router.navigate([`/${this.slectedAccountType}/auth/signin`]);
    this.closePopup('true');
  }

  /**
   * @description
   * This Method will call When Click on any Account Type
   *
   * @param {string} [accountType]
   * @memberof ChooseYourAccountPopupComponent
   */
  selectAccountCTA(accountType?: string) {
    this.slectedAccountType = accountType;
  }

  /**
   * @description
   * Click on close button this method will call
   *
   * @memberof ChooseYourAccountPopupComponent
   */
  closePopup(type: string) {
    if (type == 'true') {
      this.close.emit('icon');
    } else {
      this.close.emit('icon');
      this.router.navigate([`/home`]);
    }
  }

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   * 
   * @memberof ChooseYourAccountPopupComponent
   */
  ngOnInit(): void {

  }

}
