import { UserStoreService } from 'src/app/users/store/user-store.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-friends-section',
  templateUrl: './timeline-friends-section.component.html',
  styleUrls: ['./timeline-friends-section.component.scss']
})
export class TimelineFriendsSectionComponent implements OnInit {
  @Input('userType') userType: string = 'user';
  @Input('data') data: any;
  @Input('userID') userID : any = "";
  constructor(
    private userStoreService: UserStoreService
  ) { }

    /**
   * TODO: comment showFriendsList
   * @description Shows friends list
   * @returns  
   * @memberof ProfileCoverComponent
   */
     showFriendsList() {
      if (this.userStoreService.profileDetail?.is_own_profile) {
        return true;
      }
      else {
        if (this.userStoreService.profileDetail?.settings.see_friends_list == "everyone") {
          return true;
        }
        else if (this.userStoreService.profileDetail?.settings.see_friends_list == "friends" && !this.userStoreService.profileDetail?.is_friend) {
          return false;
        }
        else if (this.userStoreService.profileDetail?.settings.see_friends_list == "private") {
          return false;
        }
        else {
          return true;
        }
      }
    }

  ngOnInit(): void {
  }

}
