<ng-container [ngSwitch]="type">
    <div *ngSwitchCase="'Groups'">
        <div class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flexAlignItemsCenter flexJustifyBetween flex2c mob-width-card">
                <div (click)="goToRoute('groups')" class="flex flexAlignItemsCenter">
                    <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                    <div class="padleft10 cursorPtr">
                        <div class="poppins-medium font14 timeline-label">{{ data?.name }}</div>
                        <div class="poppins-regular font12 color-8E8E8E" *ngIf="data?.members_count">
                            {{ 'members count' | translate: {count: data?.members_count} }}
                        </div>
                    </div>
                </div>
                <span class="flex">
                    <div class="flex flexAlignItemsCenter flexNoShrink" #actionCmp>
                        <div class="pad3"></div>
                        <app-card-actions  [actions]="actions" (cardActionCTA)="cardCTA($event)">
                        </app-card-actions>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'SearchUserCard'">
        <div class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} {{customClass || 'bg-FFFFFF '}} boxShadow-RGBA00005 cursorPtr  bdrRadius6px pad10 flex flexAlignItemsCenter" (click)="cardClickCTA($event,data?.id)">
            <div class="flex flexAlignItemsCenter flex2c">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="padleft10 cursorPtr">
                    <div class="poppins-medium font15 color-122A4D">{{ (data.name || (data.first_name + ' ' + data?.last_name) ) |translate }}</div>
                    <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.work?.name">
                        {{ data?.work?.designation}} at {{data?.work?.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'SearchOrganizationCard'">
        <div class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter" (click)="cardClickCTA($event,data?.id)">
            <div class="flex flexAlignItemsCenter flex2c">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="padleft10 cursorPtr">
                    <div class="poppins-medium font15 color-122A4D">{{ data.name |translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'GroupMembers'">
        <div (click)="cardClickCTA($event,data?.id)" class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flexAlignItemsCenter flex2c">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="padleft10 cursorPtr">
                    <div class="poppins-medium font15 timeline-label">{{ data?.user?.name || data?.organization?.name }}
                    </div>
                </div>
            </div>
            <span class="flex">
                <button *ngIf="data?.role?.toLowerCase() == 'member' && !(data?.status?.toLowerCase() == 'invited')"
                    class="btn-get-started btn-user-default minWidth100px bdr-1-EEEEEE  ripple cursorPtr mrgnleft5 font14 poppins-regular pad5">
                    {{data?.status}}
                </button>
                <div class="flex flexAlignItemsCenter flexNoShrink" #actionCmp>
                    <div class="pad3"></div>
                    <app-card-actions  [actions]="actions" (cardActionCTA)="cardCTA($event)">
                    </app-card-actions>
                </div>
            </span>

        </div>
    </div>
    <div *ngSwitchCase="'ApplicationMembership'">
        <div (click)="cardClickCTA($event,data?.id)" class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flexAlignItemsStart flex2c">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div>
                    <div class="padleft10 cursorPtr">
                        <div class="poppins-medium font15 color-122A4D">{{ data.name |translate }}</div>
                        <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.work?.name && data?.work?.designation">
                            {{'designation at company' | translate: {designation: data?.work?.designation || 'Not yet working', company: data?.work?.name || 'briind' } }}
                        </div>
                    </div>
                    <div class="mrgntop5">
                        <app-card-actions #actionCmp [actions]="data.actions" (cardActionCTA)="cardCTA($event)">
                        </app-card-actions>
                    </div>
                </div>
            </div>
            <a (click)="cardCTA('view')" class="timeline-label cursorPtr font14 poppins-medium txtUnderline posAbsolute posTop15px posRight15px">{{'view' | translate}}</a>
        </div>
    </div>
    <div *ngSwitchCase="'MemberManagement'">
        <div (click)="cardClickCTA($event,data?.user_id || data.id)" class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flexAlignItemsStart flex2 width100">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="flex flexJustifyBetween width100">
                    <div class="padleft10 cursorPtr">
                        <div class="poppins-medium font15 color-122A4D">{{ data.name |translate }}</div>
                        <div class="poppins-regular font13 color-8E8E8E">
                            {{'designation at company' | translate: {designation: data?.work?.designation || 'Not yet working', company: data?.work?.name || 'briind' } }}
                        </div>
                    </div>
                    <div class="mrgntop5" #actionCmp>
                        <app-card-actions [actions]="actions" (cardActionCTA)="cardCTA($event)">
                        </app-card-actions>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngSwitchCase="'organizationFollowers'">
        <!-- <div (click)="routeToTimeline(data)"  [routerLink]="['/users',data?.follow_by?.id,'timeline']" class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter"> -->
        <div (click)="routeToTimeline(data)"  class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flexAlignItemsCenter flex2c">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="padleft10 cursorPtr">
                    <div class="poppins-medium font14 timeline-label">{{ data.follow_by?.name }}</div>
                    <div class="poppins-regular font12 color-8E8E8E"></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'OrganizationGroupMembers'">
        <div (click)="cardClickCTA($event,data.user_id || data.id)" class="flexResponsive bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flexAlignItemsCenter flex2c user-img-name">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="padleft10 cursorPtr">
                    <div class="poppins-medium font15 color-122A4D">{{ (data.name || data?.first_name || data?.user?.name) | translate }}</div>
                    <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.work?.name && data?.work?.designation">
                        {{'designation at company' | translate: {designation: data?.work?.designation || data?.overview?.work?.designation || 'Not yet working', company: data?.work?.name || data?.overview?.work?.name || 'briind' } }}
                    </div>
                    <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.members_count">
                        {{'members count' | translate: {count: data?.members_count} }}
                    </div>
                </div>
            </div>
            <div #actionCmp>
                <app-card-actions [actions]="actions" (cardActionCTA)="cardCTA($event);"></app-card-actions>
                <div class="custom-radio check-18878C flex flexAlignItemsStart flexAlignItemsCenter btnres" *ngIf="showCheckBox">
                    <input [(ngModel)]="data.isChecked" type="checkbox" (change)="inputActionCTA($event, data.user_id || data.id)">
                </div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'InvitedUserStatus'">
        <div (click)="cardClickCTA($event,data.id)" class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flexAlignItemsCenter flex2c">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="padleft10 cursorPtr">
                    <div class="poppins-medium font15 color-122A4D">{{ data.name | translate }}</div>
                    <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.work?.name && data?.work?.designation">
                        {{'designation at company' | translate: {designation: data?.work?.designation || data?.overview?.work?.designation || 'Not yet working', company: data?.work?.name || data?.overview?.work?.name || 'briind' } }}
                    </div>
                    <div class="poppins-medium font15 timeline-label">{{ data?.role?.name || 'not available' }}: <span class="color-red">{{data?.status}}</span></div>
                </div>
            </div>
            <app-card-actions #actionCmp [actions]="actions" (cardActionCTA)="cardCTA($event);"></app-card-actions>
        </div>
    </div>
    <div *ngSwitchCase="'InvitationMangementPending'">
        <div (click)="cardClickCTA($event, data.id)" class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsStart">
            <div class="flex flexAlignItemsCenter flex2c">
                <ng-container *ngTemplateOutlet="imageDisplay"></ng-container>
                <div class="padleft10 cursorPtr">
                    <div class="poppins-medium font15 color-122A4D">
                        {{ data?.name || data?.user?.name || data?.user?.first_name }}</div>
                    <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.user_id && data?.work?.designation">
                        {{'designation at company' | translate: {designation: data?.work?.designation || data?.overview?.work?.designation || 'Not yet working', company: data?.work?.name || data?.overview?.work?.name || 'briind' } }}
                    </div>
                </div>
            </div>
            <div class="flex" #actionCmp>
                <i class="icns icon-refresh d25 green mrgnright10" (click)="cardCTA('accept',data?.id)"></i>
                <i class="icns icon-close d25 green" (click)="cardCTA('remove',data?.id)"></i>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'UserMembership'" class="height100p">
        <div (click)="cardClickCTA($event,data?.id)" class="bdr-1-EEEEEE posRelative flexWrap flexJustifyCenter {{userType.toLowerCase()}} height100p boxShadow-RGBA00005 cursorPtr  bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter">
            <div class="flex flex2c width100 txtCenter  flexNoShrink">
                <div class="mrgnight10">
                    <app-profile-thumbnail [imageClass]="profilePicClass || 'd45'"
                        [image]="data?.profile_pic  || './assets/images/default-profile.jpeg'">
                    </app-profile-thumbnail>
                </div>
                <div class="flex flexJustifyStart   flexNoShrink">
                    <div class="padleft15 cursorPtr flexDirectionCol flex flexAlignItemsStart ">
                        <div class="poppins-medium font15 color-122A4D desktopVisible">{{ data.name || data?.organization_data?.name }}
                        </div>
                        <div class="poppins-medium font15 color-122A4D mobileAndTabVisible">{{ (data.name || data?.organization_data?.name) | truncate:[7] }}
                        </div>
                        <div class="poppins-regular font13 color-8E8E8E">
                            {{'members count' | translate:{count: data?.organization_data?.members_count?.toString() || data?.members_count?.toString()} }}
                        </div>
                        <div class="poppins-regular font13 color-8E8E8E flexNoShrink desktopVisible">
                            {{(data?.description || data?.organization_data?.description || '') | truncate:[25] }}
                        </div>
                        <div class="poppins-regular font13 color-8E8E8E flexNoShrink mobiVisible">
                            {{(data?.description || data?.organization_data?.description || '') | truncate:[18] }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="pad5"></div>
            <div class="flex flexJustifyCenter flexAlignItemsCenter ">

                <span #actionCmp>
                    <app-card-actions [actions]="data.actions" (cardActionCTA)="cardCTA($event)">
                    </app-card-actions>
                </span>
            </div>
        </div>
    </div>

    <p *ngSwitchDefault>

    </p>
</ng-container>

<ng-template #imageDisplay>
    <div class="mrgnight10">
        <app-profile-thumbnail [imageClass]="profilePicClass || 'd45'" [image]="data?.cover_photo || data?.organization_data?.cover_photo || data?.photo || data?.user?.profile_pic || data?.organization?.profile_pic || data?.profile_pic || data?.follow_by?.profile_pic || './assets/images/default-profile.jpeg'">
        </app-profile-thumbnail>
    </div>
</ng-template>