import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from "rxjs/operators";

import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth-service/auth.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private loadCount: number = 0;
  constructor(
      private authService: AuthService,
      private loader: LoaderService,
  ) { }
    
    /**
   * @description
   * Error handler function from 'catchError()'
   * @private - This Method Is  Private 
   * @param {HttpErrorResponse} err
   * @memberof HttpInterceptorService
  */
  private handleError(err: HttpErrorResponse){
    this.httpStatusHandler(err)
    const error = err.error.message || err.statusText;
  }

  /**
   * @description
   * this method is calling after success api call
   * checking the status here and performing condtions based on that
   * 
   * @private - This Method Is  Private 
   * @param {*} [response]
   * @returns HttpResponse returns
   * @memberof HttpInterceptorService
  */
  private httpStatusHandler(response ?: any){
    switch (response.status) {
      case 200:
        // //console.log("will do later");
        break;
      default:
        // //console.log("nothing to do");
        break;
    }
    return response;
  }

  /**
   * @description
   * Setting the access tokens after sign in or sign up
   * @private
   * @param {*} [body]
   * @memberof HttpInterceptorService
  */
  private setSession(body ?: any) {
    if (body.token && body.result.id) {
      this.authService.settingLocalSessionData({"token": body.token, "briind_id": body.result.briind_id,  "orgId": body.result.id, "type": body.result.user_type || ''})
    }
  };


  /**
   * @description
   * This method using for breaking the api in mid level and appending user tokens
   *
   * @param {*} req
   * @param {*} next
   * @returns this will returns api Success Datas
   * @memberof HttpInterceptorService
  */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* Setting the jwt token to the header if the local storage has the Bearer token */
    /* For the Config API doesn't need any header, so we checking weather the req has the Config API endpoint or not*/
    if ( !req.urlWithParams.includes('search') && !req.urlWithParams.includes('briind_id') && !req.urlWithParams.includes('likes') &&  !req.url.includes('api.quickblox.com') &&  !req.url.includes('notification_count') &&  !req.url.includes('top_notifications')) {
      this.loadCount ++;
      setTimeout((x :any) => {
        this.loader.show();
      },100)  
    }
    
    
    if (this.authService.isLoggedIn() && !req.url.includes('api.quickblox.com')) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getSessionData().token}`
        },
        setParams: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      });
    } else if (req.url.includes('api.quickblox.com')) {
      req = req.clone({
        setHeaders: {
          'QB-Token': JSON.parse(localStorage.getItem('sessionResponse') || '{}').token
        }
      })
      this.loader.hide();
    }
    return next
    .handle(req)
    .pipe(tap(evt => {
        if (evt instanceof HttpResponse) {
          if (!this.authService.isLoggedIn()) {
            this.setSession(evt.body);
          };
          this.httpStatusHandler(evt);
        }
    }, (error: HttpErrorResponse) => {
        this.handleError(error)
    })).pipe(finalize(() => {
      this.loadCount--;
      if (this.loadCount <= 0) {
        setTimeout((x: any) => {
          this.loader.hide();
        },100)
      }
    }));
  }
    
}
