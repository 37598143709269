<div class="comment-section bdrRadius30px pad5 bg-F1F4FE flex" *ngIf="authService.isLoggedIn()">
    <div>
        <span class="online-img-shadow bg-RGBA2691221 bdrRadius50p posRelative displayFlex">
            <img [src]="profilePic || organizationStoreService?.profileDetail?.profile_pic || userStoreService?.profileDetail?.profile_pic "
                class="d40 bdrRadius50p">
        </span>
    </div>
    <div class="pad5"></div>
    <input class="bdrNone bgTransparent width100 font14 poppins-regular" [(ngModel)]="commentText"
        placeholder="write a comment..." (keyup.enter)="postUserComment()">
    <div class="emojy-container flex flexNoShrink flexAlignItemsCenter posRelative">
        <div #emojiIconContainer>
            <div class="ripple cursorPtr bdrRadius50p d40 flex flexAlignItemsCenter flexJustifyCenter"
                (click)="toggled = !toggled">
                <i class="icns icon-smiley grey d20 cursorPtr"></i>
            </div>
            <emoji-mart [style]="{ 'position': 'absolute', 'bottom': 0, 'right': 0 , 'z-index': '120'}" *ngIf="toggled"
                [showPreview]="false" (emojiClick)="handleSelection($event)" set="google" native="true"></emoji-mart>
        </div>
        <div class=" ripple cursorPtr bdrRadius50p d40 flex flexAlignItemsCenter flexJustifyCenter"
            (click)="postUserComment()">
            <i class="icns icon-send d20" [ngClass]="theme"></i>
        </div>
    </div>
</div>