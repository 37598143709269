import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { ImageTransform, ImageCroppedEvent } from 'src/app/shared/components/image-cropper/interfaces';

@Component({
  selector: 'app-crop-image-popup',
  templateUrl: './crop-image-popup.component.html',
  styleUrls: ['./crop-image-popup.component.scss']
})
export class CropImagePopupComponent implements OnInit {
  @Input('imageType') imageType: string = '';
  @Input('imageChangedEvent') imageChangedEvent: any;
  @Output() uploadImageEvent = new EventEmitter();
  @Output() uploadImageBase64Event = new EventEmitter();
  @Output() close =  new EventEmitter();
  @Input() cropingRatio: any;
  transform: ImageTransform = {};
  scale = 1;
  croppedImage: any;
  showCropper = false;
  containWithinAspectRatio = false;
  croppedBase64Image: any;
  userType: any;
  loader = this.loadingBar.useRef();
  constructor(public authService: AuthService,public loadingBar: LoadingBarService) { }

  /**
   * @description
   * this method is used for zoom out
   *
   * @memberof CropImagePopupComponent
   */
  zoomOut() {
    if(this.scale < .3){
      this.scale = .19999999999999998;
    }else{
    this.scale -= .1;
  }
  this.transform = {
    ...this.transform,
    scale: this.scale
  };
  }

  /**
   * this method is used for closing the popup
   * @memberof CropImagePopupComponent
   */
  closePopup() {
    this.close.emit('close')
  }

  /**
   * @description
   * this method is used for zoom in
   *
   * @memberof CropImagePopupComponent
   */
  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  /**
   * @description
   * this method is used for convert base64 to file object
   *
   * @param {*} dataurl - base64 string
   * @param {string} filename - filename
   * @memberof CropImagePopupComponent
   */
  dataURLtoFile(dataurl: any, filename: any) {
  
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

 /**
   * @description
   * this method is used for crop image
   *
   * @param {*} event - event object
   * @memberof CropImagePopupComponent
   */
  imageCropped(event: ImageCroppedEvent) {
    this.loader.start();
    this.croppedImage = this.dataURLtoFile(event.base64, Math.random().toString(36).substring(2) + '.png');
    this.croppedBase64Image = event.base64;
    this.loader.stop();
    
  }

  /**
   * @description
   * this method is used for upload image emit
   *
   * @memberof CropImagePopupComponent
   */
  uploadImage() {
    this.uploadImageEvent.emit(this.croppedImage);
    this.uploadImageBase64Event.emit({croppedImage:this.croppedImage,croppedImageBase64:this.croppedBase64Image});
  }

  ngOnInit(): void {
    this.userType = this.authService.getSessionData().type.toLowerCase();
  }

}
