import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';
import { AuthService } from '../../../../../core/services/auth-service/auth.service';

@Component({
  selector: 'app-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss']
})
export class PostCommentComponent implements OnChanges, OnDestroy {
  @Input() profilePic: string = '';
  @Input() theme: string = 'blue';
  @Input() post: any;
  @Input() comment: any;
  @Input('media') media: any;
  @Input('mediaType') mediaType: any;
  toggled: boolean = false;
  subscription: Subscription = new Subscription();
  commentText: string = "";
  trimComment: string = "";
  data: any;
  @Output('update') update = new EventEmitter();
  @ViewChild('emojiIconContainer') emojiIconContainer: ElementRef | any;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public userStoreService: UserStoreService,
    public appService: AppService,
    public organizationStoreService: OrganizationStoreService,
    public organizationService: OrganizationService
  ) { }

 


  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event ?: any): void {
    if (((this.emojiIconContainer && !this.emojiIconContainer.nativeElement.contains(event.target)))) {
      this.toggled = false;
    }
  }

  
  handleSelection(event: any) {
    this.commentText = this.commentText + (event.emoji ? event.emoji.native : '');
  }


  /**
  * @description
  * this method used for posting user comment
  * @param {*} [param]
  * @returns this api will return a observalble
  * @memberof PostCommentComponent
  */

  postUserComment() {
    this.trimComment = this.commentText.trim();
    this.commentText = "";
    if (this.trimComment.length > 0) {
      this.subscription.add(
        this.userService.postUserPostComment(this.requestData(this.media?.data?.id || this.media?.id || this.post?.id, this.mediaType)).subscribe((response: any) => {
          this.commentText = "";
          let responseData = { "result": response.result, "comment": this.comment }
          this.update.emit(responseData)
          this.appService.toggleToast({
            type: 'success',
            load: true,
            position: 'top-center',
            context: { description: `Comment added!` }
          });
        })
      );
    }
  }

  /**
  * @description
  * this method used for binding request data
  * @memberof PostCommentComponent
  */
  private requestData(content_id: any, content: string) {
    if (this.comment != null) {
     return {
        "parent_id": this.comment.id,
        "content_id": content_id,
        "content": content,
        "text": this.trimComment
      };
    } else {
      return {
        "content_id": content_id,
        "content": content,
        "text": this.trimComment
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // this.profilePic  = JSON.parse(localStorage.data).profile_pic;
  }

  /**
 * @description
 * Called once, before the instance is destroyed.
 * Add 'implements OnDestroy' to the class.
 *
 * @memberof PostCommentComponent
 */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
