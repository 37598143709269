import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BriindletPerformanceService } from 'src/app/briindlet-performance/services/briindlet-performance.service';
import { BriindletPerformanceStoreService } from 'src/app/briindlet-performance/store/briindlet-performance-store.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss']
})
export class DefaultTemplateComponent implements OnChanges {
  subscription: Subscription = new Subscription();
  @Input() isPreview: boolean = false;
  @Input() data: any;
  @Input() customizeUI: any;
  countdownTimer: any = '';
  briindGuest = [{
      id: 1,
      name: 'Johns Smith',
      photo: 'assets/images/profile.png',
    },
    {
      id: 2,
      name: 'James Doe',
      photo: 'assets/images/profile.png',
    },{
      id: 3,
      name: 'Christina James',
      photo: 'assets/images/profile.png',
    }
  ]
  constructor(
    private appService: AppService,
    public briindletPerformanceService: BriindletPerformanceService,
    public briindletPerformanceStoreService: BriindletPerformanceStoreService,
    private router: Router
  ) { }

  goToLink(link: any) {
    if (link.includes('http')) {
      window.open(link, '_blank')
    } else {
      window.open(`http://${link}`, '_blank')
    }
    
  }




  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.data && changes.data.currentValue && this.data.event_from) {
      let countDownDate = new Date(this.data.event_from).getTime();
      setInterval((x:any) => {
        let now: any = new Date().getTime();
        let distance = countDownDate - now;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.countdownTimer = `${Math.abs(days)} Day(s) ${Math.abs(hours)} Hour(s) ${Math.abs(minutes)} Minutes ${Math.abs(seconds)} Seconds`;
        if (distance < 0) {
          this.countdownTimer  = '';
          clearInterval(x);
        }
      },1000)
    }
  }


  checkURL(link: any, type: any) {
    switch (type) {
      case 'cause':
        this.briindletPerformanceService.getCauseDetail(link).subscribe((response: any) => {
          this.router.navigate([`/organizations/${response.organization_id}/profile/bussiness/give`], { queryParams: {id: link}})
        })
        break;
      case 'organization_link':
        this.router.navigate([`/organizations/${this.data.briind_id}/profile/bussiness`])
        break;
      case 'website_link':
        this.goToLink(link)
        break;  
      default:
        break;
    }
  }

  /**
   * TODO: comment saveBriindletViewProfile
   * @description Saves briindlet view profile
   */
  saveBriindletViewProfile(link: any, type: any) {
    let data = { content_type: "Briindlet", content_id: this.data.id, is_viewed: true };
    this.subscription.add(
      this.briindletPerformanceService.saveBriindletViews(data).subscribe((response: any) => {
        this.checkURL(link, type)
      }, error => {
        this.checkURL(link, type)
      })
    );
    let dataClick = { content_type: "Briindlet", content_id: this.data.id, clicked_type: "Normal" };
    this.subscription.add(
      this.briindletPerformanceService.saveBriindletClick(dataClick).subscribe((response: any) => {
        this.checkURL(link, type)
      }, er => {
        this.checkURL(link, type)
      })
    );
  }


  /**
   * TODO: comment saveBriindletRegister
   * @description Saves briindlet register
   */
  saveBriindletRegister(link: any, type: any) {
    let data = { content_type: "Briindlet", content_id: this.data.id, is_viewed: true };
    this.subscription.add(
      this.briindletPerformanceService.saveBriindletViews(data).subscribe((response: any) => {
        this.checkURL(link, type)
      }, error => {
        this.checkURL(link, type)
      })
    );
    let dataClick = { content_type: "Briindlet", content_id: this.data.id, clicked_type: "Link" };
    this.subscription.add(
      this.briindletPerformanceService.saveBriindletClick(dataClick).subscribe((response: any) => {
        this.checkURL(link, type)
      }, er => {
        this.checkURL(link, type)
      })
    );
  }

}
