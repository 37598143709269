import { defualtTemplate } from './../../../../core/mock-api/briindlet-template-customization';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '../../../../users/services/user.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../core/services/auth-service/auth.service';
import { AppService } from '../../../../core/services/app.service';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { OrganizationStoreService } from '../../../../../app/organizations/store/organization-store.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent implements OnInit, OnDestroy {
  @Input() public post: any;
  @Input() user: any;
  @Input() isshared: boolean=false;
  iconClass: string = 'blue';
  defualtTemplate: any = defualtTemplate;
  subscription: Subscription = new Subscription();
  attachments: any = [];
  mediaType: string = '';
  @Output('updateList') updateList = new EventEmitter();
  @Output('removedPost') removedPost = new EventEmitter();
  defaultCoverPhoto: string = './assets/images/cover_default.jpeg';
  configUI: any;
  showMore: boolean = false;
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private appService: AppService,
    private organizationService: OrganizationService,
    public organizationStoreService: OrganizationStoreService,
  ) {
    this.iconClass = this.authService.getSessionData().type == 'User' ? 'blue' : 'green';
   
  }

  ngOnInit(){
  }

  /**
   * @description
   * this method is used for like post
   *
   * @memberof PostCardComponent
   */
  likePost() {
      const param = {
        "user_id": this.authService.getSessionData().orgId,
        "item_id": this.post.id,
        "item_type": this.post.post ? this.post.post.item_type : this.post.item_type
      }

      if(this.post.is_share) {
        this.post.posted_by.user_type.toLowerCase() === 'user' ? param.item_type = 'UserShare' : param.item_type = 'OrganizationShare'
      }
      
      switch (this.authService.getSessionData().type.toLowerCase()) {
        case 'user':
          this.userLikePost(param);
          break;
        case 'organization':
          this.organizationLikePost(param);
          break;
        default:
          break;
      }
  }

  /**
   * @description
   * this method is used for call the organization like api
   *
   * @param {*} [param]
   * @memberof PostCardComponent
   */
  organizationLikePost(param?: any) {
    this.subscription.add(
      this.organizationService.likePost(param).subscribe((response: any) => {
        if (this.post.liked) {
          this.post.liked = false;
          this.post.likes_count = this.post.likes_count - 1;
        } else {
          this.post.likes_count += 1;
          this.post.liked = true;
        }
      })
    )
  }

  /**
   * @description
   * this method is used for call the user like api
   *
   * @param {*} param
   * @memberof PostCardComponent
   */
  userLikePost(param: any) {
    this.subscription.add(
      this.userService.likePost(param).subscribe((response: any) => {
        if (this.post.liked) {
          this.post.liked = false;
          this.post.likes_count = this.post.likes_count - 1;
        } else {
          this.post.likes_count += 1;
          this.post.liked = true;
        }
      })
    )
  }

  /**
   * @description
   * this method is used for viewing the post
   *
   * @memberof PostCardComponent
   */
  viewPost(index: number) {
    this.appService.togglePopup({
      type: 'viewPost',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        post: this.post,
        medias: this.attachments,
        postType: 'post',
        selectedItem: index,
        posted_by: { profile_pic: this.user.profile_pic, name: this.user.name, id: this.user.id },
        theme: (this.authService.getSessionData().type.toLowerCase() == 'user' ? 'blue' : 'green'),
        user: this.user
      }
    });
  }



  /**
   * @description
   * this method is used for getting event fron post action component
   *
   * @param {*} event
   * @memberof PostCardComponent
   */
  postCTA(event: any) {
    switch (event) {
      case 'like':
        this.likePost();
        break;
      default:
        break;
    }
  }

  /**
   * @description
   * this method is used for updating comments in posts
   *
   * @param {*} event
   * @memberof PostCardComponent
   */
  updatePosts(event: any) {
    if (this.post.attachments?.length == 1) {
      if (this.post.attachments[0]?.data?.comments == null) {
        this.post.attachments[0].data.comments = event.result;
      } else {
        this.post.attachments[0].data.comments.push(event.result);
      }
    } else {
      if (this.post.comments == null) { 
        this.post.comments = event.result;
      } else {
        this.post.comments.push(event.result);
      }
    }
  }

  removePost(id?: number) {
    this.appService.togglePopup({
      type: 'confirmation',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        title: "confirmation",
        userType: this.authService.getSessionData().type.toLowerCase(),
        data: { id: id, message: "are you sure you want to delete ?", action_type: "removePost", is_share: this.post.is_share, item_type: this.post?.item_type,object_id:this.post.id},
      }
    });
    // this.removedPost.emit();
  }

  reportPost(id?: number) {
    this.appService.togglePopup({
      type: 'confirmation',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        title: "Report",
        userType: this.authService.getSessionData().type.toLowerCase(),
        data: { id: id, message: "Are you sure you want to report this post?", action_type: "reportPost",item_type: this.post?.item_type,object_id:this.post.id},
      }
    });
    // this.removedPost.emit();
  }

  // removePost(id?: number) {
  //   if(this.authService.getSessionData().type.toLowerCase() == "user") {
  //     this.subscription.add(
  //       this.userService.removePost(id).subscribe(response => {
  //         this.appService.showToaster(response)
  //         this.removedPost.emit();
          
  //       })
  //     )
  //   } else {
  //     this.subscription.add(
  //       this.organizationService.removePost(id).subscribe(response => {
  //         this.appService.showToaster(response)
  //         this.removedPost.emit();
  //       })
  //     )
  //   }
  // }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.post && changes.post.currentValue) {
      if (this.post.post_type == 'Briindlet') {
        this.configUI = JSON.parse(this.post.template_data)
      } else {
        if (this.post.is_share) {
          if(this.attachments = this.post.post){
            this.attachments = this.post.post.attachments;
          }else {
            this.attachments = this.post.attachments;
          }
        } else {
          this.attachments = this.post.attachments;
        }
      }
      if (this.post.attachments?.length == 1) {
        this.mediaType =  (this.post.item_type == 'UserPost' ?  (`User${_.capitalize(this.post.attachments[0].type)}`) : (`Organization${_.capitalize(this.post.attachments[0].type)}`))
      } else if(this.post.is_share) {
        this.mediaType = this.post?.item_type || `${this.authService.getSessionData().type}Share`
      } else  {
        this.mediaType =  this.post?.item_type;
      }
    }
  }


  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   *
   * @memberof PostCardComponent
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
