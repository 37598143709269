<div class="poppins-semibold font14 color-122A4D padtop20 padbtm20" *ngIf="comments && comments.length > 3" (click)="more = !more">
    <a [routerLink]="">
        {{'view' | translate}}
        {{more?('less'|translate):('all'|translate)}}
        {{'comments'|translate}}</a>
</div>
<ng-container *ngFor="let item of comments | slice:0:(more ? undefined : 3)">
    <div class="flex padbtm10" *ngIf="checkIfAbusiveComment(item)">
        <div class="padright15">
            <span class="blue-img-shadow padtop5 padbtm3 padleft10 displayFlex bdrRadius50p posRelative displayFlex">
                <img (click)="profileNav(item?.user_type?.user_type, item?.user_type?.id)" [src]="item?.user_type?.profile_pic" class="d40 bdrRadius50p cursorPtr">
            </span>
        </div>
        <div class="width100">
            <div class="bdrRadius6px bg-white boxShadow-RGBA00005 bdr-1-EEEEEE pad1020">
                <div (click)="profileNav(item?.user_type?.user_type, item?.user_type?.id)" class="poppins-semibold font14 color-122A4D cursorPtr padbtm5">{{item?.user_type?.name}}</div>
                <div class="poppins-regular font12 color-122A4D padbtm5">{{item?.text}}
                </div>
            </div>
            <div class="padtop5 flex">
                <a [routerLink]="" class="mrgnright5 poppins-medium font14  padleft5" [ngClass]="{'color-122A4D': item?.liked, 'color-8E8E8E': !item?.liked}" (click)="likeComment(item)">{{'like' | translate}}</a>
                <span class="font14 poppins-medium color-8E8E8E mrgnbtm10">
                    {{item?.likes_count || 0}}
                </span>
                <a [routerLink]="" class="poppins-medium font14 color-8E8E8E padleft20" (click)="showReply[item.id] = !showReply[item.id]">{{'reply' | translate}}</a>
                <div class="poppins-medium font14 color-8E8E8E padleft20">{{item?.created_at | dateAgo}}</div>
            </div>
            <div class="pad5"></div>
            <app-post-comment-reply-list [theme]="theme" *ngIf="item?.replies" [replies]="item?.replies" [post]="item" [profilePic]="user?.profile_pic"></app-post-comment-reply-list>
            <div *ngIf="showReply[item.id]">

                <app-post-comment [theme]="theme" [profilePic]="profilePic" [media]="media ? media : ''" [mediaType]="mediaType" [post]="post" [comment]="item" (update)="updatePosts($event)">
                </app-post-comment>
            </div>
        </div>
    </div>
</ng-container>