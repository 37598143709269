<div id="two-factor-auth" class="width100">
    <div class="width100 mobi-color-white font25 txtCenter padtop5 fontWeight600 poppins-regular padbtm25 color-02457A" [ngClass]="data?.fontClass">
        {{data?.title | translate}}
    </div>
    <div class="color-02457A font16 mobi-color-white txtCenter poppins-light lineHeight20px" [ngClass]="data?.fontClass">
        {{data?.description | translate}}
    </div>
    <div class="color-02457A mobi-color-white font16 txtCenter padbtm20 padtop20">Enter Code</div>
    <form [formGroup]="form" class="width100" (submit)="onSubmit();">
        <div class="width100 flex flexJustifyCenter">
            <input *ngFor="let item of otpFields; let last = last; let i = index;" (paste)="handlePaste($event)" [ngClass]="{'mrgnright10': !last}" id="otp{{i}}" formControlName="otp{{i}}" (keyup)="checkOtp(i, $event )" class="auth-input pd0330" placeholder="-" maxlength="1">
        </div>
        <strong *ngIf="error" class="color-red flex flexJustifyCenter mrgntop10 font15 poppins-light">{{error | translate}}</strong>
        <div class="width100 txtCenter padbtm5 padtop25">
            <button type="button" class="btn-get-started resmarbtm mrgnright10  font16 ripple poppins-medium cursorPtr" [ngClass]="data?.buttonClass" (click)="resendOtp()">
                {{'Resend OTP' | translate}}
            </button>
            <button class="btn-get-started  font16 ripple poppins-medium cursorPtr" [ngClass]="data?.buttonClass" [disabled]="form.invalid">
                {{'continue' | translate}}
            </button>
        </div>
    </form>
</div>