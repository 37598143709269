<div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 pad15 padright5 bg-white minHeight150px">
    <app-post-profile-section-card [post]="data?.get_data"></app-post-profile-section-card>
    <ng-container *ngIf="data?.get_data?.description">
        <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="data?.get_data?.description.length > 100">
            {{(showMore) ? data?.get_data?.description : data?.get_data?.description | slice: 0:50}}...
            <a href="javascript:;" class="seeMore" (click)="showMore = !showMore">{{showMore ? ' See Less': ' See more'}}</a> 
            <div class="pad5"></div>
        </div>
        <div *ngIf="data?.get_data?.description.length < 100">
            {{data?.get_data?.description }}
        </div>
        <div class="pad5"></div>
    </ng-container>
    <div class='flex flexWrap flexAlignItemsStrecth'>
        <ng-container *ngFor="let item of attachments; let i = index">
            <div class="padright10  padbtm10" *ngIf="i<5"
                [ngClass]="{ 'width100': attachments?.length == 1 || attachments?.length == 2 || (attachments?.length == 3 && i == 2 ), 'fxWidth50': (attachments?.length == 3 && i < 2 ) || (attachments?.length >= 5 && i > 2) || attachments?.length == 4, 'fxWidth33':  (attachments?.length >= 5 && i <= 2)}">
                <div [ngSwitch]="true" class="height100p posRealtive">
                    <div *ngSwitchCase="item.photo_url || item.photo" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                        <img [src]="(item?.photo_url != '' || item.photo != '')  && (item?.photo_url != undefined || item.photo != undefined)"
                            class="width100 height100p bdrRadius6px boxShadow-RGBA00005 maxHeight300px ">
                        <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                            *ngIf="attachments?.length > 5 && i == 4">
                            {{'+ '+(attachments.length - 5)}}
                        </div>
                    </div>
                    <div *ngSwitchCase="(item?.gif_url != '' || item?.gif != '') && (item?.gif_url != undefined || item.gif != undefined)" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                        <img [src]="item?.gif_url || item?.gif"
                            class="width100 height100p bdrRadius6px boxShadow-RGBA00005 maxHeight300px">
                        <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                            *ngIf="attachments?.length > 5 && i == 4">
                            {{'+ '+(attachments.length - 5)}}
                        </div>
                    </div>
                    <div *ngSwitchCase="(item?.video_url != '' || item.video != '') && (item?.video_url != undefined || item.video != undefined)" (click)="viewPost(i);"
                        class="height100p boxShadow-RGBA00005 ripple cursorPtr maxHeight300px">
                        <app-video-preview-card [media]="item">
                        </app-video-preview-card>
                        <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                            *ngIf="attachments?.length > 5 && i == 4">
                            {{'+ '+(attachments.length - 5)}}
                        </div>
                    </div>

                </div>

            </div>
        </ng-container>
    </div>

<!-- 
    <div class='flex flexWrap'>
        <ng-container *ngFor="let item of data?.get_data?.photos; let i = index">
            <div class="mrgnright10" *ngIf="i<5"
                [ngClass]="{ 'width30': data.get_data.photos?.length == 1 || data.get_data.photos?.length == 2 || (data.get_data.photos?.length == 3 && i == 2 ), 'fxWidth50': (data.get_data.photos?.length == 3 && i < 2 ) || (data.get_data.photos?.length >= 5 && i > 2) || data.get_data.photos?.length == 4, 'fxWidth33':  (data.get_data.photos?.length >= 5 && i <= 2)}">

                <div class="height100p posRealtive">
                    <div  class="height100p ripple cursorPtr" >
                        <img [src]="item?.photo_url"
                            class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                        <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                            *ngIf="data?.get_data?.photos?.length > 5 && i == 4">
                            {{'+ '+(data?.get_data?.photos?.length - 5)}}
                        </div>
                    </div>
                
                </div>

            </div>
        </ng-container>
        <ng-container *ngFor="let item of data?.get_data?.gifs; let i = index">
            <div class=" mrgnright10" *ngIf="i<5"
                [ngClass]="{ 'width100': data?.get_data?.gifs?.length == 1 || data?.get_data?.gifs?.length == 2 || (data?.get_data?.gifs?.length == 3 && i == 2 ), 'fxWidth50': (data?.get_data?.gifs?.length == 3 && i < 2 ) || (data?.get_data?.gifs?.length >= 5 && i > 2) || data?.get_data?.gifs?.length == 4, 'fxWidth33':  (data?.get_data?.gifs?.length >= 5 && i <= 2)}">

                <div  class="height100p posRealtive">
                    <div  class="height100p ripple cursorPtr" >
                        <img [src]="item?.gif_url"
                            class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                        <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                            *ngIf="data?.get_data?.gifs?.length > 5 && i == 4">
                            {{'+ '+(data?.get_data?.gifs.length - 5)}}
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>
        <ng-container *ngFor="let item of data?.get_data?.videos; let i = index">
            <div class=" mrgnright10" *ngIf="i<5"
                [ngClass]="{ 'width100': data?.get_data?.videos?.length == 1 || data?.get_data?.videos?.length == 2 || (data?.get_data?.videos?.length == 3 && i == 2 ), 'fxWidth50': (data?.get_data?.videos?.length == 3 && i < 2 ) || (data?.get_data?.videos?.length >= 5 && i > 2) || data?.get_data?.videos?.length == 4, 'fxWidth33':  (data?.get_data?.videos?.length >= 5 && i <= 2)}">

                <div  class="height100p posRealtive">
                    
                    <div 
                        class="height100p boxShadow-RGBA00005 ripple cursorPtr">
                        <app-video-preview-card [media]="item">
                        </app-video-preview-card>
                        <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                            *ngIf="data?.get_data?.videos?.length > 5 && i == 4">
                            {{'+ '+(data?.get_data?.videos.length - 5)}}
                        </div>
                    </div>

                </div>

            </div>
        </ng-container>
    </div> -->
</div>