<div class="slideshow-container posRelative" >
    <div #slideShow>
        <div class="{{autoSlide == 'true' ? side == 'right' ? 'mall-show-slide-right' : 'mall-show-slide-left' : 'mySlides'}} fade" *ngFor="let item of items; let i = index;">
            <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
        </div>
    </div>
    <div class="posAbsolute posRight15px posBtm15px navBtn" *ngIf="items?.length > 1 && autoSlide != 'true'">
        <button class="d30 btn-get-started flexAlignItemsCenter flexJustifyCenter btn-user-default cursorPtr ripple pad3 bdrRadius6px color-FFFFFF mrgnright5" (click)="plusSlides(-1)"><i class="icns icon-left-arrow white d15"></i></button>
        <button class="d30 btn-get-started flexAlignItemsCenter flexJustifyCenter btn-user-default cursorPtr ripple pad3 bdrRadius6px color-FFFFFF" (click)="plusSlides(1)"><i class="icns icon-right-arrow white d15"></i></button>
    </div>
</div>