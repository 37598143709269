import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rbac-card',
  templateUrl: './rbac-card.component.html',
  styleUrls: ['./rbac-card.component.scss']
})
export class RbacCardComponent implements OnInit {
  @Input('userType') userType: string = '';
  @Input('isShowEdit') isShowEdit: boolean = true;
  @Input() data: any;
  @Output() editItem = new EventEmitter();
  constructor() { }

  /**
   * @description
   * edit item method
   *
   * @memberof RbacCardComponent
   */
  editItemCTA() {
    this.editItem.emit(this.data);
  }

  ngOnInit(): void {
  }

}
