import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { observable } from 'mobx-angular';
import { DropDownModel, DropdownDataModel } from '../../../../core/models/form.model';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  isDropdownShow: boolean = false;
  @Input('configData') configData:DropDownModel | any;
  @Input() form: FormGroup | any;
  @Output() selectedData = new EventEmitter();
  @ViewChild('close') close: ElementRef | any;
  @ViewChild('container') container: ElementRef | any;
  @ViewChild('main') main: ElementRef | any;
  @Input() isPosAbsolute: boolean = true;
  searchText: string = '';
  selectedItems: any[] = [];
  @observable listingData: DropdownDataModel[] = [];
  isInvalidForm: boolean = false;
  isClicked: boolean = false;
  constructor() { }

  /**
   * @description
   * check Invalid or not
   *
   * @returns
   * @memberof MultiSelectDropdownComponent
   */
  isInValid() { 
    if (this.form) {
      return this.form.controls[this.configData.formControlName].invalid; 
    }
  }

  /**
   * @description
   * Host listner for out side click close
   *
   * @param {*} event
   * @memberof MultiSelectDropdownComponent
   */
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event ?: any): void {
    if (((this.main && !this.main.nativeElement.contains(event.target)))) {
      this.isDropdownShow = false;
      this.isInvalidForm = this.isInValid();
    }
  }

  /**
   * This method will call when typing in dropdown text box
   *
   * @param {*} [event]
   * @memberof MultiSelectDropdownComponent
   */
  dropDownSearch( event ?: any) {
    if(this.configData.uiSearch) {
      for (const item of this.configData.data) {
        item.selected = false;
        for (const selectedItem of this.selectedItems) {
          if (item.id == selectedItem.id) {
            item.selected = true;
            break;
          }
        }
      }
      this.listingData = this.configData.data.filter((x: any) => x.name.toLowerCase().includes(this.searchText.toLowerCase()));
    }
  }

  /**
   * @description
   * Toggle the dropdown when click
   *
   * @memberof MultiSelectDropdownComponent
   */
  toggleDropdown(event ?: any) {
    this.isClicked = true;
    if ((this.container && this.container.nativeElement.contains(event.target))) {
      if ((this.close && !this.close.nativeElement.contains(event.target)) || !this.close) {
        this.isDropdownShow = !this.isDropdownShow;
        
      }
    }

    !this.isDropdownShow ? this.isInvalidForm = this.isInValid() : this.isInvalidForm = false;
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof MultiSelectDropdownComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.configData.currentValue) {
      this.checkSelectedItems();
      try {
        this.form.controls[this.configData.formControlName].patchValue((this.selectedItems).map(x => x.id));
      } catch (error) {
        
      }

    }
  }

  /**
   * @description
   * removing selected items
   * click close icon in the chip item this method will call
   * calling one method called addOrRemoveItem() inside this 
   * 
   * @param {DropDownModel} [item]
   * @param {boolean} [event]
   * @memberof MultiSelectDropdownComponent
   */
  removeItem(item: any, event: any) {
    this.addOrRemoveItem(item || {}, event.target.checked);
  }

  /**
   * @description
   * this method will call inside the removeItem()
   *
   * @param {*} item
   * @param {boolean} flag
   * @memberof MultiSelectDropdownComponent
   */
  addOrRemoveItem(item: any, flag: boolean) {
    if (flag) {
      item.selected = true;
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(x => x.id != item.id)
    }
    this.setSelected(item, flag);
  }

  setSelected(data ?: DropdownDataModel | any, event ?: boolean) {
    for (const item of this.listingData) {
      if (item.id == data.id) {
        item.selected = event;
      }
    };
    this.form.controls[this.configData.formControlName].patchValue((this.selectedItems).map(x => x.id));
    this.selectedData.emit(this.selectedItems)
  }



  /**
   * @description
   * Checking Selected Items present already 
   * selected key setting to true if selected data already there
   * 
   * @memberof MultiSelectDropdownComponent
   */
  checkSelectedItems() {
    if (this.configData && this.configData.selectedData && this.configData.selectedData.length != 0) {
      this.selectedItems = [];
      for (const item of this.configData.data) {
        for (const selectedItem of this.configData.selectedData) {
          if (item.id == selectedItem.id) {
            item.selected = true;
            this.selectedItems.push(item)
          }
        }
      }
    }
    this.listingData = JSON.parse(JSON.stringify(this.configData.data));
  }

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   * @memberof MultiSelectDropdownComponent
   */
  ngOnInit(): void {
    // this.checkSelectedItems();
  }

}
