import { Component, OnInit, Output, ViewChild, Input, EventEmitter } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-video-preview-card',
  templateUrl: './video-preview-card.component.html',
  styleUrls: ['./video-preview-card.component.scss']
})
export class VideoPreviewCardComponent implements OnInit {
  @Input() isFromCreatePost: boolean = false;
  @Input('media') media: any;
  @Input('showThumbnail') showThumbnail = false;
  @Output() previewClicked = new EventEmitter;
  // @Output('removeVideo') removeVideo = new EventEmitter();
  imgSrc: any
  loadImage: any = false
  customId = Math.floor(Math.random() * 1000).toString(); 
  customIdTimeline = 'timeline-' + Math.floor(Math.random() * 100).toString(); 

  isIOS = false

  constructor() {
    if (Capacitor.getPlatform() === 'ios') this.isIOS = true
  }

  /**
   * @description
   * preview clicked method
   *
   * @memberof VideoPreviewCardComponent
   */
  preview() {
    this.previewClicked.emit('clicked');
  }

  // remove(id: number) {
  //   this.removeVideo.emit(id);
  // }

  /**
   * @description
   * load video method
   *
   * @memberof VideoPreviewCardComponent
   */
  loadTime() {
    let video: any = document.getElementById(this.customId);
    let timeline: any = document.getElementById(this.customIdTimeline)
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    let ctx: any = canvas.getContext('2d');
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    let width = 280;
    let height = 140;
    if(!this.isFromCreatePost) {
      width = video.videoWidth;
      height = video.videoHeight;
    }    
    ctx.drawImage(video, 0, 0, width, height);
    timeline.appendChild(canvas);
  }

  ngOnInit(): void {

  
  }

  ngAfterViewInit(): void {

  }

  play() {
    this.previewClicked.emit('clicked');
  }

}

