import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-create-organization-member-group-popup',
  templateUrl: './create-organization-member-group-popup.component.html',
  styleUrls: ['./create-organization-member-group-popup.component.scss']
})
export class CreateOrganizationMemberGroupPopupComponent implements OnInit {
  subscription: Subscription = new Subscription();
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  form: FormGroup = new FormGroup({});
  selectedFile: any;
  selectedUserIds:any = [];
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private appService: AppService
  ) { 
    this.form = fb.group({
      name: ['',[Validators.required]]
    })
  }

  /**
   *  @description
   * method is used for submitting the form
   */
  onSubmit() {
    let formData = new FormData();
    formData.append('name', this.form.value.name);
    formData.append('organization_id', this.popup?.context?.organization_id);

    formData.append('cover_photo', this.selectedFile ? this.selectedFile.file : '');
    for (let index = 0; index < this.selectedUserIds.length; index++) {
      formData.append(`user[${index}]`, this.selectedUserIds[index]);
    }
    this.subscription.add(
      this.organizationService.createOrganizationMembersGroup(formData).subscribe(response => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-left',
          context: { description: `Group Created successfully` }
        });
        this.closePopup();
      })
    )
  }

  /**
   * @description
   * method is using for setting the cover photo
   * @param event  
   */
  setCoverPhoto(event: any) {
    this.selectedFile = event;
  }

  cardActionCTA(event: any) {
    if(event.event) {
      this.selectedUserIds.push(event.id)
    } else {
      this.selectedUserIds = this.selectedUserIds.filter((x: any) => x != event.id)
    }
  }

  searchUser(event: any) {
    const param = {
      q: event,
      organization_id: this.popup.context.organization_id
    }
    this.subscription.add(
      this.organizationService.getOrganizationMemberInvitedList(param).subscribe(response => {
        this.popup.context.membersList = response;
      })
    );
  }

  /**
   * @description
   * Click on close button this method will call
   *
   * @memberof CreatePostPopupComponent
   */
  closePopup() {
    this.close.emit('icon');
  }

  ngOnInit(): void {
  }

}
