<div #el 
    class="flex flexJustifyBetween flexAlignItemsCenter cursorPtr ripple font14 width100 boxShadow-RGBA00005 pad1015 poppins-regular bg-white outline0 bdrRadiusTopRight6px bdrRadiusTopLeftt6px">
    <div class="flex flexAlignItemsCenter">
        <div class="txtCenter">
            <span class="blue-img-shadow padtop5 padbtm3 padleft6 displayFlex bdrRadius50p posRelative">
                <img class="d45 bdrRadius50p brdr4Green objectFitContain" [src]="data?.photo_url">
            </span>
        </div>
        <div class="pad5"></div>
        <div class="poppins-semibold font18 timeline-label txtTransformCaps">{{data?.title}}</div>
    </div>

    <div class="flex flexAlignItemsCenter flexJustifyCenter">
        <div *ngIf="isShowEditAll" class="d30 cursorPtr bdrRadius50p ripple flex flexAlignItemsCenter flexJustifyCenter mrgnright10 mrgnleft10" (click)="showEdit(data)">
            <i class="icns icon-edit green d20"></i>
        </div>
        <div class="bg-18878C bdrRadius50p flex flexJustifyCenter flexAlignItemsCenter d30" (click)="showPanel($event)">
            <i class="icns icon-arrow-up white d15" *ngIf="!isShowDetails"></i>
            <i class="icns icon-down-arrrow white d15" *ngIf="isShowDetails"></i>
    </div>
</div>
</div>
<div *ngIf="isShowEdit">
<form [formGroup]="form">
    <div class="transitionMaxHeightEaseOutp2">
        <div class="pad15 bdr-1-EEEEEE bdrRadiusBottomRight6px bdrRadiusBottomLeft6px">
            <div class="timeline-label font18 poppins-semibold">{{'edit ministry' | translate }}
            </div>
            <div class="pad5"></div>
            <!-- add ministry start here -->
            <div class="posRelative maxHeight200px img-overlay">
                <div
                    class="posAbsolute  posBtm posLeft posRight overlay bdrRadius6px flex flexAlignItemsCenter flexJustifyCenter flexDirectionCol">
                    <i class="icns icon-camera white d30"></i>

                    <input type="file" id="upload-cover" (change)="updatePhoto($event, 'cover_photo')"
                        class="posAbsolute posTop posBtm posRight posLeft width100 opacity0"
                        accept="image/x-png,image/png,image/jpeg,image/jpg" *ngIf="isWeb">
                    <input type="button" id="upload-cover" (click)="updatePhoto($event, 'cover_photo')"
                        class="posAbsolute posTop posBtm posRight posLeft width100 opacity0"
                        accept="image/x-png,image/png,image/jpeg,image/jpg" *ngIf="!isWeb">

                    <div class="color-FFFFFF mrgntop5 font18 poppins-medium">
                        {{'upload photo' | translate }}
                    </div>
                </div>
                <img src="assets/images/ministry_pic_background.png" id="ministries-cover-photo" *ngIf="!data.photo"
                    class="width100 maxHeight200px bdrRadius6px objectFitContain">
                <img [src]="data.photo" id="ministries-cover-photo" *ngIf="data.photo" class="width100 maxHeight200px bdrRadius6px objectFitContain">
            </div>
            <div class="pad10"></div>
            <div class="">
                <input
                    [ngClass]="{ 'bdr-red': control['title'].invalid && (control['title'].touched || control['title'].dirty) }"
                    formControlName="title" [placeholder]="'title of the ministry' | translate"
                    class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
            </div>
            <div class="pad10"></div>
            <div>
                <textarea rows="3"
                    [ngClass]="{ 'bdr-red': control['description'].invalid && (control['description'].touched || control['description'].dirty) }"
                    formControlName="description" [placeholder]="'description' | translate"
                    class="font16 resizeNone width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px"></textarea>
            </div>
            <div class="pad10"></div>
            <!-- add ministry end here -->

            <!-- add ministry leader start here -->
            <app-add-organization-ministry-leader [title]="'add ministry leader'" [userList]="data.ministry_leaders"
                (getUserData)="getDataFromChild($event, 'ministry')">
            </app-add-organization-ministry-leader>
            <!-- add ministry leader end here -->
            <div class="pad10"></div>
            <!-- add ministry activities start here -->

            <app-add-organization-ministry-activities [title]="'edit activities'"  [ministryID]="data.id" [activityList]="data?.activity">
            </app-add-organization-ministry-activities>
            <!-- add ministry activities end here -->
        </div>
        <div class="mrgntop10 mrgnbtm10">
            <button type="button" (click)="onSubmit()" [disabled]="form.invalid"
                class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">{{'update' | translate}}</button>
        </div>
    </div>
</form>
<!-- <div class="mrgntop40">
    <app-form-navigation (formClickEvent)="formCTA($event)"></app-form-navigation>
</div> -->
</div>
<div class=" poppins-regular boxShadow-RGBA00005 bg-white outline0 bdrRadiusBottomRight6px bdrRadiusBottomLeft6px" *ngIf="isShowDetails">
    <div class="pad15 bdrRadiusBottomRight6px bdrRadiusBottomLeft6px">
        <img [src]="data?.photo_url" class="width100 boxShadow-RGBA00005 bdrRadius6px maxHeight250px objectFitContain">
        <div class="timeline-label poppins-semibold font20 lineHeight26px mrgntop10 mrgnbtm10 txtTransformCaps">
            {{data?.title}}</div>
        <div class="color-484848 poppins-regular font15 lineHeight32px">{{data?.description}}</div>
        <div class="timeline-label poppins-semibold font20 lineHeight26px mrgntop10 mrgnbtm10">
            {{'leaders informations' | translate}}</div>
        <div class="flex flexWrap">
            <div class="width50 pad5" *ngFor="let item of data?.ministry_leaders">
                <div class="width100Minus10MD mrgnauto ">
                    <app-leader-card [leaderType]="'ministry'" [data]="item"></app-leader-card>
                </div>
            </div>
        </div>
        <!-- <ng-template #childTemplate>
                <div class="timeline-label poppins-semibold font20 lineHeight26px mrgntop10 mrgnbtm10">
                        {{'activities' | translate}}</div>
        </ng-template>
        <app-tab-panel [childTemplate]="childTemplate"></app-tab-panel> -->

        <div class="timeline-label poppins-semibold font20 lineHeight26px mrgntop10 mrgnbtm10">
            {{'activities' | translate}}</div>

            <ng-container  *ngIf="data?.activity?.length">
                <ng-container *ngFor="let activity of data?.activity">
                    <div class="flex posRelative  boxShadow-RGBA00005 bg-white bdrRadius6px bdr-1-EEEEEE mrgnbtm10 flexResponsive" >
                        <div class="minWidth280px mobMinWidthAuto">
                            <app-simple-carousel>
                                <ng-container *ngFor="let item of activity.photos; let i = index">
                                    <ng-container *carouselItem>
                                        <div class="width100 bdrRadius6px posRelative posRelative maxHeight150px">
                                            <img src="{{item.photo_url}}" (click)="showImagePopup(activity.photos, i)"
                                                class="width100 height100p bdrRadius6px maxWidth280px cursorPtr minWidth280px maxHeight150px objectFitContain mobMinWidthAuto">
                                            <div *ngIf="activity?.date"
                                                class="bg-18878CDB bdrRadius4px padleft10 padright10 pad5 font14 color-FFFFFF posAbsolute posLeft5px posBtm5px poppins-regular ">
                                                {{ 'date show' | translate: {date: (activity?.date ? (activity?.date | minimalDate) : '') } }}
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </app-simple-carousel>
                        </div>
                        <div class="pad5"></div>
                        <div class="padtop15 padbtm15">
                            <div class="timeline-label poppins-bold fon18 ">
                                {{activity?.title}}
                            </div>
                            <div class="color-484848 poppins-regular font14 wrdBreakAll ">
                                {{activity?.description}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        <!-- <div class="flex posRelative  boxShadow-RGBA00005 bg-white bdrRadius6px bdr-1-EEEEEE">
            <div class="minWidth280px">
                <app-simple-carousel *ngIf="data?.activity">
                    <ng-container *ngFor="let item of data?.activity?.photos">
                        <ng-container *carouselItem>
                            <div class="width100 bdrRadius6px posRelative posRelative maxHeight150px">
                                <img src="{{item.photo_url}}"
                                    class="width100 height100p bdrRadius6px maxWidth280px minWidth280px maxHeight150px objectFitContain">
                                <div *ngIf="data?.activity?.date"
                                    class="bg-18878CDB bdrRadius4px padleft10 padright10 pad5 font14 color-FFFFFF posAbsolute posLeft5px posBtm5px poppins-regular ">
                                    {{ 'date show' | translate: {date: (data?.activity?.date ? (data?.activity?.date | minimalDate) : '') } }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </app-simple-carousel>
            </div>
            <div class="pad5"></div>
            <div class="padtop15 padbtm15">
                <div class="timeline-label poppins-bold fon18 ">
                    {{data?.activity?.title}}
                </div>
                <div class="color-484848 poppins-regular font14 wrdBreakAll ">
                    {{data?.activity?.description}}
                </div>
            </div>
        </div> -->
    </div>
</div>

<app-crop-image-popup (close)="showCropper = false" [cropingRatio]="5/1" (uploadImageEvent)="uploadImage($event)" [imageType]="imageType"
  [imageChangedEvent]="imageChangedEvent" *ngIf="showCropper"></app-crop-image-popup>