import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { Subscription } from 'rxjs';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-forward-appilcation-detail-popup',
  templateUrl: './forward-appilcation-detail-popup.component.html',
  styleUrls: ['./forward-appilcation-detail-popup.component.scss']
})
export class ForwardAppilcationDetailPopupComponent implements OnDestroy {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  briindID: string = '';
  subscription: Subscription = new Subscription();
  errorMessage: string = '';
  constructor(
    private organizationService: OrganizationService,
    private appService: AppService
  ) { }

  /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof ForwardAppilcationDetailPopupComponent
   */
  closePopup() {
		this.close.emit('icon');
  }

  /**
   * @description
   * method is used for forward application to user
   *
   * @memberof ForwardAppilcationDetailPopupComponent
   */
  forwardApplication() {
    const data = {
      briind_id: this.briindID,
      membership_id: this.popup.context.applicationID
    }
    this.errorMessage = '';
    this.subscription.add(
      this.organizationService.forwardMembership(data).subscribe(response => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-left',
          context: { description: `Application detail forwarded successfully` }
        });
        this.closePopup();
      }, err => {
        this.errorMessage = err.error.message;
      })
    )
  }
  
  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   * @memberof ForwardAppilcationDetailPopupComponent
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
