import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-or-edit-membership-field-popup',
  templateUrl: './add-or-edit-membership-field-popup.component.html',
  styleUrls: ['./add-or-edit-membership-field-popup.component.scss']
})
export class AddOrEditMembershipFieldPopupComponent implements OnChanges {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  form: FormGroup = new FormGroup({});
  fieldData: any[] = [
    {
      id: 1,
      name: "Name",
      input_type: "text",
      icon: ''
    }, {
      id: 2,
      name: "Date of Birth",
      input_type: "date",
      icon: 'input-icon date-icon date-green'
    }, {
      id: 3,
      name: "Address",
      input_type: "textarea",
      icon: ''
    }, {
      id: 4,
      name: "Email Address",
      input_type: "email",
      icon: ''
    }, {
      id: 5,
      name: "Profile Pic",
      input_type: "file",
      icon: ''
    },{
      id: 6,
      name: "Custom",
      input_type: "text",
      icon: ''
    }
  ];
  selectedInput: any;
  constructor(
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      id: new FormControl(''),
      field_name: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      field_type: new FormControl(''),
      icon: new FormControl(''),
    });
  }

 
  /**
   * @description
   * this method is used for getting the selected item from dropdown
   *
   * @param {*} [event]
   * @memberof AddOrEditMembershipFieldPopupComponent
   */
  selectedItem(event ?: any) {
    this.selectedInput = event;
    this.form.patchValue({
      field_name: this.selectedInput.name,
      icon: this.selectedInput.icon,
      id: this.selectedInput.id,
      field_type: this.selectedInput.input_type
    });
  }


  addField() {
    this.close.emit(this.form.value);
  }


  /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof AddOrEditMembershipFieldPopupComponent
   */
  closePopup() {
    this.close.emit()
  }

 /**
  * @description
  * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  * Add '${implements OnChanges}' to the class.
  *
  * @param {SimpleChanges} changes
  * @memberof AddOrEditMembershipFieldPopupComponent
  */
 ngOnChanges(changes: SimpleChanges): void {
  if (changes && changes.popup && changes.popup.currentValue) {
    if (this.popup.context && this.popup.context.selectedItem) {
      this.form.patchValue({
        field_name: this.popup.context.selectedItem.field_name,
        field_type: this.popup.context.selectedItem.field_type,
        type: this.popup.context.selectedItem.id,
        icon: this.popup.context.selectedItem.icon,
        id: this.popup.context.selectedItem.id
      });
      for (const field of this.fieldData) {
        if (field.id == this.popup.context.selectedItem.id) {
          this.selectedInput = field;
          break;
        }
      }
    }
  }
 }

}
