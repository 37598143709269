<div class="width100 bdrRadius6px bdr-1-EEEEEE pad15 flex flexAlignItemsCenter bg-FFFFFF posRelative">
    <div>
        <img src="assets/images/bank.png" />
    </div>
    <div class="pad5"></div>
    <div>
        <div class="poppins-medium font16 color-18878C padbtm5">{{data?.account_name}}</div>
    <div class="poppins-regular font14 color-122A4D padbtm10"> {{'account no' | translate:{account_no: maskAccountNumber(data?.account_number) || '' } }}</div>
    </div>
    <span *ngIf="isSelected"
        class="d20 bg-18878C bdrRadius50p posAbsolute posTop5px posRight5px displayFlex flexAlignItemsCenter flexJustifyCenter">
        <i class="icns icon-tick-white d10"></i>
    </span>
</div>