<div id="create-group" class="bdrRadius6px bg-white pad20 posRelative overflowHidden"
  [ngClass]="popup?.context?.userType">
  <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
  <div id="create-group-popup" class="overflowHidden">
    <div class="width100 mrgnauto">
      <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
        {{ popup?.context?.title | translate}}
      </div>
      <div class="width100 mrgnauto boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px flex flexResponsive overflowAuto custom-scrollbar">
        <form class="mrgnauto txtCenter width100" [formGroup]="form" (submit)="onSubmit();">
          <div class="maxHeight220px">
            <app-upload-photo [placeholderImage]="'assets/images/ministry_pic_background.png'" (imageEvent)="updatePhoto($event)"></app-upload-photo>
          </div>
          <!-- <div class="cover-image height350px bgSize100p100p bgRepeat-noRepeat user flex" id="profile-cover"
            style="background-image: url({{selectedFile.cover_photo}})">
            <div class="height100p bg-00000094 width100 pad15 bdrRadius4px">
              <div class=" height100p flex flexAlignItemsEnd posRelative">
                <div class="profile-cover-display-name width100">
                  <div class="width100 txtRight update-cover-photo-container">
                    <div class="displayFlex btn-user-default pad10 bdrRadius30px cursorPtr ripple">
                      <div class="posRelative flex flexAlignItemsCenter ripple flexJustifyCenter">
                        <input type="file"
                          class="posAbsolute ripple posTop posRight posLeft posRight opacity0 width100 height100p"
                          (change)="updatePhoto($event)">
                        <i class="icns icon-camera white d20"></i>
                        <div class="pad5 desktopVisible"></div>
                        <div class="poppins-medium font14 desktopVisible color-FFFFFF">
                          {{'update cover photo' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="overflowAuto">
            <div class="width100 flex flexResponsive mrgntop20">
              <input type="text" class="card-input poppins-regular color-484848"
                [ngClass]="{'bdr-red': (f('name')?.invalid && (f('name')?.dirty || f('name')?.touched))}"
                formControlName="name" placeholder="{{'group name' | translate }}" />
            </div>
            <div class="width100 flex flexResponsive mrgntop20">
              <textarea class="card-input resizeNone height100px poppins-regular color-484848"
                [ngClass]="{'bdr-red': (f('about')?.invalid && (f('about')?.dirty || f('about')?.touched))}"
                formControlName="about" placeholder="{{'about' | translate }}"></textarea>
            </div>
            <div class="width100 flex flexResponsive mrgntop20">
              <select class="card-input ripple cursorPtr poppins-regular color-484848"
                [ngClass]="{'bdr-red': (f('category')?.invalid && (f('category')?.dirty || f('category')?.touched))}"
                formControlName="category">
                <option value="" class="ripple cursorPtr">{{'select category' | translate }}</option>
                <option value="{{groupCat.id}}" class="ripple cursorPtr"
                  *ngFor="let groupCat of popup?.context?.categoryList">
                  {{groupCat.name}}</option>
              </select>
            </div>
            <!-- <div class="width100 flex flexResponsive mrgntop20">
              <select class="card-input ripple cursorPtr poppins-regular color-484848"
                [ngClass]="{'bdr-red': (f('privacy')?.invalid && (f('privacy')?.dirty || f('privacy')?.touched))}"
                formControlName="privacy">
                <option value="" class="ripple cursorPtr">{{'select group privacy' | translate }}</option>
                <option value="public">{{'public' | translate}}</option>
                <option value="private">{{'private' | translate}}</option>
              </select>
            </div> -->
            <div class="flex width100 mrgntop20 flexJustifyCenter">
              <button type="button" class="btn-get-started cancel-btn minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030 mrgnright15">
                {{'cancel' | translate}} 
              </button>
              <button type="submit" [disabled]="form.invalid || disableBtnOnClick" class="btn-get-started btn-user-default minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030">
                {{'create' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>