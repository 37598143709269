<div class="Input posRelative">
    <label class="show" *ngIf="label">{{ label }}</label>
    <input type="text" [placeholder]="placeholder | translate" readonly="true" #datePicker class="Input-field {{iconCss}} font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px" [(ngModel)]="result"
      [ngClass]="{ 'bdr-red': isOpenedCount == 2 && formGrp.controls[formCntrlName]?.invalid }"
      (click)="onShowCalendar($event)"
      (focus)="onShowCalendar($event)" />
    
    <div class="date" #dateShow [ngClass]="{ 'isActive': showCalendar }">
      <div class="month-container flex flexJustifyBetween flexAlignItemsCenter pad10 ">
        <i class="icns ripple icon-left-arrow d15 datepicker font14 cursorPtr"
          (click)="updateMonth($event, 'dec')">
        </i>
        <div class="flex">
          <span class="mrgnright5">{{ months[month] }}</span>
          <span class="">{{ year }}</span>
        </div>
        <i class="icns ripple icon-right-arrow d15 datepicker font14 cursorPtr"
          (click)="updateMonth($event, 'inc')"></i>
      </div>
      <div class="week-container flex flexJustifyBetween">
        <span class="week width1428 font14 flex flexAlignItemsCenter flexJustifyCenter">Sun</span>
        <span class="week width1428 font14 flex flexAlignItemsCenter flexJustifyCenter">Mon</span>
        <span class="week width1428 font14 flex flexAlignItemsCenter flexJustifyCenter">Tue</span>
        <span class="week width1428 font14 flex flexAlignItemsCenter flexJustifyCenter">Wed</span>
        <span class="week width1428 font14 flex flexAlignItemsCenter flexJustifyCenter">Thu</span>
        <span class="week width1428 font14 flex flexAlignItemsCenter flexJustifyCenter">Fri</span>
        <span class="week width1428 font14 flex flexAlignItemsCenter flexJustifyCenter">Sat</span>
      </div>
      <div class=" day-container flex flexWrap">
        <span class="flex  width1428 font14 flexAlignItemsCenter flexJustifyCenter day" *ngFor="let day of days" [ngClass]="{ 'isSelected': day == date.getDate() }"
          (click)="selectDay(day)">
         <span clas="diplayInline ripple" [ngClass]="{'dayNumber': day}">
          {{ day }}
         </span>
          
        </span>
      </div>
    </div>
  </div>

  <div [formGroup]="formGrp">
    <input type="hidden" formControlName="{{formCntrlName}}">
  </div>

    