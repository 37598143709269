export const getCookie = (name ?: any) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for(var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if(name == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
      }
  }

  // Return null if not found
  return null;
}

/* Converting to Base 64 */
export const encryptBase64 = (name ?: any) => {
  return (btoa(name))
}

/* Decrypting to Base 64 */
export const decryptBase64 = (name ?: any) => {
  return (atob(name))
}


/**
 * @description
 * this method is used for getting the image path when upload a image
 * @param event
 * @param id 
 */
export const getImagePath = (event : any, id: string) => {
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();
    reader.onload = (e:any) => {
        if(id) {
          (<HTMLImageElement>document.getElementById(id)).src=e.target.result
        }
    };
    reader.readAsDataURL(event.target.files[0]);
    if (!id) {
      return reader;
    }
  }
}

export const getSingleImagePath = (event : any, id: string) => {
  if (event) {
    var reader = new FileReader();
    reader.onload = (e:any) => {
        if(id) {
          (<HTMLImageElement>document.getElementById(id)).src=e.target.result
        }
    };
    reader.readAsDataURL(event);
    if (!id) {
      return reader;
    }
  }
}

export const getTimeForDate = (date: any, hour: any, minute: any) => {
  const d = new Date(date);
  d.setHours(hour);
  d.setMinutes(minute);
  d.setSeconds(0)
  return d;
}