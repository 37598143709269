import { Component, Input, OnInit } from '@angular/core';
import { AppStore } from 'src/app/core/stores/app.store';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {
  @Input() userType: string = 'user';
  @Input() paymentType: string = '';
  @Input() success: boolean = false;
  constructor(public organizationStoreService: OrganizationStoreService,
    public appStore: AppStore,) { }

  ngOnInit(): void {
  }

  successTrue() {
    this.success = !this.success;
  }
}
