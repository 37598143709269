<div class="posRelative cursorPtr" [ngClass]="type" (click)="view()" *ngIf="data?.id">
    <div *ngIf="data?.attachments?.length">
        <ng-container *ngIf="data?.attachments[0]?.type == 'video'">
            <app-video-preview-card [media]="data?.attachments[0]?.data"></app-video-preview-card>
        </ng-container>
        <ng-container *ngIf="data?.attachments[0]?.type == 'photo'">
           <img [src]="data?.attachments[0]?.data?.photo || data?.attachments[0]?.data?.photo_url" class="bdrRadius10px boxShadow-RGBA00005  width100">
        </ng-container>
    </div>
   <div *ngIf="!data || !data?.attachments?.length">
    <img [src]="'assets/images/add-pic-layer.png'" class="bdrRadius10px boxShadow-RGBA00005  width100">
   </div>
    <img [src]="data.posted_by.photo"
        class="profile-overlay-img bdrRadius50p d40 posAbsolute posLeft10px posTop10px">
    <a href="javascript:void(0)" (click)="view()" class="view-all-btn view-btn-most-liked cursorPtr ripple poppins-light font14">{{'view' | translate }}</a>
    <!-- <div class="text-overlay pad15" *ngIf="data?.description">
        <div class="poppins-semibold mrgnbtm5 font15 fontweight600  color-FFFFFF txtCenter">
            {{'most' | translate}} {{ postType | translate }} {{ 'posts' | translate }}</div>
        <div class="lineHeight20px font14 poppins-regular color-FFFFFF txtCenter">
            {{data.description | translate}}
        </div>
    </div> -->
</div>

<app-skelton-loader *ngIf="!data?.id"></app-skelton-loader>
