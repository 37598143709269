import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { config } from 'src/environments/environment';
import { AdvertisementStoreService } from '../store/advertisement-store.service';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {

  constructor(
    public http: HttpClient,
    private advertisementStoreService: AdvertisementStoreService
  ) { }

  /**
   * @description
   * this method is used when click on create Advertisement
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof AdvertisementService
   */
   createAdvertisement(data?: any, type?: string) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${type == 'user' ? config.usersURI : config.organizationsURI}${config.briindadsURI}`, data)
      .pipe(map(response => this.advertisementStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting Advertisement List
   *
   * @param {string} type
   * @returns this api will return a observalble
   * @memberof AdvertisementService
   */
   getAdvertisementList(type?: string) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type == 'user' ? config.usersURI : config.organizationsURI}${config.briindadsURI}`)
      .pipe(map(response => this.advertisementStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting Briindlet Pro List
   *
   * @returns this api will return a observalble
   * @memberof AdvertisementService
   */
   getBriindletProList() {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.briindadURI}${config.briindletProListURI}`)
      .pipe(map(response => this.advertisementStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used for getting Advertisement List for timeline
   * 
   * @param {*} param
   * @returns this api will return a observalble
   * @memberof UserService
   */
   getAdvertisementTimelineList(param: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.briindadURI}${config.timelineURI}`, { params: param })
      .pipe(map(response => this.advertisementStoreService.apiResp = response));
  };
}
