import { Subscription } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';

@Component({
  selector: 'app-elastic-search-post-item',
  templateUrl: './elastic-search-post-item.component.html',
  styleUrls: ['./elastic-search-post-item.component.scss']
})
export class ElasticSearchPostItemComponent implements OnChanges {
  @Input() data: any;
  attachments: any = [];
  subscription: Subscription = new Subscription();
  showMore: boolean = false;
  constructor(
    private appService: AppService,
    private authService: AuthService
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.data && changes.data.currentValue) {
      let arr = [this.data.get_data.photos || [], this.data.get_data.videos || [], this.data.get_data.gifs || []]
      this.attachments = _.uniq(_.flatten(arr));
    }
    
  }

  viewPost(index: number) {
    this.subscription.add(
      this.appService.getCustomAPI(this.data.get_data.detail_uri, {}).subscribe((response: any) => {
        this.appService.togglePopup({
          type: 'viewPost',
          load: true,
          refreshParent: true,
          overlayClose: true,
          context: {
            post: response,
            medias: response.attachments,
            postType: 'post',
            selectedItem: index,
            posted_by: { profile_pic: response.posted_by.profile_pic, name: response.posted_by.name, id: response.posted_by.id },
            theme: (this.authService.getSessionData().type.toLowerCase() == 'user' ? 'blue' : 'green')
          }
        });
      })
    )
  }

 

}
