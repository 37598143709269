<div class="{{appStore?.userType}} minWidth500px" [formGroup]="formGrp">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{ (formGrp.value?.step_1?.is_event == "True" ? 'what is the name of the event' : 'name of the briindlet') | translate}}
    </div>
    <div class="body width100" formGroupName="step_3">
        <div class="width100 flex flexResponsive">
            <div class="width100 mrgnbtm20">
                <input placeholder="{{'name of the event' | translate}}" formControlName="name" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
                  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
            </div>
        </div>
        <div class="width100 padtop30 flex">
            <button  (click)="actionCTA('previous')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="formGrp?.controls?.step_3?.invalid" (click)="actionCTA('continue')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
</div>