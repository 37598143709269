<ng-container *ngIf="post.post_type == 'Briindlet'">
    <span [ngSwitch]="post.template_name?.toLowerCase()?.split(' ')?.join('')" class="mrgnbtm20 flex boxShadow-RGBA00005 bdrRadius10px">
        <span *ngSwitchCase="'template1'" class="width100">
            <app-briindlet-pro-template-one class="width100" [data]="post" [customizeUI]="configUI"></app-briindlet-pro-template-one>
        </span>
    <span *ngSwitchCase="'template2'" class="width100">
            <app-briind-pro-template-two class="width100" [data]="post" [customizeUI]="configUI"></app-briind-pro-template-two>
        </span>
    <span *ngSwitchCase="'template3'" class="width100">
            <app-briindlet-pro-template-three class="width100" [data]="post" [customizeUI]="configUI"></app-briindlet-pro-template-three>
        </span>
    <span *ngSwitchCase="'defaulttemplate'" class="width100">
            <app-default-template class="width100" [data]="post" [customizeUI]="configUI"></app-default-template>
        </span>
    <span *ngSwitchDefault class="width100">
            <app-default-template class="width100" [data]="post" [customizeUI]="defualtTemplate"></app-default-template>
        </span>
    </span>
</ng-container>

<ng-container *ngIf="post.post_type == 'VolunteerEvent'">
    
    <div  class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 pad15  bg-white mrgnbtm20">
        <app-post-profile-section-card [post]="post"></app-post-profile-section-card>
        <div class="pad5"></div>
        <div class="color-122A4D font22 poppins-semibold mrgnbtm10">
            {{post?.title}}</div>
        <img [src]="post?.photo" class="width100 maxHeight400px bdrRadius6px boxShadow-RGBA00005">
        <div class="color-122A4D poppins-regular font15 mrgntop10">
            {{post?.description}}
        </div>
        <div class="mrgntop10 flex color-122A4D poppins-medium font14">
            <i class="icns icon-time green d20 mrgnright5"></i>
            {{post?.event_date| fullDate}}
        </div>
        <div class="mrgntop10 flex color-122A4D poppins-medium font14">
            <i class="icns icon-location green d20 mrgnright5"></i>
            {{post?.location}}
        </div>
    </div>
</ng-container>

<ng-container *ngIf="post?.post_type == 'ProfilePic' || post?.post_type == 'CoverPhoto'">
    <div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 bg-white mrgnbtm20">
        <div class=" padtop15 padleft15 padright15">
            <app-post-profile-section-card [post]="post" (removePost)="removePost($event)"
                (reportPost)="reportPost($event)"></app-post-profile-section-card>
        </div>
        
        <div class="">
            <div class="pad5"></div>
            <div class="posRelative" style="height: 325px;" *ngIf="post.post_type == 'ProfilePic'">
                <div [ngStyle]="{'background-image': 'url(' +(post.posted_by?.cover_photo || defaultCoverPhoto) + ')' }" style="height: 200px;background-size: cover;" ></div>
                <div class="flex" style="align-items: center; justify-content: center;">
                    <div class="bg-white" style="position: absolute;padding: 10px;border-radius: 50%;">
                        <img [src]="post.profile_pic_url || './assets/images/default-profile.jpeg'"
                            style="height: 320px; width: 320px; border-radius: 50%;" />
                    </div>
                </div>
            </div> 
            <div class="padleft15 padright15" *ngIf="post.post_type == 'CoverPhoto'">
                <img [src]="post.cover_photo_url || defaultCoverPhoto"
                    class="width100" />
            </div>
        </div>
        <div class="pad5"></div>
        <div class="padleft15 padright15 padbtm15">
            <app-post-action [post]="post"
                [comments]="(post?.attachments?.length == 1 ? post?.attachments[0]?.data?.comments :  post?.comments)"
                [theme]="iconClass" [user]="user" (postAction)="postCTA($event)"></app-post-action>
            <div class="pad5"></div>
            <app-post-comment-list [theme]="iconClass" [media]="post?.attachments?.length == 1 ? post?.attachments[0] : ''"
                [mediaType]="mediaType"
                [comments]="(post?.attachments?.length == 1 ? post?.attachments[0]?.data?.comments :  post?.comments)"
                [post]="post" [profilePic]="user?.profile_pic">
            </app-post-comment-list>
            <div class="pad5"></div>
            <app-post-comment [theme]="iconClass" [media]="post?.attachments?.length == 1 ? post?.attachments[0] : ''"
                [mediaType]="mediaType" [profilePic]="user?.profile_pic" [post]="post"
                (update)="updatePosts($event)"></app-post-comment>
        </div>
    </div>
</ng-container>


<div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 pad15 padright5 bg-white mrgnbtm20" *ngIf="post.post_type != 'ProfilePic' && post.post_type != 'CoverPhoto' && post.post_type != 'Briindlet' && post.post_type != 'VolunteerEvent'" [id]="'post_id_' + post.id">
    <app-post-profile-section-card [post]="post" (removePost)="removePost($event)" (reportPost)="reportPost($event)"></app-post-profile-section-card>
    <div class="">
        <div class="pad5"></div>
        <ng-container *ngIf="post?.description">
            <div class="font15 color-122A4D poppins-regular" *ngIf="post?.description.length > 200">
                <span [innerHtml]="(showMore) ? post?.description : post?.description.slice(0,300)"></span>
                <span *ngIf="!showMore">...</span>
                <a href="javascript:;" class="seeMore" (click)="showMore = !showMore">{{showMore ? ' See Less': ' See more'}}</a>
                <div class="pad5"></div>
            </div>
            <div [innerHtml]="post?.description" class="font15 color-122A4D poppins-regular" *ngIf="post?.description.length < 200">
                <div class="pad5"></div>
            </div>
        </ng-container>

        <div class='flex flexWrap flexAlignItemsStrecth'>
            <!-- {{attachments?.length}} -->
            <ng-container *ngFor="let item of attachments; let i = index">
                <div class="padright10  padbtm10" *ngIf="i<5" [ngClass]="{ 'width100': attachments?.length == 1 || (attachments?.length == 3 && i == 2 ),'fxWidth50': attachments?.length == 2 || (attachments?.length == 3 && i < 2 ) || (attachments?.length >= 5 && i > 2) || attachments?.length == 4, 'fxWidth33':  (attachments?.length >= 5 && i <= 2)}">

                    <div [ngSwitch]="item?.type" class="height100p posRealtive">
                        <div *ngSwitchCase="'photo'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.photo_url" class="width100 height100p bdrRadius6px boxShadow-RGBA00005" *ngIf="attachments?.length == 1">
                            <div class="width100 ht" [style.background-image]="'url(' + item?.data?.photo_url + ')'" *ngIf="attachments?.length != 1"></div>
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px" *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'gif'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.gif_url" class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px" *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'video'" class="height100p boxShadow-RGBA00005 ripple cursorPtr">
                            <app-video-preview-card [media]="item?.data" (previewClicked)="viewPost(i)" [showThumbnail]="true">
                            </app-video-preview-card>
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px" *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>

                    </div>

                </div>
            </ng-container>
        </div>

        <app-post-shared [post]="post.post" *ngIf="post.is_share"></app-post-shared>
    </div>
    <div class="pad5"></div>
    <div class="padright10">
        <app-post-action [post]="post" [comments]="(post?.attachments?.length == 1 ? post?.attachments[0]?.data?.comments :  post?.comments)" [theme]="iconClass" [user]="user" (postAction)="postCTA($event)"></app-post-action>
        <div class="pad5"></div>
        <app-post-comment-list [theme]="iconClass" [media]="post?.attachments?.length == 1 ? post?.attachments[0] : ''" [mediaType]="mediaType" [comments]="(post?.attachments?.length == 1 ? post?.attachments[0]?.data?.comments :  post?.comments)" [post]="post"
            [profilePic]="user?.profile_pic">
        </app-post-comment-list>
        <div class="pad5"></div>
        <app-post-comment [theme]="iconClass" [media]="post?.attachments?.length == 1 ? post?.attachments[0] : ''" [mediaType]="mediaType" [profilePic]="user?.profile_pic" [post]="post" (update)="updatePosts($event)"></app-post-comment>
    </div>
</div>


<!-- <div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 pad15 padright5 bg-white mrgnbtm20" *ngIf="hashTag">
    <app-post-profile-section-card [post]="post"></app-post-profile-section-card>
    <div class="">
        <div class="pad5"></div>
        <ng-container *ngIf="post?.description">
            <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length > 200">
                {{(showMore) ? post?.description : post?.description | slice:0:200}}<span *ngIf="!showMore">...</span>
                <a href="javascript:;" class="seeMore" *ngIf="!showMore" (click)="showMore=true">See more</a> 
                <div class="pad5"></div>
            </div>
            <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description.length < 200">
                {{post?.description}}
                <div class="pad5"></div>
            </div>
        </ng-container>

        <div class='flex flexWrap flexAlignItemsStrecth'>
            <ng-container *ngFor="let item of attachments; let i = index">
                <div class="padright10  padbtm10" *ngIf="i<5"
                    [ngClass]="{ 'width100': attachments?.length == 1 || attachments?.length == 2 || (attachments?.length == 3 && i == 2 ), 'fxWidth50': (attachments?.length == 3 && i < 2 ) || (attachments?.length >= 5 && i > 2) || attachments?.length == 4, 'fxWidth33':  (attachments?.length >= 5 && i <= 2)}">

                    <div [ngSwitch]="item?.type" class="height100p posRealtive">
                        <div *ngSwitchCase="'photo'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.photo_url"
                                class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'gif'" class="height100p ripple cursorPtr" (click)="viewPost(i);">
                            <img [src]="item?.data?.gif_url"
                                class="width100 height100p bdrRadius6px boxShadow-RGBA00005">
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'video'" (click)="viewPost(i);"
                            class="height100p boxShadow-RGBA00005 ripple cursorPtr">
                            <app-video-preview-card [media]="item?.data">
                            </app-video-preview-card>
                            <div class="posAbsolute color-FFFFFF font25 poppins-medium flex flexJustifyCenter flexAlignItemsCenter posTop posBtm posLeft posRight bg-RGBA00007 bdrRadius6px"
                                *ngIf="attachments?.length > 5 && i == 4">
                                {{'+ '+(attachments.length - 5)}}
                            </div>
                        </div>

                    </div>

                </div>
            </ng-container>
        </div>

        <app-post-shared [post]="post.post" *ngIf="post.is_share"></app-post-shared>


    </div>
    <div class="pad5"></div>
    <div class="padright10">
        <app-post-action [post]="post" [theme]="iconClass" (postAction)="postCTA($event)"></app-post-action>
        <div class="pad5"></div>
        <app-post-comment-list [theme]="iconClass" *ngIf="post?.comments" [comments]="post?.comments" [post]="post"
            [profilePic]="user?.profile_pic">
        </app-post-comment-list>
        <div class="pad5"></div>
        <app-post-comment [theme]="iconClass" [profilePic]="user?.profile_pic" [post]="post"
            (update)="updatePosts($event)"></app-post-comment>
    </div>
</div> -->