import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bank-account-card',
  templateUrl: './bank-account-card.component.html',
  styleUrls: ['./bank-account-card.component.scss']
})
export class BankAccountCardComponent implements OnInit {
  @Input() data: any;
  @Input() isSelected: boolean = false;
  constructor() { }

  /**
   * @description
   * this method is used foe masking the character
   *
   * @param {*} number
   * @memberof BankAccountCardComponent
   */
  maskAccountNumber(number: any) {
    return number.replace(/\d(?=\d{4})/g, "*");
  }

  ngOnInit(): void {
  }

}
