<div id="invite-friends" class="bdrRadius6px bg-white pad20 posRelative overflowHidden" [ngClass]="popup?.context?.userType.toLowerCase()">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="overflowHidden">
        <div class="width100 mrgnauto">
            <div class="poppins-semibold font22 timeline-label padbtm10 txtCenter ">
                {{ 'add people to group' | translate }}
            </div>
        </div>
        <div class="width100 mrgnauto bg-FFFFFF">
            <div class="width50 mrgnauto flexJustifyCenter">
                <app-search-input [searchPlaceholder]="'search for people to add'" (search)="searchFriends($event)">
                </app-search-input>
            </div>
            <div class="maxHeight300px custom-scrollbar mrgntop15  verticalScrollContainer pad5">
                <ng-container *ngFor="let user of userList">
                    <div class="width70 mrgnauto mrgnbtm15">
                        <app-invite-friend-item (userEvent)="userCheckedEvent($event, user)" [user]="user"></app-invite-friend-item>
                    </div>
                </ng-container>
            </div>
            <div class="pad10"></div>
            <div class="color-red poppins-regular font15 txtCenter padbtm10" *ngIf="sentError">{{errorMsg}}</div>
            <div class="flex flexJustifyCenter">
                <button type="submit" class="btn-get-started cancel-btn minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030" (click)="closePopup();">
                    {{'cancel' | translate}}
                </button>
                <div class="pad5"></div>
                <button type="submit" class="btn-get-started btn-user-default minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030" (click)="onSubmit()">
                    {{'invite' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
<!-- <div id="choose-acount-popup" class="bdrRadius6px bg-white pad40 posRelative" [ngClass]="popup?.context?.userType">
    <span class="close-popup ripple" (click)="closePopup();">×</span>
    <div class="header txtCenter font23 poppins-medium fontWeight600 timeline-label mrgnbtm40">
        {{'add people to group' | translate}}
    </div>
    <div class="body user">
        <div class="poppins-medium font15 timeline-label flex flexAlignItemsCenter">
            <div class="width25"></div>
            <div class="width50">
                <input type="text" (input)="onChange($event)" placeholder="{{'search for people to add..' | translate}}"
                    class="width100 flex flexAlignItemsCenter btn-get-started cursorPtr bdrRadius20px bg-FFFFFF bdr-1-EEEEEE boxShadow-RGBA00005 poppins-semibold font14 timeline-label pad1020 ripple">
            </div>
            <div class="width25"></div>
        </div>
        <div class="custom-scrollbar maxHeight300px overflowAuto">
            <ng-container *ngFor="let users of popup?.context?.data">
                <div class="flex padtop30 user">
                    <div class="width100">
                        <div
                            class="bdr-1-EEEEEE boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px pad1020 flex flexAlignItemsCenter">
                            <div class="">
                                <span class="blue-img-shadow padbtm3 padleft10 displayFlex bdrRadius50p posRelative">
                                    <img [src]="users.profile_pic" class="d70 bdrRadius50p brdr4Blue" />
                                </span>
                            </div>
                            <div class="width80 pad8">
                                <div class="poppins-medium font16 timeline-label">{{ users.name }}</div>
                            </div>
                            <div class="agree-checkbox-container ">
                                <div class="custom-checkbox check-02457A flex flexAlignItemsStart">
                                    <input type="checkbox" class="flexNoShrink"
                                        (change)="onCheckboxSelect($event ,users.id)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="pad10"></div>
            <div>
                <button type="submit"
                    class="btn-get-started cancel-btn minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030"
                    (click)="closePopup();">
                    {{'cancel' | translate}}
                </button>
                <button type="submit"
                    class="btn-get-started btn-user-default minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030"
                    (click)="onSubmit()">
                    {{'invite' | translate}}
                </button>
            </div>
        </div>
    </div>
</div> -->