<div class="width100   posRelative" [ngClass]="configData?.customClass">
    <div class="poppins-regular dropdown-label-color font16 pad10 padleft0 lineHeightHeight18px letterSpacingNormal" *ngIf="configData?.label">
        {{configData?.label | translate}}</div>
    <div class="width100 bg-white boxShadow-RGBA00005 bdrRadius6px" #main>
        <div (click)="toggleDropdown($event)"
            class="posRelative cursorPtr bdrRadius6px multi-dropdown boxShadow-RGBA00005 pad15"
            [ngClass]="{'icon-arrow-up': isDropdownShow, 'icon-arrow-down': !isDropdownShow, 'bdr-red': isInvalidForm && !isDropdownShow && isClicked}" #container>
            <ul class="flex pad0 mrgn0 listStyleNone flexWrap" *ngIf="selectedItems.length != 0">
                <li class="item poppins-regular" *ngFor="let item of selectedItems">{{item.name}} <span
                        class="bg-02457A color-FFFFFF ripple cursorPtr remove-icon" (click)="addOrRemoveItem(item, false)" #list>×</span>
                </li>
            </ul>
            <input class="bdrNone input-empty poppins-regular font15" [placeholder]="(configData?.placeholder || configData?.label) | translate"
                readonly *ngIf="selectedItems.length == 0">
        </div>
        <div [ngClass]="{'posAbsolute': isPosAbsolute}" class="bdrRadius6px boxShadow-RGBA00005 bg-white mrgntop5 listing-section zIndex1" *ngIf="isDropdownShow">
            <div class="width100 padbtm5 posRelative">
                <div class="serch-container pad5 posSticky bg-white zIndex1">
                    <input type="text" placeholder="Search...." class="search-input poppins-regular font14"
                        [(ngModel)]="searchText" appDebounce [debounceTime]="400"
                        (debounceClick)="dropDownSearch($event)">
                </div>
                <div>
                    <div class="custom-checkbox check-02457A listing-item flex flexAlignItemsStart font14 ripple  cursorPtr"
                        [ngClass]="{'active': item.selected}" *ngFor="let item of listingData; let i = index;">
                        <label [for]="item.id" class="flex pad5 width100 cursorPtr">
                            <input type="checkbox" (change)="removeItem(item, $event)" [id]="item.id"
                                [name]="item.id" class="flexNoShrink" [(ngModel)]="item.selected">
                            <div class="pad5"></div>
                            <div class="poppins-regular font16 dropdown-list-font color-02457A">
                                {{item.name}}
                            </div>
                        </label>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="displayNone" [formGroup]="form" *ngIf="configData.formControlName">
    <select name="{{configData.formControlName}}" formControlName="{{configData.formControlName}}">
        <option *ngFor="let item of listingData" [value]="item.id">{{item.name}}</option>
    </select>
</div>