export class AnalyticsReportUserModel {
    total_briindlet_views: number = 0;
    total_briindlet_clicks: number = 0;
    user_profile_views: number = 0;
    briindad_views: number = 0;
    briindad_clicks: number = 0;
    likes: number = 0;
    shares: number = 0;
    friends: number = 0;
    most_views_posts: MostViewsPost[] = [];
    monthly_ad_views: MonthlyAdView[] = [];
    friends_data: FriendsData = new FriendsData();
}

export class FriendsData {
    new_friends: MonthlyAdView[] = [];
    friend_requests: MonthlyAdView[] = [];
}

export class MonthlyAdView {
    str_date: string = '';
    view_count: number = 0;
}

export class MostViewsPost {
    id: number = 0;
    photos: Photo[] = [];
    videos: any[] = [];
    gifs: any[] = [];
    posted_by: Posted = new Posted();
    posted_to: Posted = new Posted();
    likes_count: number = 0;
    liked: boolean = false;
    attachments: Attachment[] = [];
    item_type: string = '';
    is_share: boolean = false;
    comments: Comment[] = [];
    tags: any[] = [];
    see_tagged_post: string = '';
    review_post: boolean = false;
    review_tag: boolean = false;
    name: null | string = '';
    created_at: Date = new Date();
    updated_at: null = null;
    description: string = '';
    object_id: number = 0;
    post_type: string = '';
    album_privacy: string = '';
    content_type: number = 0;
    user: number = 0;
}

export class Attachment {
    data: any ;
    type: string = '';
}

export class Photo {
    id: number = 0;
    photo: string = '';
    caption: null | string = '';
    created_at: Date = new Date();
    updated_at: null = null;
    photo_url: string = '';
    user_id: number = 0;
    post: Post = new Post();
    comments: Comment[] = [];
}

export class Comment {
    id: number = 0;
    parent_id: null = null;
    user_type: UserType = new UserType();
    text: string = '';
    likes_count: number = 0;
    liked: boolean = false;
    replies: Reply[] = [];
    abusive: boolean = false;
}

export class Reply {
    id: number = 0;
    parent_id: number = 0;
    user_type: UserType = new UserType();
    text: string = '';
    likes_count: number = 0;
    liked: boolean = false;
    replies: any[] = [];
    abusive: boolean = false;
}

export class UserType {
    id: number = 0;
    profile_pic: string = '';
    name: string = '';
    user_type: string = '';
}

export class Post {
    id: number = 0;
    posted_by: Posted = new Posted();
    posted_to: Posted = new Posted();
    likes_count: number = 0;
    liked: boolean = false;
    item_type: string = '';
    name: null | string = '';
    created_at: Date = new Date();
    updated_at: null = null;
    description: string = '';
    object_id: number = 0;
    post_type: string = '';
    album_privacy: string = '';
    content_type: number = 0;
    user: number = 0;
}

export class Posted {
    id: number = 0;
    photo: string = '';
    name: string = '';
    user_type: string = '';
    briind_id: null | string = '';
}
