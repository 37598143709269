<div class="flex flexJustifyBetween mrgntop5">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'services we offer' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/organizations',organizationID,'profile','social','services']"
        class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20"></i>
    </div>
</div>

<div class="service">
    <ng-container *ngFor="let item of data">
        <span *ngIf="item.is_active" class="displayFlex bg-E5ECF1 bdrRadius20px mrgnright10 mrgnbtm10 color-122A4D poppins-regular font14 pad1015" >
            {{item?.name}}
        </span>
    </ng-container>
</div>