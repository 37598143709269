<section id="create-rbac" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar {{popup?.context?.parent}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="width100">
        <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
            {{'please specify the role of the member' | translate}}
        </div>
        <div class="color-122A4D font14 poppins-regular width80 mrgnauto txtCenter">
            {{'please use dropdown menu to assign a role for a member' | translate}}
        </div>
        <form class="" (submit)="acceptApplication();" [formGroup]="form" *ngIf="popup.context?.roleList?.length">
            <div class="mrgntop15">
                <app-custom-select class="width100" [formCntrl]="'role'" [icon]="'drop-down'" [formGrp]="form" [data]="popup.context?.roleList" [placeholder]="'select role'"></app-custom-select>
            </div>
            <div class="mrgntop15">
                <button class="btn-get-started minWidth100px mrgnright10 bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad1030 btn-user-default" [disabled]="btnDisable || form.invalid">
                    {{(popup?.context?.applicationID ? 'confirm' : 'Assign a Role') | translate}}
                </button>
            </div>
        </form>
    </div>
</section>