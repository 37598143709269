<section id="create-rbac" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar ">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="pad20 user padright10">
        <ng-container >
            <div class="color-122A4D font22 poppins-semibold mrgnbtm10">
                {{popup.context?.organizationEventDetail?.title}}</div>
            <img [src]="popup.context?.organizationEventDetail?.photo"
                class="width100 maxHeight400px bdrRadius6px boxShadow-RGBA00005">
            <div class="color-122A4D poppins-medium font15 mrgntop10">
                {{popup.context?.organizationEventDetail?.description}}
            </div>
            <div class="mrgntop10 flex color-122A4D poppins-medium font14">
                <i class="icns icon-time green d20 mrgnright5"></i>
                {{popup.context?.organizationEventDetail?.event_date| fullDate}}
            </div>
            <div class="mrgntop10 flex color-122A4D poppins-medium font14">
                <i class="icns icon-location green d20 mrgnright5"></i>
                {{popup.context?.organizationEventDetail?.location}}
            </div>
        </ng-container>
        <div class="flex user" style="margin-top: 15px;justify-content: end">
            <button type="button" style="display: flex;align-items: center; justify-content:center; gap: 5px; min-width: 95px;"
                (click)="btnCTA(popup.context?.notificationItem.notification_details.approve_url, 'approve_url')"
                *ngIf="popup.context?.notificationItem.notification_details.approve_url && popup.context?.notificationItem.notification_details.approve_url !='#'"
                class="btn-get-started  minwidth100px bdr-1-EEEEEE btn-user-default ripple cursorPtr font14 poppins-semibold pad0830">
                <span *ngIf="loader== '' || loader == 'reject_url'">
                    {{'Yes' | translate}}
                </span>
                <i class="icns icon-three-dots d20" *ngIf="loader == 'approve_url'"></i>
            </button>
            <div class="pad5"></div>
            <button type="button" (click)="btnCTA(popup.context?.notificationItem.notification_details.reject_url, 'reject_url')"
                *ngIf="popup.context?.notificationItem.notification_details.reject_url && popup.context?.notificationItem.notification_details.reject_url !='#'"
                class="btn-get-started minwidth100px bdr-1-EEEEEE bg-white cancel-btn ripple cursorPtr font14 poppins-semibold pad0830">
                <span *ngIf="loader== '' || loader == 'approve_url'">
                    {{'No' | translate}}
                </span>
                <i class="icns icon-three-dots d20" *ngIf="loader == 'reject_url'"></i>
                
            </button>
        </div>
    </div>
</section>