<div class="posRelative width100" #main>
    <input placeholder="{{ placeholder | translate}}"  appDebounce [debounceTime]="400" (debounceClick)="searchItem()" [(ngModel)]="searchInput" [readonly]="isSearchEnabled ? false : true" [ngClass]="{ 'bdr-red': !isShowList && isInvalid && isClickedFrom == 'item' }"
        class="font16 width100 cursorPtr boxShadow-RGBA00005 input-icon {{icon}} pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px mob-input-font" (click)="showList()">
    <div [ngClass]="{'posAbsolute': isPosAbsolute}" class="boxShadow-RGBA00005 posRight bg-white mrgn1 bdrRadius6px bdr-1-EEEEEE width100 zIndex1 custom-scrollbar selectListContainer" *ngIf="isShowList">
        <ng-container *ngIf="data?.length == 0 && searchInput && isShowCustomValue ">
            <div class="pad10 ripple flex cursorPtr" (click)="selectItem({id: searchInput, name: searchInput})">
                <div class="privacy-label color-02457A font15 poppins-regular ">
                    {{searchInput | translate}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="data?.length != 0 && isClickedFrom == 'item'">
            <div class="pad10 ripple flex cursorPtr" *ngFor="let item of data | search: searchInput" (click)="selectItem(item)">
                <div class="privacy-label color-02457A font15 poppins-regular ">
                    {{item.name | translate}}
                </div>
            </div>
        </ng-container>
    </div>
    <div [formGroup]="formGrp" class="hide">
        <select formControlName="{{formCntrl}}">
            <option *ngFor="let item of data" [value]="item.id">{{item.name}}</option>
        </select>
    </div>
</div>