<div class="width100 posRelative">

    <div
        class="header timeline-label font20  posRelative lineHeight40px poppins-semibold txtCenter posRelative mrgnbtm10">
        <div class="posAbsolute posTop posLeft d35 bdrRadius50p ripple bg-F1F4FE flex flexAlignItemsCenter flexJustifyCenter cursorPtr"
            (click)="backToPost();">
            <i class="icns icon-left-arrow d25 datepicker"></i>
        </div>
        {{'tag friends' | translate}}
    </div>
    <div class="flex flexAlignItemsCenter flexJustifyCenter">
        <div class="width50">
            <app-search-input (search)="getFriendsList($event)" [isLimitWidth]="false"></app-search-input>
        </div>
    </div>
    <div class="width100 mrgntop15">
        <div class="mrgnauto mrgnbtm15" *ngIf="selectedData.length != 0">
            <div class="timeline-label poppins-medium font16">{{'tagged' | translate}}</div>
            <div class="pad5"></div>
            <div class="boxShadow-RGBA00005 bg-FFFFFF bdr-1-EEEEEE bdrRadius6px pad10 flex flexAlignItemsCenter flexWrap">
                <ng-container *ngFor="let friend of selectedData">
                    <span class="bg-E5ECF1 bdrRadius6px flex flexNoShrink flexAlignItemsCenter flexJustifyBetween poppins-medium font16 pad1015 mrgnright10 timeline-label">
                        {{friend.name}}
                        <span class="pad5 cursorPtr bg-F1F4FE bdrRadius6px mrgnleft10 displayFlex d25 flexAlignItemsCenter flexJustifyCenter"
                            (click)="cancelTaggedFriend(friend)">×</span>
                    </span>
                </ng-container>
            </div>
        </div>

        <div class="width100">
            <ng-container *ngFor="let friend of friendsList;let i = index;">
                <div class="width100 flex flexAlignItemsCenter bdrRadius6px ripple cursorPtr pad10 friend-item" *ngIf="!friend?.isChecked"
                    (click)="selectFriends(i)">
                    <app-profile-thumbnail [image]="friend?.targeted_user?.photo" [imageClass]="'d40'">
                    </app-profile-thumbnail>
                    <div class="pad5"></div>
                    <div class="font16 poppins-medium timeline-label">{{friend?.targeted_user?.name}}</div>
                </div>
            </ng-container>
            <app-empty-state [stateType]="'friendsList'" *ngIf="friendsList.length == 0 "></app-empty-state>
        </div>
        <div class="flex mrgntop20" *ngIf="friendsList?.length">
            <button (click)="backToPost();"
                class="btn-get-started padleft20 padright20 padtop10 minWidth120px padbtm10 cancel-btn font15 ripple poppins-medium cursorPtr">{{'cancel' | translate}}</button>
            <div class="pad5"></div>
            <button (click)="addTaggedFriends();"
                class="btn-get-started padleft20 padright20 minWidth120px padtop10 padbtm10 btn-user-default font15 ripple poppins-medium cursorPtr">{{'tag' | translate}}</button>
        </div>

    </div>
</div>