<div class="{{appStore?.userType}}" [formGroup]="formGrp">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'registration guidlines' | translate}}
    </div>
    <div class="body width100" formGroupName="step_10">
        <div class="width100 flex flexResponsive">
            <div class="width100">
                <textarea
                    class="font16 width100 boxShadow-RGBA00005 resizeNone pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px minHeight150px"
                    placeholder="{{'enter here' | translate}}" name="registration_guidelines" id="registration_guidelines" rows="3" formControlName="registration_guidelines"></textarea>
            </div>
        </div>
        <div class="width100 padtop30 flex">
            <button  (click)="actionCTA('continue')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                {{'skip' | translate}}
            </button>
            <div class="pad5"></div>
            <button  (click)="actionCTA('previous')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="formGrp?.controls?.step_10?.invalid" (click)="actionCTA('continue')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
</div>