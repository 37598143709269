<div class="{{appStore?.userType}} " [formGroup]="formGrp">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'add speakers distingushed guests' | translate}}
    </div>
    <div class="body width100" formGroupName="step_8">
        <div formArrayName="guest" class="maxHeight300px overflowAuto custom-scrollbar">
            <div class="width100 flex flexResponsive flexAlignItemsCenter mrgnbtm20" *ngFor="let guest of formGrp.get('step_8')?.get('guest')?.controls; let i=index">
                <div formGroupName="{{i}}" class="width100 flex flexResponsive flexAlignItemsCenter">
                    <div class="d140 boxShadow-RGBA00005  bdr-1-EEEEEE bdrRadius6px">
                        <app-upload-photo [placeholderImage]="formGrp?.controls?.step_8?.value?.guest[i]?.photo || 'assets/images/add-pic-layer.png'" (imageEvent)="getImage($event, i)">
                        </app-upload-photo>
                    </div>
                    <div class="pad10"></div>
                    <div class="width80">
                        <input placeholder="{{'enter name' | translate}}" formControlName="name" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
                  bdr-1-EEEEEE bg-white outline0 bdrRadius6px">
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flexJustifyEnd mrgnbtm20">
            <button [disabled]="formGrp?.controls?.step_8?.controls?.guest?.invalid" class="btn-get-started cancel-btn minwidth100px flex bdr-1-EEEEEE 
            ripple cursorPtr font14 poppins-regular pad0815" (click)="addGuest()">
                <i class="icns icon-plus {{appStore?.iconClass}} d20 mrgnright10"></i>{{'add more' | translate}}
            </button>
        </div>
        <div>
            <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
                {{'who can attend?' | translate}}
                <div class="text-center font16 poppins-regular">(Do you have any rules and regulations to attend this Event?)</div>
            </div>
            <textarea formControlName="who_can_attend" rows="3" placeholder="{{'description' | translate}}" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
            bdr-1-EEEEEE bg-white outline0 bdrRadius6px"></textarea>
        </div>
    </div>
    <div class="width100 padtop30 flex">
        <button (click)="actionCTA('continue')" class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
            {{'skip' | translate}}
        </button>
        <div class="pad5"></div>
        <button (click)="actionCTA('previous')" class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
            {{'back' | translate}}
        </button>
        <div class="pad5"></div>
        <button [disabled]="formGrp?.controls?.step_8?.invalid" (click)="actionCTA('continue')" class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
            {{'continue' | translate}}
        </button>
    </div>
</div>