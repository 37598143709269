import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';

@Component({
    selector: 'app-volunteer-details-popup',
    templateUrl: './volunteer-details-popup.component.html',
    styleUrls: ['./volunteer-details-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolunteerDetailsPopupComponent { 
    @Input('popup') popup: PopupModel = new PopupModel();
    @Output('close') close = new EventEmitter();
    loader: string = '';
    constructor(private appService: AppService) {

    }

    /**
     * @description
     * this method is used for closing the popup
     *
     * @memberof CreateRbacPopupComponent
     */
    closePopup() {
        this.close.emit('icon');
    }

    btnCTA(url: any, urlType: string) {
        const param = { notification_id: this.popup.context.notificationItem.id };
        this.loader = urlType;
        this.appService.customApiRequest(url, param).subscribe((response: any) => {
            this.appService.toggleToast({
                type: 'success',
                load: true,
                position: 'bottom-left',
                context: { description: `${response.message}` }
            });
            this.loader = '';
            this.popup.context.organizationEventDetail.approve_url = '';
            this.popup.context.organizationEventDetail.reject_url = '';
            this.closePopup();
        // this.updateCard.emit('updated')
        }, err => {
            this.loader = '';
        })
    }
}
