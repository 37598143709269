<div class="flex">
    <div class="font16 poppins-semibold timeline-label txtTransformCaps padright10">{{post?.posted_by?.name}}
    </div>
    <div class="color-8E8E8E font14 poppins-medium padtop2">{{post?.created_at | dateAgo }}
    </div>
</div>
<ng-container *ngIf="post?.description">
    <div class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description?.length > 200">
        <span [innerHtml]="(showMore) ? post?.description : post?.description.slice(0,300)"></span>
        <span *ngIf="!showMore">...</span>
        <a href="javascript:;" class="seeMore" (click)="showMore = !showMore">{{showMore ? ' See Less': ' See more'}}</a> 
        <div class="pad5"></div>
    </div>
    <div [innerHtml]="post?.description" class="font15 color-122A4D poppins-regular wrdBreakAll" *ngIf="post?.description?.length < 200">
        <div class="pad5"></div>
    </div>
</ng-container>

<!-- Show when sharing profile or cover photo -->
<ng-container *ngIf="post?.post_type == 'ProfilePic' || post?.post_type == 'CoverPhoto'">
    <div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 width100 bg-white mrgnbtm20">        
        <div class="">
            <div class="pad5"></div>
            <div class="posRelative" style="height: 325px;" *ngIf="post.post_type == 'ProfilePic'">
                <div [ngStyle]="{'background-image': 'url(' +(post.posted_by?.cover_photo || defaultCoverPhoto) + ')' }" style="height: 200px;background-size: cover;" ></div>
                <div class="flex" style="align-items: center; justify-content: center;">
                    <div class="bg-white" style="position: absolute;padding: 10px;border-radius: 50%;">
                        <img [src]="post.posted_by?.photo || './assets/images/default-profile.jpeg'"
                            style="height: 320px; width: 320px; border-radius: 50%;" />
                    </div>
                </div>
            </div> 
            <div class="padleft15 padright15" *ngIf="post.post_type == 'CoverPhoto'">
                <img [src]="post.posted_by?.cover_photo || defaultCoverPhoto"
                    class="width100" />
            </div>
        </div>
        <div class="pad5"></div>
    </div>
</ng-container>