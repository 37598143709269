import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-work-education-preview',
  templateUrl: './work-education-preview.component.html',
  styleUrls: ['./work-education-preview.component.scss']
})
export class WorkEducationPreviewComponent implements OnInit {
  @Input('data') data: any;
  @Input('showEditIcon') showEditIcon: boolean = false;
  @Input('settings') settings :any;
  constructor() { }

  ngOnInit(): void {
  }


  /**
   * TODO: comment showUserInfo
   * @description Shows user info
   * @param settings 
   * @param showValue 
   * @returns  
   * @memberof WorkEducationPreviewComponent
   */
  showUserInfo(settings: any,showValue:any) {
    if (settings) {
      if (settings.is_own_profile) {
        return true;
      }
      else {
        if (settings.settings[showValue] == "everyone") {
          return true;
        }
        else if (settings.settings[showValue] == "friends" && !settings.is_friend) {
          return false;
        }
        else if (settings.settings[showValue] == "private") {
          return false;
        }
        else {
          return true;
        }
      }
    } else {
      return true;
    }
  }


  /**
   * TODO: comment showUserInfoSchoolUniversity
   * @description Shows user info school university
   * @param settings 
   * @param showValue 
   * @returns  
   * @memberof WorkEducationPreviewComponent
   */
  showUserInfoSchoolUniversity(settings: any, data: any) {
    if (settings) {
      if (settings.is_own_profile) {
        return true;
      }
      else {
        if (data.data_type == "HighSchool") {
          if (settings.settings.show_highschool == "everyone") {
            return true;
          }
          else if (settings.settings.show_highschool == "friends" && !settings.is_friend) {
            return false;
          }
          else if (settings.settings.show_highschool == "private") {
            return false;
          }
          else {
            return true;
          }
        }
        else if (data.data_type == "University") {
          if (settings.settings.show_university == "everyone") {
            return true;
          }
          else if (settings.settings.show_university == "friends" && !settings.is_friend) {
            return false;
          }
          else if (settings.settings.show_university == "private") {
            return false;
          }
          else {
            return true;
          }
        }
      }
    } else {
      return true;
    }
  }
}
