import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trending-organization',
  templateUrl: './trending-organization.component.html',
  styleUrls: ['./trending-organization.component.scss']
})
export class TrendingOrganizationComponent implements OnInit {
  @Input('organization') organization: any;
  @Input('userType') userType: any;

  constructor() { }

  ngOnInit(): void {
    // alert()
  }

}
