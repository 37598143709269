<div class="width100 posRelative  boxShadow-RGBA00005 bg-white bdrRadius6px pad15 bdr-1-EEEEEE">
    <app-simple-carousel>
        <ng-container *ngFor="let item of data?.photos">
            <ng-container *carouselItem>
                <div class="posRelative width100">
                        <!-- <img src="{{item?.photo}}" class="width100 maxHeight380px height100p bdrRadius6px minWidth280px"> -->
                        <div class="cover-profile-image posRelative flex flexJustifyCenter">
                            <span class="online-img-shadow bdrRadius50p posRelative displayFlex">
                              <img [src]="item?.photo" class="">
                             
                            </span>
                          </div>
                          <!-- <div class="posAbsolute d40 bdrRadius50p ripple bg-white posTop10px flex flexAlignItemsCenter flexJustifyCenter cursorPtr">
                            <i class="icns icon-delete green d20"></i>
                          </div> -->
                        <div *ngIf="isShowEdit" (click)="openCauseEditPopup()" class="posAbsolute d40 bdrRadius50p ripple posRight10px bg-white posTop10px flex flexAlignItemsCenter flexJustifyCenter cursorPtr">
                            <i class="icns icon-edit green d20"></i>
                        </div>
                </div>
                
            </ng-container>
        </ng-container>
    </app-simple-carousel>
    <div class="pad5"></div>
    <div class="poppins-regular font14 color-484848">
        <span [innerHTML]="data?.description"></span>
        <p><a [href]="data?.web_link" class="poppins-semibold font16 color-18878C">{{data?.web_link}}</a></p>
    </div>
</div>