import { AppStore } from 'src/app/core/stores/app.store';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
// import { UserStoreService } from 'src/app/users/store/user-store.service';

@Component({
  selector: 'app-organization-promote-popup',
  templateUrl: './organization-promote-popup.component.html',
  styleUrls: ['./organization-promote-popup.component.scss']
})
export class OrganizationPromotePopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  public slectedEventType: string | any = 'yes';
  parseInt: any = parseInt;
  // startDateTIme: Date = new Date();
  // endDateTime: Date = new Date();
  subscription: Subscription = new Subscription();
  selectedFile: any ;
  pageNumber: number = 1;
  promotionFormGroup!: FormGroup;
  isInvalid: boolean = false;
  errorText: string = "";
  serverError: string = "";
  userType: string = "user";
  public today = new Date();
  public min = new Date(this.today.getFullYear()+'/'+(this.today.getMonth()+Number(1))+'/'+this.today.getDate());
  public startDate = new Date();
  errorTextMessage: string = '';
  showCropper: boolean  = false;
  imageChangedEvent: any;
  constructor(
    public organizationStoreService: OrganizationStoreService,
    private organizationService: OrganizationService,
    // public userStoreService: UserStoreService,
    private userService: UserService,
    private appService: AppService,
    private fb: FormBuilder,
    public authService: AuthService,
    public appStore: AppStore
  ) { }

  /**
   * @description
   * This Method Is For Getting The each form Controls of formGroup to Check The Validation
   * @param {string} [controlName]
   * @returns the specfic FormControl Based on the Param
   * @memberof AdvertisementCreateComponent
  */
   f(controlName?: string) {
    return this.promotionFormGroup.get(controlName || '')
  }

  uploadPhoto(event : any) {
    this.showCropper = true;
    this.imageChangedEvent = event;
  }

  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  uploadImage(event: any) {
    this.getBase64(event).then(data => {
      this.selectedFile = {base64: data, file: event };
      this.showCropper = false;
    })
  }
  /**
   * Click On Continue Button This method will call
   * This method is using for Navigate to corresponding sections
   *
   * @memberof OrganizationPromotePopupComponent
   */
  nextCTA() {
    this.appService.togglePopup({
      type: 'briindletLastDateEvent',
      load: true,
      refreshParent: false,
      overlayClose: true,
    });
    this.close.emit('icon');
  }


  /**
   * @description
   * Backs button
   * @memberof OrganizationPromotePopupComponent
   */
  backButton() {
    this.appService.togglePopup({
      type: 'briindletAboutEvent',
      load: true,
      refreshParent: false,
      overlayClose: true,
    });
  }
 

 /**
  * @description
  * Click on close button this method will call
  *
  * @memberof OrganizationPromotePopupComponent
  */
  closePopup() {
		this.close.emit('icon');
  }
  
  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   * 
   * @memberof OrganizationPromotePopupComponent
   */
  ngOnInit(): void {
    this.promotionFormGroup = this.fb.group({
      briindID: ['', [Validators.required]],
      startDate: [new Date().setHours(0, 0, 0, 0), [Validators.required]],
      endDate: [new Date().setHours(0, 0, 0, 0), [Validators.required]],
      startDateTIme: [new Date().setHours(0, 0, 0, 0), [Validators.required]],
      endDateTime: [new Date().setHours(0, 0, 0, 0), [Validators.required]],
      photo: ['', [Validators.required]]
    });
    this.resetOrganizationPromotionPrice();
    this.userType = this.authService.getSessionData().type.toLowerCase();
    
  }

  checkBriindID() {
    this.errorTextMessage = '';
    this.subscription.add(
      this.appService.checkOrganizationBriindID({briind_id:  this.promotionFormGroup.value.briindID}).subscribe(response => {

      } ,(err: any) => {
        this.errorTextMessage = err.error.message;
      })
    )
  }

  /**
   * @description
   * Determines whether date rage selection on
   * @param newValue 
   * @memberof OrganizationPromotePopupComponent
   */
  onDateRageSelection(newValue: any) {
    this.startDate = this.promotionFormGroup.value.startDate;
    if (this.promotionFormGroup.value.startDate != null && this.promotionFormGroup.value.endDate != null &&  this.promotionFormGroup.value.startDateTIme != null && this.promotionFormGroup.value.endDateTime != null) {
      // let param = { start: this.promotionFormGroup.value.startDateTIme.toISOString().split('.')[0] + "Z", end: this.promotionFormGroup.value.endDateTime.toISOString().split('.')[0] + "Z" };
      const date1: any = new Date(`${moment(this.promotionFormGroup['value']['startDate']).format('YYYY-MM-DD')} ${moment(this.promotionFormGroup['value']['startDateTIme']).format('HH:mm')}`)
      const date2: any = new Date(`${moment(this.promotionFormGroup['value']['endDate']).format('YYYY-MM-DD')} ${moment(this.promotionFormGroup['value']['endDateTime']).format('HH:mm')}`)
      const diffTime = Math.abs(date2 - date1);
      const differentHours = Math.ceil(diffTime / (1000 * 60 * 60)); 
      //if (this.userType == "organization") {
        this.subscription.add(
          this.organizationService.getOrganizationPromotionPriceCalc({hours: differentHours}).subscribe(response => {
            this.organizationStoreService.setOrganizationPromotionPrice();
          })
        );

    }
  }


  /**
   * TODO: comment resetOrganizationPromotionPrice
   * @description Resets organization promotion price
   * @memberof OrganizationPromotePopupComponent
   */
  resetOrganizationPromotionPrice() {
    // if (this.userType == "organization") {
    this.organizationStoreService.resetOrganizationPromotionPrice();
    // }
    // else {
    //   this.userStoreService.resetOrganizationPromotionPrice();
    // }
  }


  /**
   * @description
   * Proceed to pay
   * @memberof OrganizationPromotePopupComponent
   */
  proceedToPay() {
    //console.log(this.promotionFormGroup);
    if (this.promotionFormGroup.valid) {
      this.pageNumber = 2;
    }
    else {
      this.errorText = "Briind id required";
    }
  }


  /**
   * @description
   * Creates organization promotion
   * @memberof OrganizationPromotePopupComponent
   */
  createOrganizationPromotion(isPaid: boolean) {
    //console.log(this.promotionFormGroup);
    if (this.promotionFormGroup.valid) {
      let formData = new FormData();
      formData.append('briind_id',this.promotionFormGroup.value.briindID);
      formData.append('start', `${moment(this.promotionFormGroup['value']['startDate']).format('YYYY-MM-DD')} ${moment(this.promotionFormGroup['value']['startDateTIme']).format('HH:mm')}`);
      formData.append('end',`${moment(this.promotionFormGroup['value']['endDate']).format('YYYY-MM-DD')} ${moment(this.promotionFormGroup['value']['endDateTime']).format('HH:mm')}`);
      formData.append('is_paid','True');
      formData.append('price',this.organizationStoreService?.promotionPriceModel?.total_price);
      formData.append('photo',this.selectedFile.file);

      // let data = {
      //   briind_id: this.promotionFormGroup.value.briindID,
      //   start: `${moment(this.promotionFormGroup['value']['startDate']).format('YYYY-MM-DD')} ${moment(this.promotionFormGroup['value']['startDateTIme']).format('HH:mm')}`,
      //   end: `${moment(this.promotionFormGroup['value']['endDate']).format('YYYY-MM-DD')} ${moment(this.promotionFormGroup['value']['endDateTime']).format('HH:mm')}`,
      //   is_paid: true,
      //   price: this.organizationStoreService?.promotionPriceModel?.total_price
      // };
      this.subscription.add(
        this.organizationService.createOrganizationPromotion(formData).subscribe((response: any) => {
          this.serverError ="";
          this.appService.toggleToast({
            type: 'success',
            load: true,
            position: 'bottom-left',
            context: { description: 'Promotion created successfully' }
          });
          this.subscription.add(
            this.organizationService.getOrganizationPromotion().subscribe((response: any) => {
              this.organizationStoreService.setOrganizationPromotionList();
            }
            )
          );
          if (!isPaid) {
            this.close.emit('icon');
          }
        }, (error: any) => {
          if (error) {
            this.serverError = error.error.message;
          }

        })
      );
      
    }
    else {
      this.errorText = "Briind id required";
    }

  }

  /**
   * @description
   * Creates promotion
   * @param event 
   * @memberof OrganizationPromotePopupComponent
   */
  createPromotion(event: any){
    if(event=="success"){
      this.createOrganizationPromotion(true);
    }
  }

  getStepperCount(event: any) {
    this.pageNumber = event
  }


  /**
   * TODO: comment getMinStartTime
   * @description Gets min start time
   * @returns  
   * @memberof OrganizationPromotePopupComponent
   */
  getMinStartTime() {
    let startDate = new Date(this.promotionFormGroup.value.startDateTIme);
    if (startDate.getDate() == this.today.getDate()) {
      return this.today;
    }

  }


  /**
   * TODO: comment getMinEndTime
   * @description Gets min end time
   * @returns  
   * @memberof OrganizationPromotePopupComponent
   */
  getMinEndTime(){
    let endDate = new Date(this.promotionFormGroup.value.endDateTime);
    if (endDate.getDate() == this.today.getDate()) {
      return this.today;
    }
  }

}
