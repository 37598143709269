<div class="bdr-1-EEEEEE boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px pad1020 flexJustifyBetween flex flexAlignItemsCenter">
    <div class="flex flexAlignItemsCenter">
        <span class="blue-img-shadow padtop5 padbtm3 padleft8 displayFlex bdrRadius50p posRelative">
            <img [src]="user?.profile_pic" class="d50 bdrRadius50p bdr-profile-image" />
        </span>
        <div class="poppins-medium font16 timeline-label mrgnleft10">
            {{user?.name}}
        </div>
    </div>
    <div class="agree-checkbox-container">
        <div class="custom-checkbox flex flexAlignItemsStart">
            <input type="checkbox" [(ngModel)]="user.isChecked" (change)="changeItem($event)" class="flexNoShrink">
        </div>
    </div>
</div>