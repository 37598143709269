import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';

@Component({
  selector: 'app-post-comment-reply-list',
  templateUrl: './post-comment-reply-list.component.html',
  styleUrls: ['./post-comment-reply-list.component.scss']
})
export class PostCommentReplyListComponent implements OnInit {
  @Input() replies: any[] = [];
  more: boolean = false;
  @Input() public post: any;
  @Input() user: any;
  @Input() theme: string = 'blue';
  @Input() profilePic: any;
  showReply: any[] = [];
  subscription: Subscription = new Subscription();
  constructor(private userService: UserService,
    private authService: AuthService,
    private organizationService: OrganizationService, public organizationStoreService: OrganizationStoreService,
    public userStoreService: UserStoreService) { }

  ngOnInit(): void {
  }


  /**
* @description
* this method is used for like a reply
*
* @param {*} event
* @memberof PostCommentReplyListComponent
*/
  likeReply(reply: any) {
    //if (!reply.liked) {
      const param = {
        "user_id": this.authService.getSessionData().orgId,
        "item_id": reply.id,
        "item_type": 'Comment'
      }
      switch (this.authService.getSessionData().type.toLowerCase()) {
        case 'user':
          this.userLikeReply(param, reply);
          break;
        case 'organization':
          this.organizationLikeReply(param, reply);
          break;
        default:
          break;
      }
    //}
  }

  /**
   * @description
   * this method is used for call the organization like api
   *
   * @param {*} [param]
   * @memberof PostCommentReplyListComponent
   */
  organizationLikeReply(param: any, reply: any) {
    this.subscription.add(
      this.organizationService.likeCommentReply(param).subscribe((response: any) => {
        
        if (reply.liked) {
          this.replies[this.replies.indexOf(reply)].liked = false;
          this.replies[this.replies.indexOf(reply)].likes_count -= 1;
        } else {
          this.replies[this.replies.indexOf(reply)].liked = true;
          this.replies[this.replies.indexOf(reply)].likes_count += 1;
        }
        // this.replies[this.replies.indexOf(reply)].liked = true;
        // this.replies[this.replies.indexOf(reply)].likes_count += 1;
        //this.post.liked = true;
        //this.post.likes_count += 1;
      })
    )
  }

  /**
   * @description
   * this method is used for call the user like api
   *
   * @param {*} param
   * @memberof PostCommentReplyListComponent
   */
  userLikeReply(param: any, reply: any) {
    this.subscription.add(
      this.userService.likeCommentReply(param).subscribe((response: any) => {
        if (reply.liked) {
          this.replies[this.replies.indexOf(reply)].liked = false;
          this.replies[this.replies.indexOf(reply)].likes_count -= 1;
        } else {
          this.replies[this.replies.indexOf(reply)].liked = true;
          this.replies[this.replies.indexOf(reply)].likes_count += 1;
        }
        // this.replies[this.replies.indexOf(reply)].liked = true;
        // this.replies[this.replies.indexOf(reply)].likes_count += 1;
        //this.post.liked = true;
        //this.post.likes_count += 1;
      })
    )
  }

  checkIfAbusiveComment(comment: any) {
    if (this.authService.getSessionData().type.toLowerCase() == 'user') {
      if (this.userStoreService.privacySettingsModel.hide_comment_words == true) {
        if (comment.abusive == true) { return false; }
        else { return true; }
      }
      else { return true; }
    }
    else {
      if (this.organizationStoreService.privacySettingsModel.hide_comment_words == true) {
        if (comment.abusive == true) { return false; }
        else { return true; }
      }
      else { return true; }
    }
  }

}
