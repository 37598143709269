import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getImagePath, getSingleImagePath } from 'src/app/core/essentials/common.libs';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { CameraService } from 'src/app/core/services/camera.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-ministry-list-item-tab',
  templateUrl: './ministry-list-item-tab.component.html',
  styleUrls: ['./ministry-list-item-tab.component.scss']
})
export class MinistryListItemTabComponent implements OnInit {
  @ViewChild("el", {read: ElementRef}) el: ElementRef | any;
  @Input('data') data: any;
  @Input('isShowEditAll') isShowEditAll: boolean =false;
  isShowEdit:boolean = false;
  form: FormGroup = new FormGroup({});
  userIds: any = [];
  activityList: any = [];
  @Output() preview = new EventEmitter();
  subscription: Subscription = new Subscription();
  removedImages: any = [];
  imageChangedEvent: any;
  showCropper: boolean =false;
  imageType: string = "";
  isShowDetails: boolean = false;
  selectedFile: any;
  isWeb = true
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    public organizationStoreService: OrganizationStoreService,
    private router: Router,
    private appService: AppService,
    public authService: AuthService,
    private cameraService: CameraService
  ) {
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isWeb = false
  }

  /**
   * @description
   * this method is used for toggling the panel
   *
   * @param {*} [event]
   * @memberof MinistryListItemTabComponent
   */
  showPanel(event?: any) {
    this.isShowDetails=!this.isShowDetails
    //if (!this.isShowDetails) {
      // let element: any = document.getElementsByClassName('panel') || [];
      // let activeElement: any = document.getElementsByClassName('active') || [];
      // for (const item of element) {
      //   item.style.maxHeight = null;
      // }
      // if (activeElement.length != 0) {
      //   activeElement[0].classList.remove("active");
      // }
      // this.el.nativeElement.classList.toggle("active");
      // var panel = this.el.nativeElement.nextElementSibling;
      // if (panel.style.maxHeight) {
      //   panel.style.maxHeight = null;
      // } else {
      //   panel.style.maxHeight = panel.scrollHeight + "px";
      // }
    //}
    //this.isShowDetails = !this.isShowDetails;
  }

  ngOnInit(): void {

  }


  /**
   * TODO: comment updatePhoto
   * @description Updates photo
   * @param event 
   * @param [type] 
   * @returns  
   * @memberof MinistryListItemTabComponent
   */
  async updatePhoto(event : any, type : string) {
    if(!this.isWeb) {
      this.imageChangedEvent = await this.cameraService.getPhoto();
      this.showCropper =true;
      this.imageType = type
    }
    if(this.isWeb) {
      if (event.target.files.length > 0) {
        this.imageChangedEvent = event;
        this.showCropper =true;
        this.imageType = type;
        return;
      }      
    }
   }

  /**
   * TODO: comment uploadImage
   * @description Uploads image
   * @param [event] 
   * @param [type] 
   * @memberof MinistryListItemTabComponent
   */
  uploadImage(event?: any) {
    var reader = new FileReader();
    reader.onload = (ev: any) => {
      this.data.photo =ev.target.result;
      this.data.file =event;
    }
    reader.readAsDataURL(event);
    this.showCropper = false;
    // getSingleImagePath(event,'ministries-cover-photo');

  }

  get control() { return this.form.controls; }

  showImagePopup(photos: any, index: number) {
    this.appService.togglePopup({
      type: 'viewActivityImages',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        index: index,
        images: photos
      }
    });
  }

  /**
   * TODO: comment getDataFromChild
   * @description Gets data from child
   * @param [event] 
   * @param [type] 
   * @memberof MinistryListItemTabComponent
   */
  getDataFromChild( event ?: any, type ?: string) {
    switch (type) {
      case 'ministry':
        this.userIds = event;
        break;
    }
  }

  getRemovedImages(event:any){
    this.removedImages = event;
  }


  /**
   * TODO: comment formCTA
   * @description Forms cta
   * @param [event] 
   * @memberof MinistryListItemTabComponent
   */
  formCTA(event ?: string) {
    switch (event) {
      case 'preview':
        this.preview.emit({pathName: 'ministries', ministries_list: this.data})
        break;
      case 'previous':
        this.router.navigate(['/organizations',this.organizationStoreService.profileDetail.briind_id,'profile','bussiness','district']);
        break
      case 'continue':
        this.router.navigate(['/organizations',this.organizationStoreService.profileDetail.briind_id,'profile','bussiness','give'])
        break;
      case 'exit':
        this.router.navigate(['/organizations/home'])
        break;
    }
  }


  /**
   * TODO: comment onSubmit
   * @description Determines whether submit on
   * @memberof MinistryListItemTabComponent
   */
  onSubmit() {
    let formData = new FormData();
    // let coverPhoto = document.getElementById('upload-cover') as HTMLInputElement;
    // if (coverPhoto.files && coverPhoto.files[0]) {
    //   formData.append('photo', coverPhoto.files[0]);
    // }
    if(this.data.file){
      formData.append('photo', this.data.file);
    }
    formData.append('organization_id', this.organizationStoreService.organizationId);
    formData.append('title', this.form.value.title);
    formData.append('description', this.form.value.description);
   
    for (let index = 0; index < this.userIds.length; index++) {
      formData.append(`ministry_leaders[${index}][user_id]`, this.userIds[index]['id']);
      formData.append(`ministry_leaders[${index}][designation]`, this.userIds[index]['designation']);
    }
    // for (let index = 0; index < this.activityList.length; index++) {
    //   formData.append(`ministry_activity_ids[${index}]`, this.activityList[index]?.id);
    //   //formData.append(`activity[0][photos][${index}][name]`, "");
    // }
    this.subscription.add(
      this.organizationService.updateMinistry(formData,this.data.id).subscribe((response: any) => {
        this.data = response.result;
        //this.form.reset();
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-left',
          context: { description: `Ministry updated successfully` }
        });
      })
    );
  }


  /**
   * TODO: comment showEdit
   * @description Shows edit
   * @param data 
   * @memberof MinistryListItemTabComponent
   */
  showEdit(data: any) {
    this.isShowEdit = !this.isShowEdit;
    this.form = this.fb.group({
      title: new FormControl(data.title, [Validators.required]),
      description: new FormControl(data.description, [Validators.required]),
      // activity_title: new FormControl(data?.activity?.title, [Validators.required]),
      // activity_description: new FormControl(data?.activity?.description, [Validators.required]),
      // activity_date: new FormControl(data?.activity?.date, [Validators.required]),
    })
  }



}
