import { Component, Input, Output, EventEmitter, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { PopupModel, DropDownModel } from 'src/app/core/models';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-update-member-role',
  templateUrl: './update-member-role.component.html',
  styleUrls: ['./update-member-role.component.scss']
})
export class UpdateMemberRoleComponent implements  OnChanges {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  parent: string = '';
  configDataForMultiSelect: DropDownModel = new DropDownModel();
  form: FormGroup = new FormGroup({});
  rolesList: any = [];
  private subscription: Subscription = new Subscription();
  roleDetail: any;
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private appService: AppService
  ) {
    this.parent = this.authService.getSessionData().type.toLowerCase();
    this.form = fb.group({
      id: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      functionality:  this.fb.array([]),
      organization: new FormControl() 
    })
    
  }

  /**
   * @description
   * for adding the
   *
   * @param {string} funtcionality
   * @param {*} isChecked
   * @memberof CreateRbacPopupComponent
   */
  onChange(funtcionality: string, event: any) {
    const functionalityFormArray = <FormArray>this.form.controls.functionality;
    let isChecked:any = event.target ? event.target.checked : event;
    if (isChecked) {
      functionalityFormArray.push(new FormControl(funtcionality));
    } else {
      let index = functionalityFormArray.controls.findIndex(x => x.value == funtcionality)
      functionalityFormArray.removeAt(index);
    };
  }
 
  onSubmit() {
    this.subscription.add(
      this.organizationService.updateMembershipDetail(this.form.value).subscribe(response => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-left',
          context: { description: `Updated successfully` }
        });
        this.closePopup();
      })
    )
  }

  getRoles() {
    this.subscription.add(
      this.organizationService.getRoleList().subscribe(response => {
        this.rolesList = response;
      })
    )
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof CreateRbacPopupComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.popup && changes.popup.currentValue) {
      this.getRoles();
      this.subscription.add(
        this.organizationService.getRoleDetails(this.popup.context.role_id).subscribe((response: any) => {
          this.roleDetail = response;
          this.form.patchValue({
            role:  response.id||  this.popup.context.role_id,
            description:  response.description || '',
            id: this.popup.context.membership_id || '',
            organization: this.popup.context.organizationID
          });
          for (const item of response.functionality) {
            for (const functionality of this.popup.context.functionalityList) {
              if (functionality.id == item.id) {
                this.onChange(item.id, true);
                functionality.isChecked = true;
                break;
              }
            };
          }
        })
      ); 
    
    }
  }


  /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof CreateRbacPopupComponent
   */
  closePopup() {
		this.close.emit('icon');
  }

}
