<section id="create-rbac" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar organization">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="width100">
        <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
            {{'activity images' | translate}}
        </div>
        <div class="posRelative flex flexAlignItemsCenter flexJustifyBetween">
            <button class="d40 btn-get-started flexAlignItemsCenter flexNoShrink flexJustifyCenter flex btn-user-default cursorPtr ripple pad5 bdrRadius6px color-FFFFFF mrgnright5" (click)="plusSlides(-1)"><i class="icns icon-left-arrow white d25"></i></button>
            <img [src]="popup.context?.images[selectedIndex]?.photo_url" class="width100 fxWidth80">
            <button class="d40 btn-get-started flexAlignItemsCenter flexNoShrink flexJustifyCenter flex btn-user-default cursorPtr ripple pad5 bdrRadius6px mrgnleft5 color-FFFFFF" (click)="plusSlides(1)"><i class="icns icon-right-arrow white d25"></i></button>
        </div>
    </div>
</section>