<section class="pad20 {{userType}} boxShadow-RGBA00005 bdr-1-EEEEEE 
  bdrRadius6px bg-white width100 section1366 mrgnauto
">
    <div class="flex flexJustifyEnd mrgntop20 mrgnright10">
        <div class="width50 posRelative account-one bg-white account-box">
            <span *ngIf="!success" class="cancel bg-FF4154 displayFlex d30 flexJustifyCenter flexAlignItemsCenter"><span class="color-white">×</span></span>
            <span *ngIf="success" class="cancel bg-008000 displayFlex d30 flexJustifyCenter flexAlignItemsCenter"><i class="icns icon-tick-white d15"></i></span>
            <span class="mrgnleft5 {{success ? 'color-008000' : 'color-FF4154' }} header txtCenter font23 poppins-medium fontWeight600 color-02457A mrgntop20 mrgnbtm20">
                {{( success ? 'payment successful' : 'payment failed') | translate}}
            </span>
            <p class="color-02457A font14 poppins-regular padbtm10 left" *ngIf="success">
                {{ success ? 'Thank you so much for your business. Your payment successfully processed.' : 'Please enter the right payment information' }}
            </p>
            <p class="color-02457A font14 poppins-regular padbtm10 left" *ngIf="!success">
                {{ "Can't complete your payment. Any amount deducted will be refunded in 3-5 business date." }}
            </p>
            <hr>
            <div *ngIf="success">
                <!-- <div class="color-02457A font14 mrgntop10 ">{{'transaction id' | translate}}</div> -->
                <div class="posRelative maxHeight14px mrgnbtm15">
                    <div class="left color-02457A font14">{{'transaction id' | translate}}</div>
                    <div class="right color-02457A font14">{{appStore?.paymentModel?.result?.id}} </div>
                    <br></div>
                <div class="posRelative maxHeight14px mrgnbtm15">
                    <div class="left color-02457A font14">{{'payment type' | translate}}</div>
                    <div class="right color-02457A font14">{{appStore?.paymentModel?.result?.payment_method_details?.type | titlecase}}</div>
                    <br></div>
                <div class="posRelative maxHeight14px mrgnbtm15">
                    <div class="left color-02457A font14 txtTransformCaps">{{'name' | translate}}</div>
                    <div class="right color-02457A font14">{{appStore?.paymentModel?.result?.billing_details?.name}}</div>
                    <br></div>
                <div class="posRelative maxHeight14px mrgnbtm15">
                    <div class="left color-02457A font14">{{'amount paid' | translate}}</div>
                    <div class="right color-02457A font14"> {{appStore?.paymentModel?.result?.currency | uppercase}} {{appStore?.paymentModel?.amount || appStore?.paymentModel?.result?.amount}}</div>
                    <br></div>
            </div>

            <!-- <div class="posRelative maxHeight14px mrgnbtm15 txtCenter">
                <button type="button" class="btn-get-started btn-18878C 
                minwidth100px bdr-1-EEEEEE color-18878C cursorPtr 
                font14 poppins-semibold center btn-user-default" (click)="successTrue()">{{'back home' | translate}}</button>
            </div> -->
        </div>
        <div class="pad15"></div>
        <div class="width50 account-one bg-white account-box txtCenter desktop-visible">
            <img src="../../../../assets/images/payment_status.png" class="minHeight250px maxWidth300px ">
        </div>
    </div>

</section>