<div id="addOrEditMembershipField" class="pad20 bdrRadius6px organization bg-white posRelative flex flexDirectionCol">
    <div class="header color-18878C mrgntop20 mrgnbtm10 font20 lineHeight40px poppins-semibold txtCenter posRelative">
        {{'add your field' | translate}}
    </div>
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px color-18878C" (click)="closePopup();">×</span>
    <form [formGroup]="form">
        <div class="flex flexResponsive mrgntop15">
            <div class="width50Minus10pxLap">
                <div class="color-18878C poppins-regular font16 mrgntop10 mrgnbtm5">
                    {{'field type' | translate}}
                </div>
                <app-custom-select (selectedItemFromDropdown)="selectedItem($event)" class="width100"
                    [formCntrl]="'type'" [icon]="'drop-down'" [selectedData]="selectedInput" [formGrp]="form"
                    [data]="fieldData" [placeholder]="'field type'"></app-custom-select>
            </div>
            <div class="pad10"></div>

            <div class="width50Minus10pxLap">
                <div class="color-18878C poppins-regular font16 mrgntop10 mrgnbtm5">
                    {{'field name' | translate}}
                </div>
                <input formControlName="field_name"
                    [ngClass]="{ 'bdr-red': form.controls['field_name'].invalid && (form.controls['field_name'].touched || form.controls['field_name'].dirty) }"
                    [placeholder]="'field name' | translate"
                    class="font16 width100 resizeNone boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0 bdrRadius6px" />
            </div>

        </div>
        <div class="mrgntop20">
            <button type="button" [disabled]="form.invalid" (click)="addField()"
                class="btn-get-started minwidth100px btn-user-default bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815">{{'save' | translate}}</button>
        </div>
    </form>
</div>