<div class="bdr-1-EEEEEE posRelative {{userType.toLowerCase()}} 
boxShadow-RGBA00005 cursorPtr flexResponsive bg-FFFFFF bdrRadius6px pad10 flex flexAlignItemsCenter" [ngClass]="{'blue-img-shadow':selectedGroup?.includes(data.id)}" 

>
<div class="flex flexAlignItemsCenter flex2">
    <div class="cursorPtr">
        <span class="blue-img-shadow padtop8 padbtm3 padleft10 displayFlex bdrRadius50p posRelative">
            <img [src]="data.cover_photo" class="d60 bdrRadius50p brdr4Blue" />
        </span>
    </div>
    <div class="padleft10 cursorPtr">
        <div class="poppins-medium font14 timeline-label">{{ data.name }}</div>
        <div class="poppins-regular font12 color-8E8E8E">
            {{ 'members count' | translate: {count: data.members_count} }}</div>
    </div>
    <!-- <div class="width20 txtRight">
        <span class="d30 bg-EEEEEE bdrRadius50p displayFlex flexAlignItemsCenter flexJustifyCenter">

        </span>
    </div> -->
</div>
</div>