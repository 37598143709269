<div class="flex flexJustifyBetween">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'places lived' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/users/profile/place']" [queryParams]="{open: 'currentcity'}" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20"></i>
    </div>
</div>
<ng-container *ngIf="(data?.places_information?.current_city || data?.places_information?.home_town) && data?.places_information?.current_city?.name">

    <div class="flex  flexResponsive flexWrap">
        <div class="info-container bg-E5ECF1 bdrRadius6px flex mrgn5 pad10 width50 " *ngIf="data?.places_information?.current_city && showUserInfo(settings,'show_current_city')">
            <div class="d20">
                <i class="icns icon-map blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'current city' | translate}}
                </div>
                <div class="color-122A4D font16 poppins-medium">{{data?.places_information?.current_city?.name}}
                </div>
            </div>
        </div>
        <div class="info-container mrgn5 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.places_information?.home_town && showUserInfo(settings,'show_hometown')">
            <div class="d20">
                <i class="icns icon-map blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'home town' | translate}}</div>
                <div class="color-122A4D font16 poppins-medium">{{data?.places_information?.home_town}}
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="data?.places_information?.cities_lived_in && data?.places_information?.cities_lived_in?.length != 0 && showUserInfo(settings,'show_other_cities')">
    <div class="flex flexResponsive flexWrap flexJustifyBetween">
        <div class="info-container bg-E5ECF1 mrgn5 bdrRadius6px flex pad10 width50" *ngFor="let item of data?.places_information?.cities_lived_in">
            <div class="d20">
                <i class="icns icon-map blue d20"></i>
            </div>
            <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
                <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                    {{'moved in year' | translate: {year: item.moved_in | date: 'yyyy'} }}
                </div>
                <div class="color-122A4D font16 poppins-medium">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
</ng-container>

<app-empty-state [stateType]="'nofriends'" class=" width100" *ngIf="!data?.places_information?.current_city && !data?.places_information?.home_town && !(data?.places_information?.cities_lived_in && data?.places_information?.cities_lived_in?.length != 0)"></app-empty-state>