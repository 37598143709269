import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  // useRef() {
  //   throw new Error('Method not implemented.');
  // }

  public isLoading = new Subject<boolean>();

  constructor() { }

  /* Function to Enabling the loader Icon */
  show() {
    this.isLoading.next(true);
  }

  /* Function to Disabling the loader Icon */
  hide() {
    this.isLoading.next(false);
  }
}
