import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-instructional-video-management-popup',
  templateUrl: './instructional-video-management-popup.component.html',
  styleUrls: ['./instructional-video-management-popup.component.scss']
})
export class InstructionalVideoManagementPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor(
    private appService: AppService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  get getInstructionalVideoMemberManagementURI() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.appService.instructionalVideoMemberManagementURI);
  }
  closePopup() {
    this.close.emit('icon');
  }

}
