import { AuthService } from './auth-service/auth.service';
import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export interface Message {
  author: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationSocketService {
  public messages !: Subject<Message>;
  constructor(
    private websocketService: WebsocketService,
    private authService: AuthService,
    http: HttpClient,
  ) {
    if (this.authService.isLoggedIn())
    this.messages = <Subject<Message>>websocketService.connect(`wss://briind-api.fortelogic.in/ws/notifications/notification_user_${authService.getSessionData().orgId}/`).pipe(map(
      (response: MessageEvent): Message => {
        let data = JSON.parse(response.data);
        //console.log(data)
        return data
      }
    ));
  }
}
