<div class="flex flexJustifyBetween">
    <div class="color-122A4D poppins-semibold font20 mrgnleft5">{{'personal informations' | translate}}</div>
    <div *ngIf="showEditIcon" [routerLink]="['/users/profile/info']" class="d30 flex flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit blue d20" *ngIf="data"></i>
        <i class="icns d20 icon-plus blue" *ngIf="!data"></i>
    </div>
</div>
<div class="flex flexResponsive" *ngIf="data?.dob || data?.gender">
    <div class="info-container mrgn0510 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.gender && showUserInfo(settings,'show_gender')">
        <div class="d20">
            <i class="icns icon-user blue d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
            <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                {{'gender' | translate}}
            </div>
            <div class="color-122A4D font16 poppins-medium">{{data?.gender}}
            </div>
        </div>
    </div>
    <div class="info-container mrgn0510 bg-E5ECF1 bdrRadius6px flex pad10 width50" *ngIf="data?.dob && showUserInfo(settings,'show_dob')">
        <div class="d20">
            <i class="icns icon-birth-date blue d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
            <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                {{'date of birth' | translate}}</div>
            <div class="color-122A4D font16 poppins-medium" *ngIf="checkType(data?.dob)">{{data?.dob}}
            </div>
            <div class="color-122A4D font16 poppins-medium" *ngIf="!checkType(data?.dob)">{{data?.dob | date: 'dd-MM-yyyy'}}
            </div>
        </div>
    </div>
</div>
<div class="flex flexResponsive" *ngIf="data?.relationship_status && showUserInfo(settings,'show_relationship')">
    <div class="info-container bg-E5ECF1 mrgn0510 bdrRadius6px flex pad10 width50">
        <div class="d20">
            <i class="icns icon-relationship blue d20"></i>
        </div>
        <div class="padleft10 flex flexDirectionCol flexJustifyBetween">
            <div class="color-8E8E8E font16 poppins-regular lineHeight18px">
                {{'relationship status' | translate}}
            </div>
            <div class="color-122A4D font16 poppins-medium">
                {{data?.relationship_status}}
            </div>
        </div>
    </div>
    <div class="pad10 desktopVisible"></div>
    <div class="width50"></div>
</div>
<app-empty-state [stateType]="'nofriends'" class=" width100" *ngIf="!data"></app-empty-state>