import { AppStore } from './../../../../../core/stores/app.store';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-briindlet-event',
  templateUrl: './briindlet-event.component.html',
  styleUrls: ['./briindlet-event.component.scss']
})
export class BriindletEventComponent {
  @Output('stepperActions') stepperActions = new EventEmitter();
  @Output('closePopup') closePopup = new EventEmitter();
  @Input() briindletType: string = '';

  @Input() formGrp: any;
  public slectedEventType: string | any = 'yes';
  constructor(
    private router: Router,
    public appStore: AppStore,
    private appService: AppService
  ) { }

  /**
   * Click On Continue Button This method will call
   * This method is using for Navigate to corresponding sections
   *
   * @memberof BriindletEventComponent
   */
  continuteCTA(event: any) {
    if (this.briindletType == "createBriindltePro") {
      this.stepperActions.emit(event);
    } else if (this.briindletType == "createBriindlte") {
      if (this.slectedEventType == 'yes') {
        let queryParam: any = {
          event: 'event'
        }
        if (this.formGrp.value.step_14.cause) {
          queryParam['cause'] = this.formGrp.value.step_14.cause;
        }
        this.router.navigate(['/briindlet/create-event'], { queryParams: queryParam});
        this.closePopup.emit();
      } else {
        this.router.navigate(['/briindlet/create-event']);
        this.closePopup.emit();
      }
      
    }
  }

  /**
   * @description
   * This Method will call When Click on any Event Type
   *
   * @param {string} [accountType]
   * @memberof BriindletEventComponent
   */
  selectEventCTA(accountType?: string) {
    this.slectedEventType = accountType;
    this.formGrp.controls['step_1'].patchValue({
      is_event: this.slectedEventType == 'yes' ? 'True' : 'False'
    })
  }

  ngOnInit(): void {
    // this.appService.cacheData.subscribe(res=>{
    //   if(res){
    //     let steps:any = []
    //     steps = res
    //     this.slectedEventType = steps.step_1?.is_event == 'True' ? 'yes' : 'no'
    //     this.formGrp.controls['step_1'].patchValue({
    //       is_event: this.slectedEventType == 'yes' ? 'True' : 'False'
    //     })
    //   }
    // })
    
  }

}
