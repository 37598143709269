import { AppStore } from 'src/app/core/stores/app.store';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private appStore: AppStore
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe( (token) => {
      //console.log(token);
      this.appStore.notificationToken = token;
      },
      (err) => {
      console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
    (payload: any) => {
    //console.log("new message received. ", payload);
    this.currentMessage.next(payload);
    this.showCustomNotification(payload);
    })
  }


  showCustomNotification(payload: any) {
    let notifyData = payload['notification'];
    let title = notifyData['title'];
    let options = {
      body: notifyData['body'],
      icon: './src/assets/images/brind-logo-blue.png',
      badge: './src/assets/images/brind-logo-blue.png',
      image: './src/assets/images/brind-logo-blue.png'
    }

    let notify: Notification = new Notification(title, options);
    notify.onclick = event => {
      event.preventDefault();
      window.location.href = '/'
    }
  }
}
