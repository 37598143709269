import { SelectListModel } from "./form.model";

export class BriindletModel {
    cause?: string = '';
    created_at?: string = '';
    description?: string = '';
    event_from?: string = '';
    event_to?: string = '';
    expiry_timestamp?: string = '';
    fee?: string = '';
    id?: number = 0;
    is_event: boolean = false;
    is_pro: boolean = false;
    name?: string = '';
    organization?: number = 0;
    organization_link?: string = '';
    photo?: any = [];
    region?: string = '';
    registration_guidelines?: string = '';
    registration_last_date?: string = '';
    template_id?: number = 0;
    website_link?: string = '';
    who_can_attend?: string = '';
    guest?: any[] = [];
    price?: number = 0;
    template?: string = '';
    template_data?: string = '';
    user?: number = 0;
    venue?: SelectListModel = {};
}

export class RegionModel {
    id?: number = 0;
    name?: string = '';
    state?: any = [];
    city?: any = [];
}

export class VenueModel {
    id?: number = 0;
    name?: string = '';
}
export class AdvertisementModel {
    briindlet?: string = '';
    description?: string = '';
    start_date?: string = '';
    end_date?: string = '';
    id?: number = 0;
    is_paid: boolean = false;
    name?: string = '';
    organization?: number = 0;
    photo?: any = [];
    website_link?: string = '';
    price?: number = 0;
    user?: number = 0;
    audience?: string = '';
    is_cancelled?: boolean = false;
}

export class AdvertisementTimelineModel {
    data?: any[] = [];
    total_count?: number = 0;
    total_pages?: number = 0;
}