import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import { ApplicationSubmittedMembershipModel, EventVolunteerListModel, MembershipApplicationDetail, MembershipGroupList, RbacRoleModel, VolunteerManagementListModel } from '../../core/models/membership.model';
import {
  OrganizationModel, BussinessProfileModel, PostPhotosModel,
  PostModel, VideoModel, GroupModel, InvitesOrganizationModel,
  InvitesUserModel,
  OrganizationFollowersModel,
  UserModel,
  OrganizationOverviewModel,
  OrganizationCauses
} from 'src/app/core/models';
import { ApplicationSubmittedActions, organizationMemberAction } from 'src/app/core/mock-api/actions';
import { RolesModel } from '../../core/models/role.model';
import { SearchModel } from 'src/app/core/models/search.model';
import { PromotionPriceModel } from 'src/app/core/models/promotion.model';
import { OrganizationPromotionModel } from 'src/app/core/models/organizationpromotion.model';
import { PaymentModel } from 'src/app/core/models/payment.model';
import { TrendingOrganizationModel } from 'src/app/core/models/trending-organization.model';
import { GeneralSettingsModel } from 'src/app/core/models/generalsettings.model';
import { PrivacySettingsModel } from 'src/app/core/models/privacysettings.model';
import { MenuCustomizationModel } from 'src/app/core/models/menucustomization.model';
import { AdvertisementTimelineModel } from 'src/app/core/models/briindlet.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationStoreService {
  @observable apiResp: any;
  @observable organizationList: OrganizationModel[] = [];
  @observable invitesOrganizationList: InvitesOrganizationModel[] = [];
  @observable organizationDetails: OrganizationModel = new OrganizationModel();
  @observable profileDetail: OrganizationModel = new OrganizationModel();
  // for other Id
  @observable otherProfileDetail: any = new UserModel();
  @observable postDetails: PostModel = new PostModel();
  @observable organizationId: any = 0;
  @observable organizationBriindId: any = 0;
  @observable organizationBussinessDetails: BussinessProfileModel = new BussinessProfileModel();
  @observable organizationVideoList: VideoModel[] = [];
  @observable photoList: PostPhotosModel[] = [];
  @observable albumList: PostModel[] = [];
  @observable postList: PostModel[] = [];
  @observable groupList: GroupModel[] = [];
  @observable groupDetail: GroupModel = new GroupModel();
  @observable trendingOrganizationsList: TrendingOrganizationModel = new TrendingOrganizationModel();
  @observable invitesUserList: InvitesUserModel[] = [];
  @observable membershipApplicationSubmitted: ApplicationSubmittedMembershipModel[] = [];
  @observable membershipDetail: MembershipApplicationDetail = new MembershipApplicationDetail();
  @observable membershipRoleList: RolesModel[] = [];
  @observable organizationFollowersList: OrganizationFollowersModel[] = [];
  @observable mostLikedPostList: PostModel = new PostModel();
  @observable mostSharedPostList: PostModel = new PostModel();
  @observable topHashTag: PostModel[] = [];
  @observable topHashTagData: PostModel = new PostModel();
  @observable getHashTagData: PostModel =new PostModel();
  @observable memberInvitedList: ApplicationSubmittedMembershipModel[] = [];
  @observable organizationMembershipGroupList: MembershipGroupList[] = []
  @observable organizationVolunteerList: VolunteerManagementListModel[] = [];
  @observable organizationEventDetail: VolunteerManagementListModel = new VolunteerManagementListModel();
  @observable eventVolunteerList: EventVolunteerListModel[] = [];
  @observable pendingInvitationList: ApplicationSubmittedMembershipModel[] = [];
  @observable searchUserList: UserModel[] = [];
  @observable invitationManagementUserList: ApplicationSubmittedMembershipModel[] = [];
  @observable searchModel: SearchModel = new SearchModel();
  @observable causesList: OrganizationCauses[] = [];
  @observable promotionPriceModel: PromotionPriceModel = new PromotionPriceModel();
  @observable organizationPromotionList: OrganizationPromotionModel[] = [];
  // @observable memberShipDetail:ApplicationSubmittedMembershipModel = new ApplicationSubmittedMembershipModel();
  @observable paymentModel: PaymentModel = new PaymentModel();
  // @observable PaymentModel:ApplicationSubmittedMembershipModel = new ApplicationSubmittedMembershipModel();
  @observable generalSettingsModel: GeneralSettingsModel = new GeneralSettingsModel();
  @observable privacySettingsModel: PrivacySettingsModel = new PrivacySettingsModel();
  @observable menuCustomizationModel: MenuCustomizationModel = new MenuCustomizationModel();
  @observable advertisementTimelineList: AdvertisementTimelineModel = new AdvertisementTimelineModel();
  @observable rbacRole: RbacRoleModel = new RbacRoleModel();
  constructor() { }

  /**
   * @description
   * setting organization data
   *
   * @memberof OrganizationStoreService
   */
  @action getOrganizationDetails() {
    this.organizationDetails = this.apiResp.result || this.apiResp;
    // this.profileDetail = this.apiResp.result || this.apiResp;
    this.organizationId = this.organizationDetails.id;
    this.organizationBriindId = this.organizationDetails.briind_id;
  };

  @action getOrganizationProfileDetails() {
    this.profileDetail = this.apiResp.result || this.apiResp;
  };

  @action getOtherOrganizationProfileDetails() {
    this.otherProfileDetail = this.apiResp.result || this.apiResp;
  };

  @action setMemberRole() {
    this.rbacRole = this.apiResp;
  };


  /**
   * @description
   * setting organization profile pic and cover photo data
   *
   * @memberof OrganizationStoreService
   */
  @action organizationUpdateProfilePic() {
    this.organizationDetails.profile_pic = this.apiResp.result.profile_pic;
    this.organizationDetails.cover_photo = this.apiResp.result.cover_photo;
  }

  /**
   * @description
   * setting organization social profile
   *
   * @memberof OrganizationStoreService
   */
  @action setSocialProfile() {
    this.profileDetail.social_profile = this.apiResp.result;
  }

  /**
   * @description
   * setting organization social Media
   *
   * @memberof OrganizationStoreService
   */
  @action setSociaMedia() {
    this.profileDetail.social_media_info = this.apiResp.result || this.apiResp;
  }



  /**
   * @description
   * setting organization basic info profile
   *
   * @memberof OrganizationStoreService
   */
  @action setBasicProfile() {
    this.profileDetail.basic_social_info = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting organization milestone list
   *
   * @memberof OrganizationStoreService
   */
  @action setMilestoneList() {
    this.profileDetail.milestone_info_list = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting organization services list
   *
   * @memberof OrganizationStoreService
   */
  @action setServicesList() {
    this.profileDetail.services_info_list = this.apiResp;
  }

  /**
   * @description
   * setting organization leaders list
   *
   * @memberof OrganizationStoreService
   */
  @action setLeaders() {
    this.profileDetail.leaders_list_info = this.apiResp;
  }

  /**
   * @description
   * setting organization overview list
   *
   * @memberof OrganizationStoreService
   */
  @action overview() {
    const { door_no, street, city, state, country, zip_code} = this.apiResp.overview
    this.profileDetail.overview = this.apiResp;
    this.profileDetail.overviewAddress = door_no + ' ' + street + ', ' + city + ', ' + state + ', ' + zip_code + ' ' + country
  }


  /**
   * @description
   * setting organization district or state
   *
   * @memberof OrganizationStoreService
   */
  @action setStateOrDistrict() {
    this.organizationBussinessDetails.branch_invite_list = this.apiResp
  }

  /**
   * @description
   * setting organization Ministries list
   *
   * @memberof OrganizationStoreService
   */
  @action setMinistriesList() {
    this.organizationBussinessDetails.ministries_list = this.apiResp
  }

  /**
   * @description
   * setting organization New to Us Detailss
   *
   * @memberof OrganizationStoreService
   */
  @action setInfoDetails() {
    this.organizationBussinessDetails.new_to_us_info = this.apiResp
  }

  /**
   * @description
   * setting organization New to Us Detailss
   *
   * @memberof OrganizationStoreService
   */
  @action setAboutInfoDetails() {
    this.organizationBussinessDetails.about_info = this.apiResp
  }

  /**
   * @description
   * setting organization New to Us Detailss
   *
   * @memberof OrganizationStoreService
   */
  @action setMembershipTemplate() {
    this.organizationBussinessDetails.member_ship_template = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting organization Give Detailss
   *
   * @memberof OrganizationStoreService
   */
  @action setGiveInfoDetails() {
    this.organizationBussinessDetails.give_info.account_list = this.apiResp[0];
    this.organizationBussinessDetails.give_info.causes_list = this.apiResp[1]
  }

  /**
   * @description
   * setting organization photos information
   *
   * @memberof OrganizationStoreService
   */
  @action getPhotosList() {
    this.photoList = this.apiResp;
  }

  /**
  * @description
   * setting organization list for user
   *
   * @memberof OrganizationStoreService
   */
  @action getVideoList() {
    this.organizationVideoList = this.apiResp
  }

  /**
   * @description
   * setting organization albul List information
   *
   * @memberof OrganizationStoreService
   */
  @action getAlbumList() {
    this.albumList = this.apiResp;
  }

  /**
   * @description
   * setting user Post List
   *
   * @memberof OrganizationStoreService
   */
  @action getPostList() {
    this.postList = this.apiResp;
  }

  /**
   * @description
   * setting user Post List
   *
   * @memberof OrganizationStoreService
   */
  @action getOrganizationHomeContent() {
    this.postList = [];
    this.trendingOrganizationsList = new TrendingOrganizationModel();
    this.postList = this.apiResp[0];
    this.trendingOrganizationsList = this.apiResp[1];
    this.advertisementTimelineList = this.apiResp[2]

    this.mostLikedPostList = this.apiResp[3].most_liked_post  ? this.apiResp[3].most_liked_post : {};
    this.mostSharedPostList = this.apiResp[3].most_shared_post  ? this.apiResp[3].most_shared_post : {};
  }

  @action setTimelinePageContent() {
    this.postList = this.apiResp[0];
    this.profileDetail.overview = this.apiResp[1] ? this.apiResp[1].overview : {};
    this.photoList = this.apiResp[2];
  }

  /**
   * @description
   * setting trending organization list for organization
   *
   * @memberof OrganizationStoreService
   */
  @action getTrendingOrganizationsList() {
    this.trendingOrganizationsList = this.apiResp
  }


  /**
   * @description
   * setting user Group List
   *
   * @memberof OrganizationStoreService
   */
  @action getGroupList() {
    this.groupList = this.apiResp;
  }

  /**
    * @description
    * setting user data
    *
    * @memberof OrganizationStoreService
    */
  @action getGroupDetails() {
    this.groupDetail = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting user data
   *
   * @memberof OrganizationStoreService
   */
  @action setGroupDetailsPage() {
    this.groupDetail = this.apiResp[0];
    this.postList = this.apiResp[1];

  }

  /**
   * @description
   * setting Organization List information
   *
   * @memberof OrganizationStoreService
   */
  @action getOrganizationList() {
    this.organizationList = this.apiResp;
  }

  /**
   * @description
   * Invites Organizations List
   *
   * @memberof OrganizationStoreService
   */
  @action getInviteOrganizationList() {
    this.invitesOrganizationList = this.apiResp;
  }

  /**
   * @description
   * setting Group Detail Page Data
   *
   * @memberof UserStoreService
   */
  @action setGroupDetailPage() {
    this.groupDetail = this.apiResp[0];
    this.postList = this.apiResp[1];
    this.invitesUserList = this.apiResp[2];
  }

  /**
   * @description
   * setting Group Detail Page Data
   *
   * @memberof UserStoreService
   */
  @action setMembershipApplicationSubmitted() {
    let response = this.apiResp;
    for (let item of response) {
      item.actions = ApplicationSubmittedActions;
    }
    this.membershipApplicationSubmitted = response;
  };

  /**
   * @description
   * setting Application Detail Page Data
   *
   * @memberof UserStoreService
   */
  @action setMembershipDetailPage() {
    this.membershipDetail = this.apiResp;
  }


  /**
   * @description
   * setting RBAC Page Data
   *
   * @memberof UserStoreService
   */
  @action setMembershipRbacPage() {
    this.membershipRoleList = this.apiResp;
  }

  /**
 * @description
 * setting shared and tagged posts for organization
 *
 * @memberof OrganizationStoreService
 */
  @action setSharedAndTaggedPosts() {
    this.postList = this.apiResp
  }

  /**
   * @description
   * organization followers list
   *
   * @memberof OrganizationStoreService
   */
  @action getOrganizationFollowersList() {
    //console.log("Followers list")
    //console.log(this.apiResp.result || this.apiResp)
    this.organizationFollowersList = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * getting Most Liked Post List
   *
   * @memberof OrganizationStoreService
   */
  @action getMostLikedPostList() {
    this.mostLikedPostList = this.apiResp;
  }

  /**
   * @description
   * setting Group Detail Page Data
   *
   * @memberof UserStoreService
   */
  @action setMembersManagementPage() {
    let response = this.apiResp;
    const mutedMemberActions = organizationMemberAction.filter((x: any) => x.action_text.toLowerCase().split(" ").join("") != 'blockmember')
    const unmutedMemberActions = organizationMemberAction.filter((x: any) => x.action_text.toLowerCase().split(" ").join("") != 'unblockmember')
    for (let item of response) {
      if (item.is_muted) {
        item.actions = mutedMemberActions;
      } else {
        item.actions = unmutedMemberActions;
      }
    }
    this.memberInvitedList = response;
  }

  /**
   * @description
   * getting Most Shared Post List
   *
   * @memberof OrganizationStoreService
   */
  @action getMostSharedPostList() {
    this.mostSharedPostList = this.apiResp;
  }

  @action getHasTagList(){
    this.topHashTag = this.apiResp;
  }

  @action getHasTagData(){
    this.topHashTagData = this.apiResp;
  }

  // @action getHashTagData(){
  //   this.getHashTagData = this.apiResp;
  // }

  /**
   * @description
   * setting Membership Group List
   *
   * @memberof OrganizationStoreService
   */
  @action setMembershipGroupPage() {
    this.organizationMembershipGroupList = this.apiResp.result || this.apiResp;
  }

  /**
   * @description
   * setting Organization Membership Volunteer List
   *
   * @memberof OrganizationStoreService
   */
  @action setMembershipVolunteerPage() {
    this.organizationVolunteerList = this.apiResp;
  }

  /**
   * @description
   * setting Organization Membership Event Details
   *
   * @memberof OrganizationStoreService
   */
  @action setEventDetailPage() {
    this.organizationEventDetail = this.apiResp;
  }

  /**
   * @description
   * setting Organization Membership Event volunteer list
   *
   * @memberof OrganizationStoreService
   */
  @action setEventVolunteerPage() {
    this.eventVolunteerList = this.apiResp;
  }

  @action setMembershipInvotationPage() {
    this.searchUserList = this.apiResp[0]
    this.pendingInvitationList = this.apiResp[1]
  }

  @action setInvitationManagementInvitedPage() {
    for (const item of this.apiResp) {
      if (item.status == 'Invited') {
        item.isChecked = true;
      }
    }
    this.invitationManagementUserList = this.apiResp
  }


  @action setInvitationManagementFollowersPage() {
    this.invitationManagementUserList = this.apiResp
  }


  @action setSearchResult() {
    this.searchModel = this.apiResp
  }

  /**
     * @description
     * getting Most Shared Post List
     *
     * @memberof OrganizationStoreService
     */
  @action getCausesList() {
    this.causesList = this.apiResp;
  }

    /**
     * @description
     * setting organization promotion price
     *
     * @memberof OrganizationStoreService
     */
     @action setOrganizationPromotionPrice() {
      this.promotionPriceModel = this.apiResp;
    }


    /**
     * TODO: comment action
     * @description Actions organization store service
     * @memberof OrganizationStoreService
     */
    @action resetOrganizationPromotionPrice() {
      this.promotionPriceModel = new PromotionPriceModel();
    }


    /**
     * TODO: comment action
     * @description Actions organization store service
     * @memberof OrganizationStoreService
     */
    @action setOrganizationPromotionList() {
      this.organizationPromotionList = this.apiResp;
    }


  /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof OrganizationStoreService
   */
  @action setPaymentSuccess() {
    this.paymentModel = this.apiResp;
  }

  
  /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof OrganizationStoreService
   */
  @action setUserSettings() {
    this.organizationDetails = this.apiResp;
  }


  /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof OrganizationStoreService
   */
  @action setOrganizationGeneralSettings(){
    this.generalSettingsModel = this.apiResp;
  }


  /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof OrganizationStoreService
   */
  @action setOrganizationPrivacySettings(){
    this.privacySettingsModel = this.apiResp.data|| this.apiResp;
  }


  /**
   * TODO: comment action
   * @description Actions organization store service
   * @memberof OrganizationStoreService
   */
  @action setOrganizationMenuCustomization(){
    this.menuCustomizationModel = this.apiResp.result || this.apiResp;
  }

  /**
   * TODO: comment action
   * @description Actions user store service
   * @memberof UserStoreService
   */
   @action getPostDetails(){
    this.postDetails = this.apiResp;
  }
}
