import { Injectable } from '@angular/core';
import { action, observable } from 'mobx-angular';
import { SelectListModel } from 'src/app/core/models';
import { BriindletModel } from 'src/app/core/models/briindlet.model';

@Injectable({
  providedIn: 'root'
})
export class BriindletStoreService {
  @observable apiResp: any;
  @observable briindletList: BriindletModel[] = [];
  @observable venueList: SelectListModel[] = [];
  @observable briindletDetails: BriindletModel = new BriindletModel();
  constructor() { }

  /**
   * @description
   * setting briindlets list
   *
   * @memberof BriindletStoreService
   */
   @action getBriindletList() {
    this.briindletList = this.apiResp;
  }

  /**
   * @description
   * setting venue list
   *
   * @memberof BriindletStoreService
   */
   @action getVenue() {
    this.venueList = this.apiResp;
   }
   
   /**
   * @description
   * setting briindlet data
   *
   * @memberof BriindletStoreService
   */
   @action getBriindletDetails() {
    this.briindletDetails = this.apiResp;
  }
}
