import { Component, OnInit, Output , EventEmitter, Input} from '@angular/core';
import { PopupModel } from 'src/app/core/models/popup.model';

@Component({
  selector: 'app-show-group-members-popup',
  templateUrl: './show-group-members-popup.component.html',
  styleUrls: ['./show-group-members-popup.component.scss']
})
export class ShowGroupMembersPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor() { }

  closePopup() {
    this.close.emit("icon");
  }

  ngOnInit(): void {
  }

}
