<section id="show-group-member" class="bdrRadius6px bg-white padright10 pad20 posRelative custom-scrollbar organization">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="width100">
        <div class="poppins-semibold font22 timeline-label padbtm20 padright10 txtCenter ">
            {{'member list' | translate}}
        </div>
        <div class="width100 flex flexWrap flexResponsive">
          <div class="width50 padbtm10 padright10 pointerEventsNone" *ngFor="let item of popup?.context?.membersList">
            <app-general-card [type]="'OrganizationGroupMembers'" [actions]="[]" [data]="item"></app-general-card>
          </div>

     
        </div>
        <div class="width100 flex flexWrap flexResponsive flexJustifyCenter">

        <app-empty-state *ngIf="popup?.context?.membersList?.length==0">
        </app-empty-state>
        </div>
    </div>
</section>
