import { AuthService } from './../../../../../core/services/auth-service/auth.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-briindlet-event-stripe',
  templateUrl: './briindlet-event-stripe.component.html',
  styleUrls: ['./briindlet-event-stripe.component.scss']
})
export class BriindletEventStripeComponent implements OnInit {
  @Output('close') close = new EventEmitter();
  @Output('stepperActions') stepperActions  = new EventEmitter();
  @ViewChild(StripeCardComponent)
  public card!: StripeCardComponent;
  @Input() briindletProId: any;
  @Input() data: any;
  @Input() customizeUI: any;
  @Input() causeID: any;
  subscription: Subscription = new Subscription();
  stripeTest!: FormGroup;
  paymentStatus: string = '';
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  errorStatus: boolean = false;
  errorMessage: any;
  constructor(private fb: FormBuilder, 
    public stripeService: StripeService, 
    public organizationService: OrganizationService, 
    public organizationStoreService: OrganizationStoreService,
    private router: Router,
    public appService: AppService, 
    private authService: AuthService,
    public appStore: AppStore) { }

  ngOnInit(): void {
    // this.close.emit('icon');
    // this.router.navigate(['/briindlet/banner']);
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  closePopup() {
    if(this.paymentStatus ) {
      if (!this.causeID) {
        this.router.navigate(['/briindlet/banner']);
      }
      this.close.emit('close')
    } else {
      this.close.emit('close')
    }
    
  }


  /**
   * @description
   * this method is used for pay briindlet pro
   *
   * @memberof BriindletEventStripeComponent
   */
  payBriindletPro(): void {
    if (this.stripeTest.valid) {
    const name = this.stripeTest?.get('name')?.value;
    this.stripeService.stripe
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          let data = { card_token: result.token.id, briindlet_pro_id: this.briindletProId, amount: this.data.template_amount };
          // let token ="tok_1IqyM7FfJiy2k2XhSVCHIRx7";
          // let data = { card_token: token, briindlet_pro_id: this.briindletProId, amount: this.data.template_amount };

          this.subscription.add(
            this.organizationService.briindletProPayment(data).subscribe((response: any) => {
              if (response.status.toLowerCase() == "success") {
                this.appStore.setPaymentSuccess();
                this.appService.toggleToast({
                  type: 'success',
                  load: true,
                  position: 'bottom-left',
                  context: { description: 'Payment successfull' }
                });
                this.paymentStatus = 'success';
                // this.router.navigate(['/briindlet/banner']);
              } else if (response.status.toLowerCase() == "failure") {
                this.appService.toggleToast({
                  type: 'failure',
                  load: true,
                  position: 'bottom-left',
                  context: { description: 'Payment failed' }
                });
                this.paymentStatus = 'failed';
              }
            })
          );
        } else if (result.error) {
          this.errorStatus=true
          this.errorMessage =result.error.message;
        }
      });
    }
  }
  

  /**
  * @description
  * Called once, before the instance is destroyed.
  * Add 'implements OnDestroy' to the class.
  *
  * @memberof BriindletEventStripeComponent
  */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
