import { Component, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';

@Component({
  selector: 'app-add-organization-ministry-leader',
  templateUrl: './add-organization-ministry-leader.component.html',
  styleUrls: ['./add-organization-ministry-leader.component.scss']
})
export class AddOrganizationMinistryLeaderComponent implements OnDestroy {
  @Output() getUserData = new EventEmitter();
  @Output() getUserList = new EventEmitter();
  @Input() title : string = '';
  briindId: string = '';
  subscription: Subscription = new Subscription();
  errorStatus: any = '';
  addMinistryLeaderForm: FormGroup = new FormGroup({});
  imageSrc = 'assets/images/add-pic-layer.png';
  @Input() userList: any = [];
  designation: any = '';
  inputFile: any;
  @Input() isMultipleUserAllow: boolean = true; 
  isDisabled: boolean = false;
  constructor(
    private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService,
    private fb: FormBuilder
  ) {
    this.addMinistryLeaderForm = fb.group({
      first_name: new FormControl('',[Validators.required]),
      designation: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      contact_no: new FormControl('',[Validators.required]),
    })
  };

  /**
   * @description
   * this method is used removing card
   *
   * @param {*} [event]
   * @memberof AddOrganizationMinistryLeaderComponent
   */
  removeItem(event ?: any) {
    this.userList = this.userList.filter((x: any) => x.user?.id != event)
    this.userList.length != 0 ? (this.isMultipleUserAllow ?  this.getUserData.emit(this.userList.map((x: any) => ({"id": x.user?.id, "designation": x.designation}))) : this.getUserData.emit( {"id": this.userList[0].user?.id, "designation": this.userList[0]?.designation} )) :  this.getUserData.emit('');
    this.getUserList.emit(this.userList)
  }


  /**
   * @description
   * this method will call when click on save button
   *
   * @memberof AddOrganizationMinistryLeaderComponent
   */
  formSubmit() {
    if (this.userList && this.userList.length != 0) {
      for (const item of this.userList) {
        if (item.user?.email == this.addMinistryLeaderForm?.value?.email) {
          this.errorStatus = 'already added user';
          return false;
        }
      }
    }

    this.briindId = '';
    this.designation = '';
    let formData = new FormData();
   
    for (const key in this.addMinistryLeaderForm.value) {
      formData.append(key, this.addMinistryLeaderForm.value[key])
    }
    if (this.inputFile) {
      formData.append('profile_pic', this.inputFile)
    }
    this.createUserOrCheckBriind(formData);
  }

  /**
   * @description
   * this method is used for uploading image
   *
   * @param {*} [event]
   * @memberof AddOrganizationMinistryLeaderComponent
   */
  uploadImage(event ?:any) {
    if (event) {
      this.inputFile = event.file
    }
  }

  /**
   * @description
   * this method is used for check brind id is available or not
   *
   * @memberof AddOrganizationMinistryLeaderComponent
   */
  checkBriindId() {
    const param = {search_param: this.briindId};
    if (this.userList && this.userList.length != 0) {
      for (const item of this.userList) {
        if (item.user?.briind_id == this.briindId || item.user?.email == this.briindId) {
          this.errorStatus = 'already added user';
          return false;
        }
      }
    }
    
    this.createUserOrCheckBriind(param);
    this.addMinistryLeaderForm.reset();
    this.inputFile = '';
    this.imageSrc = 'assets/images/add-pic-layer.png';
  }




  /**
   * @description
   * this method is used for createUserOrCheckBriind
   *
   * @param {*} [param]
   * @memberof AddOrganizationMinistryLeaderComponent
   */
  createUserOrCheckBriind(param ?: any) {
    this.isDisabled = true;
    this.errorStatus = '';
    this.subscription.add(
      this.organizationService.createMinistryLeaders(param).subscribe((response: any) => {
        this.isDisabled = false;
        let item = {
          user: response['result'],
          designation: ''
        }
        if (this.briindId) {
          item['designation'] = this.designation
        } else {
          item['designation'] = this.addMinistryLeaderForm?.value?.designation
        }
        if (this.isMultipleUserAllow) {
          this.userList.push(item);
          // this.getUserData.emit(this.userList.map((x: any) => x.id));
        } else {
          this.userList = [item];
          // this.getUserData.emit(this.userList[0].id);
        }

        this.getUserData.emit(this.userList.map((x: any) => ({"id": x.user?.id, "designation": x.designation})));
        this.getUserList.emit(this.userList)
        this.briindId = ''; 
        this.designation = '';
        this.addMinistryLeaderForm.reset();
        this.inputFile = '';
        this.imageSrc = 'assets/images/add-pic-layer.png';
        
      }, err => {
        this.errorStatus = err.error.message;
        this.isDisabled = false;
      })
    )
  }

  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   *
   * @memberof AddOrganizationMinistryLeaderComponent
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
