import { Injectable } from '@angular/core';
import { MetaTag } from '../models/meta-tag';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UpdateMetaTagService {
  private urlMeta: string = "og:url";
  private titleMeta: string = "og:title";
  private descriptionMeta: string = "og:description";
  private imageMeta: string = "og:image";
  private secureImageMeta: string = "og:image:secure_url";

  constructor(private metaService: Meta) { } 

  /* Set setSocialMediaTags */
  public setSocialMediaTags(url: string, title: string, description: string, image: string): void {
    // var imageUrl = `https://images.codinghub.net/${image}`;
    var tags = [
      new MetaTag(this.urlMeta, url),
      new MetaTag(this.titleMeta, title),
      new MetaTag(this.descriptionMeta, description),
      new MetaTag(this.imageMeta, image),
      new MetaTag(this.secureImageMeta, image)
    ];
    this.setTags(tags);
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      this.metaService.updateTag({ property: siteTag.name, content: siteTag.value });
    });
  }
}