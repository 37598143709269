<section *ngIf="comingSoonType == 'users'" id="user-signin-page" 
    class="user-auth-bg minHeight100vh pad15 
    overflowAuto">
    <h3 class="width100 mrgntop250 font40 color-FFFFFF mobi-color-white txtCenter 
    padtop5 fontWeight600poppins-regular">
    Coming Soon...</h3>
    <div class="flex flexJustifyCenter mrgntopM25">
      <button class="btn-get-started btn-02457A color-122A4D poppins-medium cancel-btn 
      minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815" 
      (click)="backButton()">Back to Previous</button>
    </div> 
</section>


<section  *ngIf="comingSoonType == 'organization'" id="organization-signin-page"  
    class="organization-auth-bg minHeight100vh pad15 
    overflowAuto">
    <h3 class="width100 mrgntop250 font40 color-FFFFFF mobi-color-white txtCenter 
    padtop5 fontWeight600poppins-regular">
    Coming Soon...</h3>
    <div class="flex flexJustifyCenter mrgntopM25">
      <button class="btn-get-started btn-18878C color-122A4D poppins-medium cancel-btn 
      minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815" 
      (click)="backButton()">Back to Previous</button>
    </div> 
</section>



<section  *ngIf="comingSoonType == 'main'" id="organization-signin-page"    
    class="bg-02457A minHeight100vh pad15 
    overflowAuto">
    <h3 class="width100 mrgntop250 font40 color-FFFFFF mobi-color-white txtCenter 
    padtop5 fontWeight600poppins-regular">
    Coming Soon...</h3>
    <div class="flex flexJustifyCenter mrgntopM25">
      <button class="btn-get-started btn-02457A color-122A4D poppins-medium cancel-btn 
      minwidth100px bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad0815" 
      (click)="backButton()">Back to Previous</button>
    </div> 
</section>