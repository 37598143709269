<div id="user-preview" class="bdrRadius6px bg-white pad40 posRelative">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="header color-122A4D font25 lineHeight40px poppins-semibold txtCenter">
        {{'user profile' | translate}}
    </div>
    <div class="body maxHeight520px overflowAuto custom-scrollbar posRelative">
        <div id="profile-cover" class="cover-image bgSizeCover bgRepeat-noRepeat flex posSticky zIndex1" style="background-image: url({{popup?.context?.cover_photo}})">
            <div class="height100p bg-00000094 width100 pad15 bdrRadius4px">
                <div class="flex flexResponsive flexAlignItemsCenter">
                    <div class="cover-profile-image posRelative flex flexJustifyCenter">
                        <span class="online-img-shadow bdrRadius50p posRelative displayFlex">
                            <img [src]="popup?.context?.profile_pic" class="d150 bdrRadius50p">
                        </span>
                    </div>
                    <div class="pad10"></div>
                    <div class="profile-cover-display-name width100">
                        <div class="widt70">
                            <div class="poppins-semibold color-FFFFFF font20 txtTransformCaps">
                                {{popup?.context?.name}}
                            </div>
                            <div class="color-FFFFFF poppins-regular font14">
                                {{popup.context?.about_me}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pad10"></div>
        <div class="boxShadowRGBA000005 bdr-1-EEEEEE bdrRadius6px flex">
            <div class="width30 bg-E5ECF1 pad10 desktopVisible">
                <div class="color-02457A poppins-medium bg-white bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple" [ngClass]="{'bg-white': popup?.context?.pathName == 'info'}">
                    {{'personal informations' | translate}}</div>
                <div [ngClass]="{'bg-white': popup?.context?.pathName == 'work'}" class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                    {{'work and education' | translate}}</div>
                <div [ngClass]="{'bg-white': popup?.context?.pathName == 'place'}" class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                    {{'places lived' | translate}}</div>
                <div [ngClass]="{'bg-white': popup?.context?.pathName == 'contact-info'}" class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                    {{'contact and basic info' | translate}}</div>
                <div [ngClass]="{'bg-white': popup?.context?.pathName == 'interest'}" class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                    {{'interests' | translate}}</div>
            </div>
            <div class="width70 pad15">
                <ng-container [ngSwitch]="popup?.context?.pathName">

                    <!-- profile information start here -->
                    <ng-container *ngSwitchCase="'info'">
                        <app-profile-preview [data]="popup.context"></app-profile-preview>
                    </ng-container>
                    <!-- profile information end here -->

                    <!-- work and education start here-->
                    <ng-container *ngSwitchCase="'work'">
                        <app-work-education-preview [data]="popup.context"></app-work-education-preview>
                    </ng-container>
                    <!-- work and education end here-->

                    <!-- contact info preview start here -->
                    <ng-container *ngSwitchCase="'contact-info'">
                        <app-contact-info-preview [data]="popup.context"></app-contact-info-preview>
                    </ng-container>
                    <!-- contact info preview end here -->

                    <!-- places lived preview start here -->
                    <ng-container *ngSwitchCase="'place'">
                        <app-places-lived-preview [data]="popup.context"></app-places-lived-preview>
                    </ng-container>
                    <!-- places lived preview end here -->

                    <!--interest preview start here -->
                    <ng-container *ngSwitchCase="'interest'">
                        <app-interest-preview [data]="popup.context"></app-interest-preview>
                    </ng-container>
                    <!-- interest preview end here -->
                    <p *ngSwitchDefault>

                    </p>
                </ng-container>




            </div>
        </div>
    </div>
</div>