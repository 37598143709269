<span [ngSwitch]="type">
    <div *ngSwitchCase="'default'">
        <div class="card posRelative">
            <div class="card__image loading"></div>
            <div class="card__title"></div>
            <!-- 
            <div class="card__description loading"></div> -->
        </div>
    </div>
    <div *ngSwitchCase="'chat'" class="flex flexJustifyCenter flexAlignItemsCenter mrgntop15">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
    </div>
    <!-- <p *ngSwitchCase="false">

</p>
<p *ngSwitchDefault>

</p> -->
</span>