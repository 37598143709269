import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../../../core/services/app.service';
import { OrganizationStoreService } from '../../../../../organizations/store/organization-store.service';

@Component({
  selector: 'app-organization-give-preview',
  templateUrl: './organization-give-preview.component.html',
  styleUrls: ['./organization-give-preview.component.scss']
})
export class OrganizationGivePreviewComponent implements OnInit {
  @Input('data') data: any;
  @Input('isShowEdit') isShowEdit:boolean = false;
  constructor(
    private appService: AppService,
    private organizationStoreService: OrganizationStoreService
  ) { }

  /**
   * @description
   * this method is used for open cause edit
   *
   * @memberof OrganizationGivePreviewComponent
   */
  openCauseEditPopup() {
    this.appService.togglePopup({
      type: 'editCause',
      load: true,
      refreshParent: false,
      overlayClose: true,
      context: {
        account_list: this.organizationStoreService.organizationBussinessDetails.give_info.account_list,
        selected_cause: this.data
      }
    });
  }

  ngOnInit(): void {
  }

}
