export const defualtTemplate: any = {
    event_title: {
      title: {
        font: 15,
        font_family: 'poppins-medium',
        color: '#ffffff',
        header_title: 'event title'
      },
      description: {
        font: 15,
        font_family: 'poppins-regular',
        color: '#122a4d',
        header_title: 'event description'
      }  
    },
    guest_speakers: {
      speakers_title: {
        font: 16,
        font_family: 'poppins-regular',
        color: '#122a4d',
        header_title: 'guest speakers title'
      },
      speakers_name: {
        font: 15,
        font_family: 'poppins-medium',
        color: '#122a4d',
        header_title: 'speakers name'

      },
      who_can_attend_title: {
        font: 16,
        font_family: 'poppins-regular',
        color: '#122a4d',
        header_title: 'who can attend'
      },
      who_can_attend_description: {
        font: 15,
        font_family: 'poppins-regular',
        color: '#122a4d',
        header_title: 'description'
      }
    },
    registration_guideline: {
      title: {
        font: 16,
        font_family: 'poppins-medium',
        color: '#122a4d',
        header_title: 'registration guidelines title'
      },
      description: {
        font: 15,
        font_family: 'poppins-regular',
        color: '#122a4d',
        header_title: 'description'
      }
    },
    registration_fee: {
      title: {
        font: 16,
        font_family: 'poppins-medium',
        color: '#122a4d',
        header_title: "registration fees title"
      },
      value: {
        font: 15,
        font_family: 'poppins-regular',
        color: '#122a4d',
        header_title: "value"
      }
    },
    date: {
      date: {
        font: 15,
        font_family: 'poppins-medium',
        color: '#ffffff',
        header_title: "date"
      }
    },
    venue_and_time: {
      venue: {
        font: 15,
        font_family: 'poppins-medium',
        color: '#ffffff',
        header_title: "venue"
      },
      time: {
        font: 15,
        font_family: 'poppins-medium',
        color: '#ffffff',
        header_title: "time"
      }
    },
    button_customization: {
      view_profile: {
        button_name: "view profile",
        font: 15,
        font_family: 'poppins-medium',
        color: '#ffffff',
        background_color: "#122a4d",
        header_title: "view profile button"
      },
      donate_now: {
        button_name: "donate now",
        font: 15,
        font_family: 'poppins-medium',
        color: '#ffffff',
        background_color: "#122a4d",
        header_title: "donate now button"
      },
      website: {
        button_name: "Register here",
        font: 15,
        font_family: 'poppins-medium',
        color: '#ffffff',
        background_color: "#122a4d",
        header_title: "register here button"
      }
    }
}


export const templateThree: any = {
  event_title: {
    title: {
      font: 35,
      font_family: 'segoe-ui-bold-italic',
      color: '#1d415a',
      header_title: 'event title'
    }, 
    description: {
      font: 14,
      font_family: 'segoe-ui-italic',
      color: '#7b7b7b',
      header_title: 'event description'
    }  
  },
  guest_speakers: {
    speakers_title: {
      font: 16,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'guest speakers title'
    },
    speakers_name: {
      font: 13,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'speakers name'

    },
    who_can_attend_title: {
      font: 16,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'who can attend'
    },
    who_can_attend_description: {
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#7b7b7b',
      header_title: 'description'
    }
  },
  registration_guideline: {
    title: {
      font: 16,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'registration guidelines title'
    },
    description: {
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#7b7b7b',
      header_title: 'description'
    }
  },
  registration_fee: {
    title: {
      font: 13,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: "registration fees title"
    },
    value: {
      font: 45,
      font_family: 'segoe-ui-bold-italic',
      color: '#122a4d',
      header_title: "value"
    }
  },
  date: {
    date: {
      font: 14,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: "date"
    }
  },
  venue_and_time: {
    venue: {
      font: 15,
      font_family: 'segoe-ui-bold-italic',
      color: '#122a4d',
      header_title: "venue"
    },
    time: {
      font: 45,
      font_family: 'segoe-ui-bold-italic',
      color: '#27eda0',
      header_title: "time"
    }
  },
  button_customization: {
    view_profile: {
      button_name: "view profile",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#2e1717",
      header_title: "view profile button"
    },
    donate_now: {
      button_name: "donate now",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#2e1717",
      header_title: "donate now button"
    },
    website: {
      button_name: "Register here",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      header_title: "register here button"
    }
  }
}

export const templateTwo: any = {
  event_title: {
    title: {
      font: 35,
      font_family: 'segoe-ui-bold-italic',
      color: '#fc696e',
      header_title: 'event title'
    }, 
    description: {
      font: 14,
      font_family: 'segoe-ui-italic',
      color: '#7b7b7b',
      header_title: 'event description'
    }  
  },
  guest_speakers: {
    speakers_title: {
      font: 16,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'guest speakers title'
    },
    speakers_name: {
      font: 13,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'speakers name'
    },
    who_can_attend_title: {
      font: 16,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'who can attend'
    },
    who_can_attend_description: {
      font: 13,
      font_family: 'segoe-ui-regular',
      color: '#7b7b7b',
      header_title: 'description'
    }
  },
  registration_guideline: {
    title: {
      font: 16,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'registration guidelines title'
    },
    description: {
      font: 13,
      font_family: 'segoe-ui-regular',
      color: '#7b7b7b',
      header_title: 'description'
    }
  },
  registration_fee: {
    title: {
      font: 13,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      header_title: "registration fees title"
    },
    value: {
      font: 36,
      font_family: 'segoe-ui-bold-italic',
      color: '#ffffff',
      header_title: "value"
    }
  },
  date: {
    date: {
      font: 13,
      font_family: 'segoe-ui-bold-italic',
      color: '#4e4e4e',
      header_title: "date"
    }
  },
  venue_and_time: {
    venue: {
      font: 14,
      font_family: 'segoe-ui-italic',
      color: '#ffffff',
      header_title: "venue"
    },
    time: {
      font: 20,
      font_family: 'segoe-ui-bold-italic',
      color: '#fc696e',
      header_title: "time"
    }
  },
  button_customization: {
    view_profile: {
      button_name: "view profile",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#00008b",
      header_title: "view profile button"
    },
    donate_now: {
      button_name: "donate now",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#00008b",
      header_title: "donate now button"
    },
    website: {
      button_name: "Register here",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#00008b",
      header_title: "register here button"
    }
  }
}

export const templateOne: any = {
  event_title: {
    title: {
      font: 35,
      font_family: 'segoe-ui-bold-italic',
      color: '#ffffff',
      header_title: 'event title'
    }, 
    description: {
      font: 14,
      font_family: 'segoe-ui-regular',
      color: '#7b7b7b',
      header_title: 'event description'
    }  
  },
  guest_speakers: {
    speakers_title: {
      font: 15,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'guest speakers title'
    },
    speakers_name: {
      font: 13,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'speakers name'
    },
    who_can_attend_title: {
      font: 15,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'who can attend'
    },
    who_can_attend_description: {
      font: 13,
      font_family: 'segoe-ui-regular',
      color: '#7b7b7b',
      header_title: 'description'
    }
  },
  registration_guideline: {
    title: {
      font: 15,
      font_family: 'poppins-semibold',
      color: '#122a4d',
      header_title: 'registration guidelines title'
    },
    description: {
      font: 13,
      font_family: 'segoe-ui-regular',
      color: '#7b7b7b',
      header_title: 'description'
    }
  },
  registration_fee: {
    title: {
      font: 18,
      font_family: 'segoe-ui-bold-italic',
      color: '#000000',
      header_title: "registration fees title"
    },
    value: {
      font: 18,
      font_family: 'segoe-ui-bold-italic',
      color: '#000000',
      header_title: "value"
    }
  },
  date: {
    date: {
      font: 13,
      font_family: 'segoe-ui-regular',
      color: '#4e4e4e',
      header_title: "date"
    }
  },
  venue_and_time: {
    venue: {
      font: 14,
      font_family: 'segoe-ui-regular',
      color: '#444444',
      header_title: "venue"
    },
    time: {
      font: 22,
      font_family: 'segoe-ui-bold-italic',
      color: '#ffffff',
      header_title: "time"
    }
  },
  button_customization: {
    view_profile: {
      button_name: "view profile",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#00008b",
      header_title: "view profile button"
    },
    donate_now: {
      button_name: "donate now",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#00008b",
      header_title: "donate now button"
    },
    website: {
      button_name: "Register here",
      font: 15,
      font_family: 'segoe-ui-regular',
      color: '#ffffff',
      background_color: "#00008b",
      header_title: "register here button"
    }
  }
}