export class SearchModel {
    status: string = '';
    results: SearchResultsModel = new SearchResultsModel();
}

export class SearchResultsModel {
    users: SearchUserModel[] = [];
    user_posts: SearchUserPostModel[] = [];
    user_photos: SearchPhotoModel[] = [];
    user_videos: any[] = [];
    user_gifs: any[] = [];
    user_groups: any[] = [];
    organizations: SearchOrganizationModel[] = [];
    organizations_posts: any[] = [];
    organizations_photos: SearchPhotoModel[] = [];
    organizations_videos: any[] = [];
    organizations_gifs: SearchOrganizationsGIFModel[] = [];
    organization_groups: any[] = [];
}

export class SearchOrganizationsGIFModel {
    id: number = 0;
    caption: string = '';
    gif_url: string = '';
    gif: string = '';
}

export class SearchPhotoModel {
    id: number = 0;
    caption: string = '';
    photo: string = '';
}


export class SearchUserModel {
    get_data: SearchGetDataModel = new SearchGetDataModel();
    id: number = 0;
    first_name: string = '';
    last_name: string = '';
    email: string = '';
    briind_id: string = '';
    profile_pic: string = '';
}

export class SearchGetDataModel {
    id: number = 0;
    name: string = '';
    work: SearchWorkModel = new SearchWorkModel();
    profile_pic: string = '';
}

export class SearchWorkModel {
    name: string = '';
    designation: string = '';
}


export class SearchOrganizationModel {
    get_data: SearchGetDataOrganizationModel = new SearchGetDataOrganizationModel();
    id: number = 0;
    name: string = '';
    assumed_name: string = '';
    email: string = '';
}

export class SearchGetDataOrganizationModel {
    id: number = 0;
    name: string = '';
    assumed_name: string = '';
    is_active: boolean = true;
    date_joined: Date = new Date();
    email: string = '';
    countries: string[] = [];
    profile_pic: string = '';
    cover_photo: string = '';
    user_type: string = '';
    country_updated: boolean = false;
    social_profile: SearchSocialProfileModel = new SearchSocialProfileModel();
    overview: SearchOverviewModel = new SearchOverviewModel();
    social_profile_completion: number = 0;
    business_profile_completion: number = 0;
    display_name: string = '';
}

export class SearchOverviewModel {
    established_on: Date = new Date();
    website: string = '';
    email: string = '';
    phone_number: string = '';
    door_no: string = '';
    street: string = '';
    city: string = '';
    state: string = '';
    country: string = '';
    zip_code: string = '';
}

export class SearchSocialProfileModel {
    id: number = 0;
    points: number = 0;
    short_bio: string = '';
    organization: number = 0;
}

export class SearchUserPostModel {
    get_data: SearchGetDataUserPostModel = new SearchGetDataUserPostModel();
    id: number = 0;
    name: string = '';
    created_at: Date = new Date();
    description: string = '';
    post_type: string = '';
}

export class SearchGetDataUserPostModel {
    id: number = 0;
    photos?: SearchPhotoUserPostModel[];
    posted_by: SearchPostedModel = new SearchPostedModel();
    posted_to: SearchPostedModel = new SearchPostedModel();
    item_type: string = '';
    is_share: boolean = false;
    name: string = '';
    created_at: Date = new Date();
    description: string = '';
    object_id: number = 0;
    post_type: string = '';
    content_type: number = 0;
    user: number = 0;
    gifs?: SearchGIFModel[];
}

export class SearchGIFModel {
    id: number = 0;
    caption: string = '';
    gif_url: string = '';
    gif: string = '';
}

export class SearchPhotoUserPostModel {
    id: number = 0;
    photo: string = '';
    caption: string = '';
    created_at: Date = new Date();
    updated_at: null = null;
    photo_url: string = '';
    user_id: number = 0;
}

export class SearchPostedModel {
    id: number = 0;
    photo?: string;
    name: string = '';
    user_type: string = '';
    briind_id?: null | string;
    cover_photo?: string;
}
