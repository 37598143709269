import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-thumbnail',
  templateUrl: './profile-thumbnail.component.html',
  styleUrls: ['./profile-thumbnail.component.scss']
})
export class ProfileThumbnailComponent implements OnChanges {
  @Input('image') image: string ='';
  @Input('imageClass') imageClass: string = 'd45';
  @Input('isShowBorder') isShowBorder: boolean = true;
  @Input('isChatThumbnail') isChatThumbnail: boolean = false
  @Input('initials') initials: string = ''
  @Input('subType') subType: string = ''
  @Input('thumbnailType') thumbnailType: string = ''
  @Input('mainData') mainData: any = {}
  thumbnailUrl: any = false
  newInitials: string = ''
  @ViewChild('profileContainer') profileContainer!: ElementRef<any>;
  constructor(private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(this.isChatThumbnail) this.getInitials()
    switch (this.thumbnailType) {
      case 'notification':
        this.thumbnailUrl = `${ this.mainData?.user_type?.toLowerCase()}s/${this.mainData?.id}/timeline`
        break;
      // case 'chat':
      //   this.thumbnailUrl = `${ this.mainData.user_type.toLowerCase()}s/${this.mainData.id}/timeline`
      //   break;          
      default:
          break;
    } 
  }

  getInitials() {
    if (this.initials) {
      const words = this.initials.split(' ');
      for (let i = 0; i < words.length && this.newInitials.length < 2; i++) {
        if (words[i] && words[i].length > 0) {
          this.newInitials += words[i][0].toUpperCase();
        }
      }
    }
   
  
  }

  navigateToProfile(event: any) {
    setTimeout(()=> {
      if (this.subType != 'Volunteer') {
        this.router.navigate([this.thumbnailUrl])
      }
    }, 10)
  }
  
}
