<div class="bdrRadius10px posRelative bdr-1-EEEEEE boxShadow-RGBA00005 pad15 padright5 bg-white {{userType}}">
    <div class="timeline-label font18 poppins-medium padright10 mrgnbtm10">
        {{data?.name}}
    </div>
    <div class="poppins-regular font14 color-1227A4D padright10 mrgnbtm10" *ngIf="data?.description">
        {{data?.description}}
    </div>
    <div class="timeline-label font18 poppins-medium padright10 mrgnbtm10" *ngIf="data?.functionality?.length">
        {{'access functionalities' | translate}}
    </div>
    <div class="width100 flex flexWrap">
        <div class="padright10 padbtm10 flexNoShrink" *ngFor="let item of (data?.functionality)">
            <div class="displayFlex bg-E5ECF1 bdrRadius20px color-122A4D poppins-regular font14 pad1015">
                {{item.display_name}}
            </div>
        </div>
    </div>
    <div (click)="editItemCTA();" *ngIf="isShowEdit" class="d30 flex posAbsolute posRight10px posTop10px flexJustifyCenter flexAlignItemsCenter cursorPtr ripple bdrRadius50p">
        <i class="icns icon-edit green d20"></i>
    </div>
</div>