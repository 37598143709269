import { Component, OnInit, Input } from '@angular/core';
import { MoviesModel } from 'src/app/core/models';

@Component({
  selector: 'app-user-interest-card',
  templateUrl: './user-interest-card.component.html',
  styleUrls: ['./user-interest-card.component.scss']
})
export class UserInterestCardComponent implements OnInit {
  @Input('data') public data: MoviesModel | any;
  constructor() { }

  ngOnInit(): void {
  }

}
