import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../organizations/service/organization.service';
import { OrganizationStoreService } from '../../../organizations/store/organization-store.service';
import { UserService } from '../../../users/services/user.service';
import { UserStoreService } from '../../../users/store/user-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-set-user-details',
  templateUrl: './set-user-details.component.html',
  styleUrls: ['./set-user-details.component.scss']
})
export class SetUserDetailsComponent implements OnInit {
  subscription: Subscription = new Subscription();
  id: any = 0;

  authData: any;
  userData: any;
  constructor(
    private organizationService: OrganizationService,
    private organizationStoreService: OrganizationStoreService,
    private userService: UserService,
    private userStoreService: UserStoreService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    public appStore: AppStore,
    private appService: AppService
  ) {
    this.authData = this.authService.getSessionData();
    this.getActivatedRouterParams();

    this.appStore.profileListener$.subscribe(async(res: any)=> {
      await this.checkTheModule();
      this.getPrivacySettings();
      this.appStore.setTimelineLoader(false)
    })
  }


  async checkTheModule() {
    return new Promise(async (resolve, reject)=> {
      if(this.authService.getSessionData().type == 'Organization') {        
        this.organizationStoreService.profileDetail = this.organizationStoreService.organizationDetails;
      } 
      if(this.authService.getSessionData().type != 'Organization'){
        this.userStoreService.profileDetail = this.userStoreService.userDetails;
      }
  
  
      if (this.router.url.includes('organizations')) {
        if((this.id && (this.id != this.authService.getSessionData().briind_id))) {
          await this.getOtherOrganizationDetails();
        } else {
          this.organizationStoreService.profileDetail = this.organizationStoreService.organizationDetails;
        }
      } else {
        if (this.id && (this.id != this.authService.getSessionData().briind_id)) {
          await this.getOtherUserDetails();
        } else {
          this.userStoreService.profileDetail = this.userStoreService.userDetails;
        }
      }

      resolve(true)
    })
    
  }

  /**
   * @description
   * this method is used for getting user information calling in ng onit function
   * 
   * @memberof SharedUserDetailComponent
   */
  async getOtherUserDetails() {
    return new Promise(async (resolve, reject)=> {
      await this.userService.getUserInfoPromise(this.id)
      this.userStoreService.getProfileDetails();
      resolve(true)
    })
  }

  async getOtherOrganizationDetails() {
    return new Promise(async (resolve, reject)=> {
      await this.organizationService.getOrganizationDetailPromise(this.id)
      this.organizationStoreService.getOrganizationProfileDetails();
      resolve(true)
    })
  }


  /**
   * @description
   * this method is used for getting other organization information calling in ng onit function
   * 
   * @memberof SharedUserDetailComponent
   */
    /**
   * TODO: comment getPrivacySettings
   * @description Gets privacy settings
   * @memberof SetUserDetailsComponent
   */
     getPrivacySettings(): void {
      this.authData = this.authService.getSessionData();
      if (this.authData.type.toLowerCase() == "user") {
        this.subscription.add(
          this.userService.getUserPrivacySettings().subscribe((response: any) => {
            this.appStore.setUserPrivacySettings();
          })
        );
      } else {
        this.subscription.add(
          this.organizationService.getOrganizationPrivacySettings().subscribe((response: any) => {
            this.appStore.setUserPrivacySettings();
          })
        );
      }
    }

   /**
   * @description 
   * get router params
   *
   * @memberof SharedOrganizationDetailComponent
   */
  getActivatedRouterParams() {
    if (this.activatedRoute.pathFromRoot[2]) {
      this.subscription.add(
        this.activatedRoute.params.subscribe(param => {
          this.id = param['id'];
        }
        )
      );
    }
  }

  ngOnInit(): void {
    this.appService.onlineUserStatusUpdate().subscribe(response => {
    })
  }

}
