<!-- Code for data inside circle starts here-->
<div *ngIf="data.detailsInside">
        <div class="progressCircle" [ngStyle]="{'width': circleDiameter +'px', 'height': circleDiameter +'px'}">
            <svg class="progressCircleSvg" viewBox="0 0 120 120" *ngIf="data.showProgress isInnerData">
                <circle class="progressCircleMeter" cx="60" cy="60" [attr.r]="radius" stroke-width="6" />
                <circle class="progressCircleFill" [style.strokeDasharray]="circumference" [style.strokeDashoffset]="dashoffset"
                cx="60" cy="60" [attr.r]="radius" stroke-width="6" [ngClass]="data.color" />
            </svg>
            <div class="progressCircleValue flex flexAlignItemsCenter flexJustifyCenter flexWrap flexDirectionCol">
                <div class="progressCircleCounter">{{displayValue}}</div>
            </div>
        </div>
    </div>
    <!-- Code for data inside circle ends here-->