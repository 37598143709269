import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-webcam-capture",
  templateUrl: "./webcam-capture.component.html",
  styleUrls: ["./webcam-capture.component.scss"]
})
export class WebcamCaptureComponent implements AfterViewInit, OnDestroy {
  @Input() imageType: string = "profile_pic";
  @Input() userType: string = "user";
  @Output() close = new EventEmitter();
  @Output() selectedFile: EventEmitter<File> = new EventEmitter();

  public WIDTH = 480;
  public HEIGHT = 360;
  public isMobile: boolean = false;

  cameraFacing: string = "user" || "environment";
  constraints = {
    audio: false,
    video: {
      facingMode: this.cameraFacing
    }
  }

  @ViewChild("video")
  public video!: ElementRef;

  @ViewChild("canvas")
  public canvas!: ElementRef;

  showCropper: any = false;
  croppedImageEvent: any;
  imageChangedEvent: any = '';
  stream!: MediaStream;

  public captureStr: string = "";
  public error: any;
  public isCaptured: boolean = false;
  public loadingVideo: boolean = false;


  constructor() {
  }

  async ngAfterViewInit() {
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isMobile = true;

    this.intiVideoFromCamera();
  }
  async intiVideoFromCamera() {
    try {
      this.requestMediaPermissions(this.constraints).then((permission) => {
        this.setupDevices();
        this.error = null;
      }).catch((err) => {
        console.log("err ",err);
        this.error = "Plese provide camera permission, then refresh the page.";
      });
    } catch (e) {
      this.error = "Plese provide camera permission";
    }
  }

  async setupDevices() {
    if(this.stream) {
      this.stopCamera(this.stream);
    }
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia(this.constraints);
        if (this.stream) {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement?.play();
          this.video.nativeElement.setAttribute('autoplay', '');
          this.video.nativeElement.setAttribute('muted', '');
          this.video.nativeElement.setAttribute('playsinline', '');
          this.error = null;
        } else {
          this.error = "You have no output video device";
        }
      } catch (e) {
        this.error = "Plese provide camera permission";
      }
    }
    this.loadingVideo = false;
  }

  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captureStr = this.canvas.nativeElement.toDataURL("image/png");
    this.isCaptured = true;
    const event = this.dataURLtoFile(this.captureStr, Math.random().toString(36).substring(2) + '.png');
    this.croppedImageEvent = event;

    this.stopVideoStream();
  }

  removeCurrent() {
    this.video.nativeElement.play();
    this.setupDevices();
    this.isCaptured = false;
  }

  openCamera() {
    this.isCaptured = false;
    this.intiVideoFromCamera();
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  stopVideoStream() {
    this.video?.nativeElement?.pause();
    this.stopCamera(this.stream);
  }

  requestMediaPermissions(constraints?: MediaStreamConstraints) {
    return new Promise<boolean>((resolve, reject) => {
      navigator?.mediaDevices?.getUserMedia(constraints ?? { audio: false, video: true })
        .then((stream: MediaStream) => {
          stream?.getTracks()?.forEach((t) => {
            t?.stop();
          });
          resolve(true);
        })
        .catch((err: any) => {
          reject({ ...err });
        });
    });
  }

  loadFileToCanvas(file: File) {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    const ctx = canvas.getContext('2d');
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  dataURLtoFile(dataURL: string, filename: string): File {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  stopCamera(stream: any) {
    stream?.getTracks()?.forEach((track: any) => {
      track?.stop();
    });
  }

  uploadImage() {
    this.selectedFile.emit(this.croppedImageEvent);
  }

  closePopup() {
    this.close.emit('close');
  }

  selectedFileHandlerFn(event?: File) {
    this.selectedFile.emit(event == undefined ? this.croppedImageEvent : event);
    this.closePopup();
  }
  setConstraints() {
    this.constraints = {
      audio: false,
      video: {
        facingMode: this.cameraFacing
      }
    }
  }
  switchCameraFacing() {
    this.loadingVideo = true;
    if (this.cameraFacing == "user") {
      this.cameraFacing = "environment";
    } else {
      this.cameraFacing = "user";
    }
    this.setConstraints();
    
    setTimeout(() => {
      this.setupDevices();
    }, 0);
    console.log("switchCameraFacing ", this.cameraFacing,);
    console.log("switchCameraFacing2 ",);
  }

  ngOnDestroy(): void {
    this.stopCamera(this.stream);
  }
}
