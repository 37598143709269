export class BriinIdCheckModel {
    message ?: string = '';
    status ?: string = '';
    suggested_briind_id ?: string = '';
}

export class HighSchoolModel {
    name ?: string = '';
    high_school_name ?: string = '';
    high_school_id ?: string = '';
    id ?: string = '';
    from_year: any = '';
    to_year: any = '';

}

export class UniversityListModel {
    name ?: string = '';
    id ?: string = '';
    from_year: any = '';
    to_year: any = '';
    concentration: string = '';
    graduated: string = '';
}

export class WorkListModel {
    name ?: string = '';
    id ?: string = '';
    from_year: any = '';
    to_year: any = '';
    designation: string = '';
    description: string = '';
}

export class CityLivedInModel {
    id ?: string = '';
    name ?: string = '';
    moved_in ?: string = '';
}

export class MoviesModel {
    id ?: string ='';
    language ?: string = '';
    poster ?: string = '';
    name ?: string = '';
}

export class ReligiousViewModel {
    id ?: string = '';
    name ?: string = '';
    description ?: string = '';
}