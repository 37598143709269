<div id="popupContainer" class="flex flexJustifyCenter flexAlignItemsCenter cropperPopUp">
    <div id="popupOverlay" (click)="closePopup()"></div>
    <div id="popupContentBox">
        <div class="defaultPopUp">
            <div id="suggested-friends" class="bdrRadius6px bg-white pad20 posRelative overflowHidden">
                <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup(false);">×</span>
                <div id="suggested-friends-popup" class="overflowHidden">
                    <div class="width100 mrgnauto">
                        <div class="poppins-semibold font22 timeline-label padbtm10 txtCenter ">
                            {{ title | translate }}
                        </div>
                    </div>
                    <div class="width100 mrgnauto bg-FFFFFF">
                        <div class="width50 poppins-regular mrgnauto txtCenter">
                            {{ description | translate }}
                        </div>
                        <div class="maxHeight300px custom-scrollbar mrgntop15  overflowAuto">
                            <div class="width70 mrgnauto txtCenter">
                                <button type="button" (click)="closePopup(false);"
                                    class="btn-get-started cancel-btn minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030 mrgnright15">
                                    {{'no' | translate}}
                                </button>
                                <button type="button" (click)="closePopup(true)"
                                    class="btn-get-started btn-user-default minWidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030">
                                    {{'yes' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>