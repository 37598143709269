import { Component, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

import * as _ from 'lodash';
import { ActionModel } from 'src/app/core/models/action.model';
import { Router } from '@angular/router';
import { UserStoreService } from 'src/app/users/store/user-store.service';

@Component({
  selector: 'app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss']
})
export class CardActionsComponent implements OnChanges {
  @Input('actions') actions: ActionModel[] = [];
  primaryActions: ActionModel[] = [];
  moreActions: ActionModel[] = [];
  @Output() cardActionCTA = new EventEmitter();
  constructor(
    private router: Router,
    public userStoreService: UserStoreService
  ) { }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   * 
   * @param {SimpleChanges} changes
   * @memberof GeneralPostCardComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.actions && this.actions.length != 0) {
      const primaryActions = this.actions.filter((x:any) => (x.action_type != 'more' && x.action_type != 'link') )
      this.primaryActions = _.orderBy(primaryActions, ['order'],['asc']);
      this.moreActions = this.actions.filter((x:any) => x.action_type == 'more');
    }
  }
  
  /**
   *  @description
   * this method is used for emiting the card action
   *
   * @param {*} action
   * @memberof GeneralCardComponent
   */
  cardCTA(action: any) {
    if (action.action_url) {
      this.router.navigate([action.action_url])
    } else {
      this.cardActionCTA.emit(action.action_text.toLowerCase().split(" ").join(''));
    }
    
  }
}
