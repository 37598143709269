import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';

@Component({
  selector: 'app-post-profile-section-card',
  templateUrl: './post-profile-section-card.component.html',
  styleUrls: ['./post-profile-section-card.component.scss']
})
export class PostProfileSectionCardComponent implements OnInit {
  subscription: Subscription = new Subscription();
  @Input('post') post: any;
  @Output('removePost') removePost = new EventEmitter();
  @Output('reportPost') reportPost = new EventEmitter();

  id: number = 0;
  constructor(
    public authService: AuthService,
    private userService: UserService,
    public userStoreService: UserStoreService,
    public organizationStoreService: OrganizationStoreService,
    private organizationService: OrganizationService,
    private appService: AppService,
    private appStore: AppStore,
  ) { 
    this.id= this.authService.getSessionData()?.orgId;
  }

  /**
   * @description
   * this method is used for delete an album
   *
   * @param {*} [event]
   * @memberof PostProfileSectionCardComponent
   * 
   */
  remove(id?: number) {
    this.removePost.emit(id);
  }

  edit(post: any){
    this.appService.togglePopup({
      type: 'createPost',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        userType: this.appStore.userType,
        userData: post.posted_by,
        uploadedFiles: [],
        postType: `${post.item_type || post?.post?.item_type}`,
        post: post.item_type.toLowerCase().includes('share') ? post : post.post || post
      }
    });
  }

  report(post: any){
    let userId = this.authService.getSessionData()
    //console.log(post, userId)

    // this.reportPost.emit(post.id);
  }

  profileNav(type:any, id:any) {
    if(type == 'User'){
      window.location.href = '/users/'+id+'/timeline';
    }
    else{
      window.location.href = '/organizations/'+id+'/timeline';
    }
  }

  ngOnInit(): void {
  }

}
