import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'minimalDate'
})
export class MinimalDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return (`${moment(value).format('MM-DD-YYYY')}`);
    } else {
        value = '';
    }
    return value;
  }

}
