<div class="{{appStore?.userType}} section800">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'region specific costing' | translate}}
    </div>
    <div class="body width100">
        <form autocomplete="off" [formGroup]="formGrp">
            <div class="bdr-ebebeb bdrRadius5px pad20 mrgnbtm20">
                <div class="width100 flex flexResponsive">
                    <div class="width30">
                        <app-custom-select [dropDownStatus]="dropDownStatus" [placeholder]="'country'"
                            [icon]="'input-icon drop-down'" [isSearchEnabled]="true" class="width100"
                            [formCntrl]="'country'" [selectedData]="''" [formGrp]="form" [data]="regionList"
                            (selectedItemFromDropdown)="selectedCountryDropdown($event)"></app-custom-select>
                    </div>
                    <div class="pad10"></div>
                    <div class="width30">
                        <app-custom-select [dropDownStatus]="dropDownStatus" [placeholder]="'state'"
                            [icon]="'input-icon drop-down'" [isSearchEnabled]="true" class="width100"
                            [formCntrl]="'state'" [selectedData]="''" [formGrp]="form"
                            [data]="selectedCountry != '' ? selectedCountry?.state : data"
                            (selectedItemFromDropdown)="selectedStateDropdown($event)"></app-custom-select>
                    </div>
                    <div class="pad10"></div>
                    <div class="width30">
                        <app-custom-select [dropDownStatus]="dropDownStatus" [placeholder]="'city'"
                            [icon]="'input-icon drop-down'" [isSearchEnabled]="true" class="width100"
                            [formCntrl]="'city'" [selectedData]="''" [formGrp]="form"
                            [data]="selectedCountry != '' ? selectedState?.city : data"
                            (selectedItemFromDropdown)="selectedCityDropdown($event)"></app-custom-select>
                    </div>
                </div>
                <div class="width100 padtop40" *ngIf="briindletType == 'createBriindlte'">
                    <button  (click)="addRegions('normal')"
                        class="btn-user-default btn-get-started  ripple font16 poppins-medium cursorPtr">
                        {{'add' | translate}}
                    </button>
                </div>
                <div *ngIf="briindletType != 'createBriindlte'"
                    class="flex flexJustifyBetween flexResponsive mrgntop30">
                    <div class="width100 flex" (click)="addRegions('pro')">
                        <i class="icns icon-plus {{appStore?.iconClass}} d20 mrgnright10"></i>
                        <a href="javascript:void(0)"
                            class="timeline-label color-02457A poppins-semibold font20 lineHeight20px mrgnbtm20">{{'add'
                            | translate}}</a>
                    </div>
                </div>
            </div>
        </form>

        <div *ngIf="briindletType != 'createBriindlte'">
            <div *ngIf="selectedRegionInfo.length > 0" class="flex flexJustifyBetween flexResponsive">
                <div class="width100 flex">
                    <ng-container *ngFor="let region of selectedRegionInfo; let i = index;">
                        <ng-container>
                            <span
                                class="{{!region?.country ? 'bg-F0EDA5' : region?.country != '' && !region?.state ? 'bg-DFC6FE' : region?.country != '' && region?.state != '' ? 'bg-FAD5B6' : ''}} bdrRadius20px flex flexNoShrink flexAlignItemsCenter flexJustifyBetween poppins-medium font16 pad0815 mrgnright10 timeline-label">
                                <span class="color-FFFFFF">{{region?.name}}</span>
                                <span
                                    class="pad5 cursorPtr bg-FFFFFF bdrRadius20px mrgnleft10 displayFlex d25 flexAlignItemsCenter flexJustifyCenter"
                                    (click)="cancelRegion(region, i)">×</span>
                            </span>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="width100 padtop30 flex">
                <button  (click)="actionCTA('previous')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                    {{'back' | translate}}
                </button>
                <div class="pad5"></div>
                <button [disabled]="selectedItems?.length == 0" (click)="actionCTA('continue')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                    {{'continue' | translate}}
                </button>
            </div>
        </div>

    </div>
</div>