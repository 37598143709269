import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-organization-leaders-preview',
  templateUrl: './organization-leaders-preview.component.html',
  styleUrls: ['./organization-leaders-preview.component.scss']
})
export class OrganizationLeadersPreviewComponent implements OnInit {
  @Input() data: any = [];
  @Input() organizationID: any;
  @Input('showEditIcon') showEditIcon: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  changeData($event:any){
    this.data = this.data.filter((element: any) => element.id !== $event.id)
  }

}
