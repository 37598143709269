import { AppStore } from './../../../../core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-share-to-group-popup',
  templateUrl: './share-to-group-popup.component.html',
  styleUrls: ['./share-to-group-popup.component.scss']
})
export class ShareToGroupPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();

  subscription: Subscription = new Subscription();
  userID: number = 1;
  selectedGroupIndex: number = -1;
  selectedUserGroup: any = [];
  searchInput:any;
  postid: any;
  userType: any;
  isLoader: boolean = false;
  shareMessage: string = '';
  constructor(
    public appService: AppService,
    private appStore: AppStore
  ) { }

  shareMessageEmit(event: any) {
    this.shareMessage = event;
  }

  ngOnInit(): void {
    
    this.postid = this.popup.context.postid;
    this.userType = this.popup.context.userType;
    //this.getGroupList();

  }

    /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof ShareToGroupPopupComponent
   */
  closePopup() {
		this.close.emit('icon');
  }
  
  

  /**
   * @description
   * this method is used for set selected user group
   *
   * @param {object} [data]
   * @memberof ShareToGroupPopupComponent
   */
  setUserGroup(data: any) {
    if (this.selectedUserGroup.includes(data.id)) {
      this.selectedUserGroup = this.selectedUserGroup.filter((x: any) => x != data.id);
    } else {
      this.selectedUserGroup.push(data.id)
    }
  }

    /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   * @memberof ShareToGroupPopupComponent
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

   /**
   * @description
   * this method is used for sharing post to group
   *
   * @memberof ShareToGroupPopupComponent
   */
  shareToGroup() {

    this.isLoader = true;
    let param: any = {};
    param.description = this.shareMessage;
    param.post_type = this.popup.context.postType;
    param.post_id = this.postid;
    param.group_ids = this.selectedUserGroup;

    this.subscription.add(
      this.appService.bulkShare(param, `${this.appStore.userType.toLowerCase()}s`).subscribe((response: any) => {
        //var res = response;
        this.isLoader = false;
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `Shared successfully!` }
        });
        this.close.emit('icon');

      }, er => {
        this.isLoader = false;
      })
    );
    // let param: any = {};
    // if (this.userType == "user") {
    //   this.shareToGroupUser(param);
    // } else if (this.userType == "organization") {
    //   this.shareToGroupOrganization(param);
    // }
  }


}
