import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../core/services/app.service';
import { UserStoreService } from '../../../users/store/user-store.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/users/services/user.service';

@Component({
  selector: 'app-timeline-photos-section',
  templateUrl: './timeline-photos-section.component.html',
  styleUrls: ['./timeline-photos-section.component.scss']
})
export class TimelinePhotosSectionComponent implements OnInit, OnChanges {
  @Input('photos') photos: any[] = [];
  @Input('id') id: number = 0;
  @Input('userType') userType: string = 'user';
  subscription: Subscription = new Subscription();
  // id: number = 0;
  constructor(
    private router: Router,
    private appService: AppService,
    private userService: UserService,
    private userStoreService: UserStoreService,
    private activatedRoute: ActivatedRoute
  ) { }

  /**
   * @description
   * get router params
   *
   * @memberof OrganizationTimelineComponent
   */
  // getRouteParams() {
  //   this.subscription.add(
  //     this.activatedRoute.params.subscribe(param => {
  //       this.id = param['id'];
  //     })
  //   )
  // }

  /**
   * @description
   * this method is used for routing to photos page
   *
   * @memberof TimelinePhotosSectionComponent
   */
  seeAll() {
    if (this.userType == 'user') {
      this.router.navigate(['/users', this.id, 'photos'])
    } else {
      this.router.navigate(['/organizations', this.id, 'photos'])
    }
  }

  /**
   * @description
   * this method is used for viewing the post 
   *
   * @param {*} index
   * @memberof TimelinePhotosSectionComponent
   */
  viewPhotos(index: any, id?: number) {
    this.subscription.add(
      this.userService.getPhotoDetails(id).subscribe((response: any) => {
        if (response) {
          this.appService.togglePopup({
            type: 'viewPost',
            load: true,
            refreshParent: true,
            overlayClose: true,
            context: {
              post: response,
              medias: [this.photos[index]],
              selectedItem: 0,
              postType: 'photo',
              posted_by: { profile_pic: response.post.posted_by.photo, name: response.post.posted_by.name, id: response.post.posted_by.id },
              theme: this.userType == 'user' ? 'blue' : 'green'
            }
          })
        }
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.photos && changes.photos.currentValue) {
      ////console.log(this.photos)
    }
  }

  ngOnInit(): void {
  }

}
