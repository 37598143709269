import { Component, OnInit, ContentChildren, QueryList, ViewChild, ElementRef, Input } from '@angular/core';
import { CarouselItemDirective } from 'src/app/core/directives/carousel-item.directive';


@Component({
  selector: 'app-simple-carousel',
  templateUrl: './simple-carousel.component.html',
  styleUrls: ['./simple-carousel.component.scss']
})
export class SimpleCarouselComponent implements OnInit {
  slideIndex = 1;
  @ContentChildren(CarouselItemDirective) items : QueryList<CarouselItemDirective> | any;
  @ViewChild('slideShow') private slideShow : ElementRef | any;
  @Input() autoSlide : string = '';
  @Input() side : string = '';
  constructor() {
  }

  plusSlides(n : number) {
    this.showSlides(this.slideIndex += n);
  }

  currentSlide(n : number) {
    this.showSlides(this.slideIndex = n);
  }



  showSlides(n : number) {
    let i;
    let slides: any = this.slideShow.nativeElement.children as HTMLCollection;
    if (n > slides.length) {this.slideIndex = 1}    
    if (n < 1) {this.slideIndex = slides.length}
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
    }
    slides[this.slideIndex-1].style.display = "flex";  
    }

  
  RepeatLeft() {
    setTimeout(() => {
      this.__FunctionSlideLeft();
      this.RepeatLeft();
    }, 2000);
  }

  RepeatRight() {
    setTimeout(() => {
      this.__FunctionSlideRight();
      this.RepeatRight();
    }, 2000);
  }

  __FunctionSlideRight() {
    const slides = Array.from(document.getElementsByClassName('mall-show-slide-right'));
    if (slides === []) {
      this.RepeatRight();
    }
    for (const x of slides) {
      const y = x as HTMLElement;
      y.style.display = 'none';
    }
    if (this.slideIndex > slides.length - 1) {
      this.slideIndex = 0;
      const slide = slides[this.slideIndex] as HTMLElement;
      slide.style.display = 'block';
      this.slideIndex++;
    } else {
      const slide = slides[this.slideIndex] as HTMLElement;
      slide.style.display = 'block';
      this.slideIndex++;
    }
  }

  __FunctionSlideLeft() {
    const slides = Array.from(document.getElementsByClassName('mall-show-slide-left'));
    if (slides === []) {
      this.RepeatLeft();
    }
    for (const x of slides) {
      const y = x as HTMLElement;
      y.style.display = 'none';
    }
    if (this.slideIndex > slides.length - 1) {
      this.slideIndex = 0;
      const slide = slides[this.slideIndex] as HTMLElement;
      slide.style.display = 'block';
      this.slideIndex++;
    } else {
      const slide = slides[this.slideIndex] as HTMLElement;
      slide.style.display = 'block';
      this.slideIndex++;
    }
  }

  autoSlideShow() {
    this.slideIndex = 0;
    setInterval((x: any) => {
      if (this.items.length >= (this.slideIndex - 1)) {
        this.showSlides(this.slideIndex);
        this.slideIndex  = this.slideIndex + 1;
      } else {
        this.slideIndex = 0;
        this.showSlides(this.slideIndex);
      }
    },5000)
  }

  ngOnInit(): void {
    if(this.autoSlide == "true" ) {
     
      setTimeout((x: any) => {
        if (this.items && this.items.length > 0) {
          this.autoSlideShow();
        } else {
          this.ngOnInit();
        }
      }, 1000)
      // if(this.side == "right"){
      //   this.RepeatRight();
      // }
      // if(this.side == "left"){
      //   this.RepeatLeft();
      // }
    } else {
      setTimeout((x: any) => {
        if (this.items && this.items.length > 0) {
          this.showSlides(this.slideIndex);
        } else {
          this.ngOnInit();
        }
      }, 1000)
    }
  }

}
