import { AppStore } from './../../../../../core/stores/app.store';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-briindlet-dontaion-campaign',
  templateUrl: './briindlet-dontaion-campaign.component.html',
  styleUrls: ['./briindlet-dontaion-campaign.component.scss']
})
export class BriindletDontaionCampaignComponent implements OnInit {
  @Output() stepperActions = new EventEmitter();
  @Input() formGrp: any;
  causesList: any = [];
  constructor(
    private organizationService: OrganizationService,
    private authService: AuthService,
    public appStore: AppStore
  ) { }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  getCausesList() {
    this.organizationService.getCauses({organization_id: this.authService.getSessionData().orgId}).subscribe(response => {
      this.causesList = response;
    })
  }

  ngOnInit(): void {
    if (this.authService.getSessionData().type == 'Organization') {
      this.getCausesList();
    }
  }  

}
