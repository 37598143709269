<div id="popupContainer" class="flex flexJustifyCenter flexAlignItemsCenter cropperPopUp">
  <div id="popupOverlay" (click)="closePopup()"></div>
  <div id="popupContentBox">
    <div class="defaultPopUp">
      <div><i class="icns icon-close d25 green right" (click)="closePopup()"></i></div>

      <div *ngIf="error" class="text-danger text-center mt-10 font18">
        {{ error }}
      </div>

      <div class="flex flexDirectionCol">
        <div class="width100 bg-common pad10 bdrRadius6px minHeight250px">
          <div class="overflowHidden bdrRadius10px">
            <video *ngIf="!error" class="bdrRadius10px width100" [ngClass]="isCaptured ? 'hide' : 'show'" #video
              id="video" muted autoplay></video>
            <canvas [ngClass]="!isCaptured ? 'hide' : 'show'" #canvas id="canvas" [width]="WIDTH"
              [height]="HEIGHT"></canvas>
          </div>
        </div>

        <div class="text-center mt-2 flex flexJustifyAround flexAlignItemsCenter">

          <div (click)="stopVideoStream()">
            <app-photo-from-file [imageType]="imageType"
              (selectedFile)="selectedFileHandlerFn($event)"></app-photo-from-file>
          </div>
          
          <button type="button" title="Capture" *ngIf="!isCaptured" (click)="capture()" class="btn-clear" [disabled]="error">
            <i class="icns icon-capture-photo d35"></i>
          </button>
          <button type="button" *ngIf="isCaptured" (click)="selectedFileHandlerFn()"
            class="btn-get-started font13 ripple poppins-medium cursorPtr"
            [ngClass]="{'btn-02457A': userType.toLowerCase() == 'user', 'btn-18878C': userType.toLowerCase() == 'organization'}"
            [disabled]="error">
            {{'save' | translate}}
          </button>
          <button type="button" (click)="removeCurrent()" class="btn-clear" [disabled]="error">
            <i class="icns icon-reload-photo d35"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>