<div id="sucess-popup" class="{{popup?.context?.profile_type}}">
    <div class="  width100 bg-common pad20 bdrRadius6px minHeight250px flex flexJustifyCenter flexDirectionCol" >
        <div class="font25 color-FFFFFF txtCenter poppins-bold">{{ popup?.context?.title | translate}}</div>
        <div class="font20 color-FFFFFF txtCenter poppins-regular">
            {{popup?.context?.subtitle | translate}}
        </div>
        <div class="pad5"></div>
        <div class="txtCenter">
            <button class="btn-get-started cancel-btn ripple cursorPtr txtTransformUp" (click)="closePopup();">{{'ok' | translate}}</button>
        </div>
    </div>
    
</div>