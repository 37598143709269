import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppStore } from 'src/app/core/stores/app.store';
import * as moment from 'moment';

@Component({
  selector: 'app-briindlet-event-preview',
  templateUrl: './briindlet-event-preview.component.html',
  styleUrls: ['./briindlet-event-preview.component.scss']
})
export class BriindletEventPreviewComponent implements OnChanges {
  @Input() data: any;
  @Input() customizeUI: any;
  @Output() stepperActions = new EventEmitter();
  @Output() templateAmount = new EventEmitter();
  briindGuest = [
    {
      id: 1,
      name: 'Johns Smith',
      profile: 'assets/images/profile.png',
    },
    {
      id: 2,
      name: 'James Doe',
      profile: 'assets/images/profile.png',
    },{
      id: 3,
      name: 'Christina James',
      profile: 'assets/images/profile.png',
    }
  ];
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) {}

  actionCTA(type: any) {
    this.stepperActions.emit(type)
    this.appService.toggleToast({
      type: 'success',
      load: true,
      position: 'top-center',
      context: { description: `Briindlet Pro Created Successfully` }
    }); 
  }

  getBriindletProPrice() {
    const param = {
      hours: parseInt(this.data.hours),
      regions: this.data.regions,
      template_price: parseInt(this.data.template_amount)
    }
    this.appService.getBriindletPrice(`${this.appStore.userType}s`,param).subscribe((response: any) => {
      this.templateAmount.emit(response.total_price);
      // this.data.template_amount = response.total_price;
      this.data.template_amount = 0;
      //console.log(response);
    })
  }; 

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) { 
      this.getBriindletProPrice();
    }
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //   //Add '${implements OnChanges}' to the class.
  //   // if (changes && changes.formGrp && changes.formGrp.currentValue) {
  //   //   this.formGrp.patchValue({
  //   //     event_from: new Date(`${this.appService.formatDateForApi(this.formGrp.value.event_from_date)} ${moment(this.formGrp.value.event_from_time).format('LT')}`),
  //   //     event_to: new Date(`${this.appService.formatDateForApi(this.formGrp.value.event_to_date)} ${moment(this.formGrp.value.event_to_time).format('LT')}`)
  //   //   })
  //   // }
  // }

}
