import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-share-post-detail',
  templateUrl: './share-post-detail.component.html',
  styleUrls: ['./share-post-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharePostDetailComponent implements OnInit {
  @Input() post: any;
  @Input() media: any;
  @Input() user: any;
  @Output() shareMessage = new EventEmitter();
  postDetail: string = '';
  showMore: boolean = false;
  toggled: boolean = false;
  @ViewChild('emojiIconContainer') emojiIconContainer: ElementRef | any;
  
  constructor() { }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event ?: any): void {
    if (((this.emojiIconContainer && !this.emojiIconContainer.nativeElement.contains(event.target)))) {
      this.toggled = false;
    }
  }

  handleSelection(event: any) {
    this.postDetail = this.postDetail + (event.emoji ? event.emoji.native : '');
    this.postComment();
  }

  postComment() {
    this.shareMessage.emit(this.postDetail)
  }

  ngOnInit(): void {
    // this.user = JSON.parse(localStorage.data)
  }

}
