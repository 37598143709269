<div id="suggested-friends" class="bdrRadius6px bg-white pad20 posRelative overflowHidden"
    [ngClass]="popup?.context?.userType.toLowerCase()">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div id="suggested-friends-popup" class="overflowHidden">
        <div class="width100 mrgnauto">
            <div class="poppins-semibold font22 timeline-label padbtm10 txtCenter ">
                {{ popup?.context?.title | translate }}
            </div>
        </div>
        <div class="width100 mrgnauto bg-FFFFFF">
            <div class="width50 mrgnauto">
                <app-search-input [searchPlaceholder]="'search..'" [icon]="'searchIcon'" [isLimitWidth]="false" (search)="searchFriends($event)">
                </app-search-input>
            </div>
            <div class="maxHeight300px custom-scrollbar mrgntop15  overflowAuto">
                <div class="width70 mrgnauto mrgnbtm15 txtCenter">
                    <span *ngIf="popup?.context?.actionType == 'FriendRequest'" class="poppins-medium font14 color-122A4D">{{ friendList.length }} Friend Requests</span>
                </div>
                <ng-container *ngFor="let user of friendList">
                    <div class="width70 mrgnauto mrgnbtm15">
                        <app-friends-card [data]="user" [userType]="'user'" [actions]="user.actions" (cardActionCTA)="cardActionCTA($event)"></app-friends-card>
                    </div>
                </ng-container>
            </div>
            <div class="pad10"></div>
        </div>
    </div>
</div>