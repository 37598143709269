export class MenuCustomizationModel {
    id: number = 0;
    ordered_menu: any[] = [];
}

export class OrderedMenuModel {
    menu_key: string = '';
    menu_name: string = '';
    order: number = 0;
}
