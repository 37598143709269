import { config } from 'src/environments/environment';
import { ElementRef } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';
import { observable } from 'mobx-angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-post-action',
  templateUrl: './post-action.component.html',
  styleUrls: ['./post-action.component.scss']
})
export class PostActionComponent implements OnInit {
  @Input('post') post: any;
  @Output() postAction = new EventEmitter();
  @Input() theme: string = 'blue';
  @Input() media: any;
  @Input() comments: any = [];
  @Input() user: any;
  isLoader: boolean = false;
  
  @Input() mediaType: any;

  sharePopup: boolean = false;
  @ViewChild('toggleShare') toggleShare!: ElementRef;
  @observable likeBtnDisable: any = false;

  subscription: Subscription = new Subscription();
  userType: string="";
  userID: number = 0;

  config: any = config;
  constructor(
    private userService: UserService,
    private appService: AppService,
    private userStoreService: UserStoreService,
    public organizationService:OrganizationService,
    public organizationStoreService:OrganizationStoreService, 
    public authService: AuthService) {
  }

  /**
   * @description
   * this method is used for post like , comment, share etc
   *
   * @param {string} type
   * @memberof PostActionComponent
   */
  postCTA  (type: string) {
    this.postAction.emit(type);
    // this.post.liked = true;
    // this.post.likes_count +=1
  }

  ngOnInit(): void {
    
    this.userType = this.authService.getSessionData()?.type?.toLowerCase();
  }

    /**
   * @description
   * this method is used for sharing post now
   *
   * @param {string} type
   * @memberof PostActionComponent
   */
  shareNow(post: any) {
  
    this.togglePopup({ 
      postid:   (this.media ? (this.media?.id ||  this.media?.data?.id) : post.id), 
      userType: this.userType, 
      postType: (this.mediaType ? this.mediaType : post.item_type), 
      post: this.post,
      user: this.user,
      media: this.media
      }, 
      'shareNow'
    )
   
  }

  /**
  * @description
  * this method is used for sharing post for organization
  *
  * @param {string} type
  * @memberof PostActionComponent
  */
  private sharePostOrganization(param: any, post: any) {
    param.description = "";
    param.post_type =  (this.mediaType ? this.mediaType : post.item_type);
    param.post_id = (this.media ? (this.media?.id ||  this.media?.data?.id) : post.id);
    param.posted_on_type =  this.authService.getSessionData()?.type;
    param.posted_on_id = this.authService.getSessionData()?.orgId;
    this.subscription.add(
      this.organizationService.shareOrganizationPost(param).subscribe((response: any) => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `Shared successfully!` }
        });
        this.isLoader = false;
      }, error => {
        this.isLoader = false;
      })
    );
  }

  /**
  * @description
  * this method is used for sharing post for user
  *
  * @param {string} type
  * @memberof PostActionComponent
  */
  private sharePostUser(param: any, post: any) {
    param.description = "";
    param.post_type =  (this.mediaType ? this.mediaType : post.item_type);
    param.post_id = (this.media ? (this.media?.id ||  this.media?.data?.id) : post.id);
    param.posted_on_type =  this.authService.getSessionData()?.type;
    param.posted_on_id = this.authService.getSessionData()?.orgId;
    this.subscription.add(
      this.userService.shareUserPost(param).subscribe((response: any) => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `Shared successfully!` }
        });
        this.isLoader = false;
      }, error => {
        this.isLoader = false;
      })
    );
  }

  /**
  * @description
  * this method is used show share group share popup
  *
  * @param {string} type
  * @memberof PostActionComponent
  */
   sharePost(post: any, type: string) {
    this.post = post;
    switch (type) {
      case 'group':
        this.getGroupList(post.id);
        break;
      case 'user':
        this.appService.getFriends().subscribe((response: any) => {
          let arr: any = [];
          if (this.authService.getSessionData().type.toLowerCase() == 'user') {
            arr = response['user_data'];
          } else {
            arr = [...response['following_users'], ...response['membership_user']]
          }
          const uniqArr = _.uniqBy(arr, (obj: any) => obj['id']);
          this.showShareGroupPopup(post.id, uniqArr, 'shareToUsers')
        })
        break;
      default:
        break;
    }
  }

  private showShareGroupPopup(postid: any,groupList:any, type ?: string) {
    this.togglePopup({ 
      postid:  (this.media ? (this.media?.id || this.media?.data?.id) : postid), 
      groupList: groupList || [], 
      userType: this.userType, 
      postType: (this.mediaType ? this.mediaType : this.post.item_type), 
      list:  groupList || [],
      post: this.post,
      user: this.user
      }, 
      type || 'shareToGroup'
    )
  }

  


  togglePopup(context: any, type: string) {
    this.appService.togglePopup({
      type: type,
      load: true,
      refreshParent: true,
      overlayClose: true,
      //context:post
      context: context
    });
  }

  /**
   * @description
   * this method is used for getting Group List
   *
   * @param {*} [event]
   * @memberof PostActionComponent
   */
  getGroupList(postid:any) {
    if (this.userType == "user") {
      this.getUserGroupList(postid);
    }
    else if (this.userType == "organization") {
      this.getOrganizationGroupList(postid);
    }
  }
  /**
   * @description
   * this method is used to get organization's group list
   *
   * @memberof PostActionComponent
   */
  private getOrganizationGroupList(postid:any) {
    this.userID = this.organizationStoreService.organizationDetails.id;
    this.subscription.add(
      this.organizationService.getOrganizationGroupsList(this.userID).subscribe((response: any) => {
        this.organizationStoreService.getGroupList();
        this.showShareGroupPopup(postid,this.organizationStoreService?.groupList);
      })
    );
  }
  /**
   * @description
   * this method is used to get user's group list
   *
   * @memberof PostActionComponent
   */
  private getUserGroupList(postid:any) {
    this.userID = this.userStoreService.userDetails.id!;
    this.subscription.add(
      this.userService.getUserGroupsList(this.userID).subscribe((response: any) => {
        this.userStoreService.getGroupList();
        this.showShareGroupPopup(postid,this.userStoreService?.groupList);
      })
    );
  }

  /**
 * @description
 * Called once, before the instance is destroyed.
 * Add 'implements OnDestroy' to the class.
 *
 * @memberof PostActionComponent
 */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
