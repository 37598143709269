import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStore } from './../../../../../core/stores/app.store';

@Component({
  selector: 'app-briindlet-event-name',
  templateUrl: './briindlet-event-name.component.html',
  styleUrls: ['./briindlet-event-name.component.scss']
})
export class BriindletEventNameComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  actionCTA(event: any) {
    this.stepperActions.emit(event);
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_3'].patchValue({
    //  name: this.appService.cacheData?.step_3?.name
    // })
    // this.appService.cacheData.subscribe(res=>{
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_3'].patchValue({
    //     name: steps.step_3.name,
    //    })
    // })
  
  }

}
