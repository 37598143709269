<div class="terms-outer-div">
    <p>This  End  User  License  Agreement  (“EULA”)  is  a  binding  legal  agreement  between  you,  as  an individual or entity, and Nowgen, Inc. By downloading, installing, or using this application on any platform (the “Software”), you agree to be bound by the terms of this EULA. If you do not agree to  this  EULA,  do  not  use  the  Software  and  delete  it.  You  agree  that  installation  or  use  of  the Software signifies that you have read, understood, and agree to be bound by this EULA.For  clarification,  any  Nowgen  service  (“Nowgen  Service”)  accessed  through  the  Software,  or otherwise, is also subject to the Privacy Policy. Additional terms may apply to specific features.</p>
    <h3>1. Description of Software</h3>
    <p>The Software is a downloadable software application [and/or a website] that enables you to access to Briind directly from your mobile device [and/or computer] (“Device”). You may download the Software whether or not you use the Nowgen Service, but you must associate it with your Briind account to enable its full functionality.</p>
    <h3>2. License</h3>
    <p>Nowgen, Inc. hereby grants to you, upon the terms and subject to the conditions of this EULA, a limited, non-exclusive, non-sublicenseable, non-transferable personal license to:</p>
    <ol>
        <li>Use the Software for personal use;</li>
        <li>Use the Software for your organization; and</li>
        <li>Install the Software</li>
    </ol> 
    <p>The license conferred to you hereunder is revocable at any time by Nowgen, Inc. in its full and absolute discretion, for any reason or no reason.</p>
    <h3>3. Title</h3>
    <p>Title, ownership and all rights (including without limitation intellectual property rights) in and to the  Software  shall  remain  with  Nowgen,  Inc.  Except  for  those  rights  expressly  granted  in  this EULA, no other rights are granted, whether express or implied.</p>
    <h3>4. Restrictions</h3>
    <p>You understand and agree that you shall only use the Software in a manner that complies with any and all applicable laws in (1) the jurisdictions in which you use the Software and (2) the State of Texas, United States of America. In the event of a conflict between the laws of the foregoing, you agree  to  comply  with  the  stricter  of  the  two.  Your  use  shall  be  in  accordance  with  applicable restrictions concerning privacy and intellectual property rights.</p>
    <p>You may not:</p>
    <ul>
        <li>Create derivative works based on the Software;</li>
        <li>Use the Software for any purpose other than as described herein;</li>
        <li>Copy or reproduce the Software except as described in this EULA;</li>
        <li>Sell,  assign,  license,  disclose,  distribute  or  otherwise  transfer  or  make  available  the Software or any copies of the Software in any form to any third parties;</li>
        <li>Alter, translate, decompile, reverse assemble or reverse engineer the Software, or attempt to do any of the foregoing, except to the extent this prohibition is not permitted under an applicable law; or</li>
        <li>Remove or alter any proprietary notices or marks on the Software.</li>
    </ul>
    <h3>5. Personal Information and Privacy</h3>
    <p>Our handling of personal information we collect through the Nowgen Services or the Software is governed by the Nowgen Privacy Policy.</p>
    <h3>6. Cookie Policy</h3>
    <p>Cookies are small pieces of text used to store information on web browsers. Cookies are used to store and receive identifiers and other information on computers, phones and other devices. Other technologies, including data that we store on your web browser or device, identifiers associated with your device and other software, are used for similar purposes (“Cookies”).</p>
    <p>We use Cookies if you have a Briind account. Cookies enable Nowgen, Inc. to offer the Nowgen Services to you and to understand the information that we receive about you, including information about your use of other websites and apps, whether or not you are registered or logged in. </p>
    <p>Cookies  help  us  provide,  protect  and  improve  the  Nowgen  Services,  such  as  by  personalizing content, and providing a safer experience. The Cookies that we use include session Cookies, which are deleted when you close your browser, and persistent Cookies, which stay in your browser until they expire or you delete them. While the Cookies that we use may change from time to time as we improve and update the Nowgen Services, we use them for the following purposes:</p>
    <ul>
        <li>
            Authentication: We use Cookies to verify your account and determine when you’re logged in so that we can make it easier for you to access the Nowgen Services and show you the appropriate experience and features.
        </li>
        <li>
            Security, site and product integrity: We use Cookies to help us keep your account, data and the Nowgen Services safe and secure. We also use Cookies to combat activity that violates our policies or otherwise degrades our ability to provide the Nowgen Services.
        </li>
        <li>
            Advertising,  recommendations,  insights  and  measurement:  We  use  Cookies  to  help  us show ads and to make recommendations for businesses and other organizations to people who  may  be  interested  in  the  products,  services  or  causes  they  promote.  We  also  use Cookies  to  help  measure  the  performance  of  ad  campaigns  for  businesses  that  use  the Nowgen Services.
        </li>
        <li>
            Site features and services: We use Cookies to enable the functionality that helps us provide the Nowgen Services. We also use Cookies to help provide you with content relevant to your locale.
        </li>
        <li>
            Performance: We use Cookies to provide you with the best experience possible.
        </li>
        <li>
            Analytics and research: We use Cookies to better understand how people use the Nowgen Services so that we can improve them.
        </li>
    </ul> 
    <h3>7. Satisfaction Guarantee; Termination of Services</h3>
    <p>If you are not completely satisfied with the Software for any reason, you may contact Nowgen, Inc. and file a formal complaint.</p>
    <p>Nowgen may, in its sole and absolute discretion, issue a refund in the</p>
    <p>Upon termination of your relationship with Nowgen, you shall cease any and all use and destroy all  copies,  full  or  partial,  of  the  Software  for  which  you  no  longer  possess  a  paid-up  license. Nowgen, Inc. reserves the right to disable the Software and/or any Software product license keys and/or activation codes issued to you for the refunded Software. We will not refund or credit the difference between the price you were charged and any later price reduction, such as a special sales event, promotion or discontinuation pricing.</p>
    <h3>8. No Warranty</h3>
    <p>NOWGEN,  INC.  DOES  NOT  WARRANT  THAT  THE  FUNCTIONS  CONTAINED  IN  THE SOFTWARE WILL MEET ANY REQUIREMENTS OR NEEDS YOU MAY HAVE, OR THAT THE SOFTWARE WILL OPERATE ERROR FREE, OR IN AN UNINTERRUPTED MANNER, OR   THAT   ANY   DEFECTS   OR   ERRORS   WILL   BE   CORRECTED,   OR   THAT   THE SOFTWARE  IS  FULLY  COMPATIBLE  WITH  ANY  PARTICULAR  PLATFORM.  THE SOFTWARE  IS  OFFERED  ON  AN  "AS-IS"  BASIS  AND  NO  WARRANTY,  EITHER EXPRESS  OR  IMPLIED,  IS  GIVEN.  NOWGEN,  INC.  EXPRESSLY  DISCLAIMS  ALL WARRANTIES  OF  ANY  KIND,  WHETHER  EXPRESS  OR  IMPLIED,  INCLUDING,  BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. SOME JURISDICTIONS DO NOT ALLOW THE WAIVER OR EXCLUSION OF IMPLIED WARRANTIES SO THEY MAY NOT APPLY TO YOU.</p>
    <h3>9. Right to Terminate or Modify Software</h3>
    <p>Nowgen, Inc. may modify the Software and this EULA without notice. You may cease use of the Software at any time. Either party may terminate this EULA at any time, with or without notice.</p>
    <h3>10. U.S. Government Restricted Rights (I believe this section can be deleted)</h3>
    <p>By accepting delivery, the government agrees that the Software and accompanying documentation qualifies  as  “commercial”  computer  software  within  the  meaning  of  the  applicable  acquisition regulations. The terms and conditions of this EULA govern the government's use and disclosure of the Software and supersede any conflicting terms and conditions. If this EULA fails to meet the government's needs or is inconsistent in any way with federal law, the government must return the Software, unused, to Nowgen, Inc.</p>
    <h3>11. Open Source (Will they be using open source?)</h3>
    <p>The Software may contain or be provided together with open source software. Each item of open source  software  is  subject  to  its  own  applicable  license  terms.  Copyrights  to  the  open  source software are held by the respective copyright holders indicated therein.</p>
    <h3>12. Indemnification</h3>
    <p>To  the  fullest  extent  permitted  by  law,  you  agree  to  indemnify  and  otherwise  hold  harmless Nowgen, Inc., its officers, employees, agents, subsidiaries, affiliates and other partners from any direct, indirect, incidental, special, consequential or exemplary damages arising out of, relating to, or resulting from your use of the Software or any other matter relating to the Software.</p>
    <h3>13. Limitation of Liability</h3>
    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT NOWGEN, INC. SHALL NOT BE LIABLE   FOR   ANY   INDIRECT,   INCIDENTAL,   SPECIAL,   CONSEQUENTIAL   OR EXEMPLARY  DAMAGES,  INCLUDING  BUT  NOT  LIMITED  TO,  DAMAGES  FOR  LOSS OF  PROFITS,  GOODWILL,  USE,  DATA  OR  OTHER  INTANGIBLE  LOSSES  (EVEN  IF LINKEDIN  HAS  BEEN  ADVISED  OF  THE  POSSIBILITY  OF  SUCH  DAMAGES).  IN  NO EVENT  WILL  NOWGEN,  INC.’S  AGGREGATE  LIABILITY  TO  YOU  EXCEED  THE AMOUNT OF LICENSING FEES PAID BY YOU TO NOWGEN, INC. THESE LIMITATIONS AND EXCLUSIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE  OF  ANY  LIMITED  REMEDY.  SOME  JURISDICTIONS  DO  NOT  ALLOW  THE LIMITATIONS OF DAMAGES AND/OR EXCLUSIONS OF LIABILITY FOR INCIDENTAL OR    CONSEQUENTIAL    DAMAGES.    ACCORDINGLY,    SOME    OF    THE    ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
    <h3>14. Export Restrictions</h3>
    <p>This EULA is expressly made subject to any laws, regulations, orders or other restrictions on the export of software from the United States of America, and may be subject to export and import regulations  of  other  countries.  You  acknowledge  and  agree  not  to  import,  export,  re-export, transfer or use, directly or indirectly, the Software without compliance with such laws, regulations, orders or other restrictions.</p>
    <h3>15. Digital Millennium Copyright Act Policy and Procedure</h3>
    <p>Nowgen, Inc. respects intellectual property rights of others and expects users of Nowgen Services to do the same.  This policy outlines how Nowgen, Inc. addresses claims of unauthorized use of copyrighted materials in accordance with the Digital Millennium Copyright Act (“DMCA”), the text of which may be found on the  U.S. Copyright Office website.</p>
    <i class="txtUnderline">Filing a DMCA notice claiming copyright infringement</i>
    <p>If you have reason to believe that your content has been copied and/or is accessible on the Nowgen Services in a way that constitutes copyright infringement, or that the Nowgen Services contains links or other references to another site, application, destination or service that contains content or activity that infringes your copyright(s), please send us a takedown notice of alleged violations in accordance with procedures allowed by the DMCA. You may provide written notice of your claim (“DMCA Notice”) to Nowgen, Inc.’s Designated Agent listed below. Please note that any other type of request will not receive a response through the process outlined below.</p>
    <p>For your DMCA Notice to be effective, it must include all of the following information:</p>
    <ul>
        <li>
            A description of the copyrighted work that you claim has been infringed
        </li>
        <li>
            Information reasonably sufficient to permit us to locate infringing material.
        </li>
        <li>
            Information reasonably sufficient to allow Nowgen, Inc. to contact you (mailing address, telephone number, and email address)
        </li>
        <li>
            A statement by you that: (1) You have a good-faith belief that the use of the material in the manner asserted is not authorized by the copyright owner, its agent, or the law; (2) The information given is accurate, and, under penalty of perjury, you are authorized to act on behalf of the copyright owner; and (3) A digital or paper signature from the copyright owner or a person authorized to act on their behalf.
        </li>
    </ul>
    <p>Important: There are financial and legal penalties for fraudulent and/or bad-faith claims. You may be liable for damages (including costs and attorneys' fees) if you knowingly misrepresent that the material is infringing your copyright. You should talk to an attorney before sending us a DMCA Notice if you are unsure whether the material infringes your copyright.</p>
    <p class="txtCenter">Nowgen, Inc.’s Designated Agent:</p>
    <div class="txtCenter">
        <div>_________________________________________________</div>
        <div class="mrgntop15">_________________________________________________</div>
        <div class="mrgntop15">_________________________________________________</div>
    </div>
    <p>Nowgen, Inc. processes claims in the order they are received. Nowgen, Inc.’s response may include removing the allegedly infringing material or restriction of access/distribution of such material. If Sophos removes or restricts access to content in this way, it will make a good-faith effort to contact the affected user and provide information concerning the removal or restriction of access, including a copy of the DMCA Notice, along with instructions for filing a counter-notice.</p>
    <i class="txtUnderline">Filing a counter-notice</i>
    <p>If you believe your content was removed or disabled by Nowgen, Inc. based on an improper DMCA Notice, you may file a counter-notice with Nowgen, Inc.’s Designated Agent listed above and request that Nowgen, Inc. restore placement of your content. Please DO NOT re-post the removed or disabled content.</p>
    <p>To submit a counter-notice, you must respond to Nowgen, Inc.’s original notice with the following:</p>
    <ul>
        <li>
            Information about the material or the reference or link that has been removed or to which access has been disabled and the location at which the appeared before it was removed or access to it was disabled
        </li>
        <li>
            Your name, mailing address, telephone number, and email
        </li>
        <li>
            A statement that: (1) Under penalty of perjury, you have a good-faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; (2) That you consent to the jurisdiction of the federal or state courts in Bexar County, San Antonio, USA, and that you will accept service of process from the person who provided the notification under the DMCA or an agent of such person; and (3) a digital or paper signature. 
        </li>
    </ul>
    <p>When Nowgen, Inc. receives a valid counter-notice, it will forward a copy to the party who sent the original DMCA Notice. Nowgen, Inc. may, in its sole discretion, restore or reinstate the content based on the DMCA counter-notice. However, in no event will Nowgen, Inc. restore or reinstate the content if it receives notice from the copyright claimant that a legal action to prevent infringement has been filed.</p>
    <p>Important: You may be liable for damages (including costs and attorneys’ fees) if you materially misrepresent that your material is not infringing the copyrights of others.    </p>
    <i class="txtUnderline">Repeat Infringer Policy</i>
    <p>It is Nowgen, Inc.’s policy, in appropriate circumstances, to disable and/or terminate the accounts of users who are found to repeatedly infringe or are repeatedly accused with infringing the copyrights of others. Accordingly, Nowgen, Inc. may in its sole discretion suspend, disable and/or terminate the accounts of users who have been identified as engaging in infringing activities or for other related reasons.</p>
    <h3>16. Personally Identifiable Information</h3>
    <p>The Software remotely, collects, processes, uses, shares and stores information, including, personally identifiable information (“Data Handling”). The purpose of such Data Handling is for: analysis, reporting, evaluation and analytics, authentication and validation, endorsements, and recruitment.  </p>
    <h3>17. General</h3>
    <p>You and Nowgen, Inc. agree that the laws of the State of Texas, U.S.A., excluding its conflict of laws rules, shall exclusively govern any dispute relating to this EULA, the Software and/or Nowgen Service; and you and Nowgen, Inc. both agree that all claims and disputes can be litigated only in the federal or state courts in Bexar County, San Antonio, USA, and you and Nowgen, Inc. each agree to personal jurisdiction in those courts. This EULA constitutes the entire agreement between you and Nowgen, Inc. regarding the Software. If any provision of this EULA is held by a court of competent jurisdiction to be contrary to law, such provision will be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law and the remaining provisions of this EULA will remain in full force and effect. You may not assign this EULA, and any assignment of this EULA by you will be null and void. Nowgen, Inc., Briind, and other Nowgen-owned logos and names are trademarks of Nowgen, Inc. and its affiliates. You agree not to display or use these trademarks in any manner without Nowgen, Inc.’s prior, written permission. The section titles and numbering of this EULA are displayed for convenience and have no legal effect.</p>
</div>
