export class PaymentModel {
    amount?: number = 0;
    status: string = '';
    result: Result = new Result();
}

export class Result {
    id: string = '';
    object: string = '';
    amount: number = 0;
    amount_captured: number = 0;
    amount_refunded: number = 0;
    application: null = null;
    application_fee: null = null;
    application_fee_amount: null = null;
    balance_transaction: string = '';
    billing_details: BillingDetails = new BillingDetails();
    calculated_statement_descriptor: string = '';
    captured: boolean = false;
    created: number = 0;
    currency: string = '';
    customer: null = null;
    description: string = '';
    destination: null = null;
    dispute: null = null;
    disputed: boolean = false;
    failure_code: null = null;
    failure_message: null = null;
    fraud_details: FraudDetails = new FraudDetails();
    invoice: null = null;
    livemode: boolean = false;
    metadata: FraudDetails = new FraudDetails();
    on_behalf_of: null = null;
    order: null = null;
    outcome: Outcome = new Outcome();
    paid: boolean = false;
    payment_intent: null = null;
    payment_method: string = '';
    payment_method_details: PaymentMethodDetails = new PaymentMethodDetails();
    receipt_email: null = null;
    receipt_number: null = null;
    receipt_url: string = '';
    refunded: boolean = false;
    refunds: Refunds = new Refunds();
    review: null = null;
    shipping: null = null;
    source: Source = new Source();
    source_transfer: null = null;
    statement_descriptor: null = null;
    statement_descriptor_suffix: null = null;
    status: string = '';
    transfer_data: null = null;
    transfer_group: null = null;
}

export class BillingDetails {
    address: Address = new Address();
    email: null = null;
    name: string = '';
    phone: null = null;
}

export class Address {
    city: string = '';
    country: string = '';
    line1: string = '';
    line2: string = '';
    postal_code: string = '';
    state: string = '';
}

export class FraudDetails {
}

export class Outcome {
    network_status: string = '';
    reason: null = null;
    risk_level: string = '';
    risk_score: number = 0;
    seller_message: string = '';
    type: string = '';
}

export class PaymentMethodDetails {
    card: Card = new Card();
    type: string = '';
}

export class Card {
    brand: string = '';
    checks: Checks = new Checks();
    country: string = '';
    exp_month: number = 0;
    exp_year: number = 0;
    fingerprint: string = '';
    funding: string = '';
    installments: null = null;
    last4: string = '';
    network: string = '';
    three_d_secure: null = null;
    wallet: null = null;
}

export class Checks {
    address_line1_check: null = null;
    address_postal_code_check: string = '';
    cvc_check: string = '';
}

export class Refunds {
    object: string = '';
    data: any[] = [];
    has_more: boolean = false;
    total_count: number = 0;
    url: string = '';
}

export class Source {
    id: string = '';
    object: string = '';
    address_city: string = '';
    address_country: string = '';
    address_line1: string = '';
    address_line1_check: null = null;
    address_line2: string = '';
    address_state: string = '';
    address_zip: string = '';
    address_zip_check: string = '';
    brand: string = '';
    country: string = '';
    customer: null = null;
    cvc_check: string = '';
    dynamic_last4: null = null;
    exp_month: number = 0;
    exp_year: number = 0;
    fingerprint: string = '';
    funding: string = '';
    last4: string = '';
    metadata: FraudDetails = new FraudDetails();
    name: string = '';
    tokenization_method: null = null;
}
