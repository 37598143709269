import { QbService } from 'src/app/core/services/qb.service';
import { AuthService } from './../../../../core/services/auth-service/auth.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BriindletService } from 'src/app/briindlet/services/briindlet.service';
import { confirmationAction, PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { UserService } from 'src/app/users/services/user.service';
@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  @Input('popup') popup: PopupModel = new PopupModel();
  @Input('removeConfirm') removeConfirm: any = false
  @Output('close') close = new EventEmitter();

  constructor(
    private appService: AppService,
    private userService: UserService,
    private organizationService: OrganizationService,
    public briindletService: BriindletService,
    private authService: AuthService,
    private qbService: QbService
  ) { }

  ngOnInit(): void {
  }

  onDelete(data: confirmationAction) {
    if (data.action_type?.toLowerCase() == "unfriend") {
      this.unFriendRequest(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "blockfriend") {
      this.blockFriendRequest(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "deletephoto") {
      this.removePhoto(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "deletevideo") {
      this.removeVideo(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "deletealbum") {
      this.removeAlbum(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "deletecard") {
      this.deleteCard(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "cancelbriindlet") {
      this.cancelBriindlet(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "cancelpromotion") {
      this.cancelPromotion(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "givecausedelete") {
      this.deleteGiveCause(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "canceladvertisement") {
      this.cancelAdvertisement(Number(data.id));
    } else if (data.action_type?.toLowerCase() == "deleteuser") {
      //console.log('here')
      this.deleteAccount();
    } else if (data.action_type?.toLowerCase() == "reportpost") {
      //console.log('Report Post here is it')
      this.reportPost({posts_id: Number(data.id)});
    } 
    else if (data.action_type?.toLowerCase() == "removepost" && (data?.item_type == 'UserShare' || data.item_type == 'OrganizationShare')) {
      this.removeSharedPost(Number(data.object_id));
    }else {
      this.removePost(Number(data.id));
    }
    //console.log(data);
  }

  /**
   * TODO: comment cancelAdvertisement
   * @description Cancels Advertisement
   * @param id 
   * @memberof ConfirmationPopupComponent
   */
   cancelAdvertisement(id: any){
    this.subscription.add(
      this.organizationService.cancelAdvertisement(id).subscribe((response: any) => {
        this.closePopup();
        if (response.status == "success") {
          this.showToggleToast(response);
        }
      }
      )
    );
  }

  /**
   * TODO: comment cancelPromotion
   * @description Cancels promotion
   * @param id 
   * @memberof ConfirmationPopupComponent
   */
  cancelPromotion(id: any){
    this.subscription.add(
      this.organizationService.cancelPromotion(id).subscribe((response: any) => {
        this.closePopup();
        if (response.status == "success") {
          this.showToggleToast(response);
        }
      }
      )
    );
  }

  removeSharedPost(id:number){
    if(this.authService.getSessionData().type.toLowerCase() == "user") {
      this.subscription.add(
        this.userService.removeSharedPost(id).subscribe(response => {
          this.closePopup()
          if (response == null) {
            this.appService.toggleToast({
              type: 'success',
              load: true,
              position: 'top-center',
              context: { description: "Deleted" }
            });
          }
          else {
            this.showToggleToast(response)
          }
        
        }, err => {
          let errorMsg = err?.error?.detail || err?.message;
          this.showToggleToast({message: errorMsg} ,"error");
        })
      )
    } else {
      this.subscription.add(
        this.organizationService.removeSharedPost(id).subscribe(response => {
          this.closePopup()
          if (response == null) {
            this.appService.toggleToast({
              type: 'success',
              load: true,
              position: 'top-center',
              context: { description: "Deleted" }
            });
          }
          else {
            this.showToggleToast(response)
          }
        }, err => {
          let errorMsg = err?.error?.detail || err?.message;
          this.showToggleToast({message: errorMsg} ,"error");
        })
      )
    }
  }

  removePost(id:number){
    if(this.authService.getSessionData().type.toLowerCase() == "user") {
      this.subscription.add(
        this.userService.removePost(id).subscribe(response => {
          this.closePopup()
        this.showToggleToast(response)
        }, err => {
          let errorMsg = err?.error?.detail || err?.message;
          this.showToggleToast({message: errorMsg} ,"error");
        })
      )
    } else {
      this.subscription.add(
        this.organizationService.removePost(id).subscribe(response => {
          this.closePopup()
          this.showToggleToast(response)
        }, err => {
          let errorMsg = err?.error?.detail || err?.message;
          this.showToggleToast({message: errorMsg} ,"error");
        })
      )
    }
  }

  deleteAccount() {
    let deleteAPIRequest;
    let authData = this.authService.getSessionData();
    if(authData && authData.type.toLowerCase() == "organization") {
      deleteAPIRequest = this.organizationService.deleteOrganization();
    } else {
      deleteAPIRequest = this.appService.deleteUser();
    }
    this.subscription.add(deleteAPIRequest.subscribe((response: any) => {
      this.qbService.findUser(this.authService.getSessionData().briind_id).then((response: any) => {
        this.qbService.deleteChatUser(response.id).then((response: any) => {
            this.closePopup()
            this.toggleToast(`${authData.type} Account Deleted Successfully`);
            setTimeout(()=> {
              this.appService.logoutSession();
            }, 2600); 
        }).catch(error => console.log("Something went wrong..."))
      })
      }, (errorResp) =>  {
        this.toggleToast(errorResp?.error?.detail, 'error');
      })
    );
  }

  toggleToast(msg: string, type = 'success' ) {
    this.appService.toggleToast({
      type: type,
      load: true,
      position: 'top-right',
      context: { description: `${msg}` }
    });
  }

  reportPost(data: any) {
    // this.subscription.add(
    //   this.appService.deleteSavedCard('', null).subscribe((response: any) => {
    //     this.closePopup();
    //   })
    // );
    this.subscription.add(
      this.appService.reportPost(data).subscribe((response: any) => {
        this.closePopup()
      })
    );
 
  }  
  /**
   * @description
   * this method is used for Unfriend
   *
   * @param {*} id
   * @memberof ConfirmationPopupComponent
   */
  unFriendRequest(id: number) {
    this.subscription.add(
      this.userService.unFriendRequest(id).subscribe(response => {
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * this method is used for BlockFriend
   *
   * @param {*} id
   * @memberof ConfirmationPopupComponent
   */
  blockFriendRequest(id: number) {
    this.subscription.add(
      this.userService.blockFriendRequest(id).subscribe(response => {
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * this method is used for delete a photo
   *
   * @param {*} [id]
   * @memberof ConfirmationPopupComponent
   */
   removePhoto(id?: number) {
    if(this.popup.context.userType == 'user') { 
      this.subscription.add(
        this.userService.removePhoto(id).subscribe((response: any) => {
          this.closePopup("true");
          if (response.status == "success") {
            this.showToggleToast(response);
          }
        }))
    } else {
      this.subscription.add(
      this.organizationService.removePhoto(id).subscribe((response: any) => {
        this.closePopup("true");
          if (response.status == "success") {
            this.showToggleToast(response);
          }
      }))
    }
  }

  /**
   * @description
   * this method is used for delete a photo
   *
   * @param {*} [id]
   * @memberof ConfirmationPopupComponent
   */
   removeVideo(id?: number) {
    if(this.popup.context.userType == 'user') { 
      this.subscription.add(
        this.userService.removeVideo(id).subscribe((response: any) => {
          this.closePopup();
          if (response.status == "success") {
            this.showToggleToast(response);
          }
        }))
    } else {
      this.subscription.add(
      this.organizationService.removeVideo(id).subscribe((response: any) => {
        this.closePopup();
          if (response.status == "success") {
            this.showToggleToast(response);
          }
      }))
    }
  }

  /**
   * @description
   * this method is used for delete an album
   *
   * @param {*} [event]
   * @memberof ConfirmationPopupComponent
   */
   removeAlbum(id?: number) {
    if(this.popup.context.userType == 'user') { 
      this.subscription.add(
        this.userService.removePost(id).subscribe((response: any) => {
          this.closePopup('true');
          if (response.status == "success") {
            this.showToggleToast(response);
          }
        }))
    } else {
      this.subscription.add(
      this.organizationService.removePost(id).subscribe((response: any) => {
        this.closePopup('true');
          if (response.status == "success") {
            this.showToggleToast(response);
          }
      }))
    }
  }

  /**
   * @description
   * this method is used for cancel briindlet 
   *
   * @memberof BriindletDetailComponent
   */
   cancelBriindlet(id?: number) {
    let data = {
      'id': id
    }
    this.subscription.add(
      this.briindletService.cancelBriindlet(data,this.popup.context.userType).subscribe((response: any) => {
        this.closePopup('true');
        if (response.status == "success") {
          this.showToggleToast(response);
        }
      })
    );
  }

  showToggleToast(response: any, type: string = 'success') {
    this.appService.toggleToast({
      type: type,
      load: true,
      position: 'top-center',
      context: { description: response?.message }
    });
  }


  /**
   * TODO: comment deleteCard
   * @description Deletes card
   * @param card 
   */
  deleteCard(id: any) {
    let apiType = this.popup.context.userType == 'user' ? 'users' : 'organizations';
    this.subscription.add(
      this.appService.deleteSavedCard(apiType, id).subscribe((response: any) => {
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * this method is used for delete cause (organization give cause)
   *
   * @param {*} id
   * @memberof ConfirmationPopupComponent
   */
   deleteGiveCause(id: number) {
    this.subscription.add(
      this.organizationService.deleteGiveCause(id).subscribe(response => {
        this.closePopup('true');
      })
    );
  }

  /**
    * @description
    * Click on close button this method will call
    *
    * @memberof ConfirmationPopupComponent
    */
  closePopup(event?: string) {
    this.close.emit(event);
  }

  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   * @memberof UserFriendsListComponent
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
