import { QbService } from './qb.service';
import {EventEmitter, Injectable} from '@angular/core';
import {CONSTANTS} from 'src/app/core/essentials/QBconfig';
declare var QB: any;
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  selfThis: any = this;
  public messages: any = [];
  public datesIds: any = [];
  messagesEvent: EventEmitter<any> = new EventEmitter();
  public intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entrie: any) => {
      if (document.visibilityState === 'visible' && entrie.isIntersecting) {
        const params = {
          messageId: entrie.target['dataset'].messageId,
          // tslint:disable-next-line:radix
          userId: parseInt(entrie.target['dataset'].userId),
          dialogId: entrie.target['dataset'].dialogId
        };
        const event = new CustomEvent('visibility', {
          detail: {
            dialogId: entrie.target['dataset'].dialogId,
            messageId: entrie.target['dataset'].messageId,
          }
        });
        entrie.target.dispatchEvent(event);
        QB.chat.sendReadStatus(params);
      }
    });
  }, {
    threshold: [1.0]
  });

  constructor(
    private qbService: QbService,
  ) {
    if ( QB && QB.chat) {
      QB.chat.onMessageListener = this.onMessageListener.bind(this);
      QB.chat.onSystemMessageListener = this.onSystemMessageListener.bind(this);
      QB.chat.onDeliveredStatusListener = this.onDeliveredStatusListener.bind(this);
      QB.chat.onReadStatusListener = this.onReadStatusListener.bind(this);
    }
    
  }


  // Get Messages
  public getMessages(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.chat.message.list(params, function (err: any, messages: any) {
        if (messages) {
          let itemMessages = messages.items
          itemMessages.sort((a: any, b: any) => {
              const dateA = new Date(a.updated_at).getTime();
              const dateB = new Date(b.updated_at).getTime();
              return dateA - dateB;
          });
  

          messages.items = itemMessages
          resolve(messages);
        } else {
          //console.log(err);
          reject(err);
        }
      });
    });
  }

  public sendMessage(dialog: any, msg: any) {
    const
      message = JSON.parse(JSON.stringify(msg)),
      jidOrUserId = dialog.xmpp_room_jid || dialog.jidOrUserId || this.qbService.getRecipientUserId(dialog.occupants_ids);
    message.id = QB.chat.send(jidOrUserId, msg);
    message.extension.dialog_id = dialog._id;
    return message;
  }

  public sendSystemMessage(userIds: any, systemMessage: any) {
    userIds.forEach((userId :any) => {
      QB.chat.sendSystemMessage(userId, systemMessage);
    });
  }

  public async setMessages(messages: any, scrollTo: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let users: any = [];
      const self = this;
      let messageSenderIDs = messages?.map((item: any) => {
        return item.sender_id;
      });
      let uuid = _.uniq(messageSenderIDs);
      self.datesIds = [];
      if (uuid.length > 0) {
        try {
          const response = await self.qbService.getUserList({
            field: 'id',
            value: uuid,
            per_page: uuid.length,
          });
          users = response;
          for (let user of users) {
            if (user.custom_data) {
              const fileUID = JSON.parse(user.custom_data)['avatar_uid'];
              user.avatar_url = QB.content.privateUrl(fileUID);
            } else {
              user.avatar_url = 'assets/images/add-pic-layer.png';
            }
            self.qbService.addToCache(user);
          }
          messages.forEach((message: any) => {
            if (!(message.date_sent instanceof Date)) {
              message.date_sent = new Date(+message.date_sent * 1000);
              for (const user of users) {
                if (user.id == message.sender_id) {
                  message.avatar_url = user.avatar_url;
                  message.briind_id = user.login;
                  let currentUserData = JSON.parse(localStorage.getItem('profileData') || '');
                  if (message.briind_id === currentUserData.briind_id) message.avatar_url = currentUserData.profile_pic;
                }
              }
            }
            self.addMessageToDatesIds(message);
          });
  
          this.messages = messages;
          this.messagesEvent.emit(self.datesIds);
          resolve(self.datesIds)
          
        } catch (error) {
          // Handle any errors that might occur during the asynchronous operations.
          console.error(error);
          reject(error); // Reject the promise in case of an error
        }
      } else {
        resolve([]); // Resolve with an empty array if uuid.length is 0
      }
    });
  }
  
  

  public addMessageToDatesIds(message:any) {
    const
      self = this,
      date = new Date(message.created_at),
      // month = date.toLocaleString('en-us', {month: 'long'}),
      key: any = moment(date).format('DD-MMM-YYYY') ;
    if (self.datesIds[key] === undefined) {
      self.datesIds[key] = [];
    }
    message['status'] = self.getMessageStatus(message);
    if (message.attachments) {
      message.attachments = message.attachments.map((attachment:any) => {
        attachment.src = QB.content.publicUrl(attachment.id) + '.json?token=' + QB.service.getSession().token;
        return attachment;
      });
    }
    if (message.read_ids && message.read_ids.indexOf(self.qbService.user.id) === -1) {
      message.visibilityEvent = true;
    }

    if (self.qbService._usersCache[message.sender_id]) {
      message['full_name'] = self.qbService._usersCache[message.sender_id].name;
    } else {
      message['full_name'] = message.sender_id;
    }
    self.datesIds[key].push(message);
  }

  public fillNewMessageParams(userId:any, msg:any) {
    let self = this,
      message: any = {
        _id: msg.id,
        attachments: [],
        created_at: +msg.extension.date_sent || Date.now(),
        date_sent: +msg.extension.date_sent || Date.now(),
        delivered_ids: [userId],
        message: msg.body,
        read_ids: [userId],
        sender_id: userId,
        chat_dialog_id: msg.extension.dialog_id,
        selfReaded: userId === this.qbService.user.id,
        read: 0,
        avatar_url: 'assets/images/add-pic-layer.png'
      };
      let user = JSON.parse(localStorage.getItem('loggedinUser') || '');
      let data = JSON.parse(localStorage.getItem('profileData') || '');
      if (user.custom_data) {
        const fileUID = JSON.parse(user.custom_data)['avatar_uid'];
        message.avatar_url = QB.content.privateUrl(fileUID);
      }else{
        message.avatar_url = data.profile_pic;
      }
      
    if (msg.extension.attachments) {
      message.attachments = msg.extension.attachments;
    }

    if (msg.extension.notification_type) {
      message['notification_type'] = msg.extension.notification_type;
    }

    if (msg.extension.new_occupants_ids) {
      message['new_occupants_ids'] = msg.extension.new_occupants_ids;
    }

    message['status'] = (userId !== this.qbService.user.id) ? self.getMessageStatus(message) : undefined;

    return message;
  }

  getMessageStatus(message:any) {
    if (message.sender_id !== this.qbService.user.id) {
      return undefined;
    }
    const
      self = this,
      deleveredToOcuupants = message.delivered_ids.some(function (id :any) {
        return id !== self.qbService.user.id;
      }),
      readedByOccupants = message.read_ids.some(function (id: any) {
        return id !== self.qbService.user.id;
      });
    return !deleveredToOcuupants ? 'sent' :
      readedByOccupants ? 'read' : 'delivered';
  }

  private onSystemMessageListener = function (this:any, message:any) {
    const self = this;
    if (message.extension === undefined || !message.extension.dialog_id === undefined
    ) {
      return false;
    }
    switch (message.extension.notification_type) {
      case CONSTANTS.NOTIFICATION_TYPES.NEW_DIALOG :
        self.dialogService.getDialogById(message.extension.dialog_id).then(function (dialog: any) {
          if (
            (self.dashboardService.components.chatsClicked &&
              dialog.type !== CONSTANTS.DIALOG_TYPES.PUBLICCHAT) ||
            (!self.dashboardService.components.chatsClicked &&
              dialog.type === CONSTANTS.DIALOG_TYPES.PUBLICCHAT)
          ) {
            if (dialog.xmpp_room_jid) {
              self.dialogService.joinToDialog(dialog);
            }
            const tmpObj: any = {};
            tmpObj[dialog._id] = dialog;
            self.dialogService.dialogs = Object.assign(tmpObj, self.dialogService.dialogs);
            self.dialogService.dialogsEvent.emit(self.dialogService.dialogs);
          }
        }).catch((error: any) => {
          console.error(error);
        });
        break;
      case CONSTANTS.NOTIFICATION_TYPES.UPDATE_DIALOG:
      case CONSTANTS.NOTIFICATION_TYPES.LEAVE_DIALOG:
        self.dialogService.getDialogById(message.extension.dialog_id).then((dialog: any) => {
          if (dialog.xmpp_room_jid) {
            self.dialogService.joinToDialog(dialog);
          }
          const tmpObj: any = {};
          delete self.dialogService.dialogs[message.extension.dialog_id];
          tmpObj[message.extension.dialog_id] = dialog;
          self.dialogService.dialogs = Object.assign(tmpObj, self.dialogService.dialogs);
          self.dialogService.dialogsEvent.emit(self.dialogService.dialogs);
        });
        break;
    }
  };

  private onMessageListener = function (this: any, userId :any, message:any,) {
    const self = this;
    message.extension.date_sent = new Date(+message.extension.date_sent * 1000);
    message = self.fillNewMessageParams(userId, message);
    if (userId === self.qbService.user.id) {
      return false;
    }
    if (message.markable) {
      QB.chat.sendDeliveredStatus({
        messageId: message._id,
        userId: userId,
        dialogId: message.chat_dialog_id
      });
    }
    self.dialogService.setDialogParams(message);
    if (message.chat_dialog_id === self.dialogService.currentDialog._id) {
      self.messages.push(message);
      self.addMessageToDatesIds(message);
      self.messagesEvent.emit(self.datesIds);
    }
  };

  private onReadStatusListener = function (this:any,messageId:any, dialogId:any) {
    const self = this;
    if (dialogId === self.dialogService.currentDialog._id) {
      for (const [key] of Object.entries(self.datesIds)) {
        // @ts-ignore
        for (let i = 0; i < self.datesId[key].length; i++) {
          if (self.datesId[key][i]._id === messageId) {
            self.datesIds[key][i].status = 'read';
          }
        }
      }
      self.messagesEvent.emit(self.datesIds);
    }
  };

  private onDeliveredStatusListener = function (this: any,messageId:any, dialogId:any) {
    const self = this;
    if (dialogId === self.dialogService.currentDialog._id) {
      for (const [key] of Object.entries(self.datesIds)) {
        // @ts-ignore
        for (let i = 0; i < self.datesIds[key].length; i++) {
          if (self.datesIds[key][i]._id === messageId && self.datesIds[key][i].status !== 'read') {
            self.datesIds[key][i].status = 'delivered';
          }
        }
      }
      self.messagesEvent.emit(self.datesIds);
    }
  };


}
