import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if(new Date().getDate() == new Date(value).getDate()){
        if (args && args.humanize) {
          return "Today ";
        } else {
          return "Today " + moment(value).format('h:mm A');
        }
        
      } else if((new Date().getDate() - 1) == new Date(value).getDate()){
        return 'Yesterday';
      } else if ((new Date().getFullYear()) == new Date(value).getFullYear()){
        if (args && args.humanize) {
          return (`${moment(value).format('MMMM DD')}`);
        } else {
          return (`${moment(value).format('MMMM DD')} at ${moment(value).format('LT')}`);
        }
        
      } else {
        if (args && args.humanize) {
          return (`${moment(value).format('MMMM DD,YYYY')}`);
        } else {
          return (`${moment(value).format('MMMM DD,YYYY')} at ${moment(value).format('LT')}`);
        }
        
      }
    } else {
        value = '';
    }
    return value;
  }

}

