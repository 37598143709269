import { AuthService } from 'src/app/core/services/auth-service/auth.service';

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopupModel } from 'src/app/core/models';
import * as moment from 'moment';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { getTimeForDate } from 'src/app/core/essentials/common.libs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-briindlet-popup',
  templateUrl: './create-briindlet-popup.component.html',
  styleUrls: ['./create-briindlet-popup.component.scss']
})
export class CreateBriindletPopupComponent implements OnChanges {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  form: FormGroup = new FormGroup({});
  cacheBrind = localStorage.getItem('brindlet-pro-create')
  previousStepperCount: number = 0;
  stepperCount: number = 1;
  eventCustomization: any;
  slectedEventType: string = 'createBriindlte';
  // eventCustomization: any =  templateOne;
  selectedGuestImages: any = [];
  brinndletMedias: any = [];
  formData: any = new FormData();
  briindletData: any = {};
  briindletResponse: any;
  userType: any;
  previousStep:number = 0;
  form2: FormGroup = new FormGroup({});
  constructor(
    private fb: FormBuilder,
    public appStore: AppStore,
    private appService: AppService,
    private authService: AuthService,
    public router: Router,
  ) { 
    this.userType = this.authService.getSessionData().type.toLowerCase();
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    let guestEntry = this.initArrayForm('guest')
    let regionEntry = this.initArrayForm('regions')
    this.form = fb.group({
      step_1: fb.group({
        is_event: ['True'],
      }),
      step_2: fb.group({
        template: ['', Validators.required],
        template_amount: [''],
        template_name: ['', Validators.required]
      }),
      step_3: fb.group({
        name: ['', [Validators.required]],
      }),
      step_4: fb.group({
        venue: [''],
      }),
      step_5: fb.group({
        description: ['', [Validators.required]],
      }),
      step_6: fb.group({
        event_from_date: [getTimeForDate(new Date(), 0, 0), [Validators.required]],
        event_to_date: [getTimeForDate(new Date(), 0, 0), [Validators.required]],
        event_to_time: [ getTimeForDate(new Date(), 23, 59), [Validators.required]],
        event_from_time: [getTimeForDate(new Date(), 0, 0), [Validators.required]],
      }),
      step_7: fb.group({
        registration_last_date: ['']
      }),
      step_8: fb.group({
        guest: fb.array(guestEntry),
        who_can_attend: ['',]
      }),
      step_9: fb.group({
        fee: ['']
      }),
      step_10: fb.group({
        registration_guidelines: ['']
      }),
      step_11: fb.group({
        organization_link: [''],
        briind_id: ['']
      }),
      step_12: fb.group({
        website_link: ['', [ Validators.pattern(reg)]]
      }),
      step_13: fb.group({
        photos:[[],[Validators.required]]
      }),
      step_14: fb.group({
        cause:['']
      }),
      step_15: fb.group({
        regions:fb.array(regionEntry)
      }),
      step_16: fb.group({
        hours: ['1', [Validators.required]],
        start_timestamp: [''],
        expiry_timestamp: ['']
      }),
      venue_item: ['']
    })
  }

  ngOnInit(): void {
    if(this.cacheBrind) {
      let cacheData = JSON.parse(this.cacheBrind)
      this.patchBrindletData(this.form, cacheData)
    }
  }

  sendCustomizeUI(event: any) {
    this.eventCustomization = event;
  }

  stepperActions(event: any) {
    switch (event) {
      case 'continue':
        this.stepperCount = this.stepperCount + 1;
        this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount - 1));
        break;
      case 'customize':
        this.setFormStructure();
        this.stepperCount = 20;
        this.previousStepperCount = JSON.parse(JSON.stringify(this.previousStepperCount + 1));
        break;
      case 'previous':
        this.previousStep = this.stepperCount;
        this.stepperCount = this.stepperCount - 1;
        this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount - 1));
        break;
      case 'reset':
        this.stepperCount =  this.previousStepperCount;
        this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount - 1));
        break;
      case 'continue_to_campaign':
        if (this.appStore.userType == 'user') {
          this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount));
          this.stepperCount = this.stepperCount + 2;
        } else {
          this.stepperCount = this.stepperCount + 1;
          this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount - 1));
        }
        break;
      case 'back_to_campaign':
        if (this.appStore.userType == 'user') { 
          this.stepperCount = this.stepperCount - 2;
        } else {
          this.stepperCount = this.stepperCount - 1;
        }
        this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount - 1));
        break;
      case 'go_to_preview':
        this.setFormStructure();
        this.stepperCount = this.stepperCount + 1;
        this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount - 1));
        break;
      case 'edit':
        this.stepperCount = 2;
        this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount - 1))
        break;
      case 'proceed_to_pay':
        this.previousStepperCount = JSON.parse(JSON.stringify(this.stepperCount))
        this.submitBriindletPro();
        break;  
      default:
        break;
    }
  }

  setParam() {
    let formData = new FormData();
    for (const key in this.briindletData) {
      switch (key) {
        case 'photos':
          if (this.briindletData[key] && this.briindletData[key].length) {
            for (let index = 0; index < this.briindletData[key].length; index++) {
              formData.append(`photos[${index}][photo]`, this.briindletData[key][index]['file'])
              formData.append(`photos[${index}][file_type]`, this.briindletData[key][index]['file_type'])
            }
          }
          break;
        case 'guest':
          if (this.briindletData[key] && this.briindletData[key].length) {
            for (let index = 0; index < this.briindletData[key].length; index++) {
              if (this.briindletData[key][index]['name']) {
                formData.append(`guests[${index}][photo]`, this.briindletData[key][index]['file'] || '');
                formData.append(`guests[${index}][name]`,this.briindletData[key][index]['name']);
              } 
            }
          }
          break;
        case 'regions':
          if (this.briindletData[key] && this.briindletData[key].length) {
            for (let index = 0; index < this.briindletData[key].length; index++) {
              formData.append(`regions[${index}][country]`,this.briindletData[key][index]['country']);
              formData.append(`regions[${index}][city]`,this.briindletData[key][index]['city']);
              formData.append(`regions[${index}][state]`,this.briindletData[key][index]['state']);
            }
          }
          break;
        case 'briind_id':
            if (this.briindletData[key]) {
              formData.append(`briind_id`, `${this.briindletData[key]}` );
            }
          break;
        case 'event_from':  
          formData.append(`event_from`, `${moment(this.briindletData[key]).format('YYYY-MM-DD HH:mm:ss')}` );
          break;
        case 'event_to':  
          formData.append(`event_to`, `${moment(this.briindletData[key]).format('YYYY-MM-DD HH:mm:ss')}` );
          break;
        case 'start_timestamp':
          if (this.briindletData[key]) {
            formData.append(`start_timestamp`, `${moment(this.briindletData[key]).format('YYYY-MM-DD HH:mm:ss')}` );
          }
          break;
        case 'expiry_timestamp':
          if (this.briindletData[key]) {
            formData.append(`expiry_timestamp`, `${moment(this.briindletData[key]).format('YYYY-MM-DD HH:mm:ss')}` );
          }
          break; 
        case 'registration_last_date':  
          if (this.briindletData[key] && this.briindletData[key] != "Invalid Date") {
            formData.append(`registration_last_date`, `${moment(this.briindletData[key]).format('YYYY-MM-DD')}` );
          }
          break;
        case 'cause':
          formData.append('cause', this.briindletData[key])
          break;  
        default:
          formData.append(key, this.briindletData[key])
          break;
      }
    }
    formData.append('template_data', JSON.stringify(this.eventCustomization))
    if (this.appStore.userType == 'organization') {
      formData.append('organization', this.authService.getSessionData().orgId)
    } else {
      formData.append('user', this.authService.getSessionData().orgId)
    }
    return formData;
  }

  setTemplateAmount( event: any) {
    this.briindletData.template_amount = event
  }

  submitBriindletPro() {
    // this.appService.createBriindletPro(this.userType+"s", this.setParam()).subscribe(response => {
    //   this.stepperCount = Number(this.stepperCount) + 1;
    //   this.briindletResponse = response;      
    // })
      this.appService.createBriindletPro(this.userType+"s", this.setParam()).subscribe(response => {this.closePopup()
        this.closePopup()
      this.router.navigate(['/briindlet/banner']);
      
    })
  }

  setFormStructure() {
    for (const key in this.form.value) {
      if (key == 'step_6') {
        let obj = {
          'event_to': `${moment(this.form['controls'][key]['value']['event_to_date']).format('YYYY-MM-DD')} ${moment(this.form['controls'][key]['value']['event_to_time']).format('HH:mm')}`,
          'event_from': `${moment(this.form['controls'][key]['value']['event_from_date']).format('YYYY-MM-DD')} ${moment(this.form['controls'][key]['value']['event_from_time']).format('HH:mm')}`
        }
        this.briindletData = {...this.briindletData, ...obj}
      } else {
        this.briindletData = {...this.briindletData, ...this.form['value'][key]}
      }
    }
  }

  /**
   * @description
   * Click on close button this method will call
   *
   * @memberof CreatePostPopupComponent
   */
  closePopup() {
    this.close.emit('icon');
  }

  /**
   * @description
   * Click on add button this method will call
   *
   * @memberof CreatePostPopupComponent
   */
   getRegion(event: any) {
    this.close.emit(event);
  }

  selectEventCTA(type: string) {
    this.slectedEventType = type;
  }


  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.popup && changes.popup.currentValue) {
      if(this.popup.context.stepperCount) {
        this.stepperCount = this.popup.context.stepperCount;
      }
      if (this.popup.context.briindletType) {
        this.slectedEventType = this.popup.context.briindletType;
      }
      if (this.popup.context.causeId) {
        this.form['controls']['step_14'].patchValue({
          cause: this.popup.context.causeId
        })
        this.stepperCount = 0;
      }
    }

    this.form.valueChanges.subscribe(val => {
      let a = JSON.stringify(this.form.getRawValue())
      localStorage.setItem('brindlet-pro-create', a)
    })
  }

  ngOnDestroy(): void {
    let a = JSON.stringify(this.form.getRawValue())
    localStorage.setItem('brindlet-pro-create', a)
  }

  patchBrindletData(group: FormGroup, cacheData: any): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.patchBrindletData(abstractControl, cacheData);
      } else {
        this.findVal(cacheData,key, abstractControl)
      }
    });
  }

  findVal(object: any, key: string, abstractControl: any): void {
      var value;
      Object.keys(object).some((k) => {
          if (k === key) {
              if(key === 'photos') return
              value = object[k];
              abstractControl?.patchValue(value)
              return true;
          }
          if (object[k] && typeof object[k] === 'object') {
              value = this.findVal(object[k], key, abstractControl);
              return value !== undefined;
          }
      });
      return value;
  }

  initArrayForm(type: string): any {
    let nLength = 1
    let finalFormControls: any = []

    if(this.cacheBrind) {
      let cacheData = JSON.parse(this.cacheBrind)
      if(type === 'guest') nLength = cacheData.step_8.guest.length 
      if(type === 'regions') nLength = cacheData.step_15.regions.length 
    }

    for(var i = 1; i <= nLength; i++) {
      if(type === 'guest')  finalFormControls.push(this.fb.group({ name: [''], photo: [''], file: ['']}))
      if(type === 'regions')  finalFormControls.push(this.fb.group({ city: [''], country: [''], state: ['']}))
    }
    return finalFormControls    
  }

}
