<div class="width100 txtCenter welcome-icon">
    <i class="icon-logo-white icns user-login"></i>
</div>
<section id="user-signin-page" class="gradient-auth-bg minHeight100vh pad20 overflowAuto overflowAuto flex flexAlignItemsCenter" id="main-bg-container">
    <div class="section1464 padtop50">
        <div class="txtCenter poppins-medium font35 fontWeight600 lineHeight40px color-FFFFFF padtop25 padbtm20 ">
            {{'welcome' | translate}}
        </div>
        <div class="desktop-card width50 mrgnauto bg-white bdrRadius10px boxShadow-RGBA00005">
            <div class="pad20">
                <div class="width100 font25 color-02457A mobi-color-white  txtCenter padtop5 fontWeight600 poppins-regular padbtm25">
                    {{'choose your briind account' | translate}} 
                </div>
                <div class="width100 color-02457A mobi-color-white  txtCenter padtop5 poppins-regular padbtm25"> 
                    <button type="submit" class="login-new-button btn-02457A ripple poppins-medium cursorPtr bdrRadius5px boxShadowNone width70 pad10 bg-02457A" [routerLink]="'/users/auth/signin'">
                         {{'Personal Account' | translate}} <i class="fa fa-arrow-right pl-1"></i> 
                    </button>
                    <button type="submit" class="login-new-button btn-02457A ripple poppins-medium cursorPtr  bdrRadius5px boxShadowNone width70 mrgntop20 pad10 mobi-color-white bg-18878C " [routerLink]="'/organizations/auth/signin'">
                        {{'Organization Account' | translate}} <i class="fa fa-arrow-right pl-1"></i> 
                    </button>
                </div>  
            </div>
        </div>

        <div class="margin-auto width50 color-FFFFFF font14 pad15 mrgntop10 color-84b3d9">
            <div class="flex flexJustifyCenter  mt-100">
                <i class="fa fa-exclamation-circle login-note-info"></i> 
                {{'With a personal account' | translate}} 
            </div>
            <div class="flex flexJustifyCenter login-page-small-text mrgntop10 ">
                <i class="fa fa-exclamation-circle login-note-info"></i> 
                {{'With an organizational account' | translate}}
            </div>
            <div class="flex flexJustifyCenter login-page-small-text mrgntop10 color-7de3e9 ">
                {{"dont have an account" | translate}}
                <a  class="cursorPtr fontWeight600 mobi-color-white font12 underline-anchor-hover color-FFFFFF poppins-regular pl-1" href="/signup"> Signup</a>
            </div>
        </div>
    </div>
</section>
