import { UserService } from 'src/app/users/services/user.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { Subscription } from 'rxjs';
import { AppStore } from 'src/app/core/stores/app.store';
import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss']
})
export class SharePopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  isLoader: boolean = false;
  subscription: Subscription = new Subscription()
  shareMessage: string = '';
  constructor(
    public appStore: AppStore,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private appService: AppService,
    private userService: UserService
  ) { }

  shareMessageEmit(event: any) {
    this.shareMessage = event;
  }

  closePopup() {
    this.close.emit('icon');
  }

  share() {
    this.isLoader = true;
    if (this.popup.context.userType == 'user') {
      this.sharePostUser({});
    }  else {
      this.sharePostOrganization({})
    }
  }



  /**
  * @description
  * this method is used for sharing post for organization
  *
  * @param {string} type
  * @memberof PostActionComponent
  */
   private sharePostOrganization(param: any) {
    param.description = this.shareMessage;
    param.post_type =  this.popup.context.postType;
    param.post_id = this.popup.context.postid;
    param.posted_on_type =  this.authService.getSessionData()?.type;
    param.posted_on_id = this.authService.getSessionData()?.orgId;
    this.subscription.add(
      this.organizationService.shareOrganizationPost(param).subscribe((response: any) => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `Shared successfully!` }
        });
        this.isLoader = false;
        this.closePopup();
      }, error => {
        this.isLoader = false;
      })
    );
  }

  /**
  * @description
  * this method is used for sharing post for user
  *
  * @param {string} type
  * @memberof PostActionComponent
  */
  private sharePostUser(param: any) {
    param.description =  this.shareMessage;
    param.post_type =   this.popup.context.postType;
    param.post_id = this.popup.context.postid;
    param.posted_on_type =  this.authService.getSessionData()?.type;
    param.posted_on_id = this.authService.getSessionData()?.orgId;
    this.subscription.add(
      this.userService.shareUserPost(param).subscribe((response: any) => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'top-center',
          context: { description: `Shared successfully!` }
        });
        this.isLoader = false;
        this.closePopup();
      }, error => {
        this.isLoader = false;
      })
    );
  }


  

  ngOnInit(): void {
    //console.log("immmmmm")
  }

}
