export class ActionModel {
    action_text : string = '';
    action_type : string = '';
    view_state ?: boolean = true;
    order ?: number = 0;
    primary_button ?: boolean = false;
    action_url ?: string = '';
}


export class confirmationAction {
    message ?: string = '';
    id ?: number = 0;
    action_type ?: string = '';
    item_type?:string='';
    object_id ?: number = 0;
}