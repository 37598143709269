<div class="{{userType}}">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 timeline-label mrgnbtm40">
        {{'how you like to promote this briindlet pro' | translate}}
    </div>
    <div class="body width100">
        <div class="width100 flex flexResponsive">
            <div class="mrgnbtm20">
                <div class="flex flexDirectionCol" [formGroup]="formGrp">
                    <div class="custom-radio {{appStore.iconClass}} flex flexAlignItemsStart flexAlignItemsCenter mrgnbtm10">
                        <input type="radio" value="city" name="promote_level" formControlName="promote_level"  class="flexNoShrink">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'city' | translate}}
                        </span>
                    </div>
                    <div class="custom-radio {{appStore.iconClass}} flex flexAlignItemsStart flexAlignItemsCenter mrgnbtm10">
                        <input type="radio" value="state" name="promote_level" formControlName="promote_level" class="flexNoShrink">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'state' | translate}}
                        </span>
                    </div>
                    <div class="custom-radio {{appStore.iconClass}} flex flexAlignItemsStart flexAlignItemsCenter ">
                        <input type="radio" value="country" name="promote_level" formControlName="promote_level" class="flexNoShrink">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'country level' | translate}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>