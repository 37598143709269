import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  public comingSoonType: string | any = 'users';

    constructor(private route: ActivatedRoute,
                private _location: Location) {
        this.route.params.subscribe(params => {
          this.comingSoonType = params['type'];
      });
    }

  ngOnInit() {
  }

  backButton() {
    this._location.back();
  }

}
