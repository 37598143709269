<div id="organization-preview" class="bdrRadius6px bg-white pad40 posRelative">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="header color-122A4D font25 lineHeight40px poppins-semibold txtCenter oraganization-header">
        {{'organization profile' | translate}}
    </div>
    <div class="body maxHeight520px overflowAuto custom-scrollbar posRelative">
        <div id="profile-cover" class="cover-image bgSizeCover bgRepeat-noRepeat flex posSticky zIndex1"
            style="background-image: url({{popup?.context?.cover_photo}})" *ngIf="popup?.context?.pathName != 'new-to-us' && popup?.context?.pathName != 'new_to_us'">
            <div class="height100p bg-00000094 width100 pad15 bdrRadius4px">
                <div class="flex flexResponsive flexAlignItemsCenter">
                    <div class="cover-profile-image posRelative user flex flexJustifyCenter">
                        <span class="online-img-shadow bdrRadius50p posRelative displayFlex">
                            <img [src]="popup?.context?.profile_pic" class="d150 bdrRadius50p">
                        </span>
                    </div>
                    <div class="pad10"></div>
                    <div class="profile-cover-display-name width100">
                        <div class="widt70">
                            <div class="poppins-semibold color-FFFFFF font20 txtTransformCaps">
                                {{popup?.context?.name}}
                            </div>
                            <div class="color-FFFFFF poppins-regular font14">
                                {{popup.context?.short_bio}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- social profile preview start here -->
        <ng-container
            *ngIf="popup?.context?.pathName != 'bio' && (!popup?.context?.profile_type || (popup?.context?.profile_type && popup?.context?.profile_type =='social'))">
            <div class="pad10"></div>
            <div class="boxShadowRGBA000005 bdr-1-EEEEEE bdrRadius6px flex">
                <div class="width30 bg-E5ECF1 pad10 desktopVisible">
                    <div class="color-02457A poppins-medium bg-white bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple"
                        [ngClass]="{'bg-white': popup?.context?.pathName == 'basic-info'}">
                        {{'name and basic informations' | translate}}</div>
                    <div [ngClass]="{'bg-white': popup?.context?.pathName == 'milestone'}"
                        class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                        {{'milestone' | translate}}</div>
                    <div [ngClass]="{'bg-white': popup?.context?.pathName == 'services'}"
                        class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                        {{'services we offer' | translate}}</div>
                    <div [ngClass]="{'bg-white': popup?.context?.pathName == 'contact-info'}"
                        class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                        {{'leaders information' | translate}}</div>
                    <div [ngClass]="{'bg-white': popup?.context?.pathName == 'social-media'}"
                        class="color-02457A poppins-medium bdrRadius30px font14 padleft10 padright15 padtop10 padbtm10 ripple">
                        {{'social media' | translate}}</div>
                </div>
                <div class="width70 pad15">
                    <ng-container [ngSwitch]="popup?.context?.pathName">
                        <!-- basic information start here -->
                        <ng-container *ngSwitchCase="'basic-info'">
                            <app-organization-basic-info-preview [data]="popup.context">
                            </app-organization-basic-info-preview>
                        </ng-container>
                        <!-- basic information end here -->

                        <!-- milestone start here -->
                        <ng-container *ngSwitchCase="'milestone'">
                            <app-organization-milestone-preview [data]="popup.context?.milestone_info_list">
                            </app-organization-milestone-preview>
                        </ng-container>
                        <!-- milestone end here -->

                        <!-- services start here -->
                        <ng-container *ngSwitchCase="'services'">
                            <app-organization-services-offered-preview [data]="popup.context?.services_info_list">
                            </app-organization-services-offered-preview>
                        </ng-container>
                        <!-- services end here -->

                        <!-- social media start here -->
                        <ng-container *ngSwitchCase="'social-media'">
                            <app-organization-social-media-preview [data]="popup.context">
                            </app-organization-social-media-preview>
                        </ng-container>
                        <!-- social media end here -->

                        <!-- leaders start here -->
                        <ng-container *ngSwitchCase="'leaders'">
                            <app-organization-leaders-preview [data]="popup.context?.leaders_list_info">
                            </app-organization-leaders-preview>
                        </ng-container>
                        <!-- leaders end here -->

                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!-- social profile preview end here -->

        <!-- bussiness profile preview start here -->
        <ng-container *ngIf="popup?.context?.profile_type == 'bussiness'">
            <div class="pad10"></div>
            <ng-container [ngSwitch]="popup?.context?.pathName">
                <!-- state start here -->
                <ng-container *ngSwitchCase="'state'">
                    <div class="flex flexWrap flexResponsive width100">
                        <app-bussiness-invite-card [data]="item" class="width50Minus10pxLap width100"
                            *ngFor="let item of popup?.context?.branch_invite_list"></app-bussiness-invite-card>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'district'">
                    <div class="flex flexWrap flexResponsive width100">
                        <app-bussiness-invite-card [data]="item" class="width50Minus10pxLap width100"
                            *ngFor="let item of popup?.context?.branch_invite_list"></app-bussiness-invite-card>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'ministries'">
                    <div class="mrgnbtm10 organization" *ngFor="let item of popup?.context?.ministries_list">
                        <app-ministry-list-item-tab [data]="item"></app-ministry-list-item-tab>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'new-to-us'">
                    <div class="width100">
                        <app-organization-new-to-us-template  [data]="popup?.context" class="width100">
                        </app-organization-new-to-us-template>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'about'">
                    <div class="width100">
                        <app-organization-bussiness-about-preview [data]="popup?.context" class="width100">
                        </app-organization-bussiness-about-preview>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'give'">
                    <div class="width100 boxShadow-RGBA00005 bdr-1-EEEEEE bdrRadius6px organization pad15">
                        <ng-container *ngFor="let item of popup?.context?.causes_list; let i = index;">
                            <div class="mrgnbtm10">
                                <app-tab-panel [childTemplate]="list" [label]="item.title"
                                    [iconClass]="'icon-down-arrow-round green'">
                                </app-tab-panel>
                                <ng-template #list>
                                    <app-organization-give-preview [data]="item"></app-organization-give-preview>
                                </ng-template>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>


                <!-- state end here -->
            </ng-container>
        </ng-container>
        <!-- bussiness profile preview end here -->
    </div>