import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { UserService } from '../../../../users/services/user.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationService } from '../../../../organizations/service/organization.service';
import { AppService } from 'src/app/core/services/app.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-create-post-popup',
  templateUrl: './create-post-popup.component.html',
  styleUrls: ['./create-post-popup.component.scss']
})
export class CreatePostPopupComponent implements OnChanges,OnDestroy {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  @Input('uploadedFiles') uploadedFiles: any = [];
  showCreatePost: boolean = true;
  subscription: Subscription = new Subscription();
  description: string = '';
  isShowGifSection: boolean = false;
  gifList: any = [];
  selectedData: any[] = [];
  taggedNames: any = '';
  isShowTag: boolean = false;
  btnPost: boolean = true;
  submittingForm: boolean = false;
  loader = this.loadingBar.useRef();
  postPermmision: string = 'everyone';
  removeIDs: any = [];
  showMore: boolean =  false;
  sharePostDetail: any; 
  constructor(
    private appService: AppService,
    private userService: UserService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    public loadingBar: LoadingBarService,
    public appStore: AppStore
  ) { }

  urlify(text: any) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url: any) {
      return '<a class="post_link" target="_blank" href="' + url + '">' + url + '</a>';
    })
  }

   getGifDatas() {
    this.subscription.add(
      this.appService.getGifDatas({}).subscribe(response => {
        this.gifList = response;
        this.isShowGifSection = true;
      })
    )
  }

  capFirst() {
    return this.appStore.userType[0].toUpperCase() + this.appStore.userType.slice(1);
  }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof CreatePostPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }

  /**
   * @description
   * this method is used for remove the files
   *
   * @param {number} index
   * @memberof CreatePostPopupComponent
   */
  removeFile(index: number, file ?: any) {
    this.uploadedFiles.splice(index,1);
    this.removeIDs.push(file)
  }

  /**
   * @description
   * this method is used for adding files
   *
   * @param {*} [event]
   * @memberof CreatePostPopupComponent
   */
  uploadedFile(event ?: any) {
    this.isShowGifSection = false;
    this.isShowTag = false;
    switch (event.type) {
      case 'emoji':
        this.description = this.description + event.value;
        this.btnPost = false;
        break;
      case 'gif':
        this.getGifDatas();
        break;
      case 'tag':
        this.isShowTag = true;
        break;  
      default:
        if (this.uploadedFiles && this.uploadedFiles.length != 0) {
          this.uploadedFiles.push(event);
        } else {
          this.uploadedFiles = [event];
        }
        break;
    }
    
  }

  /**
   * @description
   * this method is used for create post
   *
   * @memberof CreatePostPopupComponent
   */

  postbtn() {
    if (this.description.length > 0 && this.description.trim().length > 0) {
      this.btnPost = false;
    } else {
      this.btnPost = true;
    }
  }

  selectPrivacy(event: any) {
    this.postPermmision = event;
    if (this.postPermmision == 'private') {
      this.selectedData = [];
      this.taggedNames = '';
    }
  }

  createPost() {
    this.loader.start();
    this.submittingForm=true
    let formData: any = new FormData();
    if (this.selectedData) {
      if (this.popup.context.userType == 'user') {
        this.selectedData.forEach((element, i) => {
          formData.append(`briind_ids[${i}]`, element.briind_id)
        })
      }
    }
    let siteLink = this.description;

    if(new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(siteLink)) {
      let result = this.urlify(siteLink);
      formData.append('description', result);
    }  
    else{
      formData.append('description', this.description);
    }
    // if (!this.popup.context.post || !this.popup.context.post.is_share) {
     
    // } else 


    if (this.popup.context?.post?.item_type?.toLowerCase()?.includes('share')) {
      formData.append('post_type', `${this.popup.context?.post?.post?.item_type }`);
      formData.append('post_id', `${this.popup.context?.post?.post?.id}`);
      formData.append('posted_on_type', `${this.capFirst()}`)
      formData.append('posted_on_id', `${this.popup.context.userData.id}`)
    } else {
      if (!this.popup?.context?.post?.id) {
        formData.append('post_type', this.popup.context.postType);
      }
      formData.append('post_to_type', this.popup.context.postToType || this.authService.getSessionData().type);
      formData.append('post_to_id', this.popup.context.postToId || this.authService.getSessionData().orgId || this.authService.getSessionData().id );
    }
    formData.append('post_permission', this.postPermmision);
    

    for (let index = 0; index < this.uploadedFiles.length; index++) {
      if (this.uploadedFiles[index].file) {
        switch (this.uploadedFiles[index].type) {
          case 'photo':
            formData.append(`photos[${index}][photo]`, this.uploadedFiles[index].file)
            break;
          case 'video':
            formData.append(`videos[${index}][video]`, this.uploadedFiles[index].file)
            break;
          case 'gif':
            formData.append(`gifs[${index}][gif_url]`, this.uploadedFiles[index].url);
            break;
          default:
            break;
        }
      } 
    }


    if (this.removeIDs.length) {
        let photoIDS : any = [];
        let videoIDS : any = [];
        let gifIDS: any = [];
        for (const item of this.removeIDs) {
          switch (item.type) {
            case 'photo':
              photoIDS.push(item.id)
              break;
            case 'video':
              videoIDS.push(item.id)
              break;
            case 'gif':
              gifIDS.push(item.id)
              break;  
            default:
              break;
          }
        }


        for (let i = 0; i< photoIDS.length; i++) {
          formData.append('delete_photo_ids[' + i + ']', photoIDS[i]);
        }

        for (let i = 0; i< videoIDS.length; i++) {
          formData.append('delete_video_ids[' + i + ']', videoIDS[i]);
        }

        for (let i = 0; i< gifIDS.length; i++) {
          formData.append('delete_gif_ids[' + i + ']', gifIDS[i]);
        }
    }

    switch (this.authService.getSessionData().type.toLowerCase()) {
      case 'user':

        if (this.popup.context.post && this.popup.context.post.id)  {
          if (this.popup.context.post.item_type.toLowerCase().includes('share')) {
            this.updateSharePost(formData)
          } else {
            this.updateUserPost(formData);
          }
          
        } else {
          this.createUserPost(formData);
        }
        
        break;
      case 'organization':
        if (this.popup.context.post && this.popup.context.post.id)  {
          if (this.popup.context.post.item_type.toLowerCase().includes('share')) {
            this.updateSharePost(formData)
          } else {
            this.updateOrganizationPost(formData);
          }
        } else {
          this.createOrganizationPost(formData);
        }
        
        break;
      default:
        this.submittingForm = false;
        this.loader.stop();
        break;
    }
  }

  updateSharePost(params: any) {
    this.subscription.add(
      this.appService.updateSharePost(params, this.popup.context.post.id, `${this.appStore.userType.toLowerCase()}s`).subscribe(response => {
        this.closePopup();
        this.submittingForm = false;
        this.loader.stop();
      })
    )
  }

  /**
   * @description
   * create user post
   *
   * @param {*} formData
   * @memberof CreatePostPopupComponent
   */
  createUserPost(formData: any) {
    this.subscription.add(
      this.userService.createPost(formData).subscribe((response: any) => {
        this.closePopup();
        this.submittingForm = false;
        this.loader.stop();
      })
    )
  }

  /**
   * @description
   * create user post
   *
   * @param {*} formData
   * @memberof CreatePostPopupComponent
   */
   updateUserPost(formData: any) {
     //console.log(this.removeIDs)
     this.subscription.add(
      this.userService.updateUserPost(this.popup.context.post.id, formData).subscribe((response: any) => {
        this.closePopup();
        this.loader.stop();
      })
    );

  }

  /**
   * @description
   * create organization post
   *
   * @param {*} formData
   * @memberof CreatePostPopupComponent
   */
  createOrganizationPost(formData: any) {
    this.subscription.add(
      this.organizationService.createPost(formData).subscribe((response: any) => {
        this.closePopup();
        this.submittingForm = false;
        this.loader.stop();
      })
    )
  }

   /**
   * @description
   * create organization post
   *
   * @param {*} formData
   * @memberof CreatePostPopupComponent
   */
  
  updateOrganizationPost(formData: any) {
    this.subscription.add(
     this.organizationService.updateOrgnizationPost(this.popup.context.post.id, formData).subscribe((response: any) => {
       this.closePopup();
       this.loader.stop();
     })
   );

 }


  gifOrTagActionCTA(event: any, type: string) {
    switch (type) {
      case 'gif':
        this.uploadedFiles = [];
        if (event) {
          this.uploadedFiles.push( {file: event, type: 'gif', url: event})
        }
        this.isShowGifSection = false;
        break;
      case 'tag':
        this.selectedData = event || [];
        this.isShowTag = false;
        if (this.selectedData.length) {
          switch (true) {
            case this.selectedData.length  == 1 : case this.selectedData.length  == 2 :
              this.taggedNames = this.selectedData.map((x: any) => x.name).join(' and ');
              break;
            case this.selectedData.length  > 2 :
              this.taggedNames = this.selectedData[0].name + ` and ${this.selectedData.length - 2} others` ;
              break;
            default:
              break;
          }
        }
        break;  
      default:
        break;
    }
   
  }

  removeTags(str: any) {
      if ((str===null) || (str===''))
        return false;
      else
        str = str.toString();
            
      // Regular expression to identify HTML tags in 
      // the input string. Replacing the identified 
      // HTML tag with a null string.
      return str.replace( /(<([^>]+)>)/ig, '');
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add 'implements OnChanges' to the class.
    if (changes.popup && changes.popup.currentValue) {
      if(this.popup.context.post && this.popup.context.post.id ){
        this.description = this.removeTags(this.popup.context.post.description) || '';
        let files: any = []
        if (this.popup.context.post.attachments) {
          for (const item of this.popup.context.post.attachments) {
            let file: any = {}
            if (item.data.photo_url || item.data.photo) {
              file = {type: "photo", url: (item.data.photo_url || item.data.photo), id: item.data.id}
            } else if (item.data.gif || item.data.gif_url) {
              file = {type: "gif", url: (item.data.gif_url || item.data.gif), id: item.data.id}
            } else if (item.data.video || item.data.video_url) {
              file = {type: "video", url: (item.data.video_url || item.data.video), id: item.data.id}
            }
            files.push(file);
          }
          this.uploadedFiles = files;
        }

        if (this.popup.context?.post?.item_type?.toLowerCase()?.includes('share')) {
          this.sharePostDetail = this.popup.context?.post.post
        }
        
      }
      
      switch (this.popup.context.fileType) {
        case 'gif':
          this.getGifDatas();
          break;
        case 'emoji':
          this.description = this.description + this.popup.context.emoji;
          this.btnPost = false;
          break;
        case 'tag':
          this.isShowTag = true;
          break;
        default:
          break;
      }
     
    }
  }

  
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
}
