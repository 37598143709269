import { OrganizationService } from './../../../../organizations/service/organization.service';
import { UserService } from './../../../../users/services/user.service';
import { Router } from '@angular/router';
import { AppStore } from 'src/app/core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ElasticSearchModel, PopupModel } from 'src/app/core/models';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-elastic-search-popup',
  templateUrl: './elastic-search-popup.component.html',
  styleUrls: ['./elastic-search-popup.component.scss']
})
export class ElasticSearchPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  searchInput:string ='';
  subscription: Subscription = new Subscription();
  userType: any;
  iconClass: string = 'blue';
  isTyped: boolean  = false;
  searchType: string = 'all';
  tabs: any = [];
  constructor(
    public organizationStoreService: OrganizationStoreService,
    private appService: AppService, 
    public authService: AuthService,
    public appStore: AppStore,
    private router: Router,
    private userService: UserService,
    private organizationService: OrganizationService,
    public loader:LoaderService,
  ){
    this.userType = this.authService.getSessionData().type.toLowerCase();
    
    if (this.userType == 'organization') {
      this.iconClass = 'green';
    }
    this.tabs = [
      {
        name: 'all',
        id: "all"
      },{
        name: 'posts',
        id: `posts`
      },{
        name: 'users',
        id: `user`
      },{
        name: 'organizations',
        id: `organization`
      },{
        name: 'photos',
        id: `photos`
      },{
        name: 'videos',
        id: `videos`
      },{
        name: 'gifs',
        id: `gifs`
      },{
        name: 'groups',
        id: `groups`
      }
    ]
  }

  viewVideos(id: number, item: any) {
    let userType: any = (item.get_data?.user_id ? 'user' : 'organization')
    this.subscription.add(
      this.appService.getVideos(id, userType).subscribe((response: any) => {
        if(response) {
          this.appService.togglePopup({
            type: 'viewPost',
            load: true,
            refreshParent: true,
            overlayClose: true,
            context: {
              post: response.post,
              medias: [response],
              selectedItem: 0,
              postType: 'video',
              posted_by: { profile_pic: response.post.posted_by.photo, name: response.post.posted_by.name, id: response.post.posted_by.id },
              theme: ((this.appStore.userType.toLowerCase() == 'user') ?  'blue' : 'green')
            }
          });
        }
      })
    )
  }


  /**
   * @description
   * this method is used for viewing the post 
   *
   * @param {*} index
   * @memberof PhotosComponent
   */
   viewPhotos( id: number, index : any, item : any) {
     //console.log(item)
      if (this.appStore.userType.toLowerCase() == 'user') {
        this.subscription.add(
          this.userService.getPhotoDetails(id).subscribe((response: any) => {
            if(response) {
              this.appService.togglePopup({
                type: 'viewPost',
                load: true,
                refreshParent: true,
                overlayClose: true,
                context: {
                  post: response.post,
                  medias: [response],
                  selectedItem: 0,
                  postType: 'photo',
                  posted_by: { profile_pic: response.post.posted_by.photo, name: response.post.posted_by.name, id: response.post.posted_by.id },
                  theme: 'blue'
                }
              });
            }
          })
        )
      } else {
        this.subscription.add(
          this.organizationService.getPhotoDetails(id).subscribe((response: any) => {
            if(response) {
              this.appService.togglePopup({
                type: 'viewPost',
                load: true,
                refreshParent: true,
                overlayClose: true,
                context: {
                post: response.post,
                medias: [response],
                selectedItem: 0,
                postType: 'photo',
                posted_by: { profile_pic:  this.organizationStoreService.organizationDetails.profile_pic, name: this.organizationStoreService.organizationDetails.name, id: this.organizationStoreService.organizationId},
                theme: 'green'
                }
              })
            }
          })
        )
      }
  }
  
  

  /**
  * @description
  * this method is used for closing the popup
  *
  * @memberof ElasticSearchPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }


  cardActionCTA(event?: any, type ?: any, item ?: any) {
    this.closePopup();
    switch (type) {
      case 'user':
        window.location.href = '/users/'+item.briind_id+'/timeline';
        // this.router.navigate(['/users',item.briind_id,'timeline']);
        break;
      case 'organization':
        window.location.href = '/organizations/'+item.briind_id+'/profile/bussiness/new_to_us';
        // this.router.navigate(['/organizations',item.briind_id,'timeline']);
        break;  
      case 'groups':
        window.location.href = `/users/${this.authService.getSessionData().briind_id}/groups/${event.id}`;
        // this.router.navigate(['/users',this.authService.getSessionData().briind_id,'groups',event.id]);
        break;
      default:
        break;
    }
  }

  /**
  * @description
  * this metod is used for search in all data for organization
  *
  * @memberof ElasticSearchPopupComponent
  */
   searchAll(event: any) {
     this.searchInput  = event;
     this.loader.show();
     console.log("search ",event);
     
    // if (this.searchInput) {
      let data = { 
        search_term: this.searchInput,
        search_type: this.searchType
      };
      this.subscription.add(
        this.appService.searchAll(data).subscribe(response => {
          // this.organizationStoreService.setSearchResult();
          this.isTyped = true;
          this.loader.hide();
          this.appStore.getElasticSearchData();
        })
      );
    // }
  }

  changeTab(item: any) {
    this.searchType = item;
    this.appStore.elasticSearchData = new ElasticSearchModel();
    this.searchAll(this.searchInput)
  }

  ngOnInit(): void {
    if (!this.userType) {
      this.userType = this.authService.getSessionData().type;
    }
  }

  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   */
  ngOnDestroy(): void {
    if(this.subscription)
      this.subscription.unsubscribe();
      this.appStore.elasticSearchData = new ElasticSearchModel();
    
  }


}
