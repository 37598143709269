export class OtpFieldModel {
    count : number = 0;
    title: string = '';
    description : string = '';
    fontClass ?: string = '';
    buttonClass ?: string = '';
}

export class DropDownModel {
    data ?: any[] = [];
    label : string = '';
    placeholder ?: string = '';
    customClass ?: string = '';
    uiSearch ?: boolean = false;
    formControlName ?: any ;
    formGroup ?: any;
    icon ?: boolean = false;
    selectedData ?: any=[];
}

export class DropdownDataModel {
    id?: any = '';
    name?: string = '';
    key?: any = '';
    selected?: boolean = false;
    is_added?: boolean = false;
}

export class SelectListModel {
    id ?: any = '';
    name ?: any = '';
}