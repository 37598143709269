import { AppService } from 'src/app/core/services/app.service';
import { config } from 'src/environments/environment';
import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { getImagePath } from 'src/app/core/essentials/common.libs';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AppStore } from 'src/app/core/stores/app.store'; 
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { UserStoreService } from 'src/app/users/store/user-store.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-post-photos-videos-other-upload',
  templateUrl: './post-photos-videos-other-upload.component.html',
  styleUrls: ['./post-photos-videos-other-upload.component.scss']
})
export class PostPhotosVideosOtherUploadComponent implements OnInit {
  @Output() uploadedFiles = new EventEmitter();
  toggled: boolean = false;
  @Input('parent') parent: string = 'card';
  @Input() postPermission: string = 'everyone';
  @ViewChild('emojiIconContainer') emojiIconContainer: ElementRef | any;
  isWeb = true
  loader = this.loadingBar.useRef();

  constructor(
    public authService: AuthService,
    public loadingBar: LoadingBarService,
    public loadingService:LoaderService,
    public userStoreService: UserStoreService,
    private appService: AppService,
    public appStore:AppStore,
    private sanitizer: DomSanitizer
  ) {
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isWeb = false
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event ?: any): void {
    if (((this.emojiIconContainer && !this.emojiIconContainer.nativeElement.contains(event.target)))) {
      this.toggled = false;
    }
  }

  uploadGifOrTagFriend(event: any, type: string) {
    this.uploadedFiles.emit({url: '', file:  '', type: type })
  }

  handleSelection(event: any) {
    this.uploadedFiles.emit({type: 'emoji', value: event.emoji ? event.emoji.native : '' })
    if (this.parent == 'card') {
      this.toggled = false;
    }
  }

  showEmoji(event: any) {
      this.toggled = !this.toggled;
  }

  /**
   * @description
   * upload file method
   *
   * @param {*} event
   * @param {string} type
   * @memberof PostPhotosVideosOtherUploadComponent
   */
  fileReady(event: any, type: string) {    
    const file: File = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = (e: any) => {
      const arrayBuffer = [e.target.result];
      const fileType = "video/mpeg";
      const blob = new Blob( arrayBuffer, {type: fileType });
      const src = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));

      this.uploadedFiles.emit({url: src, file: file, type: type });
      this.loader.stop();
      let _timeout = setTimeout(() => {
        this.appStore.videoLoader = false;
        clearInterval(_timeout);
      },1000);
    };
    fileReader.readAsArrayBuffer(file);
  }
  uploadFile(event: any, type: string) {
    if (event.target && event.target.files[0]) {
      this.loader.start();
      this.appStore.videoLoader = true;
      let url: any = '';
      switch (type) {
        case 'photo': case 'video':
          //console.log(event.target.files[0]) 
          if ((event.target.files[0].size /1000000) < config.postFileSize) {
            // let reader: any = getImagePath(event,'');
            // reader.onload = (e:any) => {
            //   this.uploadedFiles.emit({url: e.target.result, file: event.target.files[0], type: type });
            //   this.loader.stop();
            //   setTimeout(() => {
            //     this.appStore.videoLoader = false;
            //   },1000);
            // };
            this.fileReady(event, type);
          } else {
            this.appService.toggleToast({
              type: 'error',
              load: true,
              position: 'bottom-left',
              context: { description: `Maximum file size ${config.postFileSize}MB` }
            });
            this.loader.stop();
            this.appStore.videoLoader = false;
          }
         
          break;
        default:
          this.loader.stop();
          this.appStore.videoLoader = false;
          break;
      }
    }

  }

  async pickVideos(){
    
    const uploadedVideos: any  = await FilePicker.pickVideos({ multiple: true, readData: true });
    let files: any = [];

    for (const video of uploadedVideos.files) {
      let finalUrl = video.data
      let dataUrl = 'data:video/mp4;base64,' + finalUrl
      const base64Response = await fetch(dataUrl);
      const blob = await base64Response.blob();

      files.push({url: dataUrl, file: blob, type: 'video' });
      this.appService.togglePopup({
        type: 'createPost',
        load: true,
        refreshParent: true,
        overlayClose: true,
        context: {
          userType: '',
          userData: this.userStoreService.userDetails,
          uploadedFiles: files,
          postType: 'Post',
          postToType: 'UserPost',
          postToId: this.userStoreService.userId,
        }
      });
    
    }
  };



  ngOnInit(): void {
  }

}
