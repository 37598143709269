import { Injectable } from '@angular/core';
import { action, observable } from 'mobx-angular';
import { AnalyticsReportModel } from 'src/app/core/models/analyticsreport.model';
import { AnalyticsReportUserModel } from 'src/app/core/models/analyticsreportuser.model';
import { BriindletAnalyticsModel } from 'src/app/core/models/briindletanalytics.model';

@Injectable({
  providedIn: 'root'
})
export class BriindletPerformanceStoreService {
  @observable apiResp: any;
  @observable briindletAnalytics: BriindletAnalyticsModel=new BriindletAnalyticsModel();
  @observable analyticsReport: AnalyticsReportModel =  new AnalyticsReportModel();
  @observable analyticsReportUser: AnalyticsReportUserModel =  new AnalyticsReportUserModel();
  constructor() { }


  /**
   * TODO: comment action
   * @description Actions briindlet performance store service
   * @memberof BriindletPerformanceStoreService
   */
  @action setBriindletAnalytics() {
    this.briindletAnalytics = this.apiResp;
  }

  @action setUserOrganizationAnalytics(userType ?: string){
    this.analyticsReport = this.apiResp;
  }

  @action setUserAnalytics() {
    this.analyticsReportUser = this.apiResp;
  }
}
