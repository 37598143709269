<ng-container *ngIf="!isIOS">
    <div class="video" style="display: none;">
        <video [id]="customId" preload="auto" (loadeddata)="loadTime();" [src]="media?.video_url || media" type="video/mp4" controls #video></video>
    </div>
    <!-- <div class="padbtm25">
        <div class="ripple bg-F1F4FE bdrRadius4px right d25 flexAlignItemsCenter flex flexJustifyCenter  cursorPtr" (click)="remove(media.id)">x</div>
    </div> -->
    <div class="timeline height100p bdrRadius4px posRelative overflowHidden ripple" (click)="preview()" [id]="customIdTimeline" #timeline>
        <i class="icns icon-play-button playBtnPos d40 posAbsolute"></i>
    </div>
</ng-container>



<video 
    *ngIf="isIOS"
    [id]="customId"  
    controls  
    style="width: 120px; height: 120px;"  
    src="{{media?.video_url || media}}#t=0.100"  
    #video
    webkit-playsinline 
    playsinline
    muted="true"
    (click)="play()"></video>
    