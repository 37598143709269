import { BehaviorSubject } from "rxjs";

/**
 * @description
 * Popup Object Model
 * @export PopupModel
 * @class PopupModel
 */
export class PopupModel {
  type ?: string = 'default';
  load ?: boolean = false;
  overlayClose ?: boolean = true;
  context ?: any = {};
  refreshParent ?: boolean = false;
  popupClosed?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
}
