<ng-container [ngSwitch]="data?.template_id?.toString()">
    <ng-container *ngSwitchCase="1">
        <div class="bdr-1-EEEEEE txtCenter boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px pad30 mrgnbtm15">
            <div>
                <!-- <div class="cover-profile-image posRelative flex flexJustifyCenter">
                    <span class="online-img-shadow bdrRadius50p posRelative displayFlex">
                    </span>
                </div> -->
                <img [src]="data?.photo || 'assets/images/ministry_pic_background.png'"
                                class="bdrRadius6px maxWidth300px" />
            </div>
            <div class="poppins-regular font14 color-484848 mrgntop15" [innerHTML]="data?.introduction">
            </div>
            <div class="bg-RGBA2691221 bdrRadius6px mrgntop30 pad30">
                <div class="color-02457A font18 poppins-semibold padbtm10 txtTransformUp">
                    {{'welcome popup' | translate}}</div>
                <div class="poppins-regular font14 color-484848" [innerHTML]="data?.welcome_screen"></div>
            </div>
            <div class="flex mrgntop40 padbtm30 flexJustifyCenter" *ngIf="data?.show_join && ((organizationId == userData.briind_id && userData.user_type == 'Organization') || userData.user_type != 'Organization')">
                <button type="button" [disabled]="isDisableBtn" [routerLink]="['/organizations',organizationId,'profile','bussiness','join-us']"
                    class="btn-get-started btn-18878C minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030">{{data?.membership_button}}</button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
        <div class="bdr-1-EEEEEE boxShadow-RGBA00005 bg-FFFFFF bdrRadius6px pad30 mrgnbtm15 flex">
            <div class="width30">
                <img [src]="data?.photo || 'assets/images/ministry_pic_background.png'"
                    class="bdrRadius6px width100" />
            </div>
            <div class="pad10"></div>
            <div class="width70">
                <div class="poppins-regular font14 color-484848 mrgntop15" [innerHTML]="data?.introduction">
                </div>
                <div class="bg-RGBA2691221 bdrRadius6px mrgntop30 pad30">
                    <div class="color-02457A font18 poppins-semibold padbtm10 txtTransformUp">
                        {{'welcome popup' | translate}}</div>
                       
                    <div class="poppins-regular font14 color-484848" [innerHTML]="data?.welcome_screen">
                    </div>
                </div>
                
                <div class="flex mrgntop40 padbtm30 flexJustifyCenter" *ngIf="data?.show_join && ((organizationId == userData.briind_id && userData.user_type == 'Organization') || userData.user_type != 'Organization')">
                    <button type="button" [disabled]="isDisableBtn" [routerLink]="['/organizations',organizationId,'profile','bussiness','join-us']"
                        class="btn-get-started btn-18878C minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr font14 poppins-regular pad1030">{{data?.membership_button}}</button>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>