<div id="organization-promote-popup" class="bdrRadius6px bg-white pad40 posRelative {{popup?.context?.userType}}">
  <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
  <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm40">
    {{ 'promote organization' | translate }}
  </div>
  <div [formGroup]="promotionFormGroup">
    <div class="width100" *ngIf="pageNumber == 1">
      <div class="width100">
        <div class="width100 mrgnbtm20">
          <input placeholder="{{'enter briind id' | translate}}" formControlName="briindID" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
              bdr-1-EEEEEE bg-white outline0 bdrRadius6px" appDebounce [debounceTime]="1000"
            (debounceClick)="checkBriindID()">
        </div>
        <strong class="color-red poppins-regular font13"
          *ngIf="!promotionFormGroup.valid">{{errorText | translate}}</strong>
        <strong class="color-red poppins-regular font13" *ngIf="serverError!=''">{{ serverError | translate}}</strong>
        <strong class="color-red poppins-regular font13"
          *ngIf="errorTextMessage!=''">{{ errorTextMessage | translate}}</strong>
      </div>


      <div class="flex flexJustifyBetween flexResponsive mrgnbtm20">
        <div class="width50 flex">
          <input [owlDateTime]="dt1" [min]="min" formControlName="startDate"
            (ngModelChange)="onDateRageSelection($event)" [owlDateTimeTrigger]="dt1"
            placeholder="{{'event from' | translate}}" type="text" readonly="true"
            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white outline0 bdrRadius6px input-icon"
            [ngClass]="{'calender-blue': userType.toLowerCase() == 'user', 'calender-green': userType.toLowerCase() == 'organization'}">
          <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        </div>
        <div class="pad10"></div>
        <div class="width50 flex">
          <input [owlDateTime]="dt2" [min]="startDate" formControlName="endDate"
            (ngModelChange)="onDateRageSelection($event)" [owlDateTimeTrigger]="dt2"
            placeholder="{{'event to' | translate}}" type="text" readonly="true"
            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white outline0 bdrRadius6px input-icon"
            [ngClass]="{'calender-blue': userType.toLowerCase() == 'user', 'calender-green': userType.toLowerCase() == 'organization'}">
          <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
        </div>
      </div>

      <div class="flex flexJustifyBetween flexResponsive mrgnbtm20">
        <div class="width50 flex">
          <input [owlDateTime]="dt3" formControlName="startDateTIme" (ngModelChange)="onDateRageSelection($event)"
            [owlDateTimeTrigger]="dt3" placeholder="{{'starting time' | translate}}" type="text" readonly="true"
            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon clock bdr-1-EEEEEE bg-white outline0 bdrRadius6px input-icon"
            [ngClass]="{'clock-blue': userType.toLowerCase() == 'user', 'clock-green': userType.toLowerCase() == 'organization'}">
          <owl-date-time [pickerType]="'timer'" #dt3></owl-date-time>
        </div>
        <div class="pad10"></div>
        <div class="width50 flex">
          <input [owlDateTime]="dt4" formControlName="endDateTime" (ngModelChange)="onDateRageSelection($event)"
            [owlDateTimeTrigger]="dt4" placeholder="{{'ending time' | translate}}" type="text" readonly="true"
            class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon clock bdr-1-EEEEEE bg-white outline0 bdrRadius6px input-icon"
            [ngClass]="{'clock-blue': userType.toLowerCase() == 'user', 'clock-green': userType.toLowerCase() == 'organization'}">
          <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>
        </div>
      </div>
      <div class="width100 mrgnbtm20 minHeight150px bg-DEDEDE flex flexDirectionCol flexJustifyCenter flexAlignItemsCenter bgRepeat-noRepeat bgSize100p100p
          cursorPtr posRelative" style="background-image: url({{selectedFile?.base64}})">
        <i class="icns icon-camera {{appStore.iconClass}} d50"></i>
        <ng-container *ngIf="!selectedFile?.base64">

          <input type="file" formControlName="photo"
            class="posAbsolute posTop posBtm posRight posLeft width100 opacity0" (change)="uploadPhoto($event)">
          <p class="mrgn0 font15 poppins-regular timeline-label">
            {{ 'ready to add something' | translate}}
          </p>
          <p class="mrgn0 font15 poppins-regular timeline-label">
            {{ 'drag or upload single photo or video' | translate}}
          </p>
        </ng-container>
      </div>

      <p class="flashScreen"> Thank you for using this Service, It is free now!</p>

      <!-- <div class="timeline-label width100 bg-F2F6F8 mrgnbtm20 pad10 color-122A4D txtCenter font18 fontWeight600 
                    poppins-medium ">
        {{ 'total payment' | translate }} $ {{organizationStoreService?.promotionPriceModel?.total_price?.toFixed(2) }}
      </div> -->

      <div class="width100 padtop15">
        <button type="button" [disabled]="promotionFormGroup.invalid || errorTextMessage != ''"
          *ngIf="parseInt(organizationStoreService.promotionPriceModel.total_price) <=0" class="btn-user-default mrgnright25 btn-get-started btn-18878C 
                      minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr 
                      font14 poppins-semibold pad1030 mrgnright8" (click)="createOrganizationPromotion(false)">
          {{ 'create promotion' | translate}}
        </button>

        <button type="button" [disabled]="promotionFormGroup.invalid || errorTextMessage != ''"
          *ngIf="parseInt(organizationStoreService.promotionPriceModel.total_price) > 0" class="btn-user-default mrgnright25 btn-get-started btn-18878C 
                  minwidth100px bdr-1-EEEEEE color-18878C ripple cursorPtr 
                  font14 poppins-semibold pad1030 mrgnright8" (click)="proceedToPay()">
          {{ 'proceed to pay' | translate }}
        </button>
      </div>

    </div>
  </div>
  <app-organization-promote-stripe (stepperCount)="getStepperCount($event)"
    [amount]="organizationStoreService?.promotionPriceModel?.total_price" (createPromotion)="createPromotion($event)"
    *ngIf="pageNumber == 2"></app-organization-promote-stripe>

  <ng-container *ngIf="pageNumber==3">
    <app-payment-status [userType]="popup?.context?.userType" [success]="true"></app-payment-status>
  </ng-container>
  <ng-container *ngIf="pageNumber==4">
    <app-payment-status [userType]="popup?.context?.userType" [success]="false"></app-payment-status>
  </ng-container>
  <app-crop-image-popup (close)="showCropper = false" (uploadImageEvent)="uploadImage($event)"
    [imageType]="'cover_photo'" [imageChangedEvent]="imageChangedEvent" *ngIf="showCropper"></app-crop-image-popup>
</div>