import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { OrganizationStoreService } from 'src/app/organizations/store/organization-store.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserStoreService } from 'src/app/users/store/user-store.service';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-create-group-popup',
  templateUrl: './create-group-popup.component.html',
  styleUrls: ['./create-group-popup.component.scss']
})
export class CreateGroupPopupComponent implements OnChanges, OnDestroy {

  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  subscription: Subscription = new Subscription();
  selectedFile: any = {
    cover_photo: '',
    file: ''
  };
  form: FormGroup;
  isInvalid: boolean = false;
  errorText: string = '';
  clicked:boolean = false;
  disableBtnOnClick:boolean = false
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private appService: AppService,
    public userStoreService: UserStoreService,
    private organizationService: OrganizationService,
    public organizationStoreService: OrganizationStoreService
  ) {
    this.form = fb.group({
      "name": new FormControl('', [Validators.required]),
      "about": new FormControl('', [Validators.required]),
      "category": new FormControl('', [Validators.required]),
    })

  }


  /**
   * @description
   * This Method Is For Getting The each form Controls of formGroup to Check The Validation
   * @param {string} [controlName]
   * @returns the specfic FormControl Based on the Param
   * @memberof CreateGroupPopupComponent
  */
  f(controlName?: string) {
    return this.form.get(controlName || '')
  }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof CreateGroupPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }

  /**
   * @description
   * this method is used for get the group cover photo
   *
   * @param {*} event - file event
   * @memberof CreateGroupPopupComponent
   */
  updatePhoto(event: any) {
    if (event) {
      this.selectedFile = {
        cover_photo:event.base64,
        file: event.file
      }
    }
   
  }

  /**
   * @description
   * this method is used for create group
   *
   * @param {*} data
   * @memberof CreateGroupPopupComponent
   */
  createGroup(data: any) {
    this.subscription.add(
      this.appService.createGroup(data).subscribe(response => {
        this.form.reset();
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * this method is used for update group
   *
   * @param {*} data
   * @memberof CreateGroupPopupComponent
   */
  updateGroup(data: any) {
    this.subscription.add(
      this.appService.updateGroup(data, this.popup.context.groupDetail.id).subscribe(response => {
        this.clicked = false;
        this.form.reset();
        this.closePopup();
      })
    );
  }

  /**
   * @description
   * This method is using for form submit api call
   *
   * @returns
   * @memberof CreateGroupPopupComponent
   */
  onSubmit() {
    this.clicked = true;
    this.disableBtnOnClick = true
    let formData = new FormData();
    formData.append('name', this.form.value.name);
    formData.append('about', this.form.value.about);
    formData.append('category', this.form.value.category);
    formData.append('privacy', this.form.value.privacy);
    if (this.selectedFile.file) {
      formData.append('cover_photo', this.selectedFile.file);
    }

    if (this.popup.context.groupDetail && this.popup.context.groupDetail.id) {
      this.updateGroup(formData);
    } else {
      this.createGroup(formData);
    }
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof CreateGroupPopupComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.popup && changes.popup.currentValue && this.popup.context.groupDetail && this.popup.context.groupDetail.id) {
      this.form.setValue({
        "name": this.popup.context.groupDetail.name,
        "about": this.popup.context.groupDetail.about,
        "category": this.popup.context.groupDetail.category ? this.popup.context.groupDetail.category : '',
        "privacy": this.popup.context.groupDetail.privacy ? this.popup.context.groupDetail.privacy : ''
      });
      this.selectedFile = { cover_photo: this.popup.context.groupDetail.cover_photo };
    }
  }
  
  /**
  * @description
  * Called once, before the instance is destroyed.
  * Add 'implements OnDestroy' to the class.
  * @memberof CreateGroupPopupComponent
  */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
