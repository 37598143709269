import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-initial-register-page',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
 
  constructor(
    private router: Router,
  ) { 
  
  }

  /** 
   * @description
   * This Method Is For Getting The each form Controls of formGroup to Check The Validation
   * @param {string} [controlName]
   * @returns the specfic FormControl Based on the Param
   * @memberof registerComponent
  */
 
  
  /**
   * @description
   * this method will call when click on sign in button
   * @memberof registerComponent
   */
  onSubmit() {
    
  }

  userBackHome() {
    // this.router.navigate([`/home`]); 
  }

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   *
   * @memberof UserSigninComponent
   */
  ngOnInit(): void {
    
  }

  /**
   * @description
   * Called once, before the instance is destroyed.
   * Add 'implements OnDestroy' to the class.
   * @memberof UserSigninComponent
   */
  ngOnDestroy(): void {
  }

}
