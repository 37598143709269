<div class="bdrRadius10px boxShadow-RGBA00005 bdr-1-EEEEEE posRelative width100 pad15 bg-white mrgnbtm15" [ngClass]="userType">
    <div class="flex flexJustifyBetween flexWrap">
      <div class="font15  font20 color-122A4D poppins-semibold">
        {{'trending organization' | translate}}
      </div>
    </div>
    <div class="bdr-1-EEEEEE width100 mrgnbtm15 mrgntop5"></div>
    <div class="width100 posRelative">
      <img src="assets/images/promoted.png" class="bdrRadius10px width100">
      <a
        class="bg-F1F4FE pad0510 timeline-label poppins-light posRight5px  posTop5px bdrRadius30px font14 posAbsolute">{{'promoted'
        | translate}}</a>
    </div>
    <div class="width100 flex flexJustifyBetween mrgntop10 flexWrap">
      <div>
        <div class="poppins-semibold font14 color-122A4D ">The Trends Kids</div>
        <div class="poppins-regular font13 color-8E8E8E ">California, US</div>
      </div>
      <div class="">
        <button
          class="btn-get-started ripple cursorPtr mrgnauto btn-user-default poppins-medium font14 flexAlignSelfCenter">
          {{'follow' | translate}}
        </button>
      </div>

    </div>
  </div>
  <div class="width100 padbtm10 txtCenter">
    <a href="#" class="timeline-label txtUnderline poppins-medium font15 cursorPtr">{{'view all' | translate}}</a>
  </div>