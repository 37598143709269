/* Session Object Model */
export class ProgressCircleModel {
    type : string = '';  /* **Mandatory Filed** This gives the type whether 'percentage' or 'out-of'  */
    value ?: number
    clearedOutOf ?: number
    color ?: string
    innerTitle ?: string = ''
    showProgress ?: boolean = true
    radius ?: number
    diameter ?: number
    detailsInside : boolean = true /* **Mandatory Filed** Flag to toggle whether the data should be placed the inside the circle or not    */
    animateCount ?: boolean = false /* To Enable the increment count animation inside the circle */
    animateCountDuration ?: number = 0 /* Duration will be in milliseconds [`1000ms = 1s`], If the `animateCount` is true and if need to control the duration of the count animation then we can use this */
}
