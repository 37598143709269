<div class="bdrRadius6px bdr-ebebeb posRelative">
    <div class="posRelative">


        <app-simple-carousel [autoSlide]="'false'" *ngIf="data?.photos?.length">
            <ng-container *ngFor="let item of data?.photos">
                <ng-container *carouselItem>
                    <video class="width100 bdrRadiusTopLeftt6px minHeight350px maxHeight350px bdrRadiusTopRight6px" height="350" width="100%" controls *ngIf="item?.type  == 'video'">
                        <source [src]="item?.photo || item?.base64" type="video/mp4">
                        <source [src]="item?.photo || item?.base64" type="video/ogg">
                        Your browser does not support HTML video.
                      </video>
                    <img [src]="item?.base64 || item?.photo" class="width100 bdrRadiusTopLeftt6px minHeight350px maxHeight350px bdrRadiusTopRight6px" *ngIf="item?.type  == 'photo'" />
                </ng-container>
            </ng-container>
        </app-simple-carousel>

        <img *ngIf="!data?.photos?.length && isPreview" src="assets/images/temp-img.png" class="width100 bdrRadiusTopLeftt6px bdrRadiusTopRight6px" />
        <div class="posAbsolute" style="left: 50%;bottom: -30px;">
            <div class="posRelative {{customizeUI?.event_title?.title.font_family}} txtLeft" *ngIf=" data?.name || isPreview" style="left: -50%;
        font-weight: 900;
        white-space: nowrap;
        padding: 15px 30px 14px 28px;
        box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(to top, #3fbbfe, #a541ff);
        " [ngStyle]="{'font-size.px': customizeUI?.event_title?.title.font, 'color': customizeUI?.event_title?.title?.color}">
                {{(data?.name ? data?.name : isPreview ? 'Briind Event' : '') | translate}}
            </div>
        </div>
    </div>
    <div *ngIf="data?.event_from || isPreview" class="mrgntop50 maxWidth300px mrgnauto txtCenter {{customizeUI?.venue_and_time?.time?.font_family}}" style="padding: 14px 38px;
box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.1);
background-image: linear-gradient(to top, #f772d1, #c872f2);
font-weight: bold;
" [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.time?.font, 'color': customizeUI?.venue_and_time?.time?.color}">
        {{moment(data?.event_from).format('MMMM DD')}} - {{moment(data?.event_to).format('MMMM DD')}}</div>
    <div class=" padtop20 flex flexJustifyCenter padbtm20 txtCenter {{customizeUI?.registration_fee?.title?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.title?.font, 'color': customizeUI?.registration_fee?.title?.color}">
        {{'reg' | translate}} {{'fee' | translate}} -
        <div class="{{customizeUI?.registration_fee?.value?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.registration_fee?.value?.font, 'color': customizeUI?.registration_fee?.value?.color}">
            {{'amount in number' | translate: {amount: data?.fee || 0} }}
        </div>
    </div>
    <div class=" padbtm20 txtCenter wrdBreakAll padleft10 padright10 {{customizeUI?.event_title?.description?.font_family}}" *ngIf=" data?.description || isPreview" [ngStyle]="{'font-size.px': customizeUI?.event_title?.description?.font, 'color': customizeUI?.event_title?.description?.color}">
        {{(data?.description ? data?.description : isPreview ? 'lorem ipsum' : '') | translate}}
    </div>
    <div class=" padbtm5 txtCenter  {{customizeUI?.venue_and_time?.venue?.font_family}}" *ngIf="data?.venue || data?.venue_name || isPreview" [ngStyle]="{'font-size.px': customizeUI?.venue_and_time?.venue?.font, 'color': customizeUI?.venue_and_time?.venue?.color}">
        {{((data?.venue || data?.venue_name) ? (data?.venue?.name || data?.venue_name || data.venue) : isPreview ? 'At Briind' : '') | translate}}
    </div>
    <div class=" padbtm30 txtCenter" style="font-family: Segoe UI Regular;font-size: 14px;
color: #7b7b7b;">{{'starting time' | translate}} {{moment(data?.event_from).format('HH:mm A')}} / {{'ending time' | translate}} {{moment(data?.event_to).format('HH:mm A')}}</div>
    <ng-container *ngIf="data?.guest?.length || isPreview">
        <div class="{{customizeUI?.guest_speakers?.speakers_title?.font_family}} padbtm15 txtCenter" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_title?.font, 'color': customizeUI?.guest_speakers?.speakers_title?.color}">
            {{'guest_speakers' | translate}}</div>
        <div class="flex width60 mrgnauto flexJustifyCenter padbtm25">
            <ng-container *ngIf="data?.guest?.length && !isPreview">
                <div class="txtCenter mrgn5" *ngFor="let item of data?.guest">
                    <app-profile-thumbnail [image]="item.photo" [imageClass]="'d70'"></app-profile-thumbnail>
                    <div class="padtop10  {{customizeUI?.guest_speakers?.speakers_name?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                        {{item.name}}
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf=" isPreview">
                <div class="txtCenter mrgn5" *ngFor="let item of briindGuest">
                    <app-profile-thumbnail [image]="item.photo" [imageClass]="'d70'"></app-profile-thumbnail>
                    <div class="padtop10  {{customizeUI?.guest_speakers?.speakers_name?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.speakers_name?.font, 'color': customizeUI?.guest_speakers?.speakers_name?.color}">
                        {{item.name}}
                    </div>
                </div>
            </ng-container>

        </div>
    </ng-container>



    <div class="pad15" style="background: #e9e9e9" *ngIf="data?.registration_guidelines || isPreview">
        <div class="{{customizeUI?.registration_guideline?.title?.font_family}} padbtm5 txtCenter" [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.title?.font, 'color': customizeUI?.registration_guideline?.title?.color}">
            {{'registration guidlines' | translate}}</div>
        <div class="{{customizeUI?.registration_guideline?.description?.font_family}} txtCenter" [ngStyle]="{'font-size.px': customizeUI?.registration_guideline?.description?.font, 'color': customizeUI?.registration_guideline?.description?.color}">
            {{(data?.registration_guidelines ? data?.registration_guidelines : isPreview ? 'lorem ipsum' : '') | translate}}
        </div>
    </div>

    <div class="pad15" *ngIf="data?.who_can_attend || isPreview">
        <div class="{{customizeUI?.guest_speakers?.who_can_attend_title?.font_family}} padbtm5 txtCenter" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_title?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_title?.color}">
            {{'who can attend' | translate}}</div>
        <div class="txtCenter {{customizeUI?.guest_speakers?.who_can_attend_description?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.guest_speakers?.who_can_attend_description?.font, 'color': customizeUI?.guest_speakers?.who_can_attend_description?.color}">
            {{(data?.who_can_attend ? data?.who_can_attend : isPreview ? 'lorem ipsum' : '') | translate}}
        </div>
    </div>
    <div class="txtCenter mrgnauto" *ngIf="countdownTimer">
        <span class="poppins-semibold bdrRadius6px" style="display: inline-block;background: #ffba95;padding: 17px 35px 16px;font-size: 14px;font-weight: 600;color: #ffffff;">
            {{'time' | translate}}
            {{countdownTimer}}
        </span>
    </div>
    <div class="padtop15 txtCenter {{customizeUI?.date?.date?.font_family}} padbtm15" *ngIf="data?.registration_last_date || isPreview" [ngStyle]="{'font-size.px': customizeUI?.date?.date?.font, 'color': customizeUI?.date?.date?.color}">
        {{'last date of registration' | translate}} - {{moment(data?.registration_last_date).format('DD. MMMM')}}</div>
    <!-- <div class="padtop5 txtCenter padbtm15" *ngIf="data?.website_link || isPreview">
        <a [href]="data?.website_link" class="{{customizeUI?.button_customization?.website?.font_family}}"
            [ngStyle]="{'font-size.px': customizeUI?.button_customization?.website?.font , 'color': customizeUI?.button_customization?.website?.color}">{{data?.website_link || 'www.events.com'}}</a>
    </div> -->

    <div class="flex flexJustifyBetween bdrRadiusBottomLeftt6px bdrRadiusBottomRight6px overflowHidden">
        <div class="flex1" *ngIf="data?.organization_link || isPreview">
            <button (click)="saveBriindletViewProfile(data?.organization_link, 'organization_link')" class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started" class="{{customizeUI?.button_customization?.view_profile?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.view_profile?.font, 'background-color': customizeUI?.button_customization?.view_profile?.background_color , 'color': customizeUI?.button_customization?.view_profile?.color}">
                {{customizeUI?.button_customization?.view_profile?.button_name | translate}}
            </button>
        </div>
        <div class="flex1" style="border-left: 2px solid #EEEEEE;" *ngIf="data?.cause  || isPreview">
            <button (click)="saveBriindletViewProfile(data?.cause, 'cause')" class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started" class="{{customizeUI?.button_customization?.donate_now?.font_family}}" [ngStyle]="{'font-size.px': customizeUI?.button_customization?.donate_now?.font, 'background-color': customizeUI?.button_customization?.donate_now?.background_color , 'color': customizeUI?.button_customization?.donate_now?.color}">
                {{customizeUI?.button_customization?.donate_now?.button_name | translate}}
            </button>
        </div>
        <div class="flex1 " style="border-left: 2px solid #EEEEEE;" *ngIf="data?.website_link">
            <button [routerLink]="data?.website_link" (click)="saveBriindletRegister(data?.website_link, 'website_link')" class="btn-get-started height45px ripple cursorPtr bdrRadius0 pad10 txtCenter width100  btn-get-started" class="{{customizeUI?.button_customization?.website?.font_family}}"
                [ngStyle]="{'font-size.px': customizeUI?.button_customization?.website?.font, 'background-color': customizeUI?.button_customization?.website?.background_color || 'rgb(0, 0, 139)' , 'color': customizeUI?.button_customization?.website?.color}">
                {{customizeUI?.button_customization?.website?.button_name || 'www.events.com'}}
            </button>
        </div>
    </div>



</div>