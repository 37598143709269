<section id="forward-application" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar {{popup?.context?.parent}}">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="width100">
        <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
            {{'forward application' | translate}}</div>
        <div class="color-122A4D font14 poppins-regular width80 mrgnauto txtCenter">
            {{'briind enables you to forward' | translate}}
        </div>
        <div class="pad10"></div>
        <div class="width80 mrgnauto">
            <input [(ngModel)]="briindID" class="card-input" [placeholder]="'enter briind id' | translate">
            <strong class="color-red poppins-regular font13 mrgnbtm0" *ngIf="errorMessage">
                {{(errorMessage || 'invalid briind id') | translate}}
            </strong>
            <div class="mrgntop15">
                <button [disabled]="!briindID" (click)="forwardApplication();" class="btn-get-started minWidth90px mrgnright10 bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad1030 btn-user-default">
                    {{'forward' |  translate}}
                </button>
            </div>
        </div>

    </div>
</section>