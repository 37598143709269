import { AppService } from 'src/app/core/services/app.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PopupModel } from 'src/app/core/models';
import { OrganizationService } from 'src/app/organizations/service/organization.service';

@Component({
    selector: 'app-membership-reject-reason',
    templateUrl: './membership-reject-reason.component.html',
    styleUrls: ['./membership-reject-reason.component.scss'],
})
export class MembershipRejectReasonComponent {
    @Input('popup') popup: PopupModel = new PopupModel();
    @Output('close') close = new EventEmitter();
    subscription: Subscription = new Subscription();
    reason: string = '';
    constructor(
        private organizationService: OrganizationService,
        private appService:AppService
    ) {

    }

    
  /**
   * @description
   * application reject method
   *
   * @memberof OrganizationApplicationDetailComponent
   */
  rejectApplication() {
    this.subscription.add(
      this.organizationService.rejectMembershipForm(this.popup.context.applicationID, {reason: this.reason}).subscribe(response => {
        this.appService.toggleToast({
            type: 'success',
            load: true,
            position: 'bottom-left',
            context: { description: 'Application Rejected Successfully' }
        });
        this.closePopup();
      })
    )
  }


    /**
     * @description
     * this method is used for closing the popup
     *
     * @memberof CreateRbacPopupComponent
     */
    closePopup() {
        this.close.emit('icon');
    }

}
