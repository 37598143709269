<div class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 pad15 bg-white posRelative {{userType}}">
    <div class="flex flexAlignItemsCenter">
        <i class="d25 icns icon-create-post {{userType}}"></i>
        <div class="pad5"></div>
        <div class="timeline-label font20 poppins-semibold">
            {{'create post' | translate}}
        </div>
    </div>
    <div class="pad5"></div>
    <div class="create-post-box bdrRadius10px pad15 boxShadow-RGBA00005 bdr-1-EEEEEE bg-white flex">
        <div>
            <app-profile-thumbnail [image]="userData?.profile_pic" [isShowBorder]="false" [imageClass]="'d40'">
            </app-profile-thumbnail>
        </div>
        <div class="pad3"></div>
        <div id="create-post" class="width100 cursorPtr" (click)="openCreatePostPopup()">
            <span class="poppins-semibold timeline-label font16 txtTransformCaps">
                {{userName | translate}}
            </span>
            <textarea placeholder="{{'whats on your mind' | translate: {name: firstName | titlecase} }}" class="poppins-regular padtop0 bg-white" readonly></textarea>
        </div>
    </div>
    <div class="pad5"></div>
    <app-post-photos-videos-other-upload (uploadedFiles)="openCreatePostPopup($event)">
    </app-post-photos-videos-other-upload>
</div>