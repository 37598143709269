import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from './../../../../../core/stores/app.store';
import { 
  defualtTemplate, 
  templateOne, 
  templateThree, 
  templateTwo 
} from './../../../../../core/mock-api/briindlet-template-customization';

@Component({
  selector: 'app-briindlet-event-template',
  templateUrl: './briindlet-event-template.component.html',
  styleUrls: ['./briindlet-event-template.component.scss']
})
export class BriindletEventTemplateComponent implements OnInit {
  @Output('stepperActions') stepperActions  = new EventEmitter();
  @Output('templateConfiguration') templateConfiguration  = new EventEmitter();
  @Input() formGrp: any;
  selectedTemplateItem: any;
  briindTemplate: any = []
  // selectedTemplateConfig: any = defualtTemplate;
  constructor(
    private appService: AppService,
    public appStore: AppStore
  ) { }

  btnCTA(event: any) {
    this.stepperActions.emit(event);
  }

  setTemplateConfiguration(name: any) {
    switch (name.toLowerCase().split(' ').join('')) {
      case 'defaulttemplate':
        this.templateConfiguration.emit(defualtTemplate)
        break;
      case 'template3':
        this.templateConfiguration.emit(templateThree)
        break;
      case 'template2':
        this.templateConfiguration.emit(templateTwo)
        break;
      case 'template1':
        this.templateConfiguration.emit(templateOne)
        break;  
      default:
        break;
    }
  }

  selectTemplate(item?: any) {
    if (!this.selectedTemplateItem || (this.selectedTemplateItem && this.selectedTemplateItem.id != item.id)) {
      for (const template of this.briindTemplate) {
        template.isSelected = false;
        if (template.id == item.id) {
          template.isSelected = true;
          this.selectedTemplateItem = item;
          this.formGrp['controls']['step_2'].patchValue({
            template: item.id,
            template_amount: item.price,
            template_name: item.name 
          })
        }
      }
      this.setTemplateConfiguration(item.name);
    }
  }

  getTemplateList() {
    this.appService.getTemplateList().subscribe(response => {
      this.briindTemplate = response;
      for (const item of this.briindTemplate) {
        if ( this.formGrp['controls']['step_2']['value']['template'] == item.id) {
          item.isSelected = true;
          this.selectedTemplateItem = item;
          break;
        }
      }
    })
  }

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   * 
   * @memberof BriindletEventTemplateComponent
   */
  ngOnInit(): void {
    this.getTemplateList();

    // this.formGrp.controls['step_2'].patchValue({
    //   template: this.appService.cacheData?.step_2?.template,
    //   template_amount: this.appService.cacheData?.step_2?.template_amount,
    //   template_name: this.appService.cacheData?.step_2?.template_name
    //  })
    // this.appService.cacheData.subscribe(res=>{
    //   //console.log('steps 2', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_2'].patchValue({
    //     template: steps.step_2.template,
    //     template_amount: steps.step_2.template_amount,
    //     template_name: steps.step_2.template_name,
    //    })
    // })
  }

}
