import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-top-hashtags',
  templateUrl: './top-hashtags.component.html',
  styleUrls: ['./top-hashtags.component.scss']
})
export class TopHashtagComponent implements OnInit {
  @Output() hashTagData = new EventEmitter();
  @Input('data') data: any = [];
  @Input('type') type: string = '';
  @Input('postType') postType: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  /**
   *  @description
   * this method is used for emiting the card action
   *
   * @memberof MostLikedSharedPostComponent
   */
   getHashTagData(item: any) {
    this.hashTagData.emit(item);
  }

}
