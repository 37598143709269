<div class="flex bdr-1-EEEEEE posRelative boxShadow-RGBA00005 flexResponsive flexAlignItemsCenter bg-FFFFFF bdrRadius6px pad15" *ngIf="leaderType == 'leader'">
    <span class="close-popup ripple bg-18878C bdrRadius50p color-FFFFFF posTop5px posRight5px" (click)="removeCard();" *ngIf="showClose">×</span>

    <div class="txtCenter cursorPtr" [routerLink]="data?.user_type === 'User' ? '/users/' + data?.id + '/timeline' : '/organizations/' + data?.id + '/timeline'">
        <span class="blue-img-shadow padtop8 padbtm3 padleft10 displayFlex bdrRadius50p posRelative">
            <img [src]="data?.profile_pic" class="d55 bdrRadius50p brdr4Green" />
        </span>
    </div>
    <div class="pad5 mobiVisible"></div>
    <div class="width85 flex flexAlignItemsCenter">
        <div class="width100Minus15MD mrgn000auto width100">
            <div class="poppins-semibold flex flexJustifyBetween flexAlignItemsCenter font16 color-18878C lineHeight16px xs-center txtTransformCaps ipad-margin-bottom-13">
                {{data?.first_name}}
                <div class="flex flexAlignItemsCenter">
                    <app-status-chip [status]="data?.status" *ngIf="data?.status"></app-status-chip>
                    <i class="icns icon-delete green d20 mrgnleft10 cursorPtr" (click)="deleteLeader(data)"></i>
                </div>
            </div>
            <div class="poppins-regular font14 color-8E8E8E lineHeight14px padbtm5 xs-center txtTransformCaps" *ngIf="data?.designation">
                {{'designation'| translate}}: {{data?.designation}}</div>
            <div class="poppins-regular font14 color-8E8E8E lineHeight14px padbtm5 xs-center txtTransformCaps" *ngIf="data?.briind_id != 'None'">
                {{'bid'| translate: { id: data?.briind_id } }}</div>
            <div class="flex flexWrap flexResponsive" *ngIf="data?.email">
                <div class="poppins-regular xs-center lineHeight14px font14 color-8E8E8E padbtm5 flex flexJustifyCenter flexAlignItemsCenter">
                    <i class="icns icon-email green d18 mrgnright5"></i> {{data?.email}}
                </div>
                <div class="pad5 desktopVisible"></div>
                <div *ngIf="data?.mobile_number" class="poppins-regular lineHeight14px font14 color-8E8E8E padbtm5 flex flexJustifyCenter flexAlignItemsCenter">
                    <i class="icns icon-call green d18 mrgnright5"></i> {{data?.mobile_number}}
                </div>
            </div>
        </div>

    </div>
</div>


<div class="flex bdr-1-EEEEEE posRelative boxShadow-RGBA00005 flexResponsive flexAlignItemsCenter bg-FFFFFF bdrRadius6px pad15" *ngIf="leaderType == 'ministry'">
    <span class="close-popup ripple bg-18878C bdrRadius50p color-FFFFFF posTop5px posRight5px" (click)="removeCard();" *ngIf="showClose">×</span>
    <div class="txtCenter cursorPtr" [routerLink]="data?.user?.user_type === 'User' ? '/users/' + data?.user?.id + '/timeline' : '/organizations/' + data?.user?.id + '/timeline'">
        <span class="blue-img-shadow padtop8 padbtm3 padleft10 displayFlex bdrRadius50p posRelative">
            <img [src]="data?.user?.profile_pic" class="d55 bdrRadius50p brdr4Green" />
        </span>
    </div>
    <div class="pad5 mobiVisible"></div>
    <div class="width85 flex flexAlignItemsCenter">
        <div class="width100Minus15MD mrgn000auto width100">
            <div class="poppins-semibold flex flexJustifyBetween flexAlignItemsCenter font16 color-18878C lineHeight16px xs-center txtTransformCaps">
                {{data?.user?.name}}
            </div>
            <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.designation || data?.work?.designation || data?.overview?.work?.designation">
                {{data?.designation || data?.work?.designation || data?.overview?.work?.designation || ''}}
            </div>
            <!-- <div class="poppins-regular font13 color-8E8E8E " *ngIf="data?.personal_information?.about_me">
                {{ data?.personal_information?.about_me?.substring(0, 50) }}
            </div> -->
            <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.user?.email">
                {{ data?.user?.email }}
            </div>
            <div class="poppins-regular font13 color-8E8E8E" *ngIf="data?.user?.contact_no">
                {{ data?.user?.contact_no }}
            </div>
        </div>
    </div>
</div>