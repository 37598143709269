import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../../core/services/app.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../users/services/user.service';
import { AuthService } from '../../../../core/services/auth-service/auth.service';
import { CameraService } from 'src/app/core/services/camera.service';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-group-cover',
  templateUrl: './group-cover.component.html',
  styleUrls: ['./group-cover.component.scss']
})
export class GroupCoverComponent {
  @Input('groupDetail') groupDetail: any;
  imageChangedEvent: any;
  showCropper: boolean = false;
  subscription: Subscription =  new Subscription();
  iconTheme: string = 'blue';
  userType: string = '';
  JSON:any =JSON; 
  isWeb = true
  constructor(
    private appService: AppService,
    private userService: UserService,
    private authService: AuthService,
    private cameraService: CameraService
  ) {
    this.userType = this.authService.getSessionData().type;
    if( this.userType == 'Organization') {
      this.iconTheme = 'green';
    }

    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isWeb = false
  }

  /**
   * @description
   * this method is used for getting User List and open popup
   *
   * @memberof GroupCoverComponent
   */
  openInviteFriendsPopUp() {
    this.subscription.add(
      this.appService.getCombinedUserList().subscribe((response: any) => {
        this.appService.togglePopup({
          type: 'inviteFriends',
          load: true,
          refreshParent: true, 
          overlayClose: true,
          context: {
            id: this.groupDetail.id,
            userType: this.authService.getSessionData().type.toLowerCase(),
            data: response
          }
        });
      })
    );
  }

  /**
   * @description
   * update cover photo method
   *
   * @param {*} event
   * @param {string} type
   * @returns
   * @memberof GroupCoverComponent
   */
  async updatePhoto(event: any, type: string) {
    if(!this.isWeb) {
      this.imageChangedEvent = await this.cameraService.getPhoto();
      this.showCropper =true;
    }
    if(this.isWeb) {
      if (event.target.files.length > 0) {
        this.imageChangedEvent = event;
        this.showCropper =true;
        return;
      }      
    }
  }

  /**
   * @description
   * this method is used for update croped image
   * @memberof GroupCoverComponent
   */
  uploadImage(event ?: any){
    if(event) {
      let formData = new FormData();
      formData.append('cover_photo',event);
      this.subscription.add(
        this.appService.updateGroup(formData, this.groupDetail.id).subscribe((response: any) => {
          this.groupDetail = response;
          this.showCropper = false;
        })
      );
    }
  }

}
