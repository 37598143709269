<div class="pad20 bdrRadius6px bg-white posRelative organization">
    <div class="scroller custom-scrollbar">
        <div class="header color-18878C font25 lineHeight40px poppins-semibold txtCenter">
            {{'instructional video for member management' | translate}}
        </div>
        <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>

        <div class="mrgntop20">
            <div class="width100">
                <div
                    class="bdrRadius10px bdr-1-EEEEEE boxShadow-RGBA00005 overflowHidden cursorPtr">
                    <div>
                        <video preload="auto" width="100%" type="video/mp4" autoplay controls #video>
                            <source [src]="getInstructionalVideoMemberManagementURI " type="video/mp4">
                            Your browser does not support HTML video.
                        </video>
                    </div>
                </div>
            </div>
            <div class="pad15"></div>
            <div class="flex padbtm15 flexJustifyCenter">
                <button (click)="closePopup();" type="button"
                    class="btn-get-started  cancel-btn minWidth107px flex flexAlignItemsCenter flexJustifyCenter bdr-1-707070 ripple cursorPtr font14 poppins-semibold pad0815">
                    {{'ok' | translate}}
                </button>
            </div>
        </div>
    </div>

</div>