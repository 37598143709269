import { ActionModel } from './action.model';
export class GroupModel {
    about ?: string = '';
    category ?: string = '';
    content_type ?: number =  0;
    cover_photo ?: string = '' ;
    created_at ?: string = '';
    group_type ?: string = '';
    id : any = 0;
    members_count ?: number = 0;
    name ?: string = '';
    object_id ?: number = 0;
    organization_group_members ?: any[] = [];
    privacy ?: any;
    profile_pic ?: string = '';
    updated_at ?: string = '';
    user_group_members ?: any[] = [];
    is_admin: boolean = false;
    is_member: boolean = false;
    actions ?: ActionModel[] = [];
}

export class GroupCategoryModel {
    id ?: number = 0;
    name ?: string = '';
}