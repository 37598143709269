import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { App } from '@capacitor/app';

// for capacitor camera plugin to work
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'hammerjs';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

App.addListener('backButton', ({ canGoBack }) => {
  if(canGoBack){
    window.history.back();
  } else {
    App.exitApp();
  }
});

defineCustomElements(window);
