<div class="{{appStore?.userType}}">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600  mrgnbtm40">
        {{'how long do you want to promote the event' | translate}}
    </div>
    <div class="body width100">
        <div class="width100 flex flexResponsive" [formGroup]="formGrp">
            <div class="mrgnbtm20" formGroupName="step_16">
                <div class="flex flexDirectionCol">
                    <div class="custom-radio flex flexAlignItemsStart {{appStore.iconClass}} mrgnbtm10 flexAlignItemsCenter">
                        <input type="radio" class="flexNoShrink" value="1" (change)="showCustomHoursBox('1')" formControlName="hours" name="hours">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'1 hr' | translate}}
                        </span>
                    </div>
                    <div class="custom-radio flex flexAlignItemsStart {{appStore.iconClass}} flexAlignItemsCenter mrgnbtm10">
                        <input type="radio" class="flexNoShrink" value="8" (change)="showCustomHoursBox('8')" formControlName="hours" name="hours">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'8 hrs' | translate}}
                        </span>
                    </div>
                    <div class="custom-radio flex {{appStore.iconClass}} flexAlignItemsStart flexAlignItemsCenter mrgnbtm10">
                        <input type="radio" class="flexNoShrink" value="16"  (change)="showCustomHoursBox('16')" formControlName="hours" name="hours">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'16 hrs' | translate}}
                        </span>
                    </div>
                    <div class="custom-radio flex {{appStore.iconClass}} flexAlignItemsStart flexAlignItemsCenter mrgnbtm10 ">
                        <input type="radio" class="flexNoShrink " value="24" (change)="showCustomHoursBox('24')" formControlName="hours" name="hours">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'24 hrs' | translate}}
                        </span>
                    </div>
                    <div class="custom-radio flex  {{appStore.iconClass}} flexAlignItemsStart flexAlignItemsCenter mrgnbtm10 ">
                        <input type="radio" class="flexNoShrink"  name="hours" (change)="showCustomHoursBox('custom')">
                        <span class="poppins-regular timeline-label font15 txtLeft padleft10 padtop2">
                            {{'custom' | translate}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flexJustifyBetween flexResponsive mrgnbtm20" *ngIf="showCustomHoursTextBox">
            <div class="width50">
                <input [owlDateTime]="dt1" [(ngModel)]="startDate" [owlDateTimeTrigger]="dt1" placeholder="{{'event from' | translate}}" type="text"
                  readonly="true" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white 
                      outline0 bdrRadius6px calender-{{appStore.iconClass}} input-icon">
                <owl-date-time [pickerType]="'calendar'"  (afterPickerClosed)="getDateDiff()" #dt1></owl-date-time>
              </div>
              <div class="pad10"></div>
              <div class="width50">
                <input [owlDateTime]="dt2" [min]="startDate" [(ngModel)]="endDate"  [owlDateTimeTrigger]="dt2"  placeholder="{{'event to' | translate}}" type="text"
                  readonly="true" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular input-icon date bdr-1-EEEEEE bg-white 
                      outline0 bdrRadius6px calender-{{appStore.iconClass}} input-icon">
                <owl-date-time [pickerType]="'calendar'" (afterPickerClosed)="getDateDiff()" #dt2></owl-date-time>
              </div>
        </div>
        <div class="width100 padtop30 flex">
            <button  (click)="actionCTA('previous')"  class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="formGrp?.controls?.step_16?.invalid" (click)="actionCTA('go_to_preview')"  class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
</div>