import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrganizationStoreService } from '../../../organizations/store/organization-store.service';
import { OrganizationService } from '../../../organizations/service/organization.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-or-edit-cause',
  templateUrl: './add-or-edit-cause.component.html',
  styleUrls: ['./add-or-edit-cause.component.scss']
})
export class AddOrEditCauseComponent implements OnInit, OnChanges {
  addCausesForm: FormGroup = new FormGroup({});
  @Input('data') data: any;
  // @Input('toggleBankAdddPopup') public toggleBankAdddPopup : () => void;
  @Output("toggleBankAdddPopup") toggleBankAdddPopup: EventEmitter<any> = new EventEmitter();
  displayImages: any = [];
  subscription: Subscription = new Subscription();
  removedImages: any = [];
  @Output('close') close = new EventEmitter();
  btndisable: boolean = false;
  constructor(
    private fb: FormBuilder,
    private organizationStoreService: OrganizationStoreService,
    private organizationService: OrganizationService
  ) {
    this.addCausesForm = fb.group({
      bank_account_id: new FormControl('', Validators.required),
      title:  new FormControl('', Validators.required),
      description:  new FormControl('', Validators.required),
      web_link:  new FormControl('', [Validators.required]),
    });
  }

   /**
   * @description
   * this method is used for getting images form upload component
   *
   * @param {*} [event]
   * @memberof AddOrEditCauseComponent
   */
  addImages(event ?: any) {
    this.displayImages.push(event)
  }

  

  /**
   * @description
   * this method is used select account
   *
   * @param {*} [account]
   * @memberof AddOrEditCauseComponent
   */
  selectAccount(account ?: any) {
    this.addCausesForm.patchValue({
      bank_account_id: account.id
    });
  }

  onSubmit() {
    this.btndisable = true;
    if (!this.data.selected_cause) {
      this.subscription.add(
        this.organizationService.createCauses(this.getFormData()).subscribe((response: any) => {
          this.btndisable = false;
          if (this.organizationStoreService.organizationBussinessDetails.give_info && this.organizationStoreService.organizationBussinessDetails.give_info.causes_list) {
            this.organizationStoreService.organizationBussinessDetails.give_info.causes_list.unshift(response.result)
          } else {
            this.organizationStoreService.organizationBussinessDetails.give_info.causes_list = [response.result]
          }
          this.displayImages = [];
          this.removedImages = [];
          this.addCausesForm.reset();
        })
      )
    } else {
      this.subscription.add(
        this.organizationService.updateCauses(this.getFormData(), this.data.selected_cause.id).subscribe((response: any) => {
          this.btndisable = false;
          if ( this.organizationStoreService.organizationBussinessDetails.give_info.causes_list && this.organizationStoreService.organizationBussinessDetails.give_info.causes_list.length != 0) {
            for (let index = 0; index < this.organizationStoreService.organizationBussinessDetails.give_info.causes_list.length; index++) {
              let element = this.organizationStoreService.organizationBussinessDetails.give_info.causes_list[index];
              if (element.id == this.data.selected_cause.id) {
                this.organizationStoreService.organizationBussinessDetails.give_info.causes_list[index] = response.result;
              }
            }
          }
          
          this.displayImages = [];
          this.removedImages = [];
          this.close.emit('icon');
        })
      );
    }
    
  }

  /**
   * @description
   * this method is used for return the form data of causes api
   * 
   * @returns form data of causes api
   * @memberof OrganizationBussinessGiveComponent
   */
  getFormData() {
    let formData = new FormData();
    for (const key in this.addCausesForm.value) {
      formData.append(`${key}`, this.addCausesForm.value[key])
    }
    for (let index = 0; index < this.displayImages.length; index++) {
      if (this.displayImages[index].file) {
        formData.append(`photos[${index}][photo]`, this.displayImages[index].file);
      }
    }
    for (let index = 0; index < this.removedImages.length; index++) {
      formData.append(`delete_photo_ids[${index}]`, this.removedImages[index]);
    }
    formData.append('organization_id', this.organizationStoreService.organizationId)
    return formData;
  }

  /**
   * @description
   * this method is used for removing images
   *
   * @param {number} index
   * @memberof OrganizationBussinessGiveComponent
   */
  removeImage(index : number) {
    if (this.displayImages[index].id) {
      this.removedImages.push(this.displayImages[index].id)
    };
    this.displayImages.splice(index,1);
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   * @param {SimpleChanges} changes
   * @memberof AddOrEditCauseComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
   if (changes.data && changes.data.currentValue) {
     if (this.data.selected_cause) {
      this.addCausesForm.patchValue({
        bank_account_id: this.data.selected_cause.bank_account_id,
        title:  this.data.selected_cause.title,
        description:  this.data.selected_cause.description,
        web_link:  this.data.selected_cause.web_link,
      });
      this.displayImages =  this.data.selected_cause.photos.map((x: any)=> {return {'base64': x.photo, 'id': x.id}})
    }
  }
    
  }

  ngOnInit(): void {
    
  }


  openPopupAddAccount () {  
    this.toggleBankAdddPopup.emit()
  }

}
