import { ViewPostDetailComponent } from './components/view-post-detail/view-post-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';

import { ComingSoonComponent } from './shared/components/coming-soon/coming-soon.component';
import { HomeGuard } from './core/auth/home.guard';
import { QbService } from './core/services/qb.service';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from   './privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [HomeGuard]
  }, {
		path: 'home',
		component: HomeComponent,
    canActivate: [HomeGuard]
	},
  {
		path: 'login',
		component: LoginComponent,
	},
  {
    path: 'posts/:postID',
    component: ViewPostDetailComponent
  },
  {
		path: 'signup',
		component: RegisterComponent,
	},
  
  {
		path: 'signin',
		component: HomeComponent
	}, {
		path: 'users',
		loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
	}, {
		path: 'organizations',
		loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule)
  },{ 
    path: 'briindlet', 
    loadChildren: () => import('./briindlet/briindlet.module').then(m => m.BriindletModule)
  },{
    path: 'briindlet-performance', 
    loadChildren: () => import('./briindlet-performance/briindlet-performance.module').then(m => m.BriindletPerformanceModule)
  },{
    path: 'coming-soon/:type',
    component: ComingSoonComponent
  },
  { 
    path: 'notification', 
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule) 
  },
  { 
    path: 'chat', 
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
  },
  { 
    path: 'faq', 
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
  },
  { 
    path: 'advertisement', 
    loadChildren: () => import('./advertisement/advertisement.module').then(m => m.AdvertisementModule) 
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
