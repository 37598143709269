import { ActionModel } from '../models/action.model';
export const groupAdminActions: ActionModel[] = [{
    action_text: 'make moderator or member',
    action_type: 'more',
    order: 0,
    action_url: '',
    view_state: true
} 
// ,{
//     action_text: 'remove from group',
//     action_type: 'more',
//     order: 1,
//     action_url: '',
//     view_state: true
// }
]

export const groupMemberActions: ActionModel[] = [{
    action_text: 'make admin',
    action_type: 'more',
    order: 0,
    action_url: '',
    view_state: true
}, {
    action_text: 'remove from group',
    action_type: 'more',
    order: 2,
    action_url: '',
    view_state: true
}, {
    action_text: 'mute member',
    action_type: 'more',
    order: 3,
    action_url: '',
    view_state: true
}]


export const groupBlockedMemberActions: ActionModel[] = [{
    action_text: 'unmute member',
    action_type: 'more',
    order: 3,
    action_url: '',
    view_state: true
}]

export const groupDeleteActions: ActionModel[] = [{
    action_text: 'delete',
    action_type: 'more',
    order: 3,
    action_url: '',
    view_state: true
}]

export const suggestedFriends: ActionModel[] = [
    {
        action_text: 'add friend',
        action_type: 'button',
        order: 0,
        action_url: '',
        primary_button: true,
        view_state: true
    },{
        action_text: 'cancel',
        action_type: 'button',
        order: 1,
        action_url: '',
        view_state: true
    }
]

export const friendRequest: ActionModel[] = [
    {
        action_text: 'accept',
        action_type: 'button',
        order: 0,
        action_url: '',
        primary_button: true,
        view_state: true
    },
    {
        action_text: 'reject',
        action_type: 'button',
        order: 1,
        action_url: '',
        primary_button: false,
        view_state: true
    }
]

export const friendRequestSent: ActionModel[] = [
    {
        action_text: 'cancel',
        action_type: 'button',
        order: 0,
        action_url: '',
        primary_button: false,
        view_state: true
    },
    {
        action_text: 'notify again',
        action_type: 'button',
        order: 1,
        action_url: '',
        primary_button: true,
        view_state: true
    }

]


export const ApplicationSubmittedActions: ActionModel[] = [
    {
        action_text: 'accept',
        action_type: 'button',
        order: 0,
        action_url: '',
        primary_button: true,
        view_state: true
    },
    {
        action_text: 'reject',
        action_type: 'button',
        order: 0,
        action_url: '',
        primary_button: false,
        view_state: true
    },
    {
        action_text: 'view',
        action_type: 'link',
        order: 0,
        action_url: '',
        primary_button: false,
        view_state: true
    }
]

export const friendsAction: ActionModel[] = [
    {
        action_text: 'unfriend',
        action_type: 'more',
        order: 0,
        action_url: '',
        view_state: true
    }, {
        action_text: 'block friend',
        action_type: 'more',
        order: 1,
        action_url: '',
        view_state: true
    }
];

export const organizationMemberAction: ActionModel[] = [
    {
        action_text: 'manage',
        action_type: 'more',
        order: 0,
        action_url: '',
        view_state: true
    }, {
        action_text: 'remove from organization',
        action_type: 'more',
        order: 1,
        action_url: '',
        view_state: true
    },{
        action_text: 'block member',
        action_type: 'more',
        order: 2,
        action_url: '',
        view_state: true
    },{
        action_text: 'unblock member',
        action_type: 'more',
        order: 3,
        action_url: '',
        view_state: true
    }
]

export const addPeopleToGroupAction: ActionModel[] = [{
        action_text: 'addCheckbox',
        action_type: 'checkbox',
        order: 1,
        action_url: '',
        view_state: true
    }
]

export const searchUserAction: ActionModel[] = [{
    action_text: 'continue',
    action_type: 'button',
    order: 1,
    action_url: '',
    primary_button: true,
    view_state: true
    }
]

export const cancelAction: ActionModel[] = [{
    action_text: 'cancel',
    action_type: 'button',
    order: 1,
    action_url: '',
    primary_button: false,
    view_state: true
    }
]

export const manageAction: ActionModel[] = [
    {
        action_text: 'manage',
        action_type: 'button',
        order: 1,
        action_url: '',
        primary_button: true,
        view_state: true
    }
]


export const joinAction: ActionModel[] = [
    {
        action_text: 'join',
        action_type: 'button',
        order: 1,
        action_url: '',
        primary_button: true,
        view_state: true
    }
]