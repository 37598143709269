export class TrendingOrganizationModel {
    data: DataModel[] = [];
    total_count: number = 0;
    total_pages: number = 0;
}

export class DataModel {
    id: number = 0;
    name: string = "";
    cover_photo: null | string = '';
    members_count: number = 0;
    description: string = '';
    is_promoted: boolean = false;
}
