import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from './../../../../../core/stores/app.store';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-briindlet-event-website',
  templateUrl: './briindlet-event-website.component.html',
  styleUrls: ['./briindlet-event-website.component.scss']
})
export class BriindletEventWebsiteComponent implements OnInit {
  @Input() formGrp: any;
  @Output() stepperActions = new EventEmitter();
  constructor(
    public appStore: AppStore,
    private appService: AppService
  ) { }

  actionCTA(event : any) {
    this.stepperActions.emit(event);
  }

  ngOnInit(): void {
    // this.formGrp.controls['step_12'].patchValue({
    //   website_link: this.appService.cacheData1?.step_12?.website_link
    // })
    // this.appService.cacheData1.subscribe(res=>{
    //   //console.log('steps 12', res)
    //   let steps:any = []
    //   steps = res
    //   this.formGrp.controls['step_12'].patchValue({
    //     website_link: steps.step_12?.website_link
    //    })
    // })
  }

}
