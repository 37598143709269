
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';

@Component({
  selector: 'app-user-preview-popup',
  templateUrl: './user-preview-popup.component.html',
  styleUrls: ['./user-preview-popup.component.scss']
})
export class UserPreviewPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor() { }

  /**
  * @description
  * Click on close button this method will call
  *
  * @memberof UserPreviewPopupComponent
  */
  closePopup() {
    this.close.emit('icon');
  }
  

  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   *
   * @memberof UserPreviewPopupComponent
   */
  ngOnInit(): void {
  }

}
