export class PrivacySettingsModel {
    id: number = 0;
    send_friend_request: string = '';
    see_friends_list: string = '';
    email_lookup: string = '';
    phone_lookup: string = '';
    share_your_posts: boolean = false;
    hide_comment_words: boolean = false;
    see_tagged_post: string = '';
    review_post: boolean = false;
    review_tags: boolean = false;
    language: string = '';
    region_format: string = '';
    comment_push_notify: boolean = false;
    comment_email_notify: boolean = false;
    comment_sms_notify: boolean = false;
    tags_push_notify: boolean = false;
    tags_email_notify: boolean = false;
    sms_sms_notify: boolean = false;
    reminders_push_notify: boolean = false;
    reminders_email_notify: boolean = false;
    reminders_sms_notify: boolean = false;
    requests_push_notify: boolean = false;
    requests_email_notify: boolean = false;
    requests_sms_notify: boolean = false;
    birthdays_push_notify: boolean = false;
    birthdays_email_notify: boolean = false;
    birthdays_sms_notify: boolean = false;
    group_push_notify: boolean = false;
    group_email_notify: boolean = false;
    group_sms_notify: boolean = false;
    events_push_notify: boolean = false;
    events_email_notify: boolean = false;
    events_sms_notify: boolean = false;
    org_follow_push_notify: boolean = false;
    org_follow_email_notify: boolean = false;
    org_follow_sms_notify: boolean = false;
    menu_order: boolean = false;
    user: number = 0;
    region: any;
    server_timezone: string = '';
    timezone_hour_difference: any = 0;
    region_name: any;
}
