import { config } from './../../environments/environment';
import { LoaderComponent } from './components/loader/loader.component';
import { TimePipe } from './pipes/time.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OwlDateTimeModule, OwlNativeDateTimeModule, DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
/* External Libs Start Here */
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { ChooseYourAccountPopupComponent } from './components/popup/choose-your-account-popup/choose-your-account-popup.component';
import { PopupComponent } from './components/popup/popup.component';
import { TwoFactorAuthCodeComponent } from './components/two-factor-auth-code/two-factor-auth-code.component';
import { DebounceClickDirective } from 'src/app/core/directives/debounce.directive';
import { MultiSelectDropdownComponent } from './components/custom-forms/multi-select-dropdown/multi-select-dropdown.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { PrivacyCheckComponent } from './components/privacy-check/privacy-check.component';
import { CustomSelectComponent } from './components/custom-forms/custom-select/custom-select.component';
import { UserPreviewPopupComponent } from './components/popup/user-preview-popup/user-preview-popup.component';
import { DatepickerComponent } from './components/custom-forms/datepicker/datepicker.component';
import { ProfilePreviewComponent } from './components/popup/user-preview-popup/profile-preview/profile-preview.component';
import { WorkEducationPreviewComponent } from './components/popup/user-preview-popup/work-education-preview/work-education-preview.component';
import { ContactInfoPreviewComponent } from './components/popup/user-preview-popup/contact-info-preview/contact-info-preview.component';
import { PlacesLivedPreviewComponent } from './components/popup/user-preview-popup/places-lived-preview/places-lived-preview.component';
import { UserInterestCardComponent } from './components/cards/user-interest-card/user-interest-card.component';
import { InterestPreviewComponent } from './components/popup/user-preview-popup/interest-preview/interest-preview.component';
import { ProfileCompletedSuccessPopupComponent } from './components/popup/profile-completed-success-popup/profile-completed-success-popup.component';
import { OrganizationPreviewPopupComponent } from './components/popup/organization-preview-popup/organization-preview-popup.component';
import { OrganizationBasicInfoPreviewComponent } from './components/popup/organization-preview-popup/organization-basic-info-preview/organization-basic-info-preview.component';
import { AddMilestonePopupComponent } from './components/popup/add-milestone-popup/add-milestone-popup.component';
import { MilestoneCardComponent } from './components/cards/milestone-card/milestone-card.component';
import { OrganizationMilestonePreviewComponent } from './components/popup/organization-preview-popup/organization-milestone-preview/organization-milestone-preview.component';
import { OrganizationServicesOfferedPreviewComponent } from './components/popup/organization-preview-popup/organization-services-offered-preview/organization-services-offered-preview.component';
import { OrganizationSocialMediaPreviewComponent } from './components/popup/organization-preview-popup/organization-social-media-preview/organization-social-media-preview.component';
import { LeaderCardComponent } from './components/cards/leader-card/leader-card.component';
import { AddLeaderPopupComponent } from './components/popup/add-leader-popup/add-leader-popup.component';
import { OrganizationLeadersPreviewComponent } from './components/popup/organization-preview-popup/organization-leaders-preview/organization-leaders-preview.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { BussinessInviteCardComponent } from './components/cards/bussiness-invite-card/bussiness-invite-card.component';
import { FormNavigationComponent } from './components/form-navigation/form-navigation.component';
import { MinistryListItemTabComponent } from './components/ministry-list-item-tab/ministry-list-item-tab.component';
import { CarouselItemDirective } from '../core/directives/carousel-item.directive';
import { UploadPhotoComponent } from './components/upload-photo/upload-photo.component';
import { OrganizationNewToUsTemplateComponent } from './components/popup/organization-preview-popup/organization-new-to-us-template/organization-new-to-us-template.component';
import { TabPanelComponent } from './components/tab-panel/tab-panel.component';
import { OrganizationBussinessAboutPreviewComponent } from './components/popup/organization-preview-popup/organization-bussiness-about-preview/organization-bussiness-about-preview.component';
import { AddOrEditMembershipFieldPopupComponent } from './components/popup/add-or-edit-membership-field-popup/add-or-edit-membership-field-popup.component';
import { OrganizationMembershipFormPreviewComponent } from './components/popup/organization-preview-popup/organization-membership-form-preview/organization-membership-form-preview.component';
import { BankAccountCardComponent } from './components/cards/bank-account-card/bank-account-card.component';
import { OrganizationGivePreviewComponent } from './components/popup/organization-preview-popup/organization-give-preview/organization-give-preview.component';
import { SimpleCarouselComponent } from './components/simple-carousel/simple-carousel.component';
import { SearchPipe, FullDatePipe, DateAgoPipe, TruncatePipe, SafeUrlPipe } from './pipes';
import { ImageCropperModule } from './components/image-cropper/image-cropper.module';
import { CreateAlbumComponent } from './components/popup/create-album/create-album.component';
import { AddOrEditCauseComponent } from './components/add-or-edit-cause/add-or-edit-cause.component';
import { CreatePostCardComponent } from './components/cards/create-post-card/create-post-card.component';
import { CreateGroupPopupComponent } from './components/popup/create-group-popup/create-group-popup.component';
import { CreatePostPopupComponent } from './components/popup/create-post-popup/create-post-popup.component';
import { PostPhotosVideosOtherUploadComponent } from './components/cards/create-post-card/post-photos-videos-other-upload/post-photos-videos-other-upload.component';
import { PostCardComponent } from './components/cards/post-card/post-card.component';
import { CropImagePopupComponent } from './components/popup/crop-image-popup/crop-image-popup.component';
import { InviteFriendsPopupComponent } from './components/popup/invite-friends-popup/invite-friends-popup.component';
import { ViewPostPopupComponent } from './components/popup/view-post-popup/view-post-popup.component';
import { PostProfileSectionCardComponent } from './components/cards/post-card/post-profile-section-card/post-profile-section-card.component';
import { PostActionComponent } from './components/cards/post-card/post-action/post-action.component';
import { PostCommentComponent } from './components/cards/post-card/post-comment/post-comment.component';
import { PromotionCardComponent } from './components/cards/promotion-card/promotion-card.component';
import { PostServicesCardComponent } from './components/cards/post-services-card/post-services-card.component';
import { VideoPreviewCardComponent } from './components/cards/video-preview-card/video-preview-card.component';
import { VideoDisplayComponent } from './components/video-display/video-display.component';
import { OrganizationPromotePopupComponent } from './components/popup/organization-promote-popup/organization-promote-popup.component';
import { TimelinePhotosSectionComponent } from './components/timeline-photos-section/timeline-photos-section.component';
import { TimelineFriendsSectionComponent } from './components/timeline-friends-section/timeline-friends-section.component';
import { TrendingOrganizationComponent } from './trending-organization/trending-organization.component';
import { GeneralCardComponent } from './components/cards/general-card/general-card.component';
import { GroupCoverComponent } from './components/cover/group-cover/group-cover.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { InviteFriendItemComponent } from './components/popup/invite-friends-popup/invite-friend-item/invite-friend-item.component';
import { TrendingOrganizationCardComponent } from './components/cards/trending-organization-card/trending-organization-card.component';
import { UserOrganizationHeaderComponent } from './components/user-organization-header/user-organization-header.component';
import { SuggestedFriendsPopupComponent } from './components/popup/suggested-friends-popup/suggested-friends-popup.component';
import { CardActionsComponent } from './components/cards/card-actions/card-actions.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ProfileThumbnailComponent } from './components/profile-thumbnail/profile-thumbnail.component';
import { ForwardAppilcationDetailPopupComponent } from './components/popup/forward-appilcation-detail-popup/forward-appilcation-detail-popup.component';
import { RbacCardComponent } from './components/cards/rbac-card/rbac-card.component';
import { CreateRbacPopupComponent } from './components/popup/create-rbac-popup/create-rbac-popup.component';
import { ShareToGroupPopupComponent } from './components/popup/share-to-group-popup/share-to-group-popup.component';
import { ShareToGroupCardComponent } from './components/popup/share-to-group-popup/components/share-to-group-card/share-to-group-card.component';
import { PostSharedComponent } from './components/cards/post-card/post-shared/post-shared.component';
import { FriendsCardComponent } from './components/cards/friends-card/friends-card.component';
import { FriendsPopupComponent } from './components/popup/friends-popup/friends-popup.component';
import { AddRoleToMemberPopupComponent } from './components/popup/add-role-to-member-popup/add-role-to-member-popup.component';
import { PostCommentListComponent } from './components/cards/post-card/post-comment-list/post-comment-list.component';
import { SetUserDetailsComponent } from './components/set-user-details/set-user-details.component';
import { ConfirmationPopupComponent } from './components/popup/confirmation-popup/confirmation-popup.component';
import { MostLikedSharedPostComponent } from './components/cards/most-liked-shared-post/most-liked-shared-post.component';
import { CreateOrganizationMemberGroupPopupComponent } from './components/popup/create-organization-member-group-popup/create-organization-member-group-popup.component';
import { MembershipEventCardComponent } from './components/cards/membership-event-card/membership-event-card.component';
import { CreateOrganizationEventPopupComponent } from './components/popup/create-organization-event-popup/create-organization-event-popup.component';
import { UpdateMemberRoleComponent } from './components/popup/update-member-role/update-member-role.component';
import { PostCommentReplyListComponent } from './components/cards/post-card/post-comment-reply-list/post-comment-reply-list.component';
import { ShowGroupMembersPopupComponent } from './components/popup/show-group-members-popup/show-group-members-popup.component';
import { PostGiftListComponent } from './components/popup/create-post-popup/post-gift-list/post-gift-list.component';
import { PostTagListComponent } from './components/popup/create-post-popup/post-tag-list/post-tag-list.component';
import { CreateBriindletPopupComponent } from './components/popup/create-briindlet-popup/create-briindlet-popup.component';
import { BriindletEventComponent } from './components/popup/create-briindlet-popup/briindlet-event/briindlet-event.component';
import { BriindletEventTemplateComponent } from './components/popup/create-briindlet-popup/briindlet-event-template/briindlet-event-template.component';
import { BriindletEventNameComponent } from './components/popup/create-briindlet-popup/briindlet-event-name/briindlet-event-name.component';
import { BriindletEventAboutComponent } from './components/popup/create-briindlet-popup/briindlet-event-about/briindlet-event-about.component';
import { BriindletEventDateComponent } from './components/popup/create-briindlet-popup/briindlet-event-date/briindlet-event-date.component';
import { BriindletEventLastdateComponent } from './components/popup/create-briindlet-popup/briindlet-event-lastdate/briindlet-event-lastdate.component';
import { BriindletEventGuestComponent } from './components/popup/create-briindlet-popup/briindlet-event-guest/briindlet-event-guest.component';
import { BriindletEventEntranceComponent } from './components/popup/create-briindlet-popup/briindlet-event-entrance/briindlet-event-entrance.component';
import { BriindletEventGuidelineComponent } from './components/popup/create-briindlet-popup/briindlet-event-guideline/briindlet-event-guideline.component';
import { BriindletEventBriindComponent } from './components/popup/create-briindlet-popup/briindlet-event-briind/briindlet-event-briind.component';
import { BriindletEventWebsiteComponent } from './components/popup/create-briindlet-popup/briindlet-event-website/briindlet-event-website.component';
import { BriindletEventBriindletMediaComponent } from './components/popup/create-briindlet-popup/briindlet-event-briindlet-media/briindlet-event-briindlet-media.component';
import { BriindletEventPromoteComponent } from './components/popup/create-briindlet-popup/briindlet-event-promote/briindlet-event-promote.component';
import { BriindletEventPromoteHrsComponent } from './components/popup/create-briindlet-popup/briindlet-event-promote-hrs/briindlet-event-promote-hrs.component';
import { BriindletEventPreviewComponent } from './components/popup/create-briindlet-popup/briindlet-event-preview/briindlet-event-preview.component';
import { ElasticSearchPopupComponent } from './components/popup/elastic-search-popup/elastic-search-popup.component';
import { BriindletRegionCostComponent } from './components/popup/create-briindlet-popup/briindlet-region-cost/briindlet-region-cost.component';
import { ElasticSearchPostItemComponent } from './components/popup/elastic-search-popup/components/elastic-search-post-item/elastic-search-post-item.component';
import { BriindletDontaionCampaignComponent } from './components/popup/create-briindlet-popup/briindlet-dontaion-campaign/briindlet-dontaion-campaign.component';
import { BriindletEventStripeComponent } from './components/popup/create-briindlet-popup/briindlet-event-stripe/briindlet-event-stripe.component';
import { NgxStripeModule } from 'ngx-stripe';
import { HeaderNotificationComponent } from './components/user-organization-header/header-notification/header-notification.component';
import { BriindletPreviewComponent } from './components/popup/create-briindlet-popup/briindlet-preview/briindlet-preview.component';
import { OrganizationPromoteStripeComponent } from './components/popup/organization-promote-popup/components/organization-promote-stripe/organization-promote-stripe.component';import { DefaultTemplateComponent } from './components/templates/default-template/default-template.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { MinimalDatePipe } from './pipes/minimal-date.pipe';
import { CustomizeEventTemplateComponent } from './components/popup/create-briindlet-popup/customize-event-template/customize-event-template.component';
import { AdvertisementPaymentPopupComponent } from './components/popup/advertisement-payment-popup/advertisement-payment-popup.component';
import { EventVenueComponent } from './components/popup/create-briindlet-popup/event-venue/event-venue.component';
import { BriindletProTemplateThreeComponent } from './components/templates/briindlet-pro-template-three/briindlet-pro-template-three.component';
import { BriindProTemplateTwoComponent } from './components/templates/briind-pro-template-two/briind-pro-template-two.component';
import { BriindletProTemplateOneComponent } from './components/templates/briindlet-pro-template-one/briindlet-pro-template-one.component';
import { AddPaymentCardPopupComponent } from './components/popup/add-payment-card-popup/add-payment-card-popup.component';
import { OnlinePeopleSectionComponent } from './components/online-people-section/online-people-section.component';
import { TopHashtagComponent } from './components/top-hashtags/top-hashtags.component';
import { SkeltonLoaderComponent } from './components/skelton-loader/skelton-loader.component';
import { CauseDonatePopupComponent } from './components/popup/cause-donate-popup/cause-donate-popup.component';
import { ActionConfirmComponent } from './components/action-confirm/action-confirm.component';
import { ShareToFriendsPopupComponent } from './components/popup/share-to-friends-popup/share-to-friends-popup.component'
import { AddOrganizationMinistryActivitiesComponent } from './components/ministry-list-item-tab/add-organization-ministry-activities/add-organization-ministry-activities.component';
import { AddOrganizationMinistryLeaderComponent } from './components/ministry-list-item-tab/add-organization-ministry-leader/add-organization-ministry-leader.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InviteUserPopupComponent } from './components/popup/invite-user-popup/invite-user-popup.component';
import { ViewActivityImagesPopupComponent } from './components/popup/view-activity-images-popup/view-activity-images-popup.component';
import { SharePostDetailComponent } from './components/share-post-detail/share-post-detail.component';
import { SharePopupComponent } from './components/popup/share-popup/share-popup.component';
import { WebcamCaptureComponent } from './components/webcam-capture/webcam-capture.component';
import { PhotoSelectionPopupComponent } from './components/popup/photo-selection-popup/photo-selection-popup.component';
import { InstructionalVideoManagementPopupComponent } from './components/popup/instructional-video-management-popup/instructional-video-management-popup.component';
import { PhotoFromFileComponent } from './components/popup/photo-selection-popup/photo-from-file/photo-from-file.component';
import { VolunteerDetailsPopupComponent } from './components/popup/volunteer-details-popup/volunteer-details-popup.component';
import { MembershipRejectReasonComponent } from './components/popup/membership-reject-reason/membership-reject-reason.component';
/* External Libs End Here */


export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'MM/DD/YYYY hh:mm a',
  datePickerInput: 'MM/DD/YYYY',
  timePickerInput: 'hh:mm a',
  monthYearLabel: 'MMM-YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM-YYYY'
};

/* Initializing and Exporting the Translate Loader as Factory */
export function TranslatorFactory(httpClient: HttpClient) { return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json'); }

@NgModule({
  declarations: [
    MainHeaderComponent,
    PopupComponent,
    FooterComponent,
    ChooseYourAccountPopupComponent,
    VolunteerDetailsPopupComponent,
    MembershipRejectReasonComponent,
    TwoFactorAuthCodeComponent,
    AddRoleToMemberPopupComponent,
    DebounceClickDirective,
    MultiSelectDropdownComponent,
    ToasterComponent,
    ProgressCircleComponent,
    PrivacyCheckComponent,
    CustomSelectComponent,
    UserPreviewPopupComponent,
    DatepickerComponent,
    ProfilePreviewComponent,
    WorkEducationPreviewComponent,
    ContactInfoPreviewComponent,
    PlacesLivedPreviewComponent,
    UserInterestCardComponent,
    InterestPreviewComponent,
    ProfileCompletedSuccessPopupComponent,
    OrganizationPreviewPopupComponent,
    AddMilestonePopupComponent,
    MilestoneCardComponent,
    OrganizationMilestonePreviewComponent,
    OrganizationServicesOfferedPreviewComponent,
    OrganizationSocialMediaPreviewComponent,
    OrganizationBasicInfoPreviewComponent,
    LeaderCardComponent,
    AddLeaderPopupComponent,
    OrganizationLeadersPreviewComponent,
    StatusChipComponent,
    BussinessInviteCardComponent,
    FormNavigationComponent,
    MinistryListItemTabComponent,
    CarouselItemDirective,
    UploadPhotoComponent,
    OrganizationNewToUsTemplateComponent,
    TabPanelComponent,
    OrganizationBussinessAboutPreviewComponent,
    AddOrEditMembershipFieldPopupComponent,
    OrganizationMembershipFormPreviewComponent,
    BankAccountCardComponent,
    OrganizationGivePreviewComponent,
    SimpleCarouselComponent,
    SearchPipe,
    CreateAlbumComponent,
    CreateGroupPopupComponent,
    AddOrEditCauseComponent,
    CreatePostCardComponent,
    CreatePostPopupComponent,
    PostPhotosVideosOtherUploadComponent,
    PostCardComponent,
    DateAgoPipe,
    CropImagePopupComponent,
    InviteFriendsPopupComponent,
    ViewPostPopupComponent,
    PostProfileSectionCardComponent,
    PostActionComponent,
    PostCommentComponent,
    PromotionCardComponent,
    PostServicesCardComponent,
    VideoPreviewCardComponent,
    VideoDisplayComponent,
    OrganizationPromotePopupComponent,
    TimelinePhotosSectionComponent,
    TimelineFriendsSectionComponent,
    TrendingOrganizationComponent,
    GeneralCardComponent,
    GroupCoverComponent,
    SearchInputComponent,
    InviteFriendItemComponent,
    TrendingOrganizationCardComponent,
    UserOrganizationHeaderComponent,
    SuggestedFriendsPopupComponent,
    CardActionsComponent,
    EmptyStateComponent,
    ProfileThumbnailComponent,
    ForwardAppilcationDetailPopupComponent,
    RbacCardComponent,
    CreateRbacPopupComponent,
    ShareToGroupPopupComponent,
    ShareToGroupCardComponent,
    PostSharedComponent,
    FriendsCardComponent,
    PostCommentListComponent,
    SetUserDetailsComponent,
    CreateOrganizationMemberGroupPopupComponent,
    FriendsPopupComponent,
    MostLikedSharedPostComponent,
    ConfirmationPopupComponent,
    MembershipEventCardComponent,
    CreateOrganizationEventPopupComponent,
    FullDatePipe,
    TruncatePipe,
    TimePipe,
    UpdateMemberRoleComponent,
    PostCommentReplyListComponent,
    ShowGroupMembersPopupComponent,
    PostGiftListComponent,
    ShowGroupMembersPopupComponent,
    PostTagListComponent,
    CreateBriindletPopupComponent,
    BriindletEventComponent,
    BriindletEventTemplateComponent,
    BriindletEventNameComponent,
    BriindletEventAboutComponent,
    BriindletEventDateComponent,
    BriindletEventLastdateComponent,
    BriindletEventGuestComponent,
    BriindletEventEntranceComponent,
    BriindletEventGuidelineComponent,
    BriindletEventBriindComponent,
    BriindletEventWebsiteComponent,
    BriindletEventBriindletMediaComponent,
    BriindletEventPromoteComponent,
    BriindletEventPromoteHrsComponent,
    BriindletEventPreviewComponent,
    PostTagListComponent,
    ElasticSearchPopupComponent,    
    BriindletRegionCostComponent,
    ElasticSearchPostItemComponent,
    BriindletDontaionCampaignComponent,
    BriindletEventStripeComponent,
    BriindletDontaionCampaignComponent,
    HeaderNotificationComponent,
    BriindletPreviewComponent,
    OrganizationPromoteStripeComponent,
    DefaultTemplateComponent,
    PaymentStatusComponent,
    MinimalDatePipe,
    CustomizeEventTemplateComponent,
    AdvertisementPaymentPopupComponent,
    EventVenueComponent,
    BriindletProTemplateThreeComponent,
    BriindProTemplateTwoComponent,
    BriindletProTemplateOneComponent,
    AddPaymentCardPopupComponent,
    OnlinePeopleSectionComponent,
    TopHashtagComponent,
    SkeltonLoaderComponent,
    LoaderComponent,
    CauseDonatePopupComponent,
    ActionConfirmComponent,
    AddOrganizationMinistryActivitiesComponent,
    AddOrganizationMinistryLeaderComponent,
    ShareToFriendsPopupComponent,
    InviteUserPopupComponent,
    ViewActivityImagesPopupComponent,
    SharePostDetailComponent,
    SharePopupComponent,
    SafeUrlPipe,
    WebcamCaptureComponent,
    PhotoSelectionPopupComponent,
    InstructionalVideoManagementPopupComponent,
    PhotoFromFileComponent,
],
  imports: [
    NgApexchartsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageCropperModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslatorFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
    PickerModule,
    NgxStripeModule.forRoot(config.stripeKey),
  ],
  exports: [
    NgApexchartsModule,
    TranslateModule,
    MainHeaderComponent,
    FooterComponent,
    PopupComponent,
    FormsModule,
    ReactiveFormsModule,
    TwoFactorAuthCodeComponent,
    DebounceClickDirective,
    MultiSelectDropdownComponent,
    ToasterComponent,
    ProgressCircleComponent,
    PrivacyCheckComponent,
    CustomSelectComponent,
    DatepickerComponent,
    UserInterestCardComponent,
    ProfilePreviewComponent,
    WorkEducationPreviewComponent,
    ContactInfoPreviewComponent,
    PlacesLivedPreviewComponent,
    InterestPreviewComponent,
    MilestoneCardComponent,
    LeaderCardComponent,
    OrganizationMilestonePreviewComponent,
    OrganizationServicesOfferedPreviewComponent,
    OrganizationSocialMediaPreviewComponent,
    OrganizationBasicInfoPreviewComponent,
    OrganizationLeadersPreviewComponent,
    StatusChipComponent,
    BussinessInviteCardComponent,
    FormNavigationComponent,
    MinistryListItemTabComponent,
    CarouselItemDirective,
    UploadPhotoComponent,
    OrganizationNewToUsTemplateComponent,
    TabPanelComponent,
    OrganizationBussinessAboutPreviewComponent,
    DragDropModule,
    OrganizationMembershipFormPreviewComponent,
    BankAccountCardComponent,
    OrganizationGivePreviewComponent,
    SimpleCarouselComponent,
    SearchPipe,
    SafeUrlPipe,
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AddOrEditCauseComponent,
    CreatePostCardComponent,
    PostCardComponent,
    OwlNativeDateTimeModule,
    AddOrEditCauseComponent,
    CreatePostCardComponent,
    PostCardComponent,
    DateAgoPipe,
    CropImagePopupComponent,
    PostProfileSectionCardComponent,
    PostActionComponent,
    PostCommentComponent,
    PromotionCardComponent,
    PostServicesCardComponent,
    VideoPreviewCardComponent,
    VideoDisplayComponent,
    OrganizationPromotePopupComponent,
    TimelinePhotosSectionComponent,
    TimelineFriendsSectionComponent,
    TrendingOrganizationComponent,
    GeneralCardComponent,
    GroupCoverComponent,
    SearchInputComponent,
    InviteFriendItemComponent,
    TrendingOrganizationCardComponent,
    UserOrganizationHeaderComponent,
    TrendingOrganizationComponent,
    CardActionsComponent,
    EmptyStateComponent,
    ProfileThumbnailComponent,
    RbacCardComponent,
    FriendsCardComponent,
    SetUserDetailsComponent,
    MostLikedSharedPostComponent,
    MembershipEventCardComponent,
    PaymentStatusComponent,
    FullDatePipe,
    TruncatePipe,
    PickerModule,
    TimePipe,
    DefaultTemplateComponent,
    MinimalDatePipe,
    BriindletProTemplateThreeComponent,
    BriindProTemplateTwoComponent,
    BriindletProTemplateOneComponent,
    AddPaymentCardPopupComponent,
    OnlinePeopleSectionComponent,
    TopHashtagComponent,
    SkeltonLoaderComponent,
    LoaderComponent,
    ActionConfirmComponent,
    ViewPostPopupComponent,
    AddOrganizationMinistryLeaderComponent,
    AddOrganizationMinistryActivitiesComponent,
    SharePostDetailComponent,
    WebcamCaptureComponent,
    InstructionalVideoManagementPopupComponent,
    PhotoFromFileComponent
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ]
})
export class SharedModule {
  constructor(public translateService: TranslateService) {
    translateService.setDefaultLang('en'); /* Setting up the Translate Json to English - `en` */
  }
}
