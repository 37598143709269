import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectListModel } from '../../../../core/models/form.model';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit, OnChanges {
  @Input() formGrp: FormGroup | any;
  @Input() isShowCustomValue: boolean  = true;
  @Input() dropDownStatus: any = '';
  @Input() formCntrl: any;
  @Input() data: SelectListModel[] = [];
  @Input() placeholder: string = '';
  @Input() icon: string = '';
  @Input() isSearchEnabled: boolean = false;
  @Input() selectedData: any;
  @Output() search = new EventEmitter();
  @Output() selectedItemFromDropdown = new EventEmitter();
  @Input() isPosAbsolute: boolean = true;
  isShowList: boolean = false;
  searchInput: string = '';
  isClickedFrom: any =  '';
  @ViewChild('main') main: ElementRef | any;
  isInvalid: boolean = false;
  selectedItem: any;
  constructor() { }

  /**
   * @description
   * this method is using for get controls of the form 
   *
   * @readonly
   * @memberof CustomSelectComponent
   */
  get control() { return this.formGrp.controls; }

  /**
   * @description
   * Host listner for out side click close
   *
   * @param {*} event
   * @memberof CustomSelectComponent
   */
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event ?: any): void {
    if (((this.main && !this.main.nativeElement.contains(event.target)))) {
      this.isShowList = false;
      this.checkFormInvalid();
    }
  }

  /**
   * @description
   * method is used for show / hide select list
   *
   * @memberof CustomSelectComponent
   */
  showList() {
    this.isShowList = !this.isShowList;
    this.isClickedFrom = 'item';
    this.checkFormInvalid();
  }

  /**
   * @description
   * this method is used for checking the form valid or not
   *
   * @memberof CustomSelectComponent
   */
  checkFormInvalid() {
    this.isInvalid = false;
    if (this.control[this.formCntrl].invalid) {
      this.isInvalid = true;
    }
  }

  /**
   * @description
   * this method is used for selcting the Item and updating to the form
   *
   * @param {string} [itemID]
   * @memberof CustomSelectComponent
   */
  selectItem(item ?: any) {
    let value = item.id;
    if ( this.data) {
      for (const item of this.data) {
        if (item.name == value) {
          value = item.id;
        }
      };
      this.selectedItemFromDropdown.emit(item);
      this.formGrp.patchValue({
        [this.formCntrl]: value
      });
      this.searchInput = item.name;
      this.isShowList = false;
      this.checkFormInvalid();
      if(this.searchInput) {
        this.isClickedFrom = "";
      }
    }
    
  }

  /**
   * @description
   * method is used for calling the search function
   *
   * @memberof CustomSelectComponent
   */
  searchItem() {
    this.formGrp.patchValue({
      [this.formCntrl]: ''
    });
    this.search.emit(this.searchInput);
    this.isShowList = true;
  }

  /**
   * @description
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   * Add '${implements OnChanges}' to the class.
   *
   * @param {SimpleChanges} changes
   * @memberof CustomSelectComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.selectedData && changes.selectedData.currentValue) {
      this.setValueInInput();
    }
    if(this.dropDownStatus ) {
      this.searchInput = '';
      this.dropDownStatus = '';
    }
    // if(!this.data?.length){
    //   this.selectItem({id: this.searchInput, name: this.searchInput})
    // }
  }


  /**
   * @description
   * this method is used for setting the input value
   *
   * @memberof CustomSelectComponent
   */
  setValueInInput() {
    let flag = 0;
    for (const item of this.data) {
      if(item.id == this.control[this.formCntrl].value || item.id == this.selectedData.id) {
        this.selectedItem = item;
        this.searchInput = item.name;
        flag = 1;
        break;
      }
    }
    if (flag == 0) {
      this.selectedItem = this.selectedData;
      this.searchInput = this.selectedData.name;
    }
  }


  ngOnInit(): void {
    // this.searchItem()
  }

}
