import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { config } from 'src/environments/environment';
import { BriindletStoreService } from '../store/briindlet-store.service';

@Injectable({
  providedIn: 'root'
})
export class BriindletService {

  constructor(
    public http: HttpClient,
    private briindletStoreService: BriindletStoreService
  ) { }

  /**
   * @description
   * this method is used when click on create briindlet
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof BriindletService
   */
   createBriindlet(data?: any, type?: string) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${type == 'user' ? config.usersURI : config.organizationsURI}${config.briindletsURI}`, data)
      .pipe(map(response => this.briindletStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used when click on create briindlet
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof BriindletService
   */
   searchLocation(data?: any, type?: string) {
    return this.http.get(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${data}&key=AIzaSyCnXSvIOy0LvL8Rr4L5vDo9-hHeiFa5P9U`)
      .pipe(map(response => this.briindletStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting Most Like Post List
   *
   * @param {string} type
   * @returns this api will return a observalble
   * @memberof BriindletService
   */
   getBriindletList(type?: string) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type == 'user' ? config.usersURI : config.organizationsURI}${config.briindletsURI}`)
      .pipe(map(response => this.briindletStoreService.apiResp = response));
  }

  /**
   * @description 
   * This method is used for getting venue
   *
   * @param {string} param
   * @returns this api will return a observalble
   * @memberof BriindletService
   */
   getVenue(param?: any) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${config.briindletURI}${config.venueURI}${config.searchURI}`, { params: param })
    .pipe(map(response => this.briindletStoreService.apiResp = response));
   }

   /**
   * @description 
   * This method is used for getting Most Like Post List
   *
   * @param {string} type
   * @returns this api will return a observalble
   * @memberof BriindletService
   */
   getBriindletDetail(id?: number, type?: string) {
    return this.http.get(`${config.baseApiURL}${config.apiURI}${type == 'user' ? config.usersURI : config.organizationsURI}${config.briindletsURI}${id}/${config.detailsURI}`)
      .pipe(map(response => this.briindletStoreService.apiResp = response));
  }

  /**
   * @description
   * this method is used when click on create briindlet
   *
   * @param {*} [data]
   * @returns - this api will return a observalble
   * @memberof BriindletService
   */
   cancelBriindlet(data?: any, type?: string) {
    return this.http.post(`${config.baseApiURL}${config.apiURI}${type == 'user' ? config.usersURI : config.organizationsURI}${config.briindletsURI}${config.cancelBriindletURI}/`, data)
      .pipe(map(response => this.briindletStoreService.apiResp = response));
  }
}
