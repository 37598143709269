import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../../core/services/app.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  @ViewChild('mobileNav') mobileNav: ElementRef | any;

  constructor(
    private appService: AppService
  ) { }

  /**
   * @description
   * This method is used for toggle the mobile menu
   *
   * @memberof MainHeaderComponent
   */
  mobileNavToggleCTA() {
    let mobileNative = this.mobileNav.nativeElement.classList;
    mobileNative.contains('mobile-nav-container') ? mobileNative.remove('mobile-nav-container') : mobileNative.add('mobile-nav-container');
  }

  /**
   * @description
   * Method is used for toggling the Choose your account popup 
   *
   * @memberof MainHeaderComponent
   */
  signInCTA() {
    if (this.mobileNav.nativeElement.classList.contains('mobile-nav-container')) {
      this.mobileNav.nativeElement.classList.remove('mobile-nav-container')
    }
    this.appService.togglePopup({
      type: 'chooseAccount',
      load: true,
      refreshParent: false,
      overlayClose: false,
    });
  }

  
/**
 * @description
 * this method is using for checking the scroll position of header
 *
 * @param {*} [e]
 * @memberof MainHeaderComponent
 */
@HostListener('window:scroll', ['$event'])
	onWindowScroll(e ?: any) {
    let element = document.querySelector('#navbarTop') as HTMLElement;
		if (window.pageYOffset > element.clientHeight) {
			element.classList.add('navbarFixed');
		} else {
			element.classList.remove('navbarFixed');
		}
  }
  
  /**
   * @description
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   * Add 'implements OnInit' to the class.
   * @memberof MainHeaderComponent
   */
  ngOnInit(): void {
    
  }

}
