import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-most-liked-shared-post',
  templateUrl: './most-liked-shared-post.component.html',
  styleUrls: ['./most-liked-shared-post.component.scss']
})
export class MostLikedSharedPostComponent implements OnInit {
  @Output() viewPost = new EventEmitter();
  @Input('data') data: any;
  @Input('type') type: string = '';
  @Input('postType') postType: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  /**
   *  @description
   * this method is used for emiting the card action
   *
   * @memberof MostLikedSharedPostComponent
   */
  view() {
    this.viewPost.emit(this.data);
  }
}
