import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PopupModel } from 'src/app/core/models';

@Component({
  selector: 'app-view-activity-images-popup',
  templateUrl: './view-activity-images-popup.component.html',
  styleUrls: ['./view-activity-images-popup.component.scss']
})
export class ViewActivityImagesPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  selectedIndex: number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  plusSlides(index: any) {
    if (this.selectedIndex == 0 && index == -1) {
      this.selectedIndex = this.popup.context.images.length - 1;
    } else if (this.selectedIndex == (this.popup.context.images.length - 1) && index == +1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex = this.selectedIndex + index;
    }
  }
  /**
   * @description
   * this method is used for closing the popup
   *
   * @memberof CreateRbacPopupComponent
   */
   closePopup() {
		this.close.emit('icon');
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.popup && changes.popup.currentValue) {
      this.selectedIndex = this.popup.context?.index || 0
    }
  }

}
