import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-confirm',
  templateUrl: './action-confirm.component.html',
  styleUrls: ['./action-confirm.component.scss']
})
export class ActionConfirmComponent implements OnInit {
  @Input() description: string = 'are you sure want to delete';
  @Input() title: string = 'confirm';
  @Output() close = new EventEmitter();
  constructor(
  ) { }

  closePopup(action ?: any) {
    this.close.emit(action);
  }

  ngOnInit(): void {
  }

}
