import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-create-post-card',
  templateUrl: './create-post-card.component.html',
  styleUrls: ['./create-post-card.component.scss']
})
export class CreatePostCardComponent implements OnInit {
  @Input('userType') userType: string = 'user';
  @Input('userName') userName: string = '';
  @Input('firstName') firstName: string = '';
  @Input('userData') userData: any;
  @Input('postType') postType: string  = 'Post';
  @Input('postToType') postToType: string  = '';
  @Input('postToId')  postToId: string  = '';
  constructor(
    private appService: AppService
  ) { }

 

  /**
   * @description
   * this method is used for open the create post
   *
   * @param {*} [file]
   * @memberof CreatePostCardComponent
   */
  openCreatePostPopup(file ?: any) {
    this.appService.togglePopup({
      type: 'createPost',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        userType: this.userType,
        userData: this.userData,
        uploadedFiles: (file && file.type != 'giff' && file.type != 'emoji'  && file.type != 'tag')   ? [file]: [],
        postType: this.postType,
        postToType: this.postToType,
        postToId: this.postToId,
        fileType: file ? file.type : '',
        emoji: file ? file.value : ''
      }
    });
  }

  ngOnInit(): void {
  }

}
