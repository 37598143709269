<section id="reject-reason" class="bdrRadius6px bg-white pad20 posRelative custom-scrollbar organization">
    <span class="close-popup ripple bg-F1F4FE bdrRadius4px" (click)="closePopup();">×</span>
    <div class="width100">
        <div class="poppins-semibold font22 timeline-label padbtm20 txtCenter ">
            {{'Membership Reject Reason' | translate}}
        </div>
       <textarea [(ngModel)]="reason" cols="3" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular bdr-1-EEEEEE bg-white outline0  bdrRadius6px" placeholder="Enter reason..."></textarea>

    <button [disabled]="!reason" (click)="rejectApplication()"
        class="btn-get-started minWidth90px mrgnright10 mrgntop10 bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad1030 btn-user-default">
        {{'submit' | translate}}
    </button>
    </div>
</section>