import { AppService } from './../../../../core/services/app.service';
import { OrganizationService } from 'src/app/organizations/service/organization.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { getImagePath } from 'src/app/core/essentials/common.libs';
import { CameraService } from 'src/app/core/services/camera.service';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-add-organization-ministry-activities',
  templateUrl: './add-organization-ministry-activities.component.html',
  styleUrls: ['./add-organization-ministry-activities.component.scss']
})
export class AddOrganizationMinistryActivitiesComponent implements OnInit {
  selectedFiles: any = [];
  removedFiles: any = [];
  imageChangedEvent: any;
  showCropper: boolean =false;
  imageType: string = "";
  @Input('ministryID') ministryID: any = '';
  @Input('activityList') activityList: any = []
  displayImages: any = [];
  @Input('title') title: string = 'add activities' 
  @Output('addedActivities') addedActivities = new EventEmitter();
  formGrp: FormGroup = new FormGroup({})
  isDisable: boolean = false;
  isShowConfirm: boolean = false;
  selectedActivityForRemove: number = 0;
  isWeb = true
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private appService: AppService,
    private cameraService: CameraService
  ) { 
    this.formGrp = fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      date: ['', Validators.required]
    }) 
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') this.isWeb = false

  }

  // uploadImage(event ?: any) {
  //   if (event.target && event.target.files &&  event.target.files.length != 0) {
  //     this.selectedFiles.push(event.target.files[0]);
  //     const img = getImagePath(event,'');
  //     if (img) {
  //       img.onload = (e:any) => {
  //         this.displayImages.push(e.target.result);
  //       }
  //     }
  //     this.selectedImages.emit(this.selectedFiles);
  //   }
  // }


  /**
   * TODO: comment updatePhoto
   * @description Updates photo
   * @param event 
   * @param type 
   * @returns  
   * @memberof AddOrganizationMinistryActivitiesComponent
   */
  async updatePhoto(event : any, type : string) {
    if(!this.isWeb) {
      this.imageChangedEvent = await this.cameraService.getPhoto();
      this.showCropper =true;
      this.imageType = type
    }
    if(this.isWeb) {
      if (event.target.files.length > 0) {
        this.imageChangedEvent = event;
        this.showCropper =true;
        this.imageType = type;
        return;
      }      
    }
  }

  removeActivity(id: any) {

    this.isShowConfirm = true;
    this.selectedActivityForRemove = id;
  }

  showImagePopup(photos: any, index: number) {
    this.appService.togglePopup({
      type: 'viewActivityImages',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        index: index,
        images: photos
      }
    });
  }

  confirmCTA(event: any) {
    if (this.selectedActivityForRemove != 0 && event) {
      this.organizationService.removeActivity(this.selectedActivityForRemove).subscribe(response => {
      this.activityList = this.activityList.filter((x: any) => x.id != this.selectedActivityForRemove);
      this.addedActivities.emit(this.activityList)
      this.appService.toggleToast({
        type: 'success',
        load: true,
        position: 'bottom-left',
        context: { description: `Activity deleted successfully` }
      });
      this.isShowConfirm = false;
    })
    }
  }

  onSubmit() {
    this.isDisable = true;
    let formData = new FormData();
    formData.append(`title`, this.formGrp.value['title'])
    //console.log(this.ministryID)
    if (this.ministryID) {
      formData.append(`ministry`, this.ministryID)
    }
    formData.append(`description`, this.formGrp.value['description'])
    formData.append(`date`,  this.appService.formatDateForApi(this.formGrp.value['date']))
    if (this.selectedFiles?.length) {
      for (let index = 0; index < this.selectedFiles.length; index++) {
        const element = this.selectedFiles[index];
        formData.append(`photos[${index}][photo]`, element)
      }
    }
    this.organizationService.createMinistryActivity(formData).subscribe((response: any) => {
      this.activityList.push(response.result)
      this.isDisable = false;
      this.addedActivities.emit(this.activityList)
      this.resetForm();
    }, er => {
      this.isDisable = false;
    })
    
  }

  resetForm() {
    this.formGrp.reset();
    this.selectedFiles = [];
    this.displayImages = []
  }



  /**
   * TODO: comment uploadImage
   * @description Uploads image
   * @param [event] 
   * @memberof AddOrganizationMinistryActivitiesComponent
   */
  uploadImage(event?: any) {
    var reader = new FileReader();
    this.selectedFiles.push(event);

    reader.onload = (ev: any) => {
      this.displayImages.push(ev.target.result);
    }
    reader.readAsDataURL(event);
    // this.selectedImages.emit(this.selectedFiles);
    this.showCropper = false;
    // getSingleImagePath(event,'ministries-cover-photo');

  }

  /**
   * @description
   * Remove image from array
   *
   * @param {number} index
   * @memberof AddOrganizationMinistryActivitiesComponent
   */
  removeImage(index : number) {
    // this.removedFiles.push(this.displayImages[index].id);
    this.displayImages.splice(index,1)
    this.selectedFiles.splice(index,1);
   
    // this.selectedImages.emit(this.selectedFiles);
    // this.removedImages.emit(this.removedFiles);
  }

  ngOnInit(): void {
  }

}
