<div class="{{appStore?.userType}}" [formGroup]="formGrp">
    <div class="timeline-label header txtCenter font23 poppins-medium fontWeight600 color-122A4D mrgnbtm20">
        {{'add briind id' | translate}}
    </div>
    <div class="body width100" formGroupName="step_11">
        <div class="width100 flex flexResponsive">
            <div class="width100">
                <input placeholder="{{'enter here' | translate}}" type="text" class="font16 width100 boxShadow-RGBA00005 pad13 poppins-regular 
                  bdr-1-EEEEEE bg-white outline0 bdrRadius6px" appDebounce (debounceClick)="searchBriindId()"
                    formControlName="briind_id">
            </div>
            <input type="hidden" formControlName="organization_link">
        </div>
        <strong class="color-red poppins-regular font13 mrgntop5 txtLeft"
            *ngIf="isError">{{'organization not found' | translate}}
        </strong>

        <div class="width100 padtop30 flex">
            <div class="pad5"></div>
            <button (click)="actionCTA('previous')"
                class="cancel-btn bdr-1-EEEEEE pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                {{'back' | translate}}
            </button>
            <div class="pad5"></div>
            <button [disabled]="formGrp?.controls?.step_11?.invalid || isError" (click)="actionCTA('continue')"
                class="btn-user-default pad1020 btn-get-started  ripple font16 poppins-medium cursorPtr mob-font-bttn">
                {{'continue' | translate}}
            </button>
        </div>
    </div>
</div>