<div class="bdr-1-EEEEEE boxShadow-RGBA00005 padleft15 padtop15 padbtm15 bg-FFFFFF bdrRadius6px {{userType}}">
    <div class="flex  flexJustifyBetween flexAlignItemsCenter padtop10 padbtm10 padright15">
        <div class="poppins-semibold font20 timeline-label">{{ userType == "organization" ? 'followers' : 'friends' | translate}} <span
                class="color-8E8E8E font16">( {{ data.length }} {{ userType == "organization" ? 'followers' : 'friends' | translate}})</span></div>
        <button type="button" *ngIf="data.length>4"
            class="btn-get-started btn-user-default minmaxWidth100ppx bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad5 padleft15 padright15"
            [routerLink]="userType == 'organization' ? ['/organizations',userID,'followers','list'] : ['/users',userID,'friends','list']">{{'see all' | translate}}</button>
    </div>
    <div class="flex flexJustifyBetween flexWrap padright5" >
        <div class="flex1 flexBasis33 padbtm10 padright10 maxWidth50p" *ngFor="let user of data; let i = index;">
            <div class="bg-FFFFFF bdrRadius6px bdr-1-EEEEEE boxShadow-RGBA00005 pad5" *ngIf="i < 5">
                <div class="cover-profile-image posRelative flex flexJustifyCenter">
                    <span class="online-img-shadow bdrRadius50p posRelative displayFlex">
                      <img [src]="user?.targeted_user?.photo || user?.follow_by?.profile_pic"  class="d150 bdrRadius50p">
                    </span>
                  </div>
                <!-- <img [src]="user?.targeted_user?.photo || user?.follow_by?.profile_pic" class="width100 minHeight100px maxHeight100px" /> -->
                <div class="poppins-medium font14 timeline-label padtop5">{{ user?.targeted_user?.name || user?.follow_by?.name }}</div>
            </div>
        </div>
    </div>

</div>

<!-- <div class="bdr-1-EEEEEE boxShadow-RGBA00005 padleft15 padtop15 padbtm15 bg-FFFFFF bdrRadius6px {{userType}}">
    <div class="flex  flexJustifyBetween flexAlignItemsCenter padtop10 padbtm10 padright15">
        <div class="poppins-semibold font20 timeline-label">{{'friends' | translate}} <span
                class="color-8E8E8E font16">(150 Friends)</span></div>
        <button type="button"
            class="btn-get-started btn-user-default minmaxWidth100ppx bdr-1-EEEEEE ripple cursorPtr font14 poppins-regular pad5 padleft15 padright15">{{'see all' | translate}}</button>
    </div>
    <div class="flex flexJustifyBetween flexWrap padright5">
        <div class="flex1 flexBasis33 padbtm10 padright10 maxWidth50p">
            <div class="bg-FFFFFF bdrRadius6px bdr-1-EEEEEE boxShadow-RGBA00005 pad5">
                <img src="assets/images/or-photo.png" class="width100 minHeight100px maxHeight100px" />
                <div class="poppins-medium font14 timeline-label padtop5">Rachel Peter</div>
            </div>
        </div>
        
        <div class="flex1 flexBasis33 padbtm10 padright10 maxWidth50p">
            <div class="bg-FFFFFF bdrRadius6px bdr-1-EEEEEE boxShadow-RGBA00005 pad5">
                <img src="assets/images/or-photo.png" class="width100 minHeight100px maxHeight100px" />
                <div class="poppins-medium font14 timeline-label padtop5">Rachel Peter</div>
            </div>
        </div> 
        <div class="flex1 flexBasis33 padbtm10 padright10 maxWidth50p">
                <div class="bg-FFFFFF bdrRadius6px bdr-1-EEEEEE boxShadow-RGBA00005 pad5">
                    <img src="assets/images/or-photo.png" class="width100 minHeight100px maxHeight100px" />
                    <div class="poppins-medium font14 timeline-label padtop5">Rachel Peter</div>
                </div>
        </div>
       
    </div>
</div> -->