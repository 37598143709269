import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-friends-card',
  templateUrl: './friends-card.component.html',
  styleUrls: ['./friends-card.component.scss']
})
export class FriendsCardComponent implements OnInit {
  @Input() data: any;
  @Input() userType: string = 'user';
  @Input() type: string = 'friend';
  @Input() actions: any = [];
  @Output() cardActionCTA = new EventEmitter();
  constructor(
    private router: Router
  ) { }

  /**
   * @description
   * this method is used for emitting the card actions
   * @param {*} event
   * @param {number} id
   * @param {string} notification_id
   * @memberof FriendsCardComponent
   */
  cardActions(action_text ?:any , id ?: number, notification_id ?: string) {
    this.cardActionCTA.emit({'action_text': action_text, 'id': id || this.data.id,'briindId': this.data?.targeted_user?.briind_id, 'is_sent': this.data?.is_sent, 'notification_id': notification_id})
  }

  navigateToProfile(type: string) {
    if (type == 'Organization') {
      this.router.navigate(['/organizations',this.data?.briind_id || this.data?.id,'profile','bussiness','new_to_us'])

    } else {
      this.router.navigate(['/users',this.data?.targeted_user?.briind_id || this.data?.send_user?.briind_id || this.data?.briind_id || this.data?.id,'timeline'])

    }
  }

  ngOnInit(): void {
  }

}
