<div class="photoOption flex flexAlignItemsCenter flexDirectionCol cursorPtr posRelative">
    <div class="img">
        <input type="file" class="posAbsolute ripple posTop posRight posLeft posRight opacity0 width100 height100p"
            (change)="updatePhoto($event)" accept="image/*" *ngIf="isWeb">
        <input type="button" class="posAbsolute ripple posTop posRight posLeft posRight opacity0 width100 height100p"
            (click)="updatePhoto($event)" accept="image/*" *ngIf="!isWeb">
        <i class="icns icon-photo-select d40"></i>

    </div>
    <!-- <div class="title">
        Upload from files
    </div> -->
</div>
<app-crop-image-popup (close)="showCropper = false" (uploadImageEvent)="uploadImage($event)" [imageType]="imageType"
    [imageChangedEvent]="imageChangedEvent" *ngIf="showCropper"></app-crop-image-popup>